import Screen from "../screen";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";

import Heading from "../../components/heading";
import {
    BodyText,
    ButtonText,
    ParagraphText,
} from "../../components/styled_text";
import HeaderButton from "../../components/buttons/header_button";
import { useDatabaseStore } from "../../state/database_store";
import BasicMap from "../../components/maps/basic_map";
import { Column, FlexRow, Row } from "../../components/styled_layout";
import { CardContainer } from "../../components/styled_container";
import { Property, PropertyType, Report } from "../../models/property_models";
import MainButton from "../../components/buttons/main_button";
import getPropertyLayerInformation from "../../components/maps/layer_config/property_layer";
import LoadingPlaceholder from "../../components/loading_placeholder";
import EmptyPlaceholder from "../../components/empty_placeholder";
import { ruminatiColors } from "../../utilities/colors";
import { getLatestCompletedReport, getReportOfYear, getReportsOfProperty } from "../../utilities/reports";
import { FarmPageContainer, FarmPageWrapper } from "../wrapper/farm_wrapper";
import {
    getCurrentFinancialYear,
} from "../../utilities/dates";
import { useAuthStore } from "../../state/auth_store";
import { SubscriptionType } from "../../models/subscription_model";
import PageHeader from "../../components/headers/page_header";
import { getProperty } from '../../services/property_service'
import { ReductionPlannerCard } from "./reduction_planner_card";
import { FarmInfoText } from "./farm_info_text";
import { TotalEmissionCard } from "./total_emission_card";
import { IntensityCard } from "./intensity_card";
import { EmptyEmissionReportCard } from "./empty_emission_report_card";
import { usePopupStore } from "../../state/popup_store";
import { getEnterpriseTypeTag } from "../../components/enterprise_type_tag";
import { CountryCode } from "../../utilities/countries";
import ReportSummaryCard from "../../components/cards/report_summary";
import Icon from "../../components/icon";
import OutlineButton from "../../components/buttons/outline_button";
import { NextStep, getPropertyNextStep } from "../../utilities/next_steps";
import { HelpDrawerAndTab, HelpDrawerContentWrapper, HelpDrawerExplanatoryContentContainer, HelpDrawerExplanatoryContentText, HelpDrawerExplanatoryContentTitle, HelpDrawerVideoContainer } from "@/components/HelpDrawer";
import { Accordion, AccordionItem, AccordionItemContentText } from "@/components/Accordion";

export enum FarmReportsPopupId {
    BasePlanReportLimit = 'base-plan-report-limit',
    DeleteOrArchiveReport = 'delete-report'
}

/**
 * The Farm screen for seeing the details of a particular farm
 * @returns the Farm Screen component
 */
export default function FarmScreen() {
    const navigate = useNavigate();
    const { user } = useAuthStore();
    const databaseStore = useDatabaseStore();
    const popupStore = usePopupStore();
    const { propertyId } = useParams();

    const property: Property | undefined = databaseStore.properties?.find(
        (p) => p.id === propertyId
    );

    const allReports: Report[] | undefined = databaseStore.reports ?? []
    const reductionPlans = databaseStore.reductionPlans![propertyId ?? '']

    const reports: Report[] = getReportsOfProperty(allReports, propertyId ?? '').sort((r1, r2) => r2.financialYear - r1.financialYear);

    const latestCompletedReport: Report | undefined = getLatestCompletedReport(
        reports ? reports : []
    );

    const isPrime =
        user?.subscription?.type === SubscriptionType.PRIME;

    const isNzUser = user?.country === CountryCode.NewZealand;
    
    const nextStep: NextStep | undefined = property ? getPropertyNextStep(isPrime, reports, reductionPlans, property) : undefined

    const propertyLayerInfo = getPropertyLayerInformation({
        gj: property?.geom,
        hidePropertyFill: true,
        lineWidth: 2
    });

    function goToCreateReportPage(year?: number) {
        if (!isPrime && reports?.length === 1) return popupStore.addPopup(FarmReportsPopupId.BasePlanReportLimit)

        if (year) navigate(`/farm/${propertyId}/create-report?year=${year}`);
        else navigate(`/farm/${propertyId}/create-report/`);
    }

    async function goToReductionPlannerPage() {
        // Do a quick double check if we don't yet have soil carbon data
        // as it's produced in the background during property creation
        if (property?.soilCarbonData === null) {
            const newProperty = await getProperty(propertyId as string)
            if (newProperty && newProperty.soilCarbonData) databaseStore.updateProperty(newProperty)
        }
        navigate(`/farm/${propertyId}/reductionPlanner/`);
    }

    const previousFY = getCurrentFinancialYear() - 1;
    const previousYearReport = getReportOfYear(reports ?? [], previousFY);

    return (
        <Screen pageTitle="Enterprise Overview">
            {property ? (
                <FarmPageWrapper>
                    <PageHeader>
                        <HeaderButton
                            icon="upArrow"
                            iconPos="left"
                            onClick={() => navigate(`/farms`)}
                        >
                            Back to Enterprises
                        </HeaderButton>
                    </PageHeader>
                    <FarmPageContainer>
                        <Row style={{ width: "100%" }}>
                            <Column style={{ flexGrow: '1' }}>
                                <Row style={{ width: "100%", justifyContent: 'flex-start' }}>
                                    <Column style={{width: '54px', borderRadius: '8px', overflow: 'hidden', marginRight: '12px', pointerEvents: "none",}}>
                                        <BasicMap
                                            height="54px"
                                            layers={[
                                                propertyLayerInfo.layer,
                                            ]}
                                            attributionControl={false}
                                            bounds={propertyLayerInfo.bounds}
                                        />
                                    </Column>
                                    <Column>
                                        <Heading level={3} textAlign="start">{property.name}</Heading>
                                    </Column>
                                    <Column>
                                        {getEnterpriseTypeTag(property, "small")}
                                    </Column>
                                </Row>
                            </Column>

                            <Column style={{ width: "30%", alignItems: "end" }}>
                                <FlexRow
                                    style={{
                                        width: "100%",
                                        justifyContent: "end",
                                    }}
                                >
                                    <FarmInfoText
                                        name="PIC"
                                        value={property.pic}
                                        hidden={isNzUser}
                                    />

                                    {property.propertyType === PropertyType.Farm && <FarmInfoText
                                        name="NGR"
                                        value={property.ngr}
                                        hidden={isNzUser}
                                    />}
                                </FlexRow>
                            </Column>

                            <Column style={{ alignItems: "end", paddingLeft: '16px' }}>
                                <OutlineButton 
                                width='70px'
                                height='36px'
                                fontSize='14px'
                                activeColor={ruminatiColors.dark_green}
                                onClick={() => navigate(`/farm/${propertyId}/edit/`)}>
                                    Edit
                                </OutlineButton>
                            </Column>
                        </Row>
                        
                        {/* Next Step Row */}
                        {
                            nextStep ? <Row
                            id="propertyNextSteps"
                            style={{ 
                              width: "100%",
                              justifyContent: 'flex-start',
                              marginTop: '24px',
                              borderRadius: '24px',
                              backgroundColor: ruminatiColors.green_3_5,
                              overflow: 'hidden'
                            }}
                          >
                              <Column
                                  style={{
                                      height: '100%',
                                      alignItems: 'flex-start',
                                   }}
                              >
                                  <Row>
                                      <BodyText 
                                          style={{
                                              padding: '12px 12px 12px 18px',
                                              backgroundColor: ruminatiColors.green_3_5,
                                              lineHeight: '24px'
                                          }}
                                      >
                                          Next Steps
                                      </BodyText>
                                      {/* Triangle Element */}
                                      <div style={{
                                          width: '0',
                                          height: '0',
                                          borderTop: '24px solid transparent',
                                          borderBottom: '24px solid transparent',
                                          borderLeft: `24px solid ${ruminatiColors.green_3_5}`
                                      }}/>
                                 </Row>
  
                              </Column>
                              <Column
                                  style={{
                                      flexGrow: "1",
                                      alignItems: 'flex-start',
                                      paddingLeft: '16px'
                                  }}
                              >
                                  <BodyText>
                                      {nextStep.text}
                                  </BodyText>
                              </Column>
                              <Column 
                                  style={{
                                    alignItems: 'flex-end',
                                    paddingRight: '24px'
                                  }}
                              >
                                  <ButtonText 
                                      style={{
                                          color: ruminatiColors.green_3,
                                          textDecoration: 'underline'
                                      }}
                                      onClick={() => {
                                          if (nextStep && nextStep.actionLink) navigate(nextStep.actionLink)
                                      }}
                                  >
                                      {nextStep.actionText}
                                  </ButtonText>
                              </Column>
                            </Row> : undefined
                        }

                        {/* Total Emission + Intensity Cards  */}
                        <Row style={{
                            gap: '32px',
                            marginTop: '24px',
                            alignItems: 'stretch'
                        }}>
                            <TotalEmissionCard
                              report={latestCompletedReport}
                            />
                            <IntensityCard
                                subscribed={isPrime}
                                property={property}
                                reports={reports}
                                report={latestCompletedReport}
                            />
                        </Row>

                        {/* Reduction Planner Card */}
                        {(property.propertyType === PropertyType.Farm || property.propertyType === PropertyType.Dairy || property.propertyType === PropertyType.Feedlot) && latestCompletedReport &&
                            <Row style={{ width: "100%", marginTop: "24px" }}>
                                <ReductionPlannerCard
                                    manageButtonOnClick={goToReductionPlannerPage}
                                    property={property}
                                    subscribed={isPrime}
                                    reports={reports}
                                    reductionPlans={reductionPlans!}
                                    year={getCurrentFinancialYear()}
                                />
                            </Row>
                        }

                        {/* Emissions Reports  */}
                        <Row
                            style={{
                                marginBottom: "32px",
                                marginTop: "32px"
                            }}
                        >
                            <Column
                                style={{ width: "40%", alignItems: "start" }}
                            >
                                <Heading level={3}>Emissions Reports</Heading>
                            </Column>
                            <Column style={{ width: "60%", alignItems: "end" }}>
                                <MainButton
                                    colorScheme="green"
                                    onClick={() => goToCreateReportPage()}
                                >
                                    <div style={{marginRight: '8px'}}>
                                        <Icon icon="add"/>
                                    </div>
                                    Create Emissions Report
                                </MainButton>
                            </Column>
                        </Row>

                        {(!previousYearReport) &&
                            <EmptyEmissionReportCard
                                year={previousFY}
                                buttonOnClick={() => goToCreateReportPage(previousFY)}
                        />}

                        {reports ? (
                            reports.length > 0 ? (
                                reports.map((r: Report) => (
                                    <ReportSummaryCard
                                        key={r.id}
                                        report={r}
                                        allReports={reports}
                                        property={property}
                                    />
                                ))
                            ) : (
                                <EmptyPlaceholder>
                                    No Emissions Reports Found!
                                </EmptyPlaceholder>
                            )
                        ) : (
                            <LoadingPlaceholder />
                        )}
                    </FarmPageContainer>
                    <HelpDrawerAndTab
                        renderContent={() => {
                            return (
                                <HelpDrawerContentWrapper>
                                    <HelpDrawerExplanatoryContentContainer>
                                        <HelpDrawerExplanatoryContentTitle>Enterprise Home Page</HelpDrawerExplanatoryContentTitle>
                                        <HelpDrawerExplanatoryContentText>
                                            This is your home page for your enterprise. To start a new report click on the green ‘Create Emissions Report’ button, or edit the data on a previous report using the ‘Manage’ button next to relevant report. You can see your topline emissions data, and access the Reduction Planner if you’re using Ruminati PRIME.
                                        </HelpDrawerExplanatoryContentText>
                                    </HelpDrawerExplanatoryContentContainer>
                                    <HelpDrawerVideoContainer>
                                        <iframe width="560" height="315" src="https://www.youtube.com/embed/PQ2ao8-VMtE?si=mTky1nkD7-ZQN2P5&loop=1&rel=0" title="Ruminati Help" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                    </HelpDrawerVideoContainer>
                                    <Accordion>
                                        <AccordionItem
                                            renderTitle="Who can access my data once I add it to the Ruminati platform?"
                                            renderContent={() => (
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                                                    <AccordionItemContentText style={{ fontWeight: '700' }}>
                                                        Our platform is designed to ensure you always retain complete control over your data. Ruminati does not share your data without your explicit consent.
                                                    </AccordionItemContentText>
                                                    <AccordionItemContentText>
                                                        If you choose to share your data with another organisation, you can do so by providing direct consent to a data share request on the “Corporate Partners” tab at the top of the page.
                                                    </AccordionItemContentText>
                                                </div>
                                            )}
                                        />
                                        <AccordionItem
                                            renderTitle="Why does the tool use financial years?"
                                            renderContent="We use financial years to align with the tax records that are commonly used when completing the tool. This helps maintain consistency and ensures accuracy in your emissions reporting."
                                        />
                                    </Accordion>
                                </HelpDrawerContentWrapper>
                            )
                        }}
                    />
                </FarmPageWrapper>
            ) : (
                <LoadingPlaceholder />
            )
            }
        </Screen >
    );
}

export function Card({
    header,
    body,
    id
}: {
    header: JSX.Element;
    body: JSX.Element;
    id?: string
}) {
    return (
        <CardContainer id={id ?? undefined}>
            {header}
            <Row style={{ width: "100%" }}>
                {body}
            </Row>
        </CardContainer>
    );
}

export const CardBodyText = styled(BodyText)`
    font-weight: 500;
    line-height: 24px;
    white-space: normal;
    text-align: center;
`;

export const EmissionUnitText = styled(ParagraphText)`
    font-family: "Approach", sans-serif;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.01em;
    white-space: nowrap;
`;
