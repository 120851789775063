import { MultiPolygon } from 'geojson'
import { ReactElement } from 'react';
import createGeojsonLayer from '../layer_types/geojson_lyr'
import { createLegendItem } from '../legend'
import { combineHexColorAndOpacityToHexWithOpacity } from '../../../utilities/colors';

export function createSoilCarbonActivityAndLegend (allowRevegAreas: MultiPolygon[] | undefined): {
  layer: ReactElement,
  legend: JSX.Element
} {
  const baseId = 'soil-carbon'
  const fillColor = '#CCA345'
  const fillOpacity = 0.4
  const lineColor = '#5A4414'

  return {
    layer: createGeojsonLayer(allowRevegAreas, {
      dataSourceId: `${baseId}-src`,
      fillLayerId: `${baseId}-fill-lyr`,
      lineLayerId: `${baseId}-line-lyr`,
      fillColor,
      fillOpacity,
      lineColor,
    }),
    legend: createLegendItem(
      `${baseId}-legend-item`,
      'Soil Carbon',
      combineHexColorAndOpacityToHexWithOpacity(fillColor, fillOpacity),
      lineColor
    )
  }
}