import { PaddedInlineBodyText } from "../../components/styled_text";
import { ruminatiColors } from "../../utilities/colors";

export function FarmInfoText({
    name,
    value,
    hidden = false,
}: {
    name: string;
    value?: string | number;
    hidden?: boolean
}) {
    if (hidden === true) {
        return <></>
    }
    return (
        <PaddedInlineBodyText color={ruminatiColors.light_green}>
            <span
                style={{
                    color: ruminatiColors.light_green,
                }}
            >
                {name}{" "}
            </span>
            <span
                style={{
                    color: ruminatiColors.green_3,
                }}
            >
                {value ?? "N/A"}
            </span>
        </PaddedInlineBodyText>
    );
}