import { FormData, FormState } from "../../../../models/form/form";
import { getCurrentFinancialYear } from "../../../../utilities/dates";
import { InitiativePopUp } from "../initiative_popup";
import { ManageManureForm, ManageManureDto } from "../../../../models/reduction_plans_models";
import { Report } from "../../../../models/property_models";
import { getYearField, getDefaultReductionPlanYearOptions, addExistingReductionPlanYearsToOptions } from "../initiative_helpers";
import { FieldSize } from "../../../../models/form/form_fields/form_field";
import { inputPercent, outputPercent } from "@/utilities/forms";
import { manureProportionLabels } from "@/screens/form/forms/dairy";
import { PercentageSplitFormField } from "@/models/form/form_fields/percentage_split_form_field";

export default function ManageDairyManurePopUp(props: {
    initialData?: ManageManureDto;
    compareReport?: Report;
    onSubmit: (data: FormState) => Promise<void>
}) {
    const yearOptions = getDefaultReductionPlanYearOptions()
    const existingReductionPlanYears = [
      props.initialData?.dairyManure?.targetYear
    ].filter(v => v !== undefined) as number[]
    addExistingReductionPlanYearsToOptions(existingReductionPlanYears, yearOptions)

    const initialData = props.initialData ?? {
      dairyManure: {
        ...props.compareReport?.dairyManureManagementInformation,
        targetYear: getCurrentFinancialYear()
      }
    }

    const formData: FormData<ManageManureForm, ManageManureDto> = {
      fields: {
        milkingCows: new PercentageSplitFormField({
          title: "Milking Cows",
          options: manureProportionLabels,
          units: '',
          columnWidth: FieldSize.Fifth
        }),
        otherCows: new PercentageSplitFormField({
          title: "Other Cows",
          options: manureProportionLabels,
          units: '',
          columnWidth: FieldSize.Fifth
        }),
        targetYear: getYearField('Year of completion', FieldSize.Full, yearOptions)
      },
      transformer: {
        in: (value) => ({
          milkingCows: {
            Pasture: inputPercent(value.dairyManure.milkingPasture) ?? 0,
            Lagoon: inputPercent(value.dairyManure.milkingLagoon) ?? 0,
            SumpDispersal: inputPercent(value.dairyManure.milkingSumpDispersal) ?? 0,
            DrainToPaddock: inputPercent(value.dairyManure.milkingDrainToPaddock) ?? 0,
            SolidStorage: inputPercent(value.dairyManure.milkingSolidStorage) ?? 0
          },
          otherCows: {
            Pasture: inputPercent(value.dairyManure.otherPasture) ?? 0,
            Lagoon: inputPercent(value.dairyManure.otherLagoon) ?? 0,
            SumpDispersal: inputPercent(value.dairyManure.otherSumpDispersal) ?? 0,
            DrainToPaddock: inputPercent(value.dairyManure.otherDrainToPaddock) ?? 0,
            SolidStorage: inputPercent(value.dairyManure.otherSolidStorage) ?? 0
          },
          targetYear: value.dairyManure?.targetYear ?? getCurrentFinancialYear()
        }),
        out: (value) => ({
          dairyManure: {
            milkingPasture: outputPercent(value.milkingCows.Pasture),
            milkingLagoon: outputPercent(value.milkingCows.Lagoon),
            milkingSumpDispersal: outputPercent(value.milkingCows.SumpDispersal),
            milkingDrainToPaddock: outputPercent(value.milkingCows.DrainToPaddock),
            milkingSolidStorage: outputPercent(value.milkingCows.SolidStorage),
            otherPasture: outputPercent(value.otherCows.Pasture),
            otherLagoon: outputPercent(value.otherCows.Lagoon),
            otherSumpDispersal: outputPercent(value.otherCows.SumpDispersal),
            otherDrainToPaddock: outputPercent(value.otherCows.DrainToPaddock),
            otherSolidStorage: outputPercent(value.otherCows.SolidStorage),
            targetYear: value.targetYear
          }
        })
      }
    }

    return (
        <>
        <InitiativePopUp
            formData={formData}
            initialFormValues={initialData}
            onSubmit={(state) => props.onSubmit(state)}
            infoAbove={
              !props.initialData ? "Adjust the fields below which we've pre-filled based on your most recently completed report." : undefined
          }/>
    </>);
}
