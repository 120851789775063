export const LPGVolumeConversionRatesInKilojoules = [
    {
        name: 'Kg\'s',
        value: 'kg',
        rate: 49
    },
    {
        name: 'Litres',
        value: 'l',
        rate: 25
    }
]
export function calculateLGPUsageInGigajoules(type: string, amount: number): number | null {
    const gigajoules = 1000
    const conversionRate = LPGVolumeConversionRatesInKilojoules.find(r => r.value === type);
    if (conversionRate === undefined) return null
    return (amount * conversionRate?.rate) / gigajoules
}