import BaseRangeSlider, { SharedRangeSliderProps, SliderType } from "./range_slider";

export type PercentageSliderProps = Partial<SharedRangeSliderProps> & {
    initialValue: number
}

export default function PercentageSlider(props: PercentageSliderProps) {
    return <BaseRangeSlider
        {...props}
        sliderType={SliderType.Percentage} 
        defaultRange={{"min": 0, "max": 100}}
        limits={{"min": 0, "max": 100}}
        unitLabel={"%"}
        />;
}
