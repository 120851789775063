import styled from "styled-components";
import { ruminatiColors } from "../utilities/colors";
import Heading from "./heading";
import dayjs from "dayjs";
import { BaseText } from "./styled_text";
import { getEnterpriseTypeTag } from "./enterprise_type_tag";
import { Property } from "../models/property_models";
import { Row, Column } from "./styled_layout";

export enum DisplayCardImageStyle {
    Floating,
    Fill,
}

/**
 * A card for displaying various content.
 */
export default function DisplayCard({
    id,
    title,
    property,
    date,
    contentArea,
    buttons,
    borderColor,
    titleColor,
    dividerColor,
    illustration,
    imageStyle,
    footerText
}: {
    id?: string;
    title?: string;
    property?: Property;
    date?: number;
    contentArea?: JSX.Element[];
    buttons?: JSX.Element[];
    borderColor?: string;
    titleColor?: string;
    dividerColor?: string;
    illustration?: string;
    imageStyle?: DisplayCardImageStyle
    footerText?: JSX.Element;
}) {

    return (
        <DisplayCardContainer borderColor={borderColor} key={title} id={id}>
            <Column style={{width: '100%'}}>
                <Row 
                  style={{
                    width: '100%'
                }}>
                    <Column
                        style={{
                            width: '70%',
                            padding: footerText ? '24px 24px 12px 24px' : '24px',
                        }}
                    >
                        <Row 
                          style={{
                            width: "100%",
                            borderBottom: `1px solid ${dividerColor ?? ruminatiColors.green_3_30}`,
                            paddingBottom: '12px'
                        }}>
                            <Column style={{ flexGrow: 2, justifyContent: 'flex-start' }}>
                                <Row style={{width: '100%', justifyContent: 'flex-start'}}>
                                    <Column style={{justifyContent: 'flex-start'}}>
                                        <Heading level={5} color={titleColor} textAlign="start">
                                        {title}
                                        </Heading>
                                    </Column>
                                    <Column>
                                        {property && getEnterpriseTypeTag(property, "small")}
                                    </Column>
                                </Row>
                            </Column>
                            <Column style={{ flexGrow: 1, alignItems: "end" }}>
                                {date && (
                                    <CardBodyText style={{color: ruminatiColors.light_green}}>
                                        Last Updated: {dayjs.unix(date).format("D MMM YYYY")}
                                    </CardBodyText>
                                )}                            
                            </Column>
                        </Row>
                        <Row style={{width: '100%', paddingTop: '12px'}}>
                            <Column style={{width: '100%', alignItems: 'flex-start'}}>
                                {contentArea}
                            </Column>
                        </Row>
                    </Column>
                    <Column style={{ width: '30%', height: '100%', justifyContent: 'center' }}>
                        {illustration && 
                            <DisplayCardIllustration illustration={illustration} imageStyle={imageStyle} />
                        }
                        {buttons && 
                            <DisplayCardButtons buttons={buttons} />
                        }
                    </Column>
                </Row>
            {footerText &&
                <Row style={{ 
                    width: '100%',
                    backgroundColor: ruminatiColors.orange_5, 
                    padding: "8px 24px",
                    justifyContent: 'flex-start'
                }}>
                    {footerText}
                </Row>
            }
            </Column>
        </DisplayCardContainer>
    );
}

function DisplayCardIllustration({ illustration, imageStyle }: { illustration?: string, imageStyle?: DisplayCardImageStyle }) {
    return <div
        style={{
            position: "relative",
            height: '100%',
            backgroundColor: ruminatiColors.bone,
            backgroundImage: `url(${illustration})`,
            width: '100%',
            backgroundSize: imageStyle === DisplayCardImageStyle.Fill ? "cover" : "100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            borderRadius: "0px 8px 8px 0px",
        }}
    />
}

function DisplayCardButtons({ buttons }: { buttons?: JSX.Element[] }) {
    return <Row 
        style={{
            flexWrap: 'wrap',
            gap: '8px'
        }}
    >
        {buttons?.map((btn, idx) => {
            return <Column key={idx}>{btn}</Column>
        })}
    </Row>
}

const DisplayCardContainer = styled.div.attrs(
    (props: { borderColor: string }) => props
)`
    display: flex;
    width: 100%;
    border: 1px solid ${(props) => props.borderColor ?? ruminatiColors.green_3};
    border-radius: 8px;
`;

const CardBodyText = styled(BaseText).attrs(
    (props: { color: string }) => props
)`
    color: ${(props) => props.color ?? ruminatiColors.green_3};
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
`;
