import { useEffect, PropsWithChildren } from 'react';
import { useParams } from "react-router-dom";

import LoadingPlaceholder from '../components/loading_placeholder';
import Screen from '../screens/screen'
import { getPropertyTrees } from '../services/property_service';
import { useDatabaseStore } from '../state/database_store';

/**
 * Gets the trees on a property if they have not been loaded 
 */
export default function PropertyTreesGuard({
    children,
}: PropsWithChildren<unknown>) {
    let { propertyId } = useParams();
    const { reportId } = useParams();

    const databaseStore = useDatabaseStore()

    const report = databaseStore.reports?.find(r => r.id === reportId)
    if (!propertyId && report) propertyId = report.propertyId

    const treesForProperty = propertyId ? databaseStore.trees[propertyId] : undefined
    
    useEffect(() => {
        if (propertyId !== undefined && treesForProperty === undefined) {
          getPropertyTrees(propertyId)
                .then((propertyTrees) => {
                    if (propertyTrees) databaseStore.addTrees(propertyId as string, propertyTrees)
                    else databaseStore.addTrees(propertyId as string, [])
                })
                .catch((err) => {
                  console.log(err)
                  databaseStore.addTrees(propertyId as string, [])
                });
        }
    }, [treesForProperty, databaseStore, propertyId]);

    if (!propertyId || !reportId) return children

    if (treesForProperty === undefined) {
        return <Screen>
            <LoadingPlaceholder />
        </Screen>
    } else {
        return children
    }
}