
import { NumberType } from "../models/form/form_fields/form_field";

// Undefined means no decimal point
// 0 means a decimal point and no zeroes
// (n) means n zeroes with a a decimal point
export const displayFloat = (num: number, danglingZeroes: number | undefined) => {
    let outNumber = num.toString();

    // Sometimes JavaScript will convert to scientific notation, so convert back to decimal
    if (outNumber.includes("e")) {
        const outNumberSplit = outNumber.split("e");
        const outNumberSplit0 = outNumberSplit[0];
        const outNumberSplit1 = outNumberSplit[1];

        const coefficient = parseInt(outNumberSplit0 ? outNumberSplit0.replace(".", "") : "0");
        const exponent = parseInt(outNumberSplit1 ? outNumberSplit1 : "0");
        if (exponent < 0) {
            outNumber = "0." + "0".repeat(Math.abs(exponent) - 1) + coefficient.toString();
        } else if (coefficient.toString().length < exponent) {
            outNumber = coefficient.toString() + "0".repeat(exponent - coefficient.toString().length + 1);
        }
    }

    if (danglingZeroes !== undefined) {
        return (outNumber.includes('.') ? outNumber : outNumber + '.') + '0'.repeat(danglingZeroes);
    }
    return outNumber;
}

export const parseNumberString = (value: string, type: NumberType, dp?: number) => {
    let zeroCount = 0;
    let noDangling = true;

    // Handle dangling decimal for floating point values
    if (value.includes('.')) {
        noDangling = false;
        const valueSplit = value.split('.');
        const decimals = valueSplit[1] ? valueSplit[1] : ""; // The string after the decimal point
        for (let i = 0; i < decimals.length; i++) {
            const decimal = decimals[decimals.length - 1 - i];
            if (decimal !== '0') {
                break;
            }
            zeroCount++;
        }
    }

    const danglingZeroes = noDangling ? undefined : zeroCount;

    let ret: number | undefined = undefined;
    switch (type) {
        case NumberType.Float:
            if (value === ".") { // Handle case with just a single decimal point
                ret = 0;
            } else {
                ret = parseFloat(value);
            }
            break;
        case NumberType.Int:
            ret = parseInt(value);
            break;
    }
    if (Number.isNaN(ret)) ret = undefined;


    if (dp !== undefined && dp > 0 && ret !== undefined) {
        const roundingNumber = Math.pow(10, dp);
        ret = Math.round((ret + Number.EPSILON) * roundingNumber) / roundingNumber;
    }

    return { num: ret, danglingZeroes };
}

export const numberCharacterValidator = (numberType: NumberType) => {
    return (char: string, value: string) => {
        if (!/^[-.0-9]$/.test(char)) return false;
        if (char === "-" && value.length > 0) return false;
        if (char === "." && (value.includes(".") || numberType === NumberType.Int)) return false;
        if (value.length >= 10) return false;
        return true;
    };
}


export function trimDecimalPlaces (number: number, decimals?: number): number {
    return parseFloat(number.toFixed(decimals ?? 2))
}