import { MultiPolygon } from "geojson";
import { FieldSize } from "../../../../models/form/form_fields/form_field";
import { FormData, FormState } from "../../../../models/form/form";
import { InitiativePopUpRepeatable } from "../initiative_popup";
import { ImproveSoilCarbonDTO, InitiativeType } from "../../../../models/reduction_plans_models";
import CalculateAreaButton from "../../../buttons/calculate_area_button";
import { ReportTrees } from "../../../../models/report";
import { DrawAndCalculateAreaPopupReturns } from "../../draw_and_calculate_area_calculator";
import { formatNumber } from "../../../../utilities/functions";
import { LegendItem, LegendSwatch, LegendGroupLabel } from '../../../maps/legend'
import { ReductionPlan } from "../../../../models/reduction_plans_models";
import { NumberFormField, TextFormField } from "../../../../models/form/form_fields/basic_form_fields";
import { MultiPolygonFormField } from "../../../../models/form/form_fields/multi_polygon_form_field";
 import { 
    setupReductionPlannerLayers
} from "../../../maps/layer_config/reduction_planner"
import { getYearField, getDefaultReductionPlanYearOptions } from "../initiative_helpers";

interface ImproveSoilFormData {
    name: string;
    startYear: number;
    currentSOC: number;
    targetSOC: number;
    bulkDensity: number;
    soilDepth: number;
    geom: MultiPolygon;
}

export default function ImproveSoilPopup(props: {
    initialData?: ImproveSoilCarbonDTO[];
    repeatedInitialData?: ImproveSoilCarbonDTO;
    propertyBounds: MultiPolygon;
    existingTrees?: ReportTrees[] | undefined;
    maxAllowableOrganicCarbonValue: number | undefined;
    otherReductionPlans: ReductionPlan[] | undefined;
    onSubmit: (value: ImproveSoilCarbonDTO[]) => Promise<void>
}) {
    const repeatableSectionName = "Soil Organic Carbon Area";
    const yearOptions = getDefaultReductionPlanYearOptions()

    function getTooltipText(): string {
        let basetext = "The Soil Organic Carbon Level you'd like to achieve in this area."
        if (props.maxAllowableOrganicCarbonValue) basetext += ` We've set a maximum Soil Organic Carbon level of ${formatNumber(props.maxAllowableOrganicCarbonValue, { maxDecimalPlaces: 2 })}% based on data from your region.`
        return basetext
    }

    const formData: FormData<ImproveSoilFormData, ImproveSoilCarbonDTO[]> = {
        fields: {
            name: new TextFormField({
                required: true,
                label: "Area name",
                tip: "A name to help you identify the area where you'll change practices.",
            }),
            startYear: getYearField('Start Year', FieldSize.Half, yearOptions),
            geom: new MultiPolygonFormField({
                required: true,
                label: "Area",
                unit: 'Ha',
                size: FieldSize.Half,
                children: (state) => {
                    const {
                        proposedTreePlantingsLayerAndLegend,
                        proposedSoilCarbonLayerAndLegend,
                        proposedAllowRevegLayerAndLegend,
                        existingTreeLayerAndLegend
                    } = setupReductionPlannerLayers(
                        InitiativeType.ImproveSoilCarbon,
                        state,
                        props.otherReductionPlans,
                        props.existingTrees
                    )
                    return (
                        <CalculateAreaButton
                            state={state}
                            geom={state.get.geom}
                            propertyGeom={props.propertyBounds}
                            additionalLayers={[
                                existingTreeLayerAndLegend.layer,
                                proposedTreePlantingsLayerAndLegend.layer,
                                proposedAllowRevegLayerAndLegend.layer,
                                proposedSoilCarbonLayerAndLegend.layer
                            ]}
                            additionalLegendItems={[
                                existingTreeLayerAndLegend.legend,
                                <LegendGroupLabel key="planned-label">
                                    Planned Activities
                                </LegendGroupLabel>,
                                proposedTreePlantingsLayerAndLegend.legend,
                                proposedAllowRevegLayerAndLegend.legend,
                                proposedSoilCarbonLayerAndLegend.legend,
                                <LegendItem key="this-planned-legend">
                                    <LegendSwatch color={'#F3DA064D'} borderColor={'#F3DA06'} />
                                    This Activity
                                </LegendItem>,
                            ]}
                            footerText={"Draw areas where you intend on improving soil carbon."}
                            onSubmit={(obj: DrawAndCalculateAreaPopupReturns) => {
                                if (obj.geom) {
                                    state.set({
                                        ...state.get,
                                        geom: obj.geom
                                    });
                                }
                            }}
                        />
                    )
                },
            }),
            currentSOC: new NumberFormField({
                required: true,
                label: "Current Soil Organic Carbon",
                unit: "%",
                size: FieldSize.Half,
            }),
            targetSOC: new NumberFormField({
                required: true,
                label: "Target Soil Organic Carbon",
                unit: "%",
                size: FieldSize.Half,
                validator: (value) => {
                    if (value === undefined) return true
                    if (props.maxAllowableOrganicCarbonValue === undefined) return true
                    return value <= props.maxAllowableOrganicCarbonValue
                },
                tip: getTooltipText()
            }),
            bulkDensity: new NumberFormField({
                required: true,
                label: "Bulk Density",
                unit: "g/cm3",
                size: FieldSize.Half,
                tip: "We've pre-filled this value for your property based on a broad-scale dataset but if you've done soil testing on your property you may want to adjust it.",
            }),
            soilDepth: new NumberFormField({
                required: true,
                label: "Soil Depth",
                unit: "cm",
                size: FieldSize.Half,
                tip: "We've pre-filled this value for your property based on a broad-scale dataset but if you've done soil testing on your property you may want to adjust it.",
            })
        }
    };

    return (
        <>
            <InitiativePopUpRepeatable
                repeatableSectionName={repeatableSectionName}
                formData={formData}
                initialFormValues={Array.from(props.initialData ?? [{}] as Partial<FormState>[])}
                initialRepeatedValues={props.repeatedInitialData ?? {}}
                onSubmit={(state) => props.onSubmit(state as ImproveSoilCarbonDTO[])}
                infoAbove={
                    "We've pre-filled some of the data for you based on broad-scale data, but if you've done local soil tests on your property you can over-ride the pre-filled values."
                }
            />
        </>
    );
}