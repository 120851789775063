import { calculateLGPUsageInGigajoules, LPGVolumeConversionRatesInKilojoules } from "../../utilities/lpg";
import { Form, FormData } from "../../models/form/form";
import { NumberFormField } from "../../models/form/form_fields/basic_form_fields";
import { InfoCard } from "../card";
import { Row } from "../styled_layout";
import RadioBox from "../form/radio_box";
import HiddenFormField from "@/models/form/form_fields/hidden_form_field";


export interface CalculatorDTO {
    type: string;
    amount: number;
}

const defaultValue = LPGVolumeConversionRatesInKilojoules[0];

const calculatorForm = (): FormData<CalculatorDTO> => ({
    fields: {
        type: new HiddenFormField(),
        amount: new NumberFormField({
            label: 'LPG amount',
            required: true,
            validator: (value) => value !== undefined && value >= 0,
            children: (state) => (
                <RadioBox
                    name={'_conversionTypes'}
                    id={'_conversionTypes'}
                    horizontal={true}
                    initialValue={defaultValue.value}
                    options={LPGVolumeConversionRatesInKilojoules}
                    size="small"
                    onChange={(val) => {
                        if (val === undefined) return
                        state.set({
                            ...state.get,
                            type: val,
                        });
                    }}
                />
            )
        })
    },
});

/**
 * The LPG Use Calculator form, intended to be shown inside a popup
 */
export default function LPGUseCalculator({
    onSubmit }: {
        onSubmit: (val: number) => void;
        metadata?: any
    }) {
    const formData = calculatorForm();
    return (
        <div style={{ padding: "20px", width: '450px' }}>
            <InfoCard
                content={'Estimate your LPG usage based on kilograms or litres of fuel volume used'}
            />
            <Row style={{ height: '20px' }}></Row>
            <Form

                data={formData}
                initialValue={{}}
                fieldSize="small"
                submitText={`Calculate gigajoules used`}
                buttonSize="medium"
                onSubmit={async (value: CalculatorDTO) => {
                    if (value.type === undefined) {
                        value.type = defaultValue.value
                    }
                    const amountUsed = calculateLGPUsageInGigajoules(value.type, value.amount)
                    if (amountUsed === null) return onSubmit(0)
                    onSubmit(amountUsed);
                }}
            />
        </div>
    );
}
