import { SlimCard } from "./card";
import { Column, Row } from "./styled_layout";
import Heading from './heading'
import { ruminatiColors } from "./../utilities/colors";

export default function SharingSummary(isAdvancedShare?: boolean) {

  function createColumnWithCard(title: string, listItems: string[]): JSX.Element {
    return (
      <Column style={{ width: '30%'}} key={title}>
        <SlimCard style={{height: "100%", width: '100%'}}>
          <Heading level={5}>{title}</Heading>
          <ul style={{color: ruminatiColors.dark_green, fontSize: '14px'}}>
            {listItems.map((li, idx) => {
              return <li style={{marginBottom: '5px'}} key={idx}>{li}</li>
            })}
          </ul>
        </SlimCard>
      </Column>
    )
  }

  function advancedSection (): JSX.Element[] {
    return [
      createColumnWithCard('Production', [
      'Livestock inventory including purchases and sales',
      'Wool inventory',
      'Calving and lambing rates',
      'Details of crops including type, area and yield',
      'Supplementary feed including grains, hay and cottonseed',
      'Mineral supplements'
    ]),
    createColumnWithCard('Pasture Inputs', [
      'Urea and lime',
      'Single superphosphate',
      'Pesticide and glyphosate',
      'Other forms of nitrogen'
    ]),
    createColumnWithCard('Cropping treatment', [
      'MAP, DAP and SOE',
      'Urea and lime',
      'Single superphosphate',
      'Herbicide, pesticide and glyphosate',
      'Burning rates'
    ]),
    createColumnWithCard('Consumable allocation by commodity', [
      'Petrol and diesel',
      'Electricity including proportion from renewable sources'
    ])
    ]
  }

  const resultsSection = [
    'Total emissions',
    'Total sequestration',
    'Net emissions',
    'Detailed emissions breakdown'
  ]
  if (isAdvancedShare) resultsSection.push('CO2-e per kg or tonne (for each commodity)')

  return <Row style={{ 
    alignItems: 'stretch',
    justifyContent: "space-evenly",
    gap: "16px",
    flexWrap: "wrap"
  }}>
      {createColumnWithCard('Enterprise', ['User name & Email', 'Enterprise name', 'Enterprise commodities'])}

      {createColumnWithCard('Emissions Report Status', ['Baseline report initiated or completed', 'Baseline report year'])}

      {createColumnWithCard('Reduction Plan Status', ['Reduction plan initiated', 'Reduction plan year', 'Planned reduction categories', 'Planned reduction amount'])}

      {createColumnWithCard('Location', [
        'Property location (as a single point centroid)',
        'Area of property (hectares)'
      ])}

      {createColumnWithCard('Emissions Report Results', resultsSection)}

      {isAdvancedShare === false && createColumnWithCard('Emissions Intensity', ['CO2-e per kg or tonne (for each commodity)'])}
      
      {isAdvancedShare && advancedSection()}
  </Row>
}