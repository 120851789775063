import { FieldSize } from "@/models/form/form_fields/form_field";
import { Form, FormData } from "../../models/form/form";
import { DropdownFormField, NumberFormField, RadioBoxFormField } from "../../models/form/form_fields/basic_form_fields";
import { LabelField, SeparatorField } from "@/models/form/form_fields/decoration_form_fields";
import { ReportManureManagementForm } from "@/models/report";
import { calculateSumOfArray } from "@/utilities/maths";


export interface CalculatorDTO {
    labelMilking: string  
    hoursAtDairy: number
    daysAtDairy: number
    percentToPaddocks: number
    percentToSump: number
    isWastePretreated: boolean
    hoursAtFeedpad: number
    daysAtFeedpad: number
    wasteHandling: number
    seperate: any
    label: string
    heifersHoursInYards: number
    heifersDaysInYards: number
    heiferWasteHandling: number
}

const wasteHandlingOptions = [
  {
    value: 1,
    name: 'Flushed & drains daily to the paddock'
  },
  {
    value: 2,
    name: 'Flushed & spread daily from a sump'
  },
  {
    value: 3,
    name: 'Flushed to a pond/lagoon system'
  },
  {
    value: 4,
    name: 'Scrapped & stockpiled '
  }
]

const calculatorForm = (): FormData<CalculatorDTO> => ({
    fields: {
        labelMilking: new LabelField({
          label: 'Milking Cows'
        }),
        hoursAtDairy: new NumberFormField({
            label: "Avg hours per day a milker spends at the dairy",
            required: true,
            unit: 'hours',
            validator: (value) => value !== undefined && value >= 0 && value < 25,
            size: FieldSize.Half
        }),
        daysAtDairy: new NumberFormField({
          label: "Days per annum a milker spends at the dairy",
          required: true,
          unit: 'days',
          validator: (value) => value !== undefined && value >= 0 && value < 365,
          size: FieldSize.Half
        }),
        percentToPaddocks: new NumberFormField({
          label: "% of waste drained to the paddock with no treatment of solids",
          required: true,
          unit: '%',
          validator: (value) => value !== undefined && value <= 100,
          size: FieldSize.Half
        }),
        percentToSump: new NumberFormField({
          label: "% of waste that is flushed from a sump/dispersal system",
          required: true,
          unit: '%',
          validator: (value) => value !== undefined && value <= 100,
          size: FieldSize.Half
        }),
        isWastePretreated: new RadioBoxFormField({
          label: "Do you pre-treat the waste prior to spreading daily or entering the lagoon",
          required: true,
          options: [
            {
              value: true,
              name: 'Yes'
            },
            {
              value: false,
              name: 'No'
            }
          ]
        }),
        hoursAtFeedpad: new NumberFormField({
          label: "Number of hours per day a milker spends on a feedpad, loafing area or are housed (TMR farm)",
          required: true,
          unit: 'hours',
          size: FieldSize.Half,
          validator: (value) => value !== undefined && value >= 0 && value < 25,
        }),
        daysAtFeedpad: new NumberFormField({
          label: "Days per annum a milker spends on a feedpad, loafing area or are housed (TMR farm)",
          required: true,
          unit: 'days',
          size: FieldSize.Half,
          validator: (value) => value !== undefined && value >= 0 && value < 365,
        }),
        wasteHandling: new DropdownFormField({
          label: "Majority waste handling method from the feedpad or loafing area",
          size: FieldSize.Half,
          options: wasteHandlingOptions
        }),
        seperate: new SeparatorField(),
        label: new LabelField({
          label: 'Heifers'
        }),

        heifersHoursInYards: new NumberFormField({
          label: "Avg hours per day a heifer spends at the yards",
          required: true,
          unit: 'hours',
          validator: (value) => value !== undefined && value >= 0 && value < 25,
          size: FieldSize.Half
        }),
        heifersDaysInYards: new NumberFormField({
          label: "Days per annum a heifer spends at the yards",
          required: true,
          unit: 'days',
          validator: (value) => value !== undefined && value >= 0 && value < 365,
          size: FieldSize.Half
        }),
        heiferWasteHandling: new DropdownFormField({
          label: "Majority waste handling method from the feedpad or loafing area",
          options: wasteHandlingOptions
        }),
    },
});
export const DairyManureManagementCalculatorPopupID = 'DairyManureManagementCalculator'

export default function DairyManureManagementCalculator({
  onSubmit
}: {
  onSubmit: (val: ReportManureManagementForm) => void;
}) {
  const formData = calculatorForm();
  return (
      <div style={{ 
        padding: "20px",
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 300px)'
      }}>
          <Form
              data={formData}
              initialValue={{
                isWastePretreated: true
              }}
              fieldSize="small"
              submitText={`Calculate`}
              buttonSize="medium"
              onSubmit={async (value: CalculatorDTO) => {
                  const proportionHoursDairy = value.hoursAtDairy / 24
                  const proportionDaysDairy = value.daysAtDairy / 365
                  const proportionHoursFeedpad = value.hoursAtFeedpad / 24
                  const proportionDaysFeedpad = value.daysAtFeedpad / 365
                  const percentToLagoon = 100 - (value.percentToPaddocks + value.percentToSump)

                  const percentToGrazing = 1 - ((proportionHoursDairy * proportionDaysDairy) + (proportionHoursFeedpad * proportionDaysFeedpad))
                  const percentDrainToPaddock = proportionHoursDairy * proportionDaysDairy * (value.percentToPaddocks / 100)
                  const percentSpreadDaily = proportionHoursDairy * proportionDaysDairy * (value.percentToSump / 100) * (!value.isWastePretreated ? 1 : 0.8)
                  const percentSpreadAndTreated = proportionHoursDairy * proportionDaysDairy * (value.percentToSump / 100) * (value.isWastePretreated ? 0.2 : 0)
                  const percentToLagoonNoTreatment = ((proportionHoursDairy * proportionDaysDairy * (percentToLagoon / 100)) * (!value.isWastePretreated ? 1 : 0.8))
                  const percentToLagoonWithTreatment = !value.isWastePretreated ? 0 : (proportionHoursDairy * proportionDaysDairy * (percentToLagoon / 100) * 0.2)
                  const percentFromFeedpadToPaddock = value.wasteHandling === 1 ? (proportionHoursFeedpad * proportionDaysFeedpad) : 0
                  const percentFromFeedpadSpread = (value.wasteHandling === 2 ? proportionHoursFeedpad * proportionDaysFeedpad : 0) * (value.isWastePretreated ? 0.8 : 1)
                  const percentFromFeedpadToSolidStorage = (value.wasteHandling === 2 ? proportionHoursFeedpad * proportionDaysFeedpad : 0) * (value.isWastePretreated ? 0.2 : 0)
                  const percentFromFeedpadToLagoonNoTreatment = value.wasteHandling === 3 ? proportionHoursFeedpad * proportionDaysFeedpad * (value.isWastePretreated ? 0.8 : 1) : 0
                  const percentFromFeedpadToLagoonWithTreatment = (value.wasteHandling === 3 ? proportionHoursFeedpad * proportionDaysFeedpad : 0) * (value.isWastePretreated ? 0.2 : 0)
                  const percentFeedpadWetlotted = value.wasteHandling === 4 ? proportionHoursFeedpad * proportionDaysFeedpad : 0
                  const milkingCows = {
                      "Pasture": percentToGrazing * 100,
                      "Lagoon": calculateSumOfArray([
                        percentToLagoonNoTreatment,
                        percentFromFeedpadToLagoonNoTreatment
                      ]) * 100,
                      "SumpDispersal": calculateSumOfArray([
                        percentSpreadDaily,
                        percentFromFeedpadSpread
                      ]) * 100,
                      "DrainToPaddock": calculateSumOfArray([
                        percentDrainToPaddock,
                        percentFromFeedpadToPaddock
                      ]) * 100,
                      "SolidStorage": calculateSumOfArray([
                        percentSpreadAndTreated,
                        percentToLagoonWithTreatment,
                        percentFromFeedpadToSolidStorage,
                        percentFromFeedpadToLagoonWithTreatment,
                        percentFeedpadWetlotted
                      ]) * 100
                  }

                  // Heifers
                  const proportionHoursYards = value.heifersHoursInYards / 24
                  const proportionDaysYards = value.heifersDaysInYards / 365
                  const heiferPercentToGrazing = 1 - (proportionHoursYards * proportionDaysYards)
                  const heiferDrainToPaddock = value.heiferWasteHandling === 1 ? (proportionHoursYards * proportionDaysYards) : 0 
                  const heiferSumpDispersal = value.heiferWasteHandling === 2 ? (proportionHoursYards * proportionDaysYards * 0.8) : 0
                  const heiferPercentageSolidStorage = (value.heiferWasteHandling === 2 ? proportionHoursYards * proportionDaysYards : 0) * (value.isWastePretreated ? 0.2 : 0)
                  const heiferPercentLagoonUntreated = value.heiferWasteHandling === 3 ? proportionHoursYards * proportionDaysYards * (value.isWastePretreated ? 0.8 : 1) : 0
                  const heiferPercentLagoonTreated = value.heiferWasteHandling === 3 ? proportionHoursYards * proportionDaysYards * (value.isWastePretreated ? 0.2 : 0) : 0
                  const heiferPercentToSolidStorage = value.heiferWasteHandling === 4 ? proportionHoursYards * proportionDaysYards : 0 
                  const otherCows = {
                      "Pasture": heiferPercentToGrazing * 100,
                      "Lagoon": heiferPercentLagoonUntreated * 100,
                      "SumpDispersal": heiferSumpDispersal * 100,
                      "DrainToPaddock": heiferDrainToPaddock * 100, 
                      "SolidStorage": (heiferPercentageSolidStorage + heiferPercentLagoonTreated + heiferPercentToSolidStorage) * 100
                  }

                  onSubmit({
                    milkingCows,
                    otherCows
                  });
              }}
          />
      </div>
  );
}