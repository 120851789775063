import styled from "styled-components";
import { ruminatiColors } from "../../utilities/colors";
import { Row } from "../styled_layout";
import { useState } from "react";

type ToggleButtonProps = {
    isOn: boolean;
    label: string;
    onChange: (value: boolean) => void;
};

export default function ToggleButton(props: ToggleButtonProps) {
    const [toggled, setToggled] = useState<boolean>(props.isOn);

    const toggleColor = toggled ? ruminatiColors.green_3 : ruminatiColors.green_3_50
    const backgroundColor = toggled ? ruminatiColors.green_3_10 : ruminatiColors.green_3_5

    return (
        <Row
            onClick={() => {
                props.onChange(!toggled);
                setToggled(!toggled);
            }}
        >
            <ToggleLabel style={{ color: toggleColor }}>
                {props.label}
            </ToggleLabel>
            <Toggle
                style={{
                    borderColor: toggleColor,
                    backgroundColor: backgroundColor,
                }}
            >
                <ToggleIndicator
                    style={{
                        backgroundColor: toggleColor,
                        right: toggled ? "-1px" : "calc(100% + 1px)",
                        transform: toggled ? undefined : "translateX(100%)",
                    }}
                />
            </Toggle>
        </Row>
    );
}
const ToggleLabel = styled.div`
    font-family: "Approach";
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${ruminatiColors.green_3};
    user-select: none;
    transition: 0.3s ease;
`;

const Toggle = styled.div`
    position: relative;
    cursor: pointer;
    width: 16px;
    height: 10px;
    border-radius: 16px;
    border: 1px solid ${ruminatiColors.green_3_30};
    margin: 0 0 0 8px;

    display: flex;
    align-items: center;
    transition: 0.3s ease;
`;

const ToggleIndicator = styled.div`
    position: absolute;
    right: -1px;
    width: 10px;
    height: 10px;
    border-radius: 8px;

    transition: 0.3s ease;
`;
