import { ruminatiColors } from './colors';

export const generatePieChartData = ({ labels, theme, data }: { labels: string[], theme: string[], data: number[] }) => {
    return {
        labels: labels,
        datasets: [
            {
                data: data,
                backgroundColor: theme,
                borderColor: Array(theme.length).fill(ruminatiColors.bone),
                borderWidth: 2,
            },
        ],
    };
}

export const orangePieTheme = [ruminatiColors.tag_1, ruminatiColors.tag_2, ruminatiColors.tag_3];
export const bluePieTheme = [ruminatiColors.tag_4, ruminatiColors.tag_5, ruminatiColors.tag_6];