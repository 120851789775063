import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { omit } from 'lodash-es'
import type XLSX from "xlsx";

import { createReport, patchReport } from "../services/property_service";
import { useDatabaseStore } from "../state/database_store";
import { useAuthStore } from "../state/auth_store";
import { useIntegrationsStore } from "../state/integrations";
import { ParagraphText, BodyText } from "../components/styled_text";
import {
    formatFinancialYearStringToPrettyFinancialYearString,
    getCurrentFinancialYear,
    getYearsBetween,
} from "../utilities/dates";
import Screen from "./screen";
import { Column, Row } from "../components/styled_layout";
import Heading from "../components/heading";
import { DropdownField } from "../components/form/input_field";
import { CheckBox } from "../components/form/check_box";
import {
    openDragAndDroppedFile,
    extractDataFromv18Workbook,
    SbGAFImportResults
} from "../utilities/sbGaf";
import { getReportOfYear, getReportsOfProperty, populateReportFromParsedSbGaf } from "../utilities/reports";
import { Property, PropertyType } from "../models/property_models";
import HeaderButton from "../components/buttons/header_button";
import PageHeader from "../components/headers/page_header";
import LoadingButton from "../components/buttons/loading_button";
import { ruminatiColors } from "../utilities/colors";
import { getAgriWebbAnimals, getAgriWebbAnimalCount, getAgriWebbAnimalGroups } from "../services/agriwebb_service";
import { SubscriptionType } from "../models/subscription_model";
import { Report } from "../models/property_models";
import FileDrop, { ParsingStep } from '../components/filedrop';
import { CountryCode } from "../utilities/countries";
import { CreateReportContainer, CreateReportWrapper } from "./wrapper/farm_wrapper";
import { InfoCard } from "../components/card";
import Icon from "../components/icon";
import { LivestockClassAUS } from "@/models/report";
import { generateLivestockClassesFromAgriWebbAnimalData, generateLivestockClassesFromAgriWebbMobData } from "@/utilities/agriwebb";
import { SeasonalMobResults } from "@/models/agriwebb";
import ActiveProgress from "@/components/active_progress";

const informationItems: [string, string][] = [
    ["Livestock records", "Pasture improvement records"],
    ["Accounting records", "Purchased stock and feed"]
];

export default function CreateReportScreen() {
    const navigate = useNavigate();
    const { propertyId } = useParams();
    const [searchParams] = useSearchParams()

    const databaseStore = useDatabaseStore();
    const integrationsStore = useIntegrationsStore();

    const property: Property | undefined = databaseStore.properties?.find((p) => p.id === propertyId);

    const reports = getReportsOfProperty(databaseStore.reports ?? [], propertyId ?? '');

    const startYear = 2021;
    const endYear = getCurrentFinancialYear() - 1
    const years = [startYear, ...getYearsBetween(startYear, endYear)]
    const yearsWithNoReports = years.filter(y => !getReportOfYear(reports, y)).reverse()
    const yearsWithReports = years.filter(y => getReportOfYear(reports, y)?.complete).sort((y1, y2) => y2 - y1);

    function formatYearOptions(yearList: number[]): { name: string, value: string }[] {
        return yearList.map(y => {
            return {
                name: formatFinancialYearStringToPrettyFinancialYearString(y.toString()),
                value: y.toString(),
            }
        })
    }

    const yearOptions = formatYearOptions(yearsWithNoReports)
    const completedYearOptions = formatYearOptions(yearsWithReports)

    const isPrime = useAuthStore().user?.subscription?.type === SubscriptionType.PRIME;

    const [localState, setLocalState] = useState<{
        sbGafParsingStep: ParsingStep,
        sbGafInfo: SbGAFImportResults | undefined,
        selectedYear: string | undefined,
        selectedCompletedYear: string | undefined,
        useAgriWebbPreFill: boolean,
        gettingAgriWebbAnimalCountData: boolean,
        gettingAgriWebbData: boolean,
        agriwebbHasIndividualData: boolean | undefined
    }>({
        sbGafParsingStep: ParsingStep.NotYetStarted,
        sbGafInfo: undefined,
        selectedYear: searchParams.has('year') ? searchParams.get('year') as string : yearOptions.length > 0 ? yearOptions[0]?.value : undefined,
        selectedCompletedYear: undefined,
        useAgriWebbPreFill: (integrationsStore.integrations?.agriwebb && property?.agriwebbFarmId !== undefined && (property.cattleFarm || property.sheepFarm)) ?? false,
        gettingAgriWebbAnimalCountData: false,
        gettingAgriWebbData: false,
        agriwebbHasIndividualData: undefined
    })

    const selectedYearAsNumber = localState.selectedYear ? parseInt(localState.selectedYear) : undefined

    async function getDseInfoFromAgriWebb(livestockType: string) {
        if (property?.agriwebbFarmId && selectedYearAsNumber) {
            const farmAnimalData = await getAgriWebbAnimals(property?.agriwebbFarmId, livestockType, selectedYearAsNumber)
            return farmAnimalData
        }
        return undefined
    }

    async function checkForAgriwebbLivestockData() {
        let hasData = false
        if (!property?.agriwebbFarmId || !selectedYearAsNumber) return hasData
        if (property?.cattleFarm) {
            const animalCount = await getAgriWebbAnimalCount(property.agriwebbFarmId, 'beef', selectedYearAsNumber)
            if (animalCount && animalCount.nonPagedCount > 0) hasData = true
        }
        if (property?.sheepFarm && !hasData) {
            const animalCount = await getAgriWebbAnimalCount(property.agriwebbFarmId, 'sheep', selectedYearAsNumber)
            if (animalCount && animalCount.nonPagedCount > 0) hasData = true
        }
        return hasData
    }

    async function getLivestockClassesFromAgriwebb(livestockType: string): Promise<LivestockClassAUS[]> {
        const farmAnimalData = await getDseInfoFromAgriWebb(livestockType)
        if (farmAnimalData) {
            return generateLivestockClassesFromAgriWebbAnimalData(farmAnimalData)
        }
        return []
    }

    async function getLivestockClassesFromAgriwebbMobs(mobData: SeasonalMobResults, livestockType: string): Promise<LivestockClassAUS[]> {
        return await generateLivestockClassesFromAgriWebbMobData(mobData, livestockType)
    }

    async function prefillFromAgriwebb(report: Report) {
        setLocalState({
            ...localState,
            gettingAgriWebbData: true
        })
        if (localState.agriwebbHasIndividualData) {
            if (property?.cattleFarm) {
                const cattleInformation = await getLivestockClassesFromAgriwebb('beef')
                report.livestockInformationCattleClassAus = cattleInformation
            }
            if (property?.sheepFarm) {
                const sheepInformation = await getLivestockClassesFromAgriwebb('sheep')
                report.livestockInformationSheepClassAus = sheepInformation
            }
            setLocalState({
                ...localState,
                gettingAgriWebbData: false
            })
        } else if (property?.agriwebbFarmId && selectedYearAsNumber) {
            const mobData = await getAgriWebbAnimalGroups(property?.agriwebbFarmId, selectedYearAsNumber)
            setLocalState({
                ...localState,
                gettingAgriWebbData: false
            })
            if (!mobData) return
            if (property?.cattleFarm) {
                const cattleInformation = await getLivestockClassesFromAgriwebbMobs(mobData, 'Cattle')
                report.livestockInformationCattleClassAus = cattleInformation
            }
            if (property?.sheepFarm) {
                const sheepInformation = await getLivestockClassesFromAgriwebbMobs(mobData, 'Sheep')
                report.livestockInformationSheepClassAus = sheepInformation
            }
        }
    }

    function prefillFromOldReport(report: Report) {
        if (localState.selectedCompletedYear === undefined) return
        const prevCompletedReport = reports.find(r => r.financialYear === parseInt(localState.selectedCompletedYear as string))
        if (prevCompletedReport) {
            const valuesToTake = omit(prevCompletedReport, [
                'id',
                'algorithmVersion',
                'complete',
                'completionDate',
                'completedSections',
                'financialYear',
                'results',
                'resultsReadyForGeneration',

                // specific sections to ignore
                'treesInformation',
                'livestockInformation',
                'grainInformation',
                'productionInformation'
            ])

            Object.assign(report, valuesToTake)
        }
    }

    async function submit() {
        if (!selectedYearAsNumber) return;
        if (propertyId === undefined) return;

        const report = await createReport(propertyId, selectedYearAsNumber);

        if (report && property) {

            if (localState.sbGafParsingStep === ParsingStep.Successful && localState.sbGafInfo) populateReportFromParsedSbGaf(report, localState.sbGafInfo, isPrime, property);

            if (localState.selectedCompletedYear) prefillFromOldReport(report)

            // Note: AgriWebb imports currently override SB-GAF DSE or old report figures 
            // if either of those pre-fill options are used
            if (localState.useAgriWebbPreFill) {
                await prefillFromAgriwebb(report)
            }
            databaseStore.newReport(report);
            if (
                (localState.useAgriWebbPreFill) ||
                localState.sbGafParsingStep === ParsingStep.Successful ||
                localState.selectedCompletedYear
            ) {
                const updatedReport = await patchReport(report.id, report)
                if (updatedReport) {
                    databaseStore.updateReport(updatedReport)
                } else {
                    console.error('undefined updatedReport.')
                }
            }
            navigate(`/form/${report.id}/~`);
        }
    }

    function retryUpload() {
        setLocalState({
            ...localState,
            sbGafParsingStep: ParsingStep.NotYetStarted,
            sbGafInfo: undefined
        })
    }

    function couldNotOpenOrParseSpreadsheet() {
        setLocalState({
            ...localState,
            sbGafParsingStep: ParsingStep.Error
        })
    }

    async function processedDroppedFile(file: File) {
        const data = await file.arrayBuffer();
        try {
            const workbook: XLSX.WorkBook | undefined =
                await openDragAndDroppedFile(data);
            if (workbook && property) {
                const results = extractDataFromv18Workbook(workbook, property);
                setLocalState({
                    ...localState,
                    sbGafParsingStep: ParsingStep.Successful,
                    sbGafInfo: results
                })
            } else {
                couldNotOpenOrParseSpreadsheet();
            }
        } catch {
            couldNotOpenOrParseSpreadsheet();
        }
    }

    useEffect(() => {
        if (localState.agriwebbHasIndividualData === undefined && !localState.gettingAgriWebbAnimalCountData) {
            setLocalState({
                ...localState,
                gettingAgriWebbAnimalCountData: true
            })
            checkForAgriwebbLivestockData().then(res => {
                setLocalState({
                    ...localState,
                    agriwebbHasIndividualData: res
                })
            })
        }
    }, [localState])

    return (
        <Screen pageTitle="Create Report">
            <CreateReportWrapper>
                <CreateReportContainer style={{ width: "45%" }}>
                    <Row>
                        <PageHeader>
                            <HeaderButton
                                icon="upArrow"
                                iconPos="left"
                                onClick={() => navigate(`/farm/${propertyId}`)}
                            >
                                Cancel
                            </HeaderButton>
                        </PageHeader>
                    </Row>
                    <Row>
                        <Heading level={2}>Create New Emission Report</Heading>
                    </Row>
                    <Row>
                        <Column style={{ marginTop: "24px", width: '100%' }}>
                            <InfoCard
                                padding='24px'
                                content={
                                    <>
                                        <BodyText style={{ marginBottom: '8px', alignSelf: 'flex-start' }}>To complete your emissions report, you will need the following information</BodyText>
                                        {informationItems.map((item, idx) => (
                                            <Row style={{ width: '100%', justifyContent: 'flex-start' }} key={idx}>
                                                {item.map((i, idx2) => (
                                                    <Column style={{ width: '50%', alignItems: 'center', flexFlow: 'row wrap' }} key={idx2}>
                                                        <Icon icon='tick' />
                                                        <BodyText style={{ paddingLeft: '10px', textAlign: 'left' }}>
                                                            {i}
                                                        </BodyText>
                                                    </Column>
                                                ))}
                                            </Row>
                                        ))}
                                    </>
                                } />
                        </Column>
                    </Row>

                    <Row style={{ marginTop: "40px" }}>
                        <Column style={{ width: "100%" }}>
                            {yearOptions.length > 0 ?
                                <DropdownField
                                    id="year-selector"
                                    size="small"
                                    placeholder="Select financial year"
                                    value={localState.selectedYear ?? undefined}
                                    options={yearOptions}
                                    onSelect={(value) => {
                                        if (value) {
                                            setLocalState({
                                                ...localState,
                                                selectedYear: value,
                                                agriwebbHasIndividualData: undefined
                                            })
                                        }
                                    }}
                                />
                                : <ParagraphText>You have already created a report for each available financial year. You can edit them from the farm dashboard.</ParagraphText>
                            }

                            {yearOptions.length > 0 &&
                                <Row style={{ marginTop: '40px' }}>
                                    <Column style={{ width: "100%", alignItems: "center" }}>
                                        <>
                                            <Heading level={5}>
                                                Ways to pre-fill your data
                                            </Heading>
                                            {property?.country === CountryCode.Australia &&
                                                <> {
                                                    !integrationsStore.integrations?.agriwebb ?
                                                        <ParagraphText
                                                            style={{
                                                                marginTop: '20px',
                                                                fontSize: "16px"
                                                            }}>
                                                            If you'd like to pre-fill some of the data for your report using your AgriWebb account, head to your Account page and enable the integration.
                                                        </ParagraphText>
                                                        : !property.agriwebbFarmId ?
                                                            <></>
                                                            : <CheckBox
                                                                checkBoxKey={1}
                                                                checked={localState.useAgriWebbPreFill}
                                                                size={"15px"}
                                                                label={"Pre-fill livestock information from AgriWebb"}
                                                                labelColor={ruminatiColors.green_3}
                                                                labelFontWeight={"500"}
                                                                labelWidth={"auto"}
                                                                onChange={(val) => {
                                                                    setLocalState({
                                                                        ...localState,
                                                                        useAgriWebbPreFill: val.target.checked
                                                                    })
                                                                }}
                                                            />
                                                } </>
                                            }

                                            {completedYearOptions.length > 0 &&
                                                <>
                                                    <ParagraphText
                                                        style={{
                                                            marginTop: "20px",
                                                            fontSize: "15px",
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        Pre-fill using a previously completed report
                                                    </ParagraphText>
                                                    <DropdownField
                                                        id={"prev-year-selector"}
                                                        size="small"
                                                        placeholder={`Previously completed report year`}
                                                        value={localState.selectedCompletedYear ?? undefined}
                                                        options={completedYearOptions}
                                                        onSelect={(value) => {
                                                            if (value) {
                                                                setLocalState({
                                                                    ...localState,
                                                                    selectedCompletedYear: value
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </>
                                            }

                                            {property?.propertyType === PropertyType.Farm && property?.country === CountryCode.Australia &&
                                                <>
                                                    <ParagraphText
                                                        style={{
                                                            fontSize: "15px",
                                                            marginBottom: "20px",
                                                            marginTop: "20px",
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        If you’ve already completed an SB-GAF
                                                        spreadsheet, you can upload that here
                                                    </ParagraphText>
                                                    <FileDrop
                                                        parsingStep={localState.sbGafParsingStep}
                                                        accept={{
                                                            "application/vnd.ms-excel": [".xlsx", ".xlsm"]
                                                        }}
                                                        notYetStartedText={"Optional: Upload your completed SB-GAF spreadsheet here"}
                                                        successText={"SB-GAF successfully loaded!"}
                                                        errorText={"Could not import that SB-GAF file sorry"}
                                                        handleDroppedFile={processedDroppedFile}
                                                        onRetry={retryUpload}
                                                    />
                                                </>
                                            }
                                        </>
                                    </Column>
                                </Row>
                            }
                            <Row style={{ marginTop: "40px" }}>

                                {yearOptions.length > 0 ?
                                    <Column >
                                        <Row>
                                            <LoadingButton
                                                size="medium"
                                                disabled={!localState.selectedYear}
                                                onClick={submit}
                                                colorScheme="green"
                                            >
                                                Create Report
                                            </LoadingButton>
                                        </Row>
                                        {localState.gettingAgriWebbData ? <ActiveProgress /> : undefined}
                                    </Column>
                                    : <LoadingButton
                                        size="medium"
                                        onClick={() => navigate(`/farm/${propertyId}#reports`)}
                                        colorScheme="green"
                                    >
                                        Manage Reports
                                    </LoadingButton>}
                            </Row>

                        </Column>
                    </Row>
                </CreateReportContainer>
            </CreateReportWrapper>
        </Screen>
    );
}
