import { useLocation, Navigate } from "react-router-dom";

export function AddPartnerPageRedirection() {
  const location = useLocation()
  // This ensures the invite ID search parameter is retained
  return <Navigate to={{
    ...location,
    pathname: "/partners"
  }} />
}
