import SubButton from "./sub_button";
import { State } from "@/models/form/form_fields/form_field";
import { ReportDairyProduction, SeasonalFigures } from "@/models/report";
import { Row } from "../styled_layout";
import { usePopupStore } from "@/state/popup_store";
import { MilkCalculatorPopupID } from "../popup/milk_calculator";

type MilkCalculatorButtonProps = {
    state: State<ReportDairyProduction>;
    dairyHeadcount: SeasonalFigures
};

export default function MilkCalculatorButton(props: MilkCalculatorButtonProps) {
    const popupStore = usePopupStore();

    return (<Row>
        <div style={{ padding: "0 0 0 8px" }}>
          <SubButton
              onClick={() =>
                popupStore.addPopup(
                  MilkCalculatorPopupID,
                  (val) => props.state.set({
                    ...props.state.get,
                    milkProduction: val
                  }),
                  {
                    dairyHeadcount: props.dairyHeadcount
                  }
              )
          }>
              Calculate based on Milk Solids
          </SubButton>
        </div>
      </Row>
    );
}
