import Screen from "./screen";
import styled from "styled-components";
import Heading from "../components/heading";
import { BaseSpan, BaseText, TextLink } from "../components/styled_text";
import { ruminatiColors } from "../utilities/colors";
import { Link } from "react-router-dom";
import { Illustration, Wrapper } from "../components/styled_layout";
import MainButton from "../components/buttons/main_button";
import { sendVerificationEmail } from "../services/auth_service";
import { useAuthStore } from "../state/auth_store";
import { useState } from "react";

/**
 * A screen allowing the user to send a verification email, or when
 * the email is verified.
 * @returns the Verify Email Screen component
 */
export default function VerifyEmailScreen() {
    type EmailState = "unsent" | "sending" | "sent" | "error";
    const [status, setStatus] = useState<EmailState>("unsent");

    const authStore = useAuthStore();
    const stateToText = (state: EmailState) => {
        switch (state) {
            case "sent":
                return "Sent!";
            case "sending":
                return "Sending email...";
            case "error":
                return "Error sending email";
            default:
                return "send email";
        }
    };

    const sendVerify = async () => {
        setStatus("sending");
        try {
            if (authStore.user?.email) {
                await sendVerificationEmail(authStore.user.email);
            }
            setStatus("sent");
        } catch (err) {
            setStatus("error");
        }

        // Rate limit sending emails
        setTimeout(() => setStatus("unsent"), 5000);
    };

    return (
        <Screen pageTitle="Verify Email">
            <VerifyEmailWrapper>
                <Wrapper>
                    <Heading level={2}>
                        {!authStore.user?.emailVerified
                            ? "Email Verification"
                            : "Email Verified"}
                    </Heading>
                </Wrapper>

                {/**
                 * Verify page text content
                 */}
                <Wrapper style={{ padding: "0 0 24px 0" }}>
                    {!authStore.user?.emailVerified ? (
                        <BaseText
                            style={{
                                textAlign: "center",
                                color: ruminatiColors.green_3,
                            }}
                        >
                            Please check your email. We have sent you a
                            verification link. It may take a minute or two to
                            arrive in your inbox, and if you don't see anything
                            check your spam folder.
                            <br />
                            <br />
                            Can't see an email from us?{" "}
                            {status === "unsent" ? (
                                <TextLink onClick={sendVerify}>
                                    Send again
                                </TextLink>
                            ) : (
                                <BaseSpan
                                    style={{
                                        color:
                                            status === "error"
                                                ? ruminatiColors.red
                                                : ruminatiColors.orange,
                                    }}
                                >
                                    {stateToText(status)}
                                </BaseSpan>
                            )}
                        </BaseText>
                    ) : (
                        <BaseText
                            style={{
                                textAlign: "center",
                                color: ruminatiColors.green_3,
                            }}
                        >
                            Thanks! You’re all done.
                        </BaseText>
                    )}
                </Wrapper>

                {/**
                 * Button to continue to Get Started
                 */}
                {authStore.user?.emailVerified && (
                    <VerifyEmailContainer>
                        <Wrapper>
                            <Link to="/dashboard" style={{ margin: "0 auto" }}>
                                <MainButton
                                    colorScheme="green"
                                    size="large"
                                    width="100%"
                                >
                                    Get Started
                                </MainButton>
                            </Link>
                        </Wrapper>
                    </VerifyEmailContainer>
                )}
            </VerifyEmailWrapper>
            <Illustration
                src="/images/feeding.png"
                alt="Farmer feeding two brown cows"
            />
        </Screen>
    );
}

const VerifyEmailWrapper = styled.div`
    flex-grow: 1;

    width: 75%;
    min-height: 507px;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    padding: 112px 0 0 0;
`;

const VerifyEmailContainer = styled.div`
    width: 464px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
