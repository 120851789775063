import Map, {
    SourceProps,
    MapLayerMouseEvent,
    MapRef,
    MapEvent,
    LngLatBoundsLike
} from "react-map-gl/maplibre";
import { LngLat, LngLatBounds, LngLatBoundsLike as MlLngLatBoundsLike, ResourceType } from "maplibre-gl";
import {
    isMapboxURL,
    transformMapboxUrl,
} from "maplibregl-mapbox-request-transformer";
import { BBox } from "geojson";
import { useEffect, useRef } from "react";
import { useAuthStore } from "../../state/auth_store";
import { CountryCode } from "../../utilities/countries";
import { baseUrl } from '@/services/api_service';

type BasicMapProps = {
    mapPos?: LngLat;
    bounds?: BBox;
    layers?: SourceProps[];
    height?: string;
    onMapClick?: (e: MapLayerMouseEvent) => void;
    onMapLoad?: (e: MapEvent) => void;
    attributionControl?: boolean;
    fitToBoundsChangeOnBoundsChange?: boolean;
    zoomPadding?: number
    preferredZoom?: number
    onMouseOver?: (e: MapLayerMouseEvent, mapRef: MapRef | null) => void;
    interactiveLayerIds?: string[]
};

const mapboxKey = import.meta.env.REACT_APP_MAPBOX_API_KEY;

export default function BasicMap(props: BasicMapProps) {
    const mapRef = useRef<MapRef>(null);
    const styleUrl = "mapbox://styles/ruminati/cl91xxind000w15pewfxw0bz9";

    const _height: string = props.height ?? "400px";
    const authStore = useAuthStore()
    // Go to the given bounds in props, updating on change
    useEffect(() => {
        if (props.bounds && props.fitToBoundsChangeOnBoundsChange) {
            if (props.preferredZoom === undefined) {
                mapRef.current?.fitBounds(props.bounds as MlLngLatBoundsLike, {
                    padding: 20,
                    maxZoom: 14
                })
            } else {
                mapRef.current?.flyTo({
                    center: new LngLatBounds(props.bounds as [number, number, number, number]).getCenter(),
                    zoom: props.preferredZoom,
                })
            }
        }
    }, [props.bounds, props.fitToBoundsChangeOnBoundsChange, props.preferredZoom]);

    // Go to the given position in props, updating on change
    useEffect(() => {
        if (props.mapPos) {
            mapRef.current?.jumpTo({
                center: { lat: props.mapPos.lat, lng: props.mapPos.lng },
                zoom: 10,
            });
        }
    }, [props.mapPos]);

    const transformRequest = (url: string, resourceType: ResourceType | undefined) => {
        if (mapboxKey === undefined) return { url };
        if (isMapboxURL(url) && resourceType) return transformMapboxUrl(url, resourceType, mapboxKey);
        if (url.startsWith('https://api.psma.com.au/v1/maps/geoscape_v1')) {
            return {
                url: `${baseUrl}/geo/proxy?url=${url}`
            }
        }
        return { url };
    };

    let bounds: LngLatBoundsLike | undefined = undefined;

    if (props.bounds && props.bounds[0] !== Infinity) {
        bounds = props.bounds as LngLatBoundsLike;
    }

    const onMapClick = (event: MapLayerMouseEvent) => {
        if (props.onMapClick) {
            props.onMapClick(event);
        }
    };

    const initalViewOptions = {
        longitude: 133,
        latitude: -27,
        zoom: 3,
        bounds,
        fitBoundsOptions: {
            padding: props.zoomPadding ?? 10
        }
    }
    if (authStore.user?.country === CountryCode.NewZealand) {
        initalViewOptions.longitude = 174
        initalViewOptions.latitude = -41
        initalViewOptions.zoom = 4
    }
    if (import.meta.env.MODE === 'test') return <></>
    return (
        <Map
            ref={mapRef}
            id="myMapA"
            onClick={onMapClick}
            onLoad={props.onMapLoad ?? undefined}
            initialViewState={initalViewOptions}
            style={{ height: _height }}
            mapStyle={styleUrl}
            transformRequest={transformRequest}
            attributionControl={props.attributionControl ?? true}
            maxPitch={0}
            dragRotate={false}
            onMouseMove={(e) => {
                if (props.onMouseOver) props.onMouseOver(e, mapRef.current)
            }}
            interactiveLayerIds={props.interactiveLayerIds}
        >
            {props.layers}
        </Map>
    );
}
