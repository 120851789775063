import styled from "styled-components";
import { MapLayerMouseEvent } from "maplibre-gl";
import { BBox, FeatureCollection, MultiPolygon, Polygon } from "geojson";
import { ReactElement, useEffect, useState } from "react";
import { LngLat, Map } from "maplibre-gl";

import { useAuthStore } from "../../state/auth_store";
import { ruminatiColors } from "../../utilities/colors";
import BasicMap from "../maps/basic_map";
import getPropertyLayerInformation, { backgroundPropertyLayerStyling } from "../maps/layer_config/property_layer";
import {
    createEmptyFeatureCollection,
    removeFeatureFromFeatureCollectionAtCoords,
    calculateBoundingBoxFromGeojson,
    isPointInFeatureCollection,
    convertFeatureCollectionToMultiPolygon,
    createFeatureCollectionFromMultiPolygon,
    sortFeaturesByDistanceToLngLat
} from "../../utilities/geojson-helpers";
import { getPropertyBoundary } from "../../services/property_service";
import EditPropertyMenu from "./edit_property_menu";
import { RotatingIcon } from "../buttons/loading_button";
import Icon from "../icon";
import { CountryCode, countryIsAustralia, countryIsNZ } from "../../utilities/countries";
import createEsriFeatureLayer from "../maps/layer_types/esri_feature_lyr";
import createVectorTileLayer from "../maps/layer_types/vector_tile_lyr";
import ZoomInOverlay from "../maps/zoom_in_overlay";
import ZoomButtonOverlay from "../maps/zoom_button_overlay";
import { LayerOptions, VectorTileLayerOptions } from "../maps/layer_config/styling_options";

export type AreaSelectProps = {
    height: number;
    initialGeom?: MultiPolygon;
    suggestedBounds?: BBox;
    onPropertyBoundaryChanged: (propertyBoundary: MultiPolygon | undefined) => void;
    showEditOverlay?: boolean;
};

/**
 * A map allowing the user to select an area around their property.
 * @param height the height of the map
 * @param suggestedBounds optional.
 * @param onPropertyBoundaryChanged A function that will be called when the property boundary changes.
 * @param showEditOverlay Whether to show the Edit overlap
 * @param onStartEditing A function that will be called when the start editing button is hit
 * @param onStopEditing A function that will be called when the stop editing button is hit
 */
export default function AreaSelect({
    height,
    suggestedBounds,
    initialGeom,
    onPropertyBoundaryChanged,
    showEditOverlay
}: AreaSelectProps) {
    const mapData: FeatureCollection<Polygon|MultiPolygon> = initialGeom ? createFeatureCollectionFromMultiPolygon(initialGeom) : createEmptyFeatureCollection()

    const [usersPosition, setUsersPosition] = useState<LngLat | undefined>(undefined);
    const [propertyBounds, setPropertyBounds] = useState<BBox | undefined>(initialGeom ? calculateBoundingBoxFromGeojson(initialGeom) : undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const authStore = useAuthStore()

    // Attempt to get the user's location for initial map position
    useEffect(() => {
        // Check if geolocation API is enabled
        if (initialGeom === undefined && "geolocation" in navigator && suggestedBounds === undefined) {
            navigator.geolocation.getCurrentPosition(function (position) {
                if (position) {
                    setUsersPosition(
                        new LngLat(
                            position.coords.longitude,
                            position.coords.latitude
                        )
                    );
                }
            });
        }
    }, [initialGeom, suggestedBounds]);

    const onMapClick = async (event: MapLayerMouseEvent) => {
        // If the edit tools are enabled let's not request property boundaries from the API
        if (showEditOverlay) return;

        // If the user clicks on an existing feature on the map 
        // then we remove it
        if (isPointInFeatureCollection(
            [event.lngLat.lng, event.lngLat.lat],
            mapData
        )) {
            const updatedFc = createEmptyFeatureCollection();
            updatedFc.features.push(...mapData.features);
            removeFeatureFromFeatureCollectionAtCoords([event.lngLat.lng, event.lngLat.lat], updatedFc)
            emitPropertyBounds(updatedFc);
            return;
        }

        setLoading(true);

        try {
            const fc = await getPropertyBoundary(
                event.lngLat.lat,
                event.lngLat.lng,
                authStore.user?.country as CountryCode
            );

            if (!fc) return;
            
            // Features from the Geoscape service aren't returned by distance from
            // the click event hence we sort them
            fc.features = sortFeaturesByDistanceToLngLat(fc.features, event.lngLat);
            const updatedFc = createEmptyFeatureCollection();

            if (!fc.features || !fc.features[0]) return;
            
            updatedFc.features.push(...mapData.features, fc.features[0]);
            emitPropertyBounds(updatedFc);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    function emitPropertyBounds(fc: FeatureCollection<Polygon | MultiPolygon>) {
        if (fc.features.length === 0) onPropertyBoundaryChanged(undefined);
        else {
            setPropertyBounds(calculateBoundingBoxFromGeojson(fc))
            const mp = convertFeatureCollectionToMultiPolygon(fc)
            if (mp === null) return
            onPropertyBoundaryChanged(mp);
        }
    }

    const propertyLayerInfo = getPropertyLayerInformation({ gj: mapData });

    const bestBounds = propertyBounds ?? suggestedBounds;

    const editingFinished = (newMapData: FeatureCollection<Polygon>) => {
        emitPropertyBounds(newMapData);
    };

    // @ts-expect-error weird typing compatiability
    const dataSourceSettings: VectorTileLayerOptions | LayerOptions = countryIsAustralia(authStore.user?.country as CountryCode) ? {
        dataSourceId: 'aus-property-tiles',
        fillLayerId: 'aus-property-tiles-fill-lyr',
        lineLayerId: 'aus-property-tiles-line-lyr',
        vtLayerId: 'property',
        promoteId: 'property_polygon_pid',
        ...backgroundPropertyLayerStyling
    } : {
        dataSourceId: 'nz-titles',
        fillLayerId: 'nz-titles-fill-lyr',
        lineLayerId: 'nz-titles-line-lyr',
        promoteId: 'OBJECTID',
        ...backgroundPropertyLayerStyling
    }

    const backgroundPropertyBoundaryLayer = countryIsAustralia(authStore.user?.country as CountryCode) ? createVectorTileLayer('https://api.psma.com.au/v1/maps/geoscape_v1/property/{z}/{x}/{y}.pbf', dataSourceSettings as VectorTileLayerOptions) : undefined

    function setupBackgroundPropertyBoundaryLayerForNZ(mapInstance: Map) {
        const propertySizeFilter = "Shape__Area > 3000"
        createEsriFeatureLayer(
            'https://services.arcgis.com/xdsHIIxuCWByZiCB/arcgis/rest/services/LINZ_NZ_Property_Titles/FeatureServer/0',
            dataSourceSettings,
            mapInstance,
            propertySizeFilter
        )
    }

    return (
        <AreaSelectContainer>
            <AreaSelectWrapper>
                {loading && (
                    <PropertyLoading>
                        <RotatingIcon>
                            <Icon icon="loading" />
                        </RotatingIcon>
                    </PropertyLoading>
                )}
                <div style={{ filter: loading ? "brightness(0.8)" : "none" }}>
                    <BasicMap
                        mapPos={usersPosition}
                        height={`${height}px`}
                        onMapClick={onMapClick}
                        onMapLoad={(e) => {
                            if (countryIsNZ(authStore.user?.country as CountryCode)) {
                                const mapInstance = e.target
                                setupBackgroundPropertyBoundaryLayerForNZ(mapInstance)
                            }
                        }}
                        attributionControl={false}
                        layers={
                            showEditOverlay ? [backgroundPropertyBoundaryLayer as ReactElement] :
                                [
                                    propertyLayerInfo.layer,
                                    backgroundPropertyBoundaryLayer as ReactElement
                                ]
                        }
                        bounds={bestBounds}
                        fitToBoundsChangeOnBoundsChange={mapData.features.length < 2}
                        preferredZoom={mapData.features.length < 1 ? 12 : undefined}
                        zoomPadding={10}
                        onMouseOver={(e, map) => {
                            if (showEditOverlay && map !== null) {
                                map.removeFeatureState({
                                    source: dataSourceSettings.dataSourceId,
                                    sourceLayer: (dataSourceSettings as VectorTileLayerOptions).vtLayerId ?? undefined
                                })
                                return
                            }
                            if (map !== null && e.features && e.features[0]) {
                                map.removeFeatureState({
                                    source: dataSourceSettings.dataSourceId,
                                    sourceLayer: (dataSourceSettings as VectorTileLayerOptions).vtLayerId ?? undefined
                                })
                                map.setFeatureState({
                                    source: dataSourceSettings.dataSourceId,
                                    sourceLayer: (dataSourceSettings as VectorTileLayerOptions).vtLayerId ?? undefined,
                                    id: e.features[0].id
                                }, { hover: true })
                            }
                        }}
                        interactiveLayerIds={[dataSourceSettings.fillLayerId]}
                    />

                    {showEditOverlay && (
                        <EditPropertyMenu
                            geojsonToEdit={mapData}
                            finishEditing={editingFinished}
                        />
                    )}

                    <ZoomInOverlay
                        minZoom={backgroundPropertyLayerStyling.minZoom}
                    />

                    <ZoomButtonOverlay />
                </div>
            </AreaSelectWrapper>
        </AreaSelectContainer>
    );
}

const AreaSelectContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const AreaSelectWrapper = styled.div`
    border-radius: 8px;
    background-color: ${ruminatiColors.green_3_30};
    overflow: hidden;
`;

const PropertyLoading = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: scale(2) translate(-50%, -50%);
    svg path {
        fill: white;
        box-shadow: 5px 5px solid black;
    }
`;
