import { MultiPolygon } from "geojson";
import { ReportResults } from "./property_models";
import { ReportManureManagement, ReportManureManagementForm } from "./report";

export enum InitiativeType {
    ImproveSoilCarbon = "improve-soil-carbon",
    PlantTrees = "plant-trees",
    PlantTreesNZ = "plant-trees-nz",
    AllowRevegetation = "allow-revegetation",
    ManageLivestock = "manage-livestock",
    ManageLivestockClassesProduction = "manage-livestock-classes-production",
    ManageLivestockClassesProductionNZ = "manage-livestock-classes-production-nz",
    ManagePasture = "manage-pasture",
    ManagePasture_Post_V3_4 = "manage-pasture-post-v3_4",
    ManageConsumables = "manage-consumables",
    ManageSupplement = "manage-supplements",
    ManageCrops = "manage-crops",
    DairyRations = "dairy-rations",
    DairyLivestockProduction = "dairy-production",
    DairyManure = "dairy-manure",
    CattlePurchaseAverageIntensity = "cattle-purchase-average-intensity",
    FeedAdditives = "feed-additives"
}

/*
 ** Get initiative names using their ID.
 */
export const initiativeNamesMap: Record<InitiativeType, string> = {
    [InitiativeType.ImproveSoilCarbon]: "Improve Soil Carbon Storage",
    [InitiativeType.PlantTrees]: "Plant Trees",
    [InitiativeType.PlantTreesNZ]: "Plant Trees",
    [InitiativeType.AllowRevegetation]: "Allow Revegetation",
    [InitiativeType.ManageLivestock]: "Manage Livestock",
    [InitiativeType.ManageLivestockClassesProduction]: "Manage Livestock & Production",
    [InitiativeType.ManageLivestockClassesProductionNZ]: "Manage Livestock & Production",
    [InitiativeType.ManagePasture]: "Manage Pasture",
    [InitiativeType.ManagePasture_Post_V3_4]: "Manage Pasture",
    [InitiativeType.ManageConsumables]: "Manage Consumables",
    [InitiativeType.ManageSupplement]: "Manage Supplementary Feed",
    [InitiativeType.ManageCrops]: "Manage Crops",
    [InitiativeType.DairyRations]: "Manage Rations",
    [InitiativeType.DairyLivestockProduction]: "Manage Livestock & Production",
    [InitiativeType.DairyManure]: "Manage Manure",
    [InitiativeType.CattlePurchaseAverageIntensity]: "Emissions Profile of Purchased Cattle",
    [InitiativeType.FeedAdditives]: "Methane-reducing Feed Additives"
};

export const initiativeFieldNamesMap: Record<string, string> = {
    cattleHeadcountChange: "Cattle headcount change",
    sheepHeadcountChange: "Sheep headcount change",
    beefDSE: "Beef DSE",
    sheepDSE: "Sheep DSE",
    urea: "Urea",
    lime: "Lime",
    singleSuperphosphate: "Single Superphosphate",
    pesticidePurchased: "Pesticide Purchased",
    pesticideHerbicide: "Pesticide & Herbicide",
    petrolDiesel: "Petrol/Diesel",
    energyUsage: "Energy Usage",
    renewableUsage: "Renewable Usage",
    supplementaryFeed: "Supplementary Feed",
    areaSown: "Area Sown",
    averageYield: "Average Yield Change",
    fertiliser: "Fertiliser",
    herbicidePesticide: "Herbicide/Pesticide",
    dairyRations: "Dairy Rations",
    dairyManure: "Manure Management",
    dairyProduction: "Livestock & Production",
    cattlePurchaseAverageIntensity: "Cattle Purchase Average Intensity",
    feedAdditives: "Methane-reducing Feed Additives"
};

export enum InitiativeCategory {
    AbsorbEmissions = 0,
    AvoidEmissions = 1,
    FutureSolutions = 2,
}

const initiativeTypesByCategory: Record<InitiativeCategory, InitiativeType[]> =
{
    [InitiativeCategory.AbsorbEmissions]: [
        InitiativeType.ImproveSoilCarbon,
        InitiativeType.AllowRevegetation,
        InitiativeType.PlantTrees,
        InitiativeType.PlantTreesNZ,

    ],
    [InitiativeCategory.AvoidEmissions]: [
        InitiativeType.ManageLivestock,
        InitiativeType.ManageLivestockClassesProduction,
        InitiativeType.ManageLivestockClassesProductionNZ,
        InitiativeType.ManagePasture,
        InitiativeType.ManagePasture_Post_V3_4,
        InitiativeType.ManageConsumables,
        InitiativeType.ManageCrops,
        InitiativeType.DairyRations,
        InitiativeType.DairyLivestockProduction,
        InitiativeType.DairyManure,
        InitiativeType.CattlePurchaseAverageIntensity,
        InitiativeType.FeedAdditives
    ],
    [InitiativeCategory.FutureSolutions]: [],
};

export const initiativeCategoriesMap: Record<InitiativeCategory, string> = {
    [InitiativeCategory.AbsorbEmissions]: "Absorb Emissions",
    [InitiativeCategory.AvoidEmissions]: "Avoid Emissions",
    [InitiativeCategory.FutureSolutions]: "Future Solutions",
};

export class Initiative {
    id: InitiativeType;
    category: InitiativeCategory;
    name: string;
    description: string;
    infoUrl?: string;

    constructor(
        id: InitiativeType,
        category: InitiativeCategory,
        name: string,
        description: string,
        infoUrl?: string
    ) {
        this.id = id;
        this.category = category;
        this.name = name;
        this.description = description;
        this.infoUrl = infoUrl ?? undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static fromJSON(json: any): Initiative | null {
        if (!json || !("id" in json)) return null;

        return new Initiative(
            json.id,
            json.category,
            json.name,
            json.description,
            json.infoUrl
        );
    }
}

/**
 * A request sent to Reduction Plans update endpoint.
 */

export interface ReductionPlansRequest<T extends ReductionPlanInputDTO> {
    initiativeType: InitiativeType;
    data: T;
}

/**
 * The model for a single reduction plan.
 * Also the response received from Reduction Plans update endpoint.
 */
export class ReductionPlan {
    id: string;
    propertyId: string;
    initiativeType: InitiativeType;
    inputs: ReductionPlanInputDTO | ReductionPlanInputDTO[];
    results: Record<string, ReductionPlanResultDTO> | ReductionPlanResultDTO[];

    constructor(
        id: string,
        propertyId: string,
        initiativeType: InitiativeType,
        inputs: ReductionPlanInputDTO | ReductionPlanInputDTO[],
        results:
            | Record<string, ReductionPlanResultDTO>
            | ReductionPlanResultDTO[]
    ) {
        this.id = id;
        this.propertyId = propertyId;
        this.initiativeType = initiativeType;
        this.inputs = inputs;
        this.results = results;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static fromJSON(json: any): ReductionPlan | undefined {
        if (!json || !("id" in json)) return undefined;

        return new ReductionPlan(
            json.id,
            json.propertyId,
            json.initiativeType,
            json.inputs,
            json.results
        );
    }
    public static getCategoryFromType(type: InitiativeType) {
        for (const category of [
            InitiativeCategory.AbsorbEmissions,
            InitiativeCategory.AvoidEmissions,
            InitiativeCategory.FutureSolutions,
        ]) {
            if (initiativeTypesByCategory[category].includes(type)) {
                return category;
            }
        }
    }

    public removingIndexes(indexes: number[]): ReductionPlan {
        if (this.inputs instanceof Array && this.results instanceof Array) {
            return new ReductionPlan(
                this.id,
                this.propertyId,
                this.initiativeType,
                this.inputs.filter((_, index) => !indexes.includes(index)),
                this.results.filter((_, index) => !indexes.includes(index))
            );
        }
        return this;
    }

    public total(
        key: keyof ReductionPlanResultDTO["totals"],
        latestCompletedReportFY?: number
    ): number {
        if (this.results instanceof Array) {
            const inputs = this.inputs as ReductionPlanInputDTO[];
            const results = this.results as ReductionPlanResultDTO[];
            return results.reduce((total, r) => {
                const val = r.totals[key];
                const year = (inputs[results.indexOf(r)] as | PlantTreesDTO | AllowRevegetationDTO).startYear;
                return (total + (val && year > (latestCompletedReportFY ?? 0) ? val : 0));
            }, 0);
        } else {
            const inputs = this.inputs as Record<string, AvoidEmissionsDTO>;
            const results = this.results as Record<string, ReductionPlanResultDTO>;
            return Object.keys(results).reduce(
                (total, r) => { 
                    const val = results[r].totals[key];
                    return total + (val && inputs[r].targetYear > (latestCompletedReportFY ?? 0) ? val : 0);
                }, 0);
        }
    }

    public totalIntensity(
        key: keyof ReportResults["intensity"],
        latestCompletedReportFY?: number
    ): number {
        if (this.results instanceof Array) {
            const inputs = this.inputs as ReductionPlanInputDTO[];
            const results = this.results as ReductionPlanResultDTO[];
            return results.reduce((total, r) => {
                const val = (r.intensity ?? {})[key];
                const year = (inputs[results.indexOf(r)] as | PlantTreesDTO | AllowRevegetationDTO).startYear;
                return (total + (val && year > (latestCompletedReportFY ?? 0) ? val : 0));
            }, 0);
        } else {
            const inputs = this.inputs as Record<string, AvoidEmissionsDTO>;
            const results = this.results as Record<string, ReductionPlanResultDTO>
            return Object.keys(results).reduce(
                (total, r) => {
                    const val = (results[r].intensity ?? {})[key];
                    return total + (val && inputs[r].targetYear > (latestCompletedReportFY ?? 0) ? val : 0);
                }, 0);
        }
    }
}

// export interface ReductionPlan

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ReductionPlanInputDTO { }
export interface ReductionPlanResultDTO {
    totals: {
        total: number;
        sequestration: number;
        net: number;
    };
    sequestration?: {
        soil: number;
        trees: number;
    };
    byScopes?: {
        scope1: number;
        scope2: number;
        scope3: number;
    };
    scope1ByGas?: {
        carbonDioxide: number;
        methane: number;
        nitrousOxide: number;
    };
    intensity?: ReportResults["intensity"];
}

/*
 ** Reduce Emissions-related DTOs.
 */

export interface ImproveSoilCarbonDTO extends ReductionPlanInputDTO {
    name: string;
    startYear: number;
    currentSOC: number;
    targetSOC: number;
    bulkDensity: number;
    soilDepth: number;
    geom: MultiPolygon;
}

export interface PlantTreesDTO extends ReductionPlanInputDTO {
    name: string;
    geom: MultiPolygon;
    startYear: number;
}

export interface PlantTreesNzDTO extends PlantTreesDTO {
    // see nzVegetationTypes in /src/models/form_options.tsx
    species: number;
}

export interface AllowRevegetationDTO extends ReductionPlanInputDTO {
    name: string;
    geom: MultiPolygon;
    startYear: number;
}

/*
 ** Avoid Emissions-related DTOs.
 */

export interface AvoidEmissionsDTO extends ReductionPlanInputDTO {
    targetValue: number;
    targetYear: number;
}

export interface ManageLivestockDTO extends ReductionPlanInputDTO {
    beefDSE?: AvoidEmissionsDTO;
    sheepDSE?: AvoidEmissionsDTO;
}

export interface ManageLivestockClassesProductionDTO extends ReductionPlanInputDTO {
    cattleHeadcountChange?: AvoidEmissionsDTO;
    beefProduction?: number;
    sheepHeadcountChange?: AvoidEmissionsDTO;
    sheepProduction?: number;
}

export interface ManagePasturesDTO extends ReductionPlanInputDTO {
    urea?: AvoidEmissionsDTO;
    lime?: AvoidEmissionsDTO;
    singleSuperphosphate?: AvoidEmissionsDTO;
    pesticidePurchased?: AvoidEmissionsDTO;
}

export interface ManagePastures_Post_V3_4_DTO extends ReductionPlanInputDTO {
    urea?: AvoidEmissionsDTO;
    lime?: AvoidEmissionsDTO;
    singleSuperphosphate?: AvoidEmissionsDTO;
    pesticideHerbicide?: AvoidEmissionsDTO;
}

export interface ManageConsumablesDTO extends ReductionPlanInputDTO {
    petrolDiesel?: AvoidEmissionsDTO;
    energyUsage?: AvoidEmissionsDTO;
    renewableUsage?: AvoidEmissionsDTO;
}

export interface ManageSupplementsDTO extends ReductionPlanInputDTO {
    supplementaryFeed?: AvoidEmissionsDTO;
}

export interface ManageCropInputsDTO extends ReductionPlanInputDTO {
    areaSown?: AvoidEmissionsDTO;
    fertiliser?: AvoidEmissionsDTO;
    herbicidePesticide?: AvoidEmissionsDTO;
    averageYield?: AvoidEmissionsDTO;
}

export interface ManageManureForm extends ReportManureManagementForm {
    targetYear: number
}

export interface ManageManureDto extends ReductionPlanInputDTO {
    dairyManure: ReportManureManagement & {
        targetYear: number
    }
}

export interface ManageDairyLivestockProductionForm {
    dairyHeadcountChange?: number;
    milkProductionChange?: number;
    targetYear: number;
}

export interface ManageDairyLivestockProduction extends ReductionPlanInputDTO {
  dairyProduction: ManageDairyLivestockProductionForm
}

export interface ManageRationsInputs extends ReductionPlanInputDTO {
    dairyRations: ManageRationsForm
}

export interface ManageRationsForm  {
    avgProteinChangeMilkingCows: number
    avgProteinChangeNonMilkingCows: number
    seperator?: any
    avgDmdChangeMilkingCows: number
    avgDmdChangeNonMilkingCows: number
    targetYear: number
}

export interface ManageCattlePurchaseAverageIntensityDTO extends ReductionPlanInputDTO {
    cattlePurchaseAverageIntensity?: AvoidEmissionsDTO;
}

export interface ManageFeedAdditivesDTO extends ReductionPlanInputDTO {
    feedAdditives?: AvoidEmissionsDTO;
}