import BaseRangeSlider, { SharedRangeSliderProps, SliderType } from "./range_slider";

export type RelativeChangeOfAbsoluteValueProps = Omit<SharedRangeSliderProps, "sliderType"> & {
    initialValue: number
    decimalPrecisionTop?: number
}

export default function RelativeChangeOfAbsoluteValueSlider(props: RelativeChangeOfAbsoluteValueProps) {

    function calculateInitialValue(originalValue: number, percentChange: number) {
        if (percentChange === 0) return originalValue
        if (percentChange < 0) {
            const out = originalValue - (Math.abs(percentChange) * originalValue)
            return out
        }
        const out = originalValue + (Math.abs(percentChange) * originalValue)
        return out
    }

    function calculateRelativeDifference (originalValue: number, newValue: number) {
        return ((newValue - originalValue) / originalValue) * 100
    }

    return <BaseRangeSlider
        {...props}
        initialValue={calculateInitialValue(props.prevReportValue ?? 0, props.initialValue ?? 0)}
        sliderType={SliderType.AbsoluteValues}
        onMove={(val) => {
            if (props.onMove) props.onMove(calculateRelativeDifference(props.prevReportValue ?? 0, val))}
        }
        />;
}
