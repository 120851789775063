import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { MultiPolygon } from "geojson";
import { useRollbar } from "@rollbar/react";

import Screen from "./screen";
import Heading from "../components/heading";
import { Column, Row } from "../components/styled_layout";
import GetStartedName from "../components/property/get_started_name";
import GetStartedMap from "../components/property/get_started_map";
import GetStartedReview from "../components/property/get_started_review";
import { createProperty, createReport } from "../services/property_service";
import { useDatabaseStore } from "../state/database_store";
import { BodyText, ParagraphText } from "../components/styled_text";
import { getAgriWebbBoundaries } from "../services/agriwebb_service";
import { convertPolygonsToMultiPolygon } from '../utilities/geojson-helpers'
import GetStartedEnterprise, { EnterpriseType } from "../components/property/get_started_enterprise";
import { CreateDairyDTO, CreateFarmDTO, CreateFeedlotDTO, CreateProcessorDTO, DairyProductionSystemType, FeedlotSystemType, PropertyType } from "../models/property_models";
import { CountryCode } from "../utilities/countries";
import { useAuthStore } from "../state/auth_store";
import Steps, { Step } from "../components/steps";
import { InfoCard } from "../components/card";

import { openNotificationError } from "@/utilities/notification-defaults";
import { convertUnknownErrortoStringable } from "@/utilities/errors";

export enum GetStartedStep {
    EnterpriseType,
    Name,
    SelectProperty,
    Review
}

type StepAndEnum = Step & {
    enum: GetStartedStep
    pageTitle: string
    description: string
    helpText?: JSX.Element
}

const steps: StepAndEnum[] = [
    {
        id: 'type',
        label: 'Enterprise Type',
        enum: GetStartedStep.EnterpriseType,
        pageTitle: 'Create New Enterprise',
        description: 'Ruminati currently supports producers, feedlots, processing facilities and dairies.'
    },
    {
        id: 'details',
        label: 'Enterprise Details',
        enum: GetStartedStep.Name,
        pageTitle: 'Enterprise Details',
        description: 'Enter some basic information about your enterprise.'
    },
    {
        id: 'location',
        label: 'Location & Boundary',
        enum: GetStartedStep.SelectProperty,
        pageTitle: 'Locate your property',
        description: 'Use the interactive map below to find the location of your enterprise and provide its property boundary.',
        helpText: <BodyText><strong>Note:</strong> Don't worry if you can't get the boundary exactly correct - an approximation is sufficient.</BodyText>
    },
    {
        id: 'review',
        label: 'Review',
        enum: GetStartedStep.Review,
        pageTitle: 'Review Enterprise',
        description: 'Check all the details about your enterprise before submitting.',
        helpText: <BodyText><strong>Note:</strong> Need to make an adjustment? Use the progress buttons on the left.</BodyText>
    }
]

export type GetStartedProperty = {
    name: string | undefined,
    type: EnterpriseType,
    geom: MultiPolygon | undefined,
    pic?: string,
}

export type GetStartedDairy = GetStartedProperty & {
    dairyProductionSystem: DairyProductionSystemType | undefined
}

export type GetStartedFarm = GetStartedProperty & {
    type: EnterpriseType.Farm,
    cattleFarm: boolean,
    sheepFarm: boolean,
    grainFarm: boolean,
    agriwebbFarmId: string | undefined,
    ngr?: string,
}

export type GetStartedFeedlot = GetStartedProperty & {
    type: EnterpriseType.Feedlot,
    productionSystem: FeedlotSystemType | undefined,
}

export type GetStartedProcessor = GetStartedProperty & {
    type: EnterpriseType.ProcessingFacility,
    propertyType: PropertyType.Processor | PropertyType.FurtherProcessor,
    beefProcessor: boolean,
    sheepProcessor: boolean,
}

const emptyFarm: GetStartedFarm = {
    name: undefined,
    type: EnterpriseType.Farm,
    cattleFarm: false,
    sheepFarm: false,
    grainFarm: false,
    pic: undefined,
    agriwebbFarmId: undefined,
    ngr: undefined,
    geom: undefined,
}

const emptyFeedlot: GetStartedFeedlot = {
    name: undefined,
    type: EnterpriseType.Feedlot,
    productionSystem: undefined,
    pic: undefined,
    geom: undefined,
}

const emptyProcessor: GetStartedProcessor = {
    name: undefined,
    type: EnterpriseType.ProcessingFacility,
    propertyType: PropertyType.Processor,
    beefProcessor: false,
    sheepProcessor: false,
    pic: undefined,
    geom: undefined,
}

const emptyDairy: GetStartedDairy = {
    name: undefined,
    type: EnterpriseType.Dairy,
    dairyProductionSystem: undefined,
    pic: undefined,
    geom: undefined
}

/**
 * The Get Started screen to set up a property
 * @returns the Get Started Screen component
 */

export default function GetStartedScreen() {
    const navigate = useNavigate();
    const user = useAuthStore((state) => state.user);
    const databaseStore = useDatabaseStore();

    const [step, setStep] = useState<GetStartedStep>(GetStartedStep.EnterpriseType);
    const selectedStep = steps.find(s => s.enum === step)
    // const [completedSteps, setCompletedSteps] = useState([] as string[]);
    const completedSteps = steps.slice(0, steps.findIndex(s => s.enum === step)).map(s => s.id)

    const [property, setProperty] = useState<GetStartedProperty>(emptyFarm);

    const [searchParams] = useSearchParams();

    const rollbar = useRollbar()
    const [showMissingFields, setShowMissingFields] = useState(false)

    const getAgriWebbFarmId = (property: GetStartedProperty) => {
        if (property.type === EnterpriseType.Farm) {
            const farm = property as GetStartedFarm;
            return farm.agriwebbFarmId;
        }
        return undefined;
    };

    const onPropertyBoundaryChanged = async (propertyGeometry: MultiPolygon | undefined) => {
        setProperty((property) => ({
            ...property,
            geom: propertyGeometry
        }));
    }

    const propertyToDto = (property: GetStartedProperty, userCountryCode: CountryCode) => {
        if (property.geom === undefined) {
            throw new Error("[get_started_screen.tsx] Can't create property if geom is undefined.");
        }
        if (property.name === undefined) {
            throw new Error("[get_started_screen.tsx] Can't create property if name is undefined.");
        }

        if (property.type === EnterpriseType.Farm) {
            const farm: GetStartedFarm = property as GetStartedFarm;
            const dto: CreateFarmDTO = {
                name: property.name,
                propertyType: PropertyType.Farm,
                cattleFarm: farm.cattleFarm,
                sheepFarm: farm.sheepFarm,
                grainFarm: farm.grainFarm,
                pic: farm.pic,
                ngr: farm.ngr,
                agriwebbFarmId: farm.agriwebbFarmId,
                geom: property.geom,
                country: userCountryCode
            };
            return dto;
        } else if (property.type === EnterpriseType.Feedlot) {
            const feedlot: GetStartedFeedlot = property as GetStartedFeedlot;
            if (feedlot.productionSystem === undefined)
                throw new Error("[get_started_screen.tsx] Feed lot can't be created with undefined system");

            const dto: CreateFeedlotDTO = {
                name: property.name,
                propertyType: PropertyType.Feedlot,
                productionSystem: feedlot.productionSystem,
                pic: feedlot.pic,
                geom: property.geom,
                country: userCountryCode
            };
            return dto;
        } else if (property.type === EnterpriseType.ProcessingFacility) {
            const processor: GetStartedProcessor = property as GetStartedProcessor;
            const dto: CreateProcessorDTO = {
                name: property.name,
                propertyType: processor.propertyType,
                beefProcessor: processor.beefProcessor,
                sheepProcessor: processor.sheepProcessor,
                pic: processor.pic,
                geom: property.geom,
                country: userCountryCode
            };
            return dto;
        } else if (property.type === EnterpriseType.Dairy) {
            const dairy: GetStartedDairy = property as GetStartedDairy;
            if (dairy.dairyProductionSystem === undefined) {
                throw new Error("Dairy can't be created with undefined system");
            }

            const dto: CreateDairyDTO = {
                name: property.name,
                dairyProductionSystem: dairy.dairyProductionSystem,
                pic: dairy.pic,
                geom: property.geom,
                country: userCountryCode,
                propertyType: PropertyType.Dairy
            };
            return dto;
        }

        throw new Error('[get_started_screen.tsx] Unimplemented enterprise type');
    }

    const submitProperty = async () => {
        if (!user) return
        try {
            const newProperty = await createProperty(propertyToDto(property, user.country ?? CountryCode.Australia));
            if (newProperty) {
                databaseStore.updateProperty(newProperty);

                if (searchParams.has("year")) {
                    const year = searchParams.get("year") as string
                    const reportFinancialYear = parseInt(year);
                    const report = await createReport(
                        newProperty.id,
                        reportFinancialYear
                    );
                    if (report) {
                        databaseStore.updateReport(report);
                        return navigate(`/summary/${report.id}`);
                    }
                }
                navigate(`/farm/${newProperty.id}`);
            }
        } catch (err) {
            const isDataError = err instanceof Error && err.message === "INVALID_DATA"
            if (isDataError) {
                openNotificationError({
                    heading: 'Error Creating Enterprise',
                    message: 'It looks like something went wrong submitting your data, please see description below. Let us know if you continue to have problems.',
                    cause: err instanceof Error ? err.cause as string : undefined
                })
            } else {
                openNotificationError({
                    heading: 'Error Creating Enterprise',
                    message: 'Looks like something went wrong. Please try again later or let us know if you continue to have problems.',
                })
            }

            rollbar.error('Error creating Enterprise', {
                errorDetails: convertUnknownErrortoStringable(err),
            })
        }
    };

    const loadAgriWebbBoundaries = async (farmId: string) => {
        const boundaries = await getAgriWebbBoundaries(farmId)
        if (boundaries) {
            const mp = convertPolygonsToMultiPolygon(boundaries)
            if (mp) onPropertyBoundaryChanged(mp)
        }
    }

    const getHeaderContent = () => {
        return (<Row style={{ marginBottom: '20px' }}>
            <Column>
                <Row>
                    <Heading level={2}>{selectedStep?.pageTitle}</Heading>
                </Row>
                <Row>
                    <ParagraphText style={{ marginTop: '10px', marginBottom: '12px', fontSize: '15px' }}>
                        {selectedStep?.description}
                    </ParagraphText>
                </Row>

                {selectedStep?.helpText ?
                    <Row style={{ marginBottom: '12px' }}>
                        <InfoCard
                            content={selectedStep?.helpText}
                        />
                    </Row> : undefined
                }
            </Column>
        </Row>)
    }

    const getPageContent = (step: GetStartedStep) => {
        switch (step) {
            case GetStartedStep.EnterpriseType:
                return <GetStartedEnterprise onSubmit={(type) => {
                    switch (type) {
                        case EnterpriseType.Farm:
                            setProperty(emptyFarm);
                            break;
                        case EnterpriseType.Feedlot:
                            setProperty(emptyFeedlot);
                            break;
                        case EnterpriseType.ProcessingFacility:
                            setProperty(emptyProcessor);
                            break;
                        case EnterpriseType.Dairy:
                            setProperty(emptyDairy);
                            break;
                    }
                    setStep(GetStartedStep.Name);
                }} />;
            case GetStartedStep.Name:
                return <GetStartedName
                    property={property}
                    setProperty={(value) => {
                        setProperty({
                            ...property,
                            ...value,
                            geom: property.geom !== undefined && value.geom === undefined ? property.geom : value.geom
                        })
                    }}
                    onSubmit={async (value) => {
                        if (value.type === EnterpriseType.Farm) {
                            const farm = value as GetStartedFarm;
                            if (farm.agriwebbFarmId !== undefined) {
                                await loadAgriWebbBoundaries(farm.agriwebbFarmId);
                            }
                        }
                        setStep(GetStartedStep.SelectProperty)
                    }}
                    showMissingFields={showMissingFields}
                    setShowMissingFields={setShowMissingFields}
                />
            case GetStartedStep.Review:
                return <GetStartedReview
                    property={property as GetStartedFarm | GetStartedFeedlot | GetStartedProcessor}
                    onSubmit={submitProperty}
                />
            default:
                return <>
                    {
                        getAgriWebbFarmId(property) !== undefined ?
                            <BodyText style={{ marginBottom: "20px", fontWeight: 700 }}>We've imported your property boundary from AgriWebb.</BodyText>
                            : undefined
                    }
                    <GetStartedMap
                        step={step}
                        hideUpload={getAgriWebbFarmId(property) !== undefined}
                        initialGeom={property.geom}
                        agriwebbFarmId={getAgriWebbFarmId(property)}
                        setPropertyBoundary={onPropertyBoundaryChanged}
                        onSubmit={() => {
                            setStep(GetStartedStep.Review)
                        }}
                    />
                </>
        }
    };

    return (
        <Screen pageTitle="Create Enterprise">
            <GetStartedWrapper>
                <FloatingStepsWrapper>
                    <Steps
                        steps={steps}
                        completedStepIds={completedSteps}
                        currentStepId={selectedStep?.id as string}
                        onClick={(step) => {
                            const s = steps.find(s => s.id === step.id)
                            if (s) setStep(s.enum)
                        }}
                    />
                </FloatingStepsWrapper>
                <GetStartedContainer
                    style={{
                        width: step === GetStartedStep.EnterpriseType ? undefined : '800px'
                    }}
                >
                    {getHeaderContent()}
                    {getPageContent(step)}
                </GetStartedContainer>

            </GetStartedWrapper>
        </Screen >
    );
}


const GetStartedWrapper = styled.div`
    padding: 80px 0 112px 0;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const FloatingStepsWrapper = styled.div`
    padding: 10px;
    width: 220px;
    position: absolute;
    left: 10px;
    display: flex;
    align-self: flex-start;
    @media (max-width: 768px) {
        display: none;
    }
`;

const GetStartedContainer = styled.div`
    max-width: calc(100vw - 470px);
    display: flex;
    flex-direction: column;
    align-items: center;
`;
