import { useState } from "react";

import { Form } from "../../../models/form/form";
import {
  PrimeLambSystemParameters,
  WoolSystemParameters,
  DualPurposeSystemParameters
} from '../../../models/dse'

import CheckBoxGroup from '../../form/check_box'
import Heading from '../../heading'

import OutlineButton from "../../buttons/outline_button";
import { Row, Column } from '../../styled_layout'
import TradeCalculator from "./trade-calculator";

import {
  caclulateSheepDse,
  SheepClasses,
} from '../../../utilities/dse'
import { NumberFormField, RadioBoxFormField } from "../../../models/form/form_fields/basic_form_fields";

export enum SheepDseStep {
  SystemType,
  WoolSystemPage1,
  WoolSystemPage2,
  DualPurposeSystemPage1,
  DualPurposeSystemPage2,
  PrimeLambSystemPage1,
  PrimeLambSystemPage2,
  Trading
}

const WoolLambFormPage1 = {
  fields: {
    numberEwesJoined: new NumberFormField({
      required: true,
      placeholder: 'Number of adult ewes joined',
      tip: "Enter the number of ewes (>18 months old) joined.",
    }),
    avgWeightEwes: new NumberFormField({
      required: true,
      placeholder: 'Average weight of ewes in condition score 3',
      tip: "Enter the average live weight of an adult sheep in this flock which is dry, bare shorn and empty.",
    }),
    proportionLambsEwesJoined: new NumberFormField({
      required: true,
      placeholder: 'Percentage of lambs weaned from adult ewes joined',
      tip: "Enter the percentage of lambs weaned from the total number of adult ewes joined.",
    }),
    proportionEweLambsSoldUnder12Months: new NumberFormField({
      required: true,
      placeholder: 'What percentage of ewe weaners (female sheep 3 to 12 months) did you sell this year?',
      tip: "Enter the percentage of ewe weaners (female sheep 3 to 12 months old) that were sold this year. This is the number of ewe weaners that were sold before they were 12 months old as a percentage of the total number of ewe weaners that were weaned. Those which are retained as breeders should be included in the total, but not in the number sold.",
    }),
    avgAgeMonthsEweLambsSale: new NumberFormField({
      required: true,
      placeholder: 'Average age of ewe weaners at sale (months)',
      tip: "Enter the average age of the ewe weaners sold (female sheep 3 to 12 months).",
    })
  }
};

const WoolLambFormPage2 = {
  fields: {
    proportionWetherLambsSoldUnder12Months: new NumberFormField({
      required: true,
      placeholder: 'What percentage of wether weaners (male sheep 3 to 12 months) did you sell this year?',
      tip: "Enter the percentage of wether weaners (male sheep 3 to 12 months old) that were sold this year.This is the number of wether weaners that were sold before they were 12 months old as a percentage of the total number of wether weaners that were weaned.",
    }),
    avgAgeMonthsWetherLambsSale: new NumberFormField({
      required: true,
      placeholder: 'Average age of wether weaners <12 months at sale (months)',
      tip: "Enter the average age of the wether weaners sold (male sheep 3 to 12 months).",
    }),
    matureWetherAgeGroups: new NumberFormField({
      required: true,
      placeholder: 'How many years do you keep wethers?',
      tip: "If wethers are kept for longer than 12 months, how long are they kept?",
      validator: (value) => value !== undefined && value <= 13,
    }),
    monthsOldestWetherRetained: new NumberFormField({
      required: true,
      placeholder: 'How many months of the year is the oldest wether age group retained?',
      tip: "The time on farm of the oldest wether age group in this year.",
      validator: (value) => value !== undefined && value <= 13,
    }),
    mortalityRate: new NumberFormField({
      required: true,
      placeholder: 'Mortality Rate',
      validator: (value) => value !== undefined && value <= 100,
      tip: "The percentage of your wool flock that died in this year.",
    }),
  }
};

const PrimeLambFormPage1 = {
  fields: {
    numberEwesJoined: new NumberFormField({
      required: true,
      placeholder: 'Number of adult ewes joined',
      tip: "Enter the number of ewes (>12 months old) joined.",
    }),
    avgWeightEwes: new NumberFormField({
      required: true,
      placeholder: 'Average weight of ewes in condition score 3',
      tip: "Enter the average live weight of an adult sheep in this flock which is dry, bare shorn and empty.",
    }),
    proportionLambsEwesJoined: new NumberFormField({
      required: true,
      placeholder: 'Percentage of lambs weaned from adult ewes joined',
      tip: "Enter the percentage of lambs weaned from the total number of adult ewes joined.",
    }),
    eweLambsJoinedAt12Months: new RadioBoxFormField({
      required: true,
      placeholder: "Do you join ewe weaners?",
      options: [{
        name: "Yes",
        value: true
      }, {
        name: "No",
        value: false
      }],
      tip: "A ewe weaner is a female sheep between 3 and 12 months old."
    }),
    numberOfEweLambsJoined: new NumberFormField({
      required: true,
      placeholder: 'Number of ewe lambs joined',
      tip: "Enter the number of ewe weaners (3 to 12 months old) joined in this year.",
    }),
    proportionLambsWeanedToEwesJoinedEweLambs: new NumberFormField({
      required: true,
      placeholder: 'Percentage of lambs weaned from ewe lambs joined',
      tip: "Enter the percentage of lambs weaned from the total number of ewe weaners joined.",
    }),
    proportionEweLambsSoldUnder12Months: new NumberFormField({
      required: true,
      placeholder: 'What percentage of ewe weaners (female sheep 3 to 12 months) did you sell this year?',
      tip: "Enter the percentage of ewe weaners (female sheep 3 to 12 months old) that were sold this year. This is the number of ewe weaners that were sold before they were 12 months old as a percentage of the total number of ewe weaners that were weaned. Those which are retained as breeders should be included in the total, but not in the number sold.",
    }),
    avgAgeMonthsEweLambsSale: new NumberFormField({
      required: true,
      placeholder: 'Average age of ewe weaners at sale (months)',
      tip: "Enter the average age of the ewe weaners sold (female sheep 3 to 12 months).",
    })
  }
};

const PrimeLambFormPage2 = {
  fields: {
    proportionWetherLambsSoldUnder12Months: new NumberFormField({
      required: true,
      placeholder: 'What percentage of wether weaners (male sheep 3 to 12 months) did you sell this year?',
      tip: "Enter the percentage of wether weaners (male sheep 3 to 12 months old) that were sold this year.This is the number of wether weaners that were sold before they were 12 months old as a percentage of the total number of wether weaners that were weaned.",
    }),
    avgAgeMonthsWetherLambsSale: new NumberFormField({
      required: true,
      placeholder: 'Average age of wether weaners <12 months at sale (months)',
      tip: "Enter the average age of the wether weaners sold (male sheep 3 to 12 months).",
    }),
    proportionEweHoggetsSold: new NumberFormField({
      required: true,
      placeholder: 'What percentage of ewe hoggets (female sheep 12 to 24 months) did you sell this year? ',
      tip: "Enter the percentage of ewe hoggets (female sheep 12 to 24 months old) that were sold this year.This is the number of ewe hoggets that were sold before they were 24 months old as a percentage of the total number of ewe hoggets in the flock.",
    }),
    avgAgeMonthsEweHoggetsSale: new NumberFormField({
      required: true,
      placeholder: 'Average age of ewe hoggets at sale (months)',
      tip: "Enter the average age of the ewe hoggets sold (female sheep 12 to 24 months).",
    }),
    proportionWetherHoggetsSold: new NumberFormField({
      required: true,
      placeholder: 'What percentage of wether hoggets (male sheep 12 to 24 months) did you sell this year?',
      tip: "Enter the percentage of wether hoggets (male sheep 12 to 24 months old) that were sold this year. This is the number of wether hoggets that were sold between 12 and 24 months old as a percentage of the total number of wether hoggets in the flock.",
    }),
    avgAgeMonthsWetherHoggetsSale: new NumberFormField({
      required: true,
      placeholder: 'Average age of wether hoggets at sale (months)',
      tip: "Enter the average age of the wether hoggets sold (male sheep 12 to 24 months).",
    }),
    mortalityRate: new NumberFormField({
      required: true,
      placeholder: 'Mortality rate',
      validator: (value) => value !== undefined && value <= 100,
      tip: "Enter the percentage of your prime lamb flock that died in this year.",
    }),
  }
};

const DualPurposeLambFormPage1 = {
  fields: {
    numberEwesJoined: new NumberFormField({
      required: true,
      placeholder: 'Number of adult ewes joined',
      tip: "Enter the number of ewes (>18 months old) joined.",
    }),
    avgWeightEwes: new NumberFormField({
      required: true,
      placeholder: 'Average weight of ewes in condition score 3',
      tip: "Enter the average live weight of an adult sheep in this flock which is dry, bare shorn and empty.",
    }),
    proportionLambsEwesJoined: new NumberFormField({
      required: true,
      placeholder: 'Percentage of lambs weaned from adult ewes joined',
      tip: "Enter the percentage of lambs weaned from the total number of adult ewes joined. ",
    }),
    proportionEweLambsSoldUnder12Months: new NumberFormField({
      required: true,
      placeholder: 'What percentage of ewe weaners (female sheep 3 to 12 months) did you sell this year?',
      tip: "Enter the percentage of ewe weaners (female sheep 3 to 12 months old) that were sold this year. This is the number of ewe weaners that were sold before they were 12 months old as a percentage of the total number of ewe weaners that were weaned. Those which are retained as breeders should be included in the total, but not in the number sold.",
    }),
    avgAgeMonthsEweLambsSale: new NumberFormField({
      required: true,
      placeholder: 'Average age of ewe weaners at sale (months)',
      tip: "Enter the average age of the ewe weaners sold (female sheep 3 to 12 months).",
    })
  }
};

const DualPurposeLambFormPage2 = {
  fields: {
    proportionWetherLambsSoldUnder12Months: new NumberFormField({
      required: true,
      placeholder: 'What percentage of wether weaners (male sheep 3 to 12 months) did you sell this year?',
      tip: "Enter the percentage of wether weaners (male sheep 3 to 12 months old) that were sold this year.This is the number of wether weaners that were sold before they were 12 months old as a percentage of the total number of wether weaners that were weaned.",
    }),
    avgAgeMonthsWetherLambsSale: new NumberFormField({
      required: true,
      placeholder: 'Average age of wether weaners <12 months at sale (months)',
      tip: "Enter the average age of the wether weaners sold (male sheep 3 to 12 months).",
    }),
    proportionEweHoggetsSold: new NumberFormField({
      required: true,
      placeholder: 'What percentage of ewe hoggets (female sheep 12 to 24 months) did you sell this year?',
      tip: "Enter the percentage of ewe hoggets (female sheep 12 to 24 months old) that were sold this year.This is the number of ewe hoggets that were sold before they were 24 months old as a percentage of the total number of ewe hoggets in the flock.",
    }),
    avgAgeMonthsEweHoggetsSale: new NumberFormField({
      required: true,
      placeholder: 'Average age of ewe hoggets at sale (months)',
      tip: "Enter the average age of the ewe hoggets sold (female sheep 12 to 24 months). ",
    }),
    matureWetherAgeGroups: new NumberFormField({
      required: true,
      placeholder: 'How many years do you keep wethers?',
      tip: "If wethers are kept for longer than 12 months, how long are they kept?",
    }),
    monthsOldestWetherRetained: new NumberFormField({
      required: true,
      placeholder: 'How many months of the year is the oldest wether age group retained?',
      tip: "The time on farm of the oldest wether age group in this year.",
    }),
    mortalityRate: new NumberFormField({
      required: true,
      placeholder: 'Mortality rate',
      validator: (value) => value !== undefined && value <= 100,
      tip: "The percentage of your dual purpose flock that died in this year.",
    }),
  }
};

/**
 * The Sheep DSE Calculator form, intended to be shown inside a popup
 */
export default function DseSheepCalculator({
  onSubmit,
  metadata,
  isAE
}: {
  onSubmit: (val: number) => void;
  metadata?: any
  isAE: boolean
}) {

  const [step, setStep] = useState<SheepDseStep>(SheepDseStep.SystemType);
  const [selectedSystems, setSelectedSystems] = useState<string[]>([]);

  const [primeLambFiguresPage1, setPrimeLambFiguresPage1] = useState<Partial<PrimeLambSystemParameters>>({
    numberEwesJoined: 0,
    avgWeightEwes: 0,
    proportionLambsEwesJoined: 0,
    eweLambsJoinedAt12Months: true,
    numberOfEweLambsJoined: 0,
    proportionLambsWeanedToEwesJoinedEweLambs: 0,
    proportionEweLambsSoldUnder12Months: 0,
    avgAgeMonthsEweLambsSale: 0
  })

  const [woolFiguresPage1, setWoolFiguresPage1] = useState<Partial<WoolSystemParameters>>({
    numberEwesJoined: 0,
    avgWeightEwes: 0,
    proportionLambsEwesJoined: 0,
    proportionEweLambsSoldUnder12Months: 0,
    avgAgeMonthsEweLambsSale: 0
  })

  const [dualPurposeFiguresPage1, setDualPurposeFiguresPage1] = useState<Partial<DualPurposeSystemParameters>>({
    numberEwesJoined: 0,
    avgWeightEwes: 0,
    proportionLambsEwesJoined: 0,
    proportionEweLambsSoldUnder12Months: 0,
    avgAgeMonthsEweLambsSale: 0,
  })

  const [woolDse, setWoolDse] = useState<number>(0)
  const [dualPurposeDse, setDualPurposeDse] = useState<number>(0)
  const [primeLambDse, setPrimeLambDse] = useState<number>(0)

  function woolSystemSelected(): boolean {
    return selectedSystems.indexOf('wool') > -1
  }

  function dualPurposeSystemSelected(): boolean {
    return selectedSystems.indexOf('dualPurpose') > -1
  }

  function primeLambSystemSelected(): boolean {
    return selectedSystems.indexOf('primeLamb') > -1
  }

  function tradingSystemSelected(): boolean {
    return selectedSystems.indexOf('trading') > -1
  }

  function setSheepSystems(selectedValues: string[]) {
    setSelectedSystems(selectedValues)
  }

  function setNextStep(currentStep: SheepDseStep) {
    if (
      currentStep === SheepDseStep.SystemType &&
      woolSystemSelected()) {
      return setStep(SheepDseStep.WoolSystemPage1)
    } else if (
      (currentStep === SheepDseStep.SystemType ||
        currentStep === SheepDseStep.WoolSystemPage2) &&
      dualPurposeSystemSelected()
    ) {
      return setStep(SheepDseStep.DualPurposeSystemPage1)
    } else if (
      (currentStep === SheepDseStep.SystemType ||
        currentStep === SheepDseStep.WoolSystemPage2 ||
        currentStep === SheepDseStep.DualPurposeSystemPage2) &&
      primeLambSystemSelected()) {
      return setStep(SheepDseStep.PrimeLambSystemPage1)
    } else if (tradingSystemSelected()) {
      return setStep(SheepDseStep.Trading)
    }
  }

  return (
    <Row style={{ width: '100%' }}>
      <Column style={{ width: '100%' }}>
        {step === SheepDseStep.SystemType && (
          <div>
            <Row>
              <h3>What type of Sheep Systems are you running?</h3>
            </Row>
            <Row>
              <CheckBoxGroup
                name={'Sheep Systems'}
                horizontal={true}
                initialValue={selectedSystems}
                options={[
                  {
                    name: 'Wool',
                    value: 'wool'
                  },
                  {
                    name: 'Dual Purpose',
                    value: 'dualPurpose'
                  },
                  {
                    name: 'Prime Lamb',
                    value: 'primeLamb'
                  },
                  {
                    name: 'Trading',
                    value: 'trading'
                  }
                ]}
                onChange={(val) => {
                  if (val === undefined) return
                  setSheepSystems(val)
                }}
              />
            </Row>


            <Row style={{
              marginTop: "40px"
            }}>
              <OutlineButton
                disabled={selectedSystems.length === 0}
                width={"250px"}
                children={
                  'Continue'
                }
                onClick={() => {
                  setNextStep(step)
                }}
              />
            </Row>
          </div>
        )}

        {step === SheepDseStep.WoolSystemPage1 && (
          <>
            <Row style={{ marginBottom: '20px' }}>
              <Heading level={3}>Wool System {isAE ? 'AE' : 'DSE'} Calculator</Heading>
            </Row>
            <Form<Partial<WoolSystemParameters>>
              data={WoolLambFormPage1}
              initialValue={{}}
              onSubmit={async (formData) => {
                setWoolFiguresPage1(formData)
                setStep(SheepDseStep.WoolSystemPage2)
              }}
              submitText="Continue"
              buttonWidth="30%"
              buttonSize="small"
              fieldSize="small"
            />
          </>
        )}

        {step === SheepDseStep.WoolSystemPage2 && (
          <>
            <Row style={{ marginBottom: '20px' }}>
              <Heading level={3}>Wool System {isAE ? 'AE' : 'DSE'} Calculator</Heading>
            </Row>
            <Form<Partial<WoolSystemParameters>>
              data={WoolLambFormPage2}
              initialValue={{}}
              onSubmit={async (formData) => {
                const dse = caclulateSheepDse({
                  ...woolFiguresPage1,
                  ...formData
                } as WoolSystemParameters, 'wool')
                setWoolDse(dse)
                if (
                  dualPurposeSystemSelected() ||
                  primeLambSystemSelected() ||
                  tradingSystemSelected()
                ) return setNextStep(step)
                else onSubmit(dse)
              }}
              submitText={tradingSystemSelected() || primeLambSystemSelected() || dualPurposeSystemSelected() ? 'Continue' : `Calculate ${isAE ? 'AE' : 'DSE'}`}
              buttonWidth="30%"
              buttonSize="small"
              fieldSize="small"
            />
          </>
        )}

        {step === SheepDseStep.DualPurposeSystemPage1 && (
          <>
            <Row style={{ marginBottom: '20px' }}>
              <Heading level={3}>Dual Purpose System {isAE ? 'AE' : 'DSE'} Calculator</Heading>
            </Row>
            <Form<Partial<DualPurposeSystemParameters>>
              data={DualPurposeLambFormPage1}
              initialValue={{}}
              onSubmit={async (formData) => {
                setDualPurposeFiguresPage1(formData)
                setStep(SheepDseStep.DualPurposeSystemPage2)
              }}
              submitText="Continue"
              buttonWidth="30%"
              buttonSize="small"
              fieldSize="small"
            />
          </>
        )}

        {step === SheepDseStep.DualPurposeSystemPage2 && (
          <>
            <Row style={{ marginBottom: '20px' }}>
              <Heading level={3}>Dual Purpose System {isAE ? 'AE' : 'DSE'} Calculator</Heading>
            </Row>
            <Form<Partial<DualPurposeSystemParameters>>
              data={DualPurposeLambFormPage2}
              initialValue={{}}
              onSubmit={async (formData) => {
                const dse = caclulateSheepDse({
                  ...dualPurposeFiguresPage1,
                  ...formData
                } as DualPurposeSystemParameters, 'dualPurpose')
                setDualPurposeDse(dse)

                if (
                  primeLambSystemSelected() ||
                  tradingSystemSelected()
                ) return setNextStep(step)
                else onSubmit(woolDse + dse)
              }}
              submitText={tradingSystemSelected() || primeLambSystemSelected() ? 'Continue' : `Calculate ${isAE ? 'AE' : 'DSE'}`}
              buttonWidth="30%"
              buttonSize="small"
              fieldSize="small"
            />
          </>
        )}

        {step === SheepDseStep.PrimeLambSystemPage1 && (
          <>
            <Row style={{ marginBottom: '20px' }}>
              <Heading level={3}>Prime Lamb System {isAE ? 'AE' : 'DSE'} Calculator</Heading>
            </Row>
            <Form<Partial<PrimeLambSystemParameters>>
              data={PrimeLambFormPage1}
              initialValue={{
                eweLambsJoinedAt12Months: true
              }}
              onSubmit={async (formData) => {
                setPrimeLambFiguresPage1(formData)
                setStep(SheepDseStep.PrimeLambSystemPage2)
              }}
              submitText="Continue"
              buttonWidth="30%"
              buttonSize="small"
              fieldSize="small"
            />
          </>
        )}

        {step === SheepDseStep.PrimeLambSystemPage2 && (
          <>
            <Row style={{ marginBottom: '20px' }}>
              <Heading level={3}>Prime Lamb System {isAE ? 'AE' : 'DSE'} Calculator</Heading>
            </Row>
            <Form<Partial<PrimeLambSystemParameters>>
              data={PrimeLambFormPage2}
              initialValue={{}}
              onSubmit={async (formData) => {
                const dse = caclulateSheepDse({
                  ...primeLambFiguresPage1,
                  ...formData as Partial<PrimeLambSystemParameters>
                } as PrimeLambSystemParameters, 'primeLamb')

                setPrimeLambDse(dse)
                if (tradingSystemSelected()) return setStep(SheepDseStep.Trading)
                return onSubmit(woolDse + dualPurposeDse + dse)
              }}
              submitText={tradingSystemSelected() ? 'Continue' : `Calculate ${isAE ? 'AE' : 'DSE'}`}
              buttonWidth="30%"
              buttonSize="small"
              fieldSize="small"
            />
          </>
        )}

        {step === SheepDseStep.Trading && (
          <TradeCalculator
            dseType={'sheep'}
            availableClasses={SheepClasses}
            financialYear={metadata.financialYear}
            onSubmit={(tradingDse) => {
              onSubmit(tradingDse + primeLambDse + dualPurposeDse + woolDse)
            }}
            isAE={isAE}
          />
        )}
      </Column>

    </Row>
  )
}
