import { Link } from "react-router-dom";
import { ReportFormPage, ReportPageMultipleForms } from "@/models/form/form_page"
import { ReportFormPageId } from "@/utilities/forms"
import Steps, { Step } from "@/components/steps"
import { User } from "@/models/auth_models"
import { SubscriptionType } from "@/models/subscription_model"
import { Report } from "@/models/property_models"
import { FloatingStepsWrapper } from "../get_started_screen";
import { ruminatiColors } from "@/utilities/colors"
import { BodyText } from "@/components/styled_text";
import { Row } from "@/components/styled_layout";

export type FormStepsProps = {
  pages: (ReportFormPage<any, any> | ReportPageMultipleForms)[]
  pageId: string
  report: Report
  user: User,
  navigate: (id: string) => void
  paddingTop?: string
}

export default function FormSteps (props: FormStepsProps) {
  type StepAndEnum = Step & {
    pageTitle: string
    description: string
    helpText?: JSX.Element
  }

  const steps: StepAndEnum[] = props.pages.map(p => {
      const out: StepAndEnum = {
          id: p.id,
          label: p.title,
          pageTitle: p.title,
          description: p.description ?? ''
      }
      if (p.id === ReportFormPageId.Production && props.user?.subscription.type !== SubscriptionType.PRIME) {
          out.disabled = true
          out.tooltip = (
            <Row style={{padding: '12px'}}>
              <BodyText style={{ color: ruminatiColors.bone }}>To access Production <Link to="/account?tab=subscription" style={{ color: ruminatiColors.bone, textDecoration: 'underline' }}>upgrade to Ruminati PRIME.</Link></BodyText>
            </Row>
          )
      }
      return out
  })
  steps.push({
      id: 'summary',
      label: 'Summary',
      pageTitle: 'Summary',
      description: '',
  })

  const selectedStep = steps.find(s => s.id === props.pageId)
  const completedSteps: string[] = []
  props.pages.forEach(p => {
    if ('formComponents' in p) {
      let allFormFieldsFound = true
      p.formComponents.forEach(fc => {
        if (props.report.completedSections.findIndex(cs => cs === fc.id) === -1) {
          allFormFieldsFound = false
        }        
      })
      if (allFormFieldsFound) completedSteps.push(p.id)
    } else {
      if (props.report.completedSections.findIndex(cs => cs === p.id) > -1) {
        completedSteps.push(p.id)
      }
    }
  })

  if (props.report?.complete) completedSteps.push('summary')
  
  return (
    <FloatingStepsWrapper style={{
      paddingTop: props.paddingTop ?? '0px'
    }}>
      <Steps
        steps={steps}
        completedStepIds={completedSteps}
        currentStepId={selectedStep?.id as string}
        allowForwardClick={true}
        onClick={(step) => {
            props.navigate(step.id)
        }}
      />
    </FloatingStepsWrapper>
  )
}