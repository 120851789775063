import { CountryCode } from "./countries"

type ConsumableByRegion = {
  localityId: number | string
  localityName: string
  avgPetrolPrice: Record<number, number>
  avgDieselPrice: Record<number, number>
}

// Aus https://www.aip.com.au/aip-annual-retail-price-data
// NZ https://www.mbie.govt.nz/building-and-energy/energy-and-natural-resources/energy-statistics-and-modelling/energy-statistics/energy-prices/
const fuelRatesByLocalityId: ConsumableByRegion[] = [
  {
    localityId: 1,
    localityName: 'ACT',
    avgPetrolPrice: {
      2021: 1.29,
      2022: 1.73,
      2023: 1.84,
      2024: 1.97
    },
    avgDieselPrice: {
      2021: 1.25,
      2022: 1.74,
      2023: 2.08,
      2024: 2.03
    }
  },
  {
    localityId: 2,
    localityName: 'NSW',
    avgPetrolPrice: {
      2021: 1.29,
      2022: 1.73,
      2023: 1.84,
      2024: 1.97
    },
    avgDieselPrice: {
      2021: 1.25,
      2022: 1.74,
      2023: 2.08,
      2024: 2.03
    }
  },
  {
    localityId: 3,
    localityName: 'TAS',
    avgPetrolPrice: {
      2021: 1.04,
      2022: 1.79,
      2023: 1.88,
      2024: 1.98
    },
    avgDieselPrice: {
      2021: 1.35,
      2022: 1.81,
      2023: 2.13,
      2024: 2.08
    }
  },
  {
    localityId: 5,
    localityName: 'SA',
    avgPetrolPrice: {
      2021: 1.25,
      2022: 1.67,
      2023: 1.79,
      2024: 1.91
    },
    avgDieselPrice: {
      2021: 1.25,
      2022: 1.74,
      2023: 2.04,
      2024: 2.00
    }
  },
  {
    localityId: 6,
    localityName: 'VIC',
    avgPetrolPrice: {
      2021: 1.30,
      2022: 1.71,
      2023: 1.84,
      2024: 1.96
    },
    avgDieselPrice: {
      2021: 1.25,
      2022: 1.74,
      2023: 2.08,
      2024: 2.03
    }
  },
  {
    localityId: 7,
    localityName: 'QLD',
    avgPetrolPrice: {
      2021: 1.31,
      2022: 1.73,
      2023: 1.83,
      2024: 1.98
    },
    avgDieselPrice: {
      2021: 1.24,
      2022: 1.75,
      2023: 2.09,
      2024: 2.04
    }
  },
  {
    localityId: 8,
    localityName: 'NT',
    avgPetrolPrice: {
      2021: 1.37,
      2022: 1.82,
      2023: 1.98,
      2024: 2.06
    },
    avgDieselPrice: {
      2021: 1.40,
      2022: 1.87,
      2023: 2.31,
      2024: 2.21
    }
  },
  {
    localityId: 4,
    localityName: 'SW WA',
    avgPetrolPrice: {
      2021: 1.27,
      2022: 1.71,
      2023: 1.79,
      2024: 1.90
    },
    avgDieselPrice: {
      2021: 1.25,
      2022: 1.73,
      2023: 2.02,
      2024: 2.00
    }
  },
  {
    localityId: 9,
    localityName: 'NW WA',
    avgPetrolPrice: {
      2021: 1.27,
      2022: 1.71,
      2023: 1.79,
      2024: 1.90
    },
    avgDieselPrice: {
      2021: 1.25,
      2022: 1.73,
      2023: 2.02,
      2024: 2.00
    }
  },
  {
    localityId: CountryCode.NewZealand,
    localityName: 'NZ',
    avgPetrolPrice: {
      2021: 2.20,
      2022: 2.61,
      2023: 2.70,
    },
    avgDieselPrice: {
      2021: 1.34,
      2022: 1.78,
      2023: 2.51,
    }
  }
]

export function getAverageRateForLocalityAndYear (localityId: number | string, fuelType: 'Petrol' | 'Diesel', year: number): number | null {
  const fuelRegion = fuelRatesByLocalityId.find(r => r.localityId === localityId)
  if (fuelRegion === undefined) return null
  const key: 'avgPetrolPrice' | 'avgDieselPrice' = `avg${fuelType}Price`
  const fuelRate = fuelRegion[key][year]
  return fuelRate
}
