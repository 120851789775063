import { usePopupStore } from "../../state/popup_store";
import { Property, Report } from "../../models/property_models";
import { useDatabaseStore } from "../../state/database_store";
import { getPropertyFromReport, getReport } from "../../utilities/functions";

import { useParams } from "react-router-dom";
import SubButton from "./sub_button";
import { CountryCode, countryIsAustralia } from "@/utilities/countries";

type FuelUseButtonProps = {
    fuelType: 'Petrol' | 'Diesel'
    onSubmit: (val: number) => void;
};

export default function FuelUseButton(props: FuelUseButtonProps) {
    const popupStore = usePopupStore();
    const databaseStore = useDatabaseStore();
    const { reportId } = useParams();
    
    const report: Report | undefined = getReport(
        databaseStore.reports,
        reportId
    );
    const property: Property | undefined = getPropertyFromReport(
        databaseStore.properties,
        report
    );
    
    if (!property?.country) return

    const localityId = countryIsAustralia(property?.country) ? property?.stateId : CountryCode.NewZealand

    return (
        <div style={{ padding: "0 0 0 8px" }}>
            <SubButton
                colorScheme="orange"
                onClick={() =>
                    popupStore.addPopup(
                        'FuelUsePopup',
                        props.onSubmit,
                        {
                            localityId,
                            reportYear: report?.financialYear,
                            fuelType: props.fuelType
                        }
                    )
                }
            >
                Calculate
            </SubButton>
        </div>
    );
}
