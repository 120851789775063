import { useState } from "react"
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { AccountPageContainer } from './account_screen'
import {
    Column,
    Row,
    Flex,
    LeftAligned,
    RightAligned,
} from "../../components/styled_layout";
import { BodyText } from "../../components/styled_text";
import Heading from "../../components/heading";
import BaseIcon from "/icons/ruminati-base-icon.svg";
import PrimeIcon from "/icons/ruminati-prime-icon.svg";
import RuminatiLogo from "/icons/ruminati-logo.svg";
import Table from "../../components/table";
import Icon from "../../components/icon";
import OutlineButton from "../../components/buttons/outline_button";
import { ruminatiColors } from "../../utilities/colors";
import { useAuthStore } from "../../state/auth_store";
import {
    cancelSubscription,
    upgradeToPrime,
} from "../../services/subscription_service";
import {
    SubscriptionType,
    SubscriptionUpgradeResponse,
    SubscriptionPrime,
} from "../../models/subscription_model";
import LoadingButton from "../../components/buttons/loading_button";
import { usePopupStore } from "../../state/popup_store";
import CheckBoxGroup from "../../components/form/check_box";
import { UserVoucherDTO } from "../../models/voucher";
import { GreenCard } from "../../components/card";
import { usePartnerStore } from "@/state/partners";

type SubscriptionBenefit = {
    name: JSX.Element | string;
    value: JSX.Element | string;
};

export enum SubscriptionPopupId {
    PaymentStarting = 'payment-starting',
    PaymentAndShareStarting = 'payment-share-starting'
}

export default function AccountSubscriptionTab() {
    const authStore = useAuthStore()
    const popupStore = usePopupStore()
    const partnerStore = usePartnerStore()

    const user = authStore.user;
    const usersVouchers = authStore.vouchers;
    const unusedVouchers = usersVouchers === undefined ? [] as UserVoucherDTO[] : usersVouchers?.filter(v => v.dateUsed === null)
    const availableVoucher = unusedVouchers.length > 0 ? unusedVouchers[0] : undefined

    const [checkboxOptions, setCheckboxOptions] = useState<number[]>([1]);
    const [useVoucher, setUseVoucher] = useState<boolean>(availableVoucher ? true : false);

    const isPrime = user?.subscription?.type === SubscriptionType.PRIME;
    const autoRenew = isPrime ? (user?.subscription as SubscriptionPrime).autoRenew : false;

    const navigate = useNavigate();

    const cancel = async () => {
        await cancelSubscription();
        navigate("/account/refresh");
        return;
    };

    const upgradeOrRenew = async () => {
        const voucherToUse = useVoucher ? availableVoucher : undefined
        const response = await upgradeToPrime(voucherToUse?.promoCode);
        if (response instanceof SubscriptionUpgradeResponse) {
            if (voucherToUse) {
                const relatedInvite = partnerStore.invitations?.find(invite => invite.visionOrgId === voucherToUse.visionOrgId)
                popupStore.addPopup(SubscriptionPopupId.PaymentAndShareStarting, undefined, {
                    href: response.checkoutUrl,
                    invite: relatedInvite
                })
            } else {
                popupStore.addPopup(SubscriptionPopupId.PaymentStarting, undefined, {
                    href: response.checkoutUrl
                })
            }
        } else if (response instanceof SubscriptionPrime) {
            navigate("/account/refresh");
        }
        return;
    };

    // discountDescription: "$300 off per year for 2 years",
    // amountPaidByUser: "$300"
    function convertDiscountDescriptionToActualAmountText(discountDescription: string, amountPaidByUser: string) {
        return amountPaidByUser + discountDescription.split('off')[1]
    }

    const liteData: SubscriptionBenefit[] = [
        { name: "BASE Edition", value: <BodyText> Free </BodyText> },
        { name: "Number of Farms", value: <BodyText> 1 </BodyText> },
        { name: "Emissions Reports", value: <BodyText> 1 </BodyText> },
        { name: "Feedlot Emissions Reports", value: <Icon icon="x" /> },
        { name: "Processor Emissions Reports", value: <Icon icon="x" /> },
        { name: "Reduction Planner", value: <Icon icon="x" /> },
        { name: "Intensity Calculations", value: <Icon icon="x" /> },
    ];

    const defaultPriceText = "$600 AUD per year"
    const enterpriseData: SubscriptionBenefit[] = [
        {
            name: "PRIME Edition",
            value: availableVoucher && useVoucher ?
                `With your voucher ${convertDiscountDescriptionToActualAmountText(availableVoucher.discountDescription, availableVoucher.amountPaidByUser)}. ${defaultPriceText} after that`
                : defaultPriceText
        },
        { name: "Number of Farms", value: 'Unlimited' },
        { name: "Emissions Reports", value: 'Unlimited' },
        { name: "Feedlot Emissions Reports", value: <Icon icon="tick" /> },
        { name: "Processor Emissions Reports", value: <Icon icon="tick" /> },
        { name: "Reduction Planner", value: <Icon icon="tick" /> },
        { name: "Intensity Calculations", value: <Icon icon="tick" /> },
    ];

    const currentPlanButton = (
        <tr>
            <th colSpan={2}>
                <FooterRow>
                <OutlineButton
                    width="228px"
                    height="64px"
                    borderRadius="48px"
                    disabled={true}
                >
                    <BodyText
                        style={{
                            fontSize: "20px",
                            color: ruminatiColors.dark_green,
                        }}
                    >
                        Current Plan
                    </BodyText>
                </OutlineButton>
                </FooterRow>
            </th>
        </tr>
    );

    const autoRenewButton = (): JSX.Element => {
        const buttonText = autoRenew
            ? "Disable auto-renew"
            : "Enable auto-renew";
        return (
            <tr>
                <th colSpan={2}>
                    <FooterRow>

                    <LoadingButton
                        colorScheme="orange"
                        size="small"
                        onClick={async () => {
                            if (autoRenew) {
                                await cancel();
                            } else {
                                await upgradeOrRenew();
                            }
                        }}
                        width="auto"
                    >
                        {" "}
                        {buttonText}
                    </LoadingButton>
                    </FooterRow>
                </th>
            </tr>
        );
    };

    const selectPrimeButton = (
        <tr>
            <th colSpan={2}>
            <FooterRow>
                <LoadingButton
                    colorScheme="orange"
                    size="large"
                    onClick={async () => {
                        if (!isPrime) {
                            await upgradeOrRenew();
                        }
                    }}
                    width={useVoucher ? "280px" : "228px"}
                >
                    {useVoucher ? 'Select using voucher' : 'Select'}
                </LoadingButton>
                </FooterRow>
            </th>
        </tr>
    );

    const liteTableFooterText = () => {
        const text = autoRenew
            ? 'Click "Disable Auto-renew" to move to this plan at the end of your billing period.'
            : "You will move to this plan at the end of your billing period.";
        return (
            <tr>
                <th colSpan={2}>
                    <FooterRow>
                        <BodyText style={{ textAlign: "center", lineHeight: "20px" }}>
                            {text}
                        </BodyText>                        
                    </FooterRow>
                </th>
            </tr>
        );
    };

    const liteTable = (
        <Table<SubscriptionBenefit>
            id={'baseTable'}
            customHeader={
                <tr>
                    <th colSpan={2} style={{
                        borderBottom: `1px solid ${ruminatiColors.green_3}`,

                    }}>
                        <Row style={{ columnGap: '8px' }}>
                            <img src={RuminatiLogo} alt="Ruminati Logo" />
                            <img src={BaseIcon} alt="Ruminati Base badge" />
                        </Row>
                    </th>
                </tr>
            }
            data={liteData}
            builder={(s: SubscriptionBenefit) => [
                <LeftAligned>
                    <BodyText>{s.name}</BodyText>
                </LeftAligned>,
                <RightAligned>{s.value}</RightAligned>,
            ]}
            footer={isPrime ? liteTableFooterText() : currentPlanButton}
        />
    );

    const enterpriseTable = (
        <Table<SubscriptionBenefit>
            id={'primeTable'}
            customHeader={
                <tr>
                    <th colSpan={2} style={{ columnGap: '8px', borderBottom: `1px solid ${ruminatiColors.green_3}` }}>
                        <Row style={{ columnGap: '8px' }}>
                            <img src={RuminatiLogo} alt="Ruminati Logo" />
                            <img src={PrimeIcon} alt="Ruminati Prime badge" />
                        </Row>
                    </th>
                </tr>
            }
            data={enterpriseData}
            builder={(s: SubscriptionBenefit) => [
                <Row style={{ width: '100%' }}>
                    <Column style={{ width: '50%', textAlign: 'left' }}>
                        <BodyText style={{ width: '100%' }}>{s.name}</BodyText>
                    </Column>
                    <Column style={{ width: '50%', textAlign: 'right' }}>
                        <BodyText style={{ width: '100%' }}>{s.value}</BodyText>
                    </Column>
                </Row>
            ]}
            footer={isPrime ? autoRenewButton() : selectPrimeButton}
        />
    );

    function getCurrentPlanTable() {
        type CurrentPlanDatum = {
            dateEndType: string;
            dateEnd: string;
        };

        let isPrimeUser = false
        if (user?.subscription?.type === SubscriptionType.PRIME) {
            isPrimeUser = true
        }

        const currentPlan = isPrimeUser ? "PRIME Edition" : "BASE Edition"

        let dateEndType = ''
        let dateEnd = ''

        if (user?.subscription) {
            dateEndType = (user?.subscription as SubscriptionPrime).autoRenew ? "Auto-renewal Date" : "Expiration Date"
            dateEnd = dayjs.unix((user?.subscription as SubscriptionPrime).endDate).format("DD MMMM YYYY")
        }

        function makeColumnContent(content: string) {
            return <BodyText>{content}</BodyText>
        }

        return (<Table<CurrentPlanDatum>
            id={'currentPlanTable'}
            customHeader={
                <tr style={{
                    padding: '0px 24px',
                }}>
                    <th 
                    colSpan={2}
                    style={{
                        borderBottom: `1px solid ${ruminatiColors.green_3}`,
                        borderRadius: !isPrimeUser ? '8px' : '0px'
                    }}>
                        <Row style={{justifyContent: 'flex-start', padding: '0px 24px'}}>
                            <Column style={{width: '23%', alignItems: 'flex-start'}}>{makeColumnContent('Current Plan')}</Column>
                            <Column style={{width: '23%', alignItems: 'flex-start'}}>{makeColumnContent(currentPlan)}</Column>
                        </Row>
                    </th>
                </tr>
            }
            data={isPrimeUser ? [{
                dateEndType: dateEndType,
                dateEnd: dateEnd
            }] : []}
            builder={(data) => [
                isPrimeUser ? <Row style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    height: '100%'
                }}>
                    <Column style={{width: '23%', alignItems: 'flex-start'}}>
                        {makeColumnContent('Payment Gateway')}
                    </Column>
                    <Column style={{width: '23%', alignItems: 'flex-start'}}>
                        {makeColumnContent('Stripe')}
                    </Column>
                    <Column style={{ width: '8%', height: '100%' }}>
                        <div style={{
                            height: '100%',
                            width: '2px',
                            backgroundColor: ruminatiColors.green_3_10
                        }} />
                    </Column>
                    <Column style={{ width: '23%', alignItems: 'flex-start' }}>
                        {makeColumnContent(data.dateEndType)}
                    </Column>
                    <Column style={{ width: '23%', alignItems: 'flex-start' }}>
                          {makeColumnContent(data.dateEnd)}
                  </Column>
                </Row> : undefined
            ]}
        />)
    }

    function getVoucherCard(): JSX.Element {
        if (availableVoucher === undefined) return <></>
        return (
            <GreenCard
                style={{
                    borderWidth: '0px',
                    padding: '24px'
                }}
            >
                <Row style={{ marginBottom: '14px', justifyContent: 'flex-start' }}>
                    <Column>
                        <Heading level={5}>Subscription Voucher Available <span style={{ marginLeft: '10px' }}>🎉</span></Heading>
                    </Column>
                </Row>
                <BodyText>
                    You've been issued a voucher by <strong>{availableVoucher.visionOrgName}</strong> which provides you with access to {availableVoucher.discountDescription} on the standard annual subscription fee for Ruminati PRIME. This means you’ll be able to upgrade and access the additional features available in Ruminati PRIME at a cost of {availableVoucher.amountPaidByUser} per year, for the voucher period.
                </BodyText>
                {
                    availableVoucher.amountPaidByUser === '$0' ?
                        <BodyText style={{ width: '100%', marginTop: '10px' }}>
                            Even though the amount due is $0 for the voucher period, you will still need to go through the payment gateway to complete the subscription process to upgrade your account to Ruminati PRIME.
                        </BodyText> : undefined
                }
                <BodyText style={{ width: '100%', marginTop: '10px' }}>
                    <strong>Voucher Code:</strong> {availableVoucher.promoCode}
                </BodyText>
                <Row style={{
                    justifyContent: 'flex-start',
                    width: "100%",
                    marginTop: '8px',
                }}>
                    <CheckBoxGroup
                        size="small"
                        name={"accept"}
                        initialValue={checkboxOptions}
                        options={[
                            {
                                name: 'Use voucher during subscription process below',
                                value: 1
                            }
                        ]}
                        onChange={(v) => {
                            if (v === undefined) return
                            setCheckboxOptions(v)
                            if (v.length > 0) return setUseVoucher(true)
                            return setUseVoucher(false)
                        }}
                    />
                </Row>
                <Row style={{
                    justifyContent: 'flex-start',
                    width: "100%",
                    marginTop: '8px',
                    columnGap: '8px'
                }}>
                    <Icon icon="info-circle" />
                    <BodyText>By using this voucher we'll enable data sharing with {availableVoucher.visionOrgName}</BodyText>
                </Row>
            </GreenCard>)
    }

    return (
        <AccountPageContainer style={{ rowGap: "32px" }}>
            {availableVoucher ? getVoucherCard() : undefined}
            {getCurrentPlanTable()}

            <Flex
                style={{
                    columnGap: "32px",
                    marginBottom: '40px',
                    alignItems: 'flex-start'
                }}
            >
                <TableContainer>{liteTable}</TableContainer>
                <TableContainer>{enterpriseTable}</TableContainer>
            </Flex>
        </AccountPageContainer>
    );
}

const TableContainer = styled.div`
    width: 50%;
    @media (max-width: 900px) {
        width: 100%;
    }
`;

const FooterRow = styled(Row)`
    border-top: 1px solid ${ruminatiColors.green_3_30};
    padding: 16px;
`