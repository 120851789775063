import React, { useEffect, useState } from "react";
import Icon, { IconType } from "./icon";
import styled from "styled-components";
import { BaseText } from "./styled_text";
import { ruminatiColors } from "../utilities/colors";
import { Column } from "./styled_layout";

type TabType = {
    icon?: IconType;
    text: string;
    subtext?: string;
    notiCount?: number;
    disabled?: boolean;
};

type TabsProps = {
    selected?: number;
    tabs: TabType[];
    tabWidth?: string;
    onTabChange: (tabIndex: number) => void;
    layoutType?: "Card" | "Line";
};

/**
 * A visual tabs selector. Only contains the tab themselves, not the content.
 * @param tabs the list of tabs to display, with the icon and text for each
 * @param onTabChange a callback function that is called when the tab changes
 * @param tabWidth width of each tab. Defaults to 464px.
 */
export default function Tabs(props: TabsProps) {
    const [tabIndex, setTabIndex] = useState<number>(props.selected ?? 0);

    useEffect(() => {
        setTabIndex(props.selected ?? 0);
    }, [props.selected]);

    const changeTab = (index: number) => {
        if (!props.tabs[index]?.disabled) {
            setTabIndex(index);
            props.onTabChange(index);
        }
    };

    const isLine = props.layoutType === "Line";

    return (
        <TabsWrapper 
        className="tabsWrapper"
        style={{
            borderBottom: `1px solid ${isLine ? ruminatiColors.green_3_30 : ruminatiColors.green_3}`,
            justifyContent: `${isLine ? 'flex-start' : 'center'}`,
            height: `${isLine ? undefined : '80px'}`
        }}>
            {props.tabs.map((tab, index) => {
                const isActive = tabIndex === index;
                return (
                    <React.Fragment key={tab.text}>
                        <Tab
                            active={isActive}
                            onClick={() => changeTab(index)}
                            width={props.tabWidth}
                            layoutType={props.layoutType}
                            disabled={tab.disabled}
                        >
                            {tab.notiCount !== undefined && tab.notiCount > 0 && (
                                <NotificationIcon count={tab.notiCount} />
                            )}
                            {tab.icon && (
                                <div style={{ padding: "2px 8px 0 0" }}>
                                    <Icon icon={tab.icon} />
                                </div>
                            )}
                            <Column>
                                <TabText style=
                                {
                                    isLine ? 
                                    { 
                                        color: isActive ? ruminatiColors.dark_green : ruminatiColors.light_green, 
                                        fontSize: "16px",
                                        fontFamily: "TTInterfaces, sans-serif"
                                    } : 
                                    {
                                        color: isActive ? ruminatiColors.dark_green : ruminatiColors.bone,
                                        fontSize: "20px",
                                        fontFamily: "Approach, sans-serif"
                                    }
                                }>
                                    {tab.text}
                                </TabText>
                                <TabSubText 
                                    style={{ 
                                      color: ruminatiColors.bone 
                                    }}
                                >
                                    {tab.subtext}
                                </TabSubText>
                            </Column>
                        </Tab>
                    </React.Fragment>
                );
            })}
        </TabsWrapper>
    );
}

function NotificationIcon({ count }: { count: number }) {
    return <NotificationIconContainer> {count} </NotificationIconContainer>;
}

const TabsWrapper = styled.div`
    width: 100%;
    display: flex;
`;

type TabProps = {
    active: boolean;
    width?: string;
    layoutType?: string;
    disabled?: boolean
};

const Tab = styled.div<TabProps>`
  height: ${(props) => props.layoutType === "Line" ? "50px" : "80px"};
  width: ${(props) => props.layoutType === "Line" ? "auto" : props.width || "464px"};
  margin: ${(props) => props.layoutType === "Line" ? "0px" : "0px 4px 0px 4px"};
  padding: ${(props) => props.layoutType === "Line" ? "0px 16px" : "0px"};
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.layoutType === "Line" ? ruminatiColors.bone : (props.active ? ruminatiColors.bone : ruminatiColors.green_3)};
  border-radius: ${(props) => props.layoutType === "Line" ? "0px" : "8px 8px 0px 0px"};
  border: ${(props) => props.layoutType === "Line" ? "0px solid" : `1px solid ${ruminatiColors.green_3}`};
  border-bottom: ${(props) => props.layoutType === "Line" ? (
        props.active ? `2px solid ${ruminatiColors.green_3}` : `2px solid transparent`
  ) : "0px solid"};
  cursor: ${(props) => !props.disabled ? props.active ? "default" : "pointer" : "not-allowed"};
  transition: 0.2s;

  svg path {
    fill: ${(props) => props.active ? ruminatiColors.green_3 : ruminatiColors.bone};
  }
`;

const TabText = styled(BaseText)`
    line-height: 32px;
`;

const TabSubText = styled(BaseText)`
    font-family: "TT Interfaces", sans-serif;
    font-size: 13px;
    line-height: 12px;
    letter-spacing:0.02em;
    color: ${ruminatiColors.bone_60};
    font-weight: 500;
    font-style: italic;
`;

const NotificationIconContainer = styled.div`
    width: 20px;
    height: 20px;
    background-color: ${ruminatiColors.orange};
    color: white;
    border-radius: 16px;
    text-align: center;
    margin-right: 8px;
    font-family: "TT Interface", sans-serif;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0.02em;
    font-weight: 500;
`;
