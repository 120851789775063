// Use ISO 3166-1 Alpha-3 codes
// https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
export enum CountryCode {
  Australia = "AUS",
  NewZealand = "NZL"
}

export function countryIsAustralia(country: CountryCode) {
  return country === "AUS"
}

export function countryIsNZ(country: CountryCode) {
  return country === "NZL"
}

export const countriesDropdownOptions = [
  { name: "Australia", value: CountryCode.Australia },
  { name: "New Zealand", value: CountryCode.NewZealand },
]

// Determines whether the annualAvgLiveweightGain field is required for a NZ Sheep class.
export function isLiveweightGainRequiredForNzSheepClass(classId: number) {
  // See class list in nzSheepClasses /src/models/form_options.tsx
  if ([12, 13, 16].indexOf(classId) > -1) return false
  return true
}
