import { Form } from "../../../models/form/form";
import { FieldSize } from "../../../models/form/form_fields/form_field";
import {
  ClassTradingParameters
} from "../../../models/dse";
import { Row } from '../../styled_layout'
import Heading from '../../heading'
import { getClassNameByValue } from '../../../utilities/dse'
import { sortArrayOfMonthValuesByFinancialYear } from '../../../utilities/dates'
import { NumberFormField } from "../../../models/form/form_fields/basic_form_fields";
import { LabelField } from "../../../models/form/form_fields/decoration_form_fields";

export type DseTradeClassProps = {
  className: string;
  months: string[];
  submitText: string;
  onSubmit: (val: ClassTradingParameters) => void;
};

export type DSETrade = {
  name: string;
  openingCount: number;
  deaths: number;
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * @param className the height of the map
 */
export default function DseTradeClassForm({
  className,
  months,
  submitText,
  onSubmit
}: DseTradeClassProps) {

  function getForm(months: string[]) {
    sortArrayOfMonthValuesByFinancialYear(months)

    const monthFields: any = {}

    months.forEach(m => {
      const lowerMonthName = m.toLowerCase()
      const prettyMonthName = capitalizeFirstLetter(m)
      monthFields[`${lowerMonthName}Bought` as string] = new NumberFormField({
        required: true,
        placeholder: `${prettyMonthName} Bought`,
        size: FieldSize.Half,
      })
      monthFields[`${lowerMonthName}Sold` as string] = new NumberFormField({
        required: true,
        placeholder: `${prettyMonthName} Sold`,
        size: FieldSize.Half
      })
    })

    return {
      fields: {
        openingCount: new NumberFormField({
          required: true,
          placeholder: 'Opening count',
          tip: "The number of this class of cattle you had on the 1st of July in the trading herd.",
        }),
        label: new LabelField({
          label: 'Purchases & Sales (number)'
        }),
        ...monthFields,
        label2: new LabelField({
          label: '  '
        }),
        deaths: new NumberFormField({
          required: true,
          placeholder: 'Deaths',
          tip: "The number of deaths in this class of cattle in the trading herd.",
        })
      }
    }
  }

  async function formatAndSubmit(formData: any) {
    const out: ClassTradingParameters = {
      class: className,
      opening: formData.openingCount,
      deaths: formData.deaths,
      trades: []
    }
    Object.keys(formData).forEach(k => {
      if (k.indexOf('Bought') > -1 || k.indexOf('Sold') > -1) {
        const month = (k.indexOf('Bought') > -1 ? k.split('Bought')[0] : k.split('Sold')[0]) ?? 'N/A';

        out.trades.push({
          month,
          purchases: formData[`${month}Bought`],
          sales: formData[`${month}Sold`]
        })
      }
    })
    onSubmit(out)
  }

  return (
    <div>
      <Row style={{ marginBottom: '20px' }}>
        <Heading level={3}>{getClassNameByValue(className)}</Heading>
      </Row>
      <Form<DSETrade>
        data={getForm(months)}
        initialValue={{}}
        onSubmit={async (formData) => {
          formatAndSubmit(formData)
        }}
        submitText={submitText}
        buttonWidth="30%"
        buttonSize="small"
        fieldSize="small"
      />
    </div>

  )
}