import { FillLayerSpecification, LineLayerSpecification, Map } from 'maplibre-gl';
// @ts-expect-error no typings available
import FeatureService from 'mapbox-gl-arcgis-featureserver'

import { LayerOptions } from '../layer_config/styling_options';

export default function createEsriFeatureLayer(tileUrl: string, layerOptions: LayerOptions, map: Map, whereQuery?: string) {

  new FeatureService(layerOptions.dataSourceId, map, {
    url: tileUrl,
    minZoom: layerOptions.minZoom ?? 0,
    outFields: [],
    where: whereQuery ?? undefined
  })

  const lineLayerStyle: LineLayerSpecification = {
    id: layerOptions.lineLayerId,
    source: layerOptions.dataSourceId,
    type: 'line',
    minzoom: layerOptions.minZoom ?? 0,
    paint: {
      'line-color': layerOptions.lineColor,
      'line-width': layerOptions.lineWidth ?? 1.5,
      'line-opacity': layerOptions.lineOpacity ?? 1
    }
  };

  const fillLayerStyle: FillLayerSpecification = {
    id: layerOptions.fillLayerId,
    source: layerOptions.dataSourceId,
    type: 'fill',
    minzoom: layerOptions.minZoom ?? 0,
    paint: {
      'fill-color': layerOptions.fillColor,
      'fill-opacity': layerOptions.fillOpacity ?? 0.25
    }
  }

  map.addLayer(fillLayerStyle)
  map.addLayer(lineLayerStyle)
}