import { PropsWithChildren, useEffect } from 'react';
import LoadingPlaceholder from '../components/loading_placeholder';
import Screen from '../screens/screen'
import { getUsersVouchers } from "../services/voucher_service";

import { useAuthStore } from "../state/auth_store";

/**
 * Gets the users vouchers if they have not been loaded 
 */
export default function VouchersGuard({
    children,
}: PropsWithChildren<unknown>) {
    const vouchersRetrieved = useAuthStore((state) => state.vouchers !== undefined);
    const setVouchers = useAuthStore((state) => state.setVouchers)

    // Fetch the users integrations if not loaded yet
    useEffect(() => {
        if (vouchersRetrieved === false) {
          getUsersVouchers()
                .then((vouchers) => {
                    if (vouchers) setVouchers(vouchers)
                })
                .catch((err) => console.log(err));
        }
    }, [vouchersRetrieved, setVouchers]);

    if (!vouchersRetrieved) {
        return <Screen>
            <LoadingPlaceholder />
        </Screen>
    } else {
        return children
    }
}
