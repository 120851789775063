import styled from "styled-components";
/**
 * Wrapper components for farm-related screens such as FarmScreen,
 * ReductionPlannerScreen etc.
 **/
export const FarmPageWrapper = styled.div`
    display: flex;
    align-items: center;

    flex-direction: column;
    width: 100%;
    flex-grow: 1;
`;

export const FarmPageContainer = styled.div`
    width: 90%;
    max-width: 936px;
    text-align: left;
    padding-bottom: 40px;
`;

export const CreateReportContainer = styled.div`
    width: 85%;
    max-width: 936px;
    text-align: left;
    padding-bottom: 40px;
`;

export const CreateReportWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 1;
    `