import styled from "styled-components";
import { ruminatiColors } from '../../utilities/colors';
import { BodyText } from '../styled_text'


export default function Legend({
  children,
  showProperty,
}: {
  children?: JSX.Element[] | undefined;
  showProperty?: boolean | undefined;
}) {

  return (
  <LegendContainer className="mapLegend">
    <BodyText 
      style={{
        fontSize: '0.7rem',
        fontWeight: '600',
        letterSpacing: '1.1px',
        color: '#4D4F4D'
      }}
    >
      LEGEND
    </BodyText>

    {showProperty !== false ?
      <LegendItem>
        <LegendSwatch color={'#FF983900'} borderColor={'#FF9839'} borderWidth={'2px'}/>
        Property Boundary
      </LegendItem>
    : undefined }
  
    {children ? children : undefined}

  </LegendContainer>
  )
}

export function createLegendItem (key: string, label: string, fillColor: string, borderColor: string, borderWidth?: string) {
  return (
    <LegendItem key={key}>
      <LegendSwatch color={fillColor} borderColor={borderColor} borderWidth={borderWidth}/>
      {label}
    </LegendItem>
  )
}

export const LegendContainer = styled.div`
  position: absolute;
  bottom: 143px;
  left: 15px;
  background: ${ruminatiColors.bone};
  border-radius: 4px;
  padding: 8px 12px;
`;

export const LegendItem = styled(BodyText)`
    font-size: 0.7rem;
    vertical-align: middle;
    height: 14px;
    line-height: 14px;
    letter-spacing: 0.4px;
    color: #4D4F4D;
`;

export const LegendGroupLabel = styled(LegendItem)`
    font-size: 0.7rem;
    vertical-align: middle;
    height: 14px;
    line-height: 14px;
    font-weight: 700;
    margin-top: 6px;
`;


export const LegendSwatch = styled.span<{color: string, borderColor: string, borderWidth?: string;}>`
    background-color: ${props => (props.color)};
    border: ${props => (props.borderWidth ?? '2px')} solid ${props => (props.borderColor)};
    display: inline-block;
    height: 13px;
    width: 20px;
    margin-right: 7px;
    vertical-align: bottom;
`;