import { Property, Report } from '../models/property_models';

export const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

/**
 * Formats a number to display in the report
 * @param val the number to format
 * @returns the formatted number as a string
 */
export function formatReportNumber(val: number | null, options: NumberFormatOptions = {
    maxDecimalSigFig: 3
}, forceDp = false): string {
    if (val === undefined || Number.isNaN(val) || val === null) return "-";

    const formatted = formatNumber(val, options);

    const result = formatted.toLocaleString(undefined, {
        maximumFractionDigits: forceDp ? options.maxDecimalSigFig : 2
    });
    return result
}

/**
 * Formats a percentage number to display in the report
 * @param val the number to format
 * @returns the formatted number as a string
 */
export function formatReportPercent(val: number, forceDp = false) {
    return formatReportNumber(val * 100, { maxDecimalPlaces: 2 }, forceDp) + "%";
}

interface NumberFormatOptions {
    maxSignificantFigures?: number
    maxDecimalPlaces?: number
    maxDecimalSigFig?: number
}

export function formatNumber(val: number, options: NumberFormatOptions = {}): number {
    let ret = val
    const retSplit = ret.toString().split(".");
    if (options.maxDecimalPlaces !== undefined) ret = +ret.toFixed(options.maxDecimalPlaces)
    if (options.maxSignificantFigures !== undefined) ret = +ret.toPrecision(options.maxSignificantFigures)
    if (options.maxDecimalSigFig !== undefined) ret = +ret.toFixed(Math.max(0, options.maxDecimalSigFig - (retSplit === undefined || retSplit[0] === undefined ? 0 : retSplit[0].length)))
    return ret
}

/**
 * Finds the corresponding property for a report, returning undefined if not
 * available.
 */
export function getPropertyFromReport(properties: Property[] | undefined, report: Report | undefined): Property | undefined {
    if (properties === undefined || report === undefined) return undefined;

    return properties.find(p => p.id === report.propertyId);
}

export function getReport(reports: Report[] | undefined, reportId: string | undefined): Report | undefined {
    if (reports === undefined || reportId === undefined) return undefined;

    return reports.find(r => r.id === reportId);

}

export function isNotNullOrUndefined (value: string | number | null | undefined): boolean {
    return value !== undefined && value !== null
}