import { FormData } from "../../../../models/form/form";
import { getCurrentFinancialYear } from "../../../../utilities/dates";
import { InitiativePopUp } from "../initiative_popup";
import { Report } from "../../../../models/property_models";
import { PecentageSliderFormField } from "../../../../models/form/form_fields/basic_form_fields";
import { getYearField, getDefaultReductionPlanYearOptions, addExistingReductionPlanYearsToOptions } from "../initiative_helpers";
import { FieldSize } from "../../../../models/form/form_fields/form_field";
import { ManageFeedAdditivesDTO } from "@/models/reduction_plans_models";
import EmissionUnit from "@/models/emission-unit";

interface FeedAdditivesFormData {
  feedAdditives: number;
  feedAdditivesTargetYear: number;
}

export default function ManageFeedAdditivesPopup(props: {
  initialData?: ManageFeedAdditivesDTO;
  compareReport?: Report;
  onSubmit: (data: ManageFeedAdditivesDTO) => Promise<void>;
}) {
  const yearOptions = getDefaultReductionPlanYearOptions()

  const existingReductionPlanYears = [
    props.initialData?.feedAdditives?.targetYear,
  ].filter(v => v !== undefined) as number[]
  addExistingReductionPlanYearsToOptions(existingReductionPlanYears, yearOptions)

  const initialData: ManageFeedAdditivesDTO = props.initialData ?? {
    feedAdditives: {
      targetValue: 0,
      targetYear: getCurrentFinancialYear()
    }
  }

  const formData: FormData<FeedAdditivesFormData, ManageFeedAdditivesDTO> = {
    fields: {
      feedAdditives: new PecentageSliderFormField({
        required: true,
        sliderProps: {
          defaultRange: { min: 0, max: 100 },
          prevReportValue: 0,
          initialValue: initialData.feedAdditives?.targetValue ?? 0,
          unitLabel: EmissionUnit.Change,
          label: `Enteric methane reduction (${EmissionUnit.Change})`,
        },
      }),
      feedAdditivesTargetYear: getYearField('Year of completion', FieldSize.Full, yearOptions),
    },
    transformer: {
      in: (value) => ({
        feedAdditives: value.feedAdditives?.targetValue,
        feedAdditivesTargetYear: value.feedAdditives?.targetYear,
      }),
      out: (value) => ({
        feedAdditives: {
          targetValue: value.feedAdditives,
          targetYear: value.feedAdditivesTargetYear,
        }
      })
    }
  };

  return (
    <InitiativePopUp
      formData={formData}
      infoAbove={"This reduction initiative allows you to trial adding Methane-reducing feed additives, such as Bovaer or asparagopsis-based products. Methane-reducing Feed Additives will reduce your <strong><u>Scope 1</u></strong> emissions generated on-site."}
      initialFormValues={initialData}
      onSubmit={(state) => props.onSubmit(state)}
    />
  );
}
