import styled from "styled-components";
import { ruminatiColors } from "../../utilities/colors";
import { BaseText } from "../styled_text";
import Heading from "../heading";

type LabelledValueProps = {
    /**
     * The label describing the item
     */
    label: JSX.Element | string;
    labelHeading?: 1 | 2 | 3 | 4 | 5 | 6;
    labelColor?: string;
    labelSpacerWidth?: string;
    children?: JSX.Element | string;
    fontSize?: string;
    spacerMargin?: string;
    spacerWidth?: string;
    dashStyle?: "center" | "underline" | "none";
    labelVerticalAlign?: 'flex-start';
};

/**
 * A label and a value separated with a underline spacer.
 * @param label the text to display as the label.
 * @param labelHeading the heading level to display the label (if not given, then p)
 * @param labelColor optional. The color of the label. Defaults to green
 * @param labelSpacerWidth optional. The width of the label and the spacer. Defaults to auto
 * @param fontSize optional. The font size of the label and value. Defaults to 16px.
 * @param spacerMargin optional. The margin values of the spacer. Default to `0 0 0 0`.
 * @param children the content to display as the value.
 * @param dashStyle if dashStyle is set as center | underline | none, the dash will be centered, underlined or none respectively. The default is underline.
 */
export default function LabelledValue(props: LabelledValueProps) {
    const LabelComponent = props.labelHeading ? (
        <Heading level={props.labelHeading}>{props.label}</Heading>
    ) : (
        <ValueLabel color={props.labelColor ?? ruminatiColors.green_3} style={{ fontSize: props.fontSize }}>
            {props.label}
        </ValueLabel>
    );

    const SpacerComponent = (() => {
        switch(props.dashStyle) {
            case "center":
                return <DashSpacer margin={props.spacerMargin} width={props.labelSpacerWidth} />;
            case "none":
                return null;
            default:
                return <UnderlineSpacer margin={props.spacerMargin} width={props.labelSpacerWidth} />;
        }
    })();

    return (
        <LabelledValueWrapper alignItems={props.labelVerticalAlign}>
            <div style={{ display: "flex", flexGrow: 1, maxWidth: props.labelSpacerWidth }}>
                {LabelComponent}
                {SpacerComponent}
            </div>
            {props.children}
        </LabelledValueWrapper>
    );
}

type LabelledValueWrapperProps = {
    alignItems?: string;
};
const LabelledValueWrapper = styled.div<LabelledValueWrapperProps>`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: ${(props) => props.alignItems ?? 'center'};
    padding: 3px;
`;

const ValueLabel = styled(BaseText)`
    color: ${(props) => props.color};
    line-height: 32px;
`;

type SpacerProps = {
    margin?: string;
    width?: string;
};

const UnderlineSpacer = styled.div<SpacerProps>`
    flex-grow: 1;
    height: 24px;
    border-bottom: 1px solid ${ruminatiColors.green_3_30};
    margin: ${(props) => props.margin ?? "0 0 0 0"};
    width: ${(props) => props.width ?? 'auto'};
`;

const DashSpacer = styled(UnderlineSpacer)`
  height: 1px;
  background-color: ${ruminatiColors.green_3_30};
  border-bottom: none;
  transform: translateY(8.5px);
`;
