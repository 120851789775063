import styled from "styled-components";
import { usePopupStore } from "../../state/popup_store";
import { ruminatiColors } from "../../utilities/colors";
import MainButton from "../buttons/main_button";
import { Row } from "../styled_layout";
import { BodyText, ParagraphText } from "../styled_text";
import { DropdownField, DropdownFieldProps } from "../form/input_field";
import { useRef, useState } from "react";
import { RotatingIcon } from "../buttons/loading_button";
import Icon from "../icon";

interface DropdownPopupI<T extends string | number> extends DropdownFieldProps<T> {
    buttonText: string;
    preText: string | undefined;
    onConfirm: (selectedValue?: T) => Promise<void>;
}

export default function DropdownPopup<T extends string | number>({
    buttonText,
    preText,
    onConfirm,
    ...dropdownProps
}: DropdownPopupI<T>) {
    const [loading, setLoading] = useState(false);
    const [selectionMade, setSelectionMade] = useState(dropdownProps.value !== undefined);
    const popupStore = usePopupStore();
    const dropdownValueRef = useRef<T | undefined>(undefined);

    const handleClick = async () => {
        setLoading(true);
        await onConfirm(dropdownValueRef.current);
        setLoading(false)
        popupStore.closePopup();
    }

    return (
        <div
            style={{
                width: "464px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                overflow: "hidden",
            }}
        >   
            {
                preText ? 
                <ParagraphText style={{marginTop: "20px"}}>
                    {preText}
                </ParagraphText>
                : undefined
            }
            
            <BodyText style={{
                color: ruminatiColors.green_3,
                textAlign: "center",
                letterSpacing: "0.28px",
                padding: "20px 20px",
            }}>
                <DropdownField {...dropdownProps} onSelect={value => {
                    if (value) setSelectionMade(true)
                    dropdownValueRef.current = value
                }}
                 />
            </BodyText>
            <Footer>
                <Row>
                    {loading ? <div style={{ padding: "0 4px 0 0" }}>
                        <RotatingIcon>
                            <Icon icon="loading" />
                        </RotatingIcon>
                    </div> :
                        <MainButton
                            size="small"
                            onClick={handleClick}
                            disabled={!selectionMade}
                        >
                            {buttonText}
                        </MainButton>}
                </Row>
            </Footer>
        </div>
    );
}

const Footer = styled.div`
    color: #ffffff;
    background-color: ${ruminatiColors.bone};

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    font-family: "Approach", sans-serif;
    height: 64px;
    width: 100%;
    padding: 24px 0 24px 0;
    border-radius: 0px 0px 8px 8px;
    border-top: 1px solid ${ruminatiColors.effective_black_30};
`;
