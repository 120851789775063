import styled from "styled-components"
import { useState } from "react";
import { useMap } from "react-map-gl";
import { useRollbar } from "@rollbar/react";

import { DropdownOptionData } from "@/models/form/form_fields/form_field";
import { SuggestionField } from "../form/suggestion_field"
import { getPlaceSuggestions, getPlace } from "@/services/property_service";
import { useAuthStore } from "@/state/auth_store";
import { CountryCode } from "@/utilities/countries";
import { convertUnknownErrortoStringable } from "@/utilities/errors";

export default function SearchBox(props: {
  onSelect: () => void
}) {
  const authStore = useAuthStore()
  const rollbar = useRollbar()

  const {myMapA: map} = useMap()

  const [timeoutId, setTimeoutId] = useState<number | null>(null);

  const [dropdownOptions, setDropdownOptions] = useState<
    DropdownOptionData<string>[]
  >([]);

  const onSearchUpdate = async (value: string) => {
    if (value.length < 3) return;
    if (timeoutId !== null) window.clearTimeout(timeoutId);
    const tempTimeoutId = window.setTimeout(async () => {
      await requestPlaceSuggestions(value);
    }, 300);
    setTimeoutId(tempTimeoutId);
  };

  const requestPlaceSuggestions = async (value?: string) => {
    if (value === undefined) return;
    try {
      const predictions = await getPlaceSuggestions(value, authStore.user?.country as CountryCode);

      setDropdownOptions(predictions.map((p) => {
        return {
          value: p.place_id,
          name: p.description,
        } as DropdownOptionData<string>;
      }));
    } catch (err) {
      if (err instanceof Error) console.log(err.message);
    }
  };

  const onSearchSelect = async (value?: string) => {
    if (!value || !map) return;

    try {
      const result = await getPlace(value);

      if (result) {
        map.fitBounds([
            result.geometry.viewport.southwest.lng,
            result.geometry.viewport.southwest.lat,
            result.geometry.viewport.northeast.lng,
            result.geometry.viewport.northeast.lat,
        ])
        props.onSelect()
      }
    } catch (err) {
      rollbar.error('Error getting place or fitting bounds', {
        errorDetails: convertUnknownErrortoStringable(err)
      })
    }
  }

  return <OverlayWrapper style={{ width: "270px" }}>
    <SuggestionField
      suggestions={dropdownOptions}
      placeholder="Search for a location"
      onChange={(value) => {
        if (value && typeof value === 'string') onSearchSelect(value ?? "")
      }}
      onSearch={(value) => {
        if (value && typeof value === 'string') onSearchUpdate(value)
      }}
    />
  </OverlayWrapper>
}

const OverlayWrapper = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
`;
