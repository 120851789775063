import { PropsWithChildren, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import LoadingPlaceholder from '../components/loading_placeholder';
import Screen from '../screens/screen'
import { upsertSubscriptionUsingVoucher } from '@/services/auth_service';
import { useRollbar } from '@rollbar/react';
import { convertUnknownErrortoStringable } from '@/utilities/errors';
import { createShareAll } from '@/services/vision_service';
import { useAuthStore } from '@/state/auth_store';
import dayjs from 'dayjs';

export const RESULTS_SHARE_ALL_STATUS_KEY = "status_share_all"
export const RESULTS_VOUCHER_STATUS_KEY = "status_voucher"

export enum Status {
  Success = 'success',
  Failed = 'failed',
  AlreadyShared = 'already_shared'
}

export default function ShareAllVoucherGuard({
    children,
}: PropsWithChildren<unknown>) {
    const rollbar = useRollbar()
    const authStore = useAuthStore()

    const [searchParams, setSearchParams] = useSearchParams()
    const shareAllVisionOrgId = searchParams.get("shareAllVisionOrgId") ?? undefined
    const voucherPoolId = searchParams.get("voucherPoolId") ?? undefined

    const shareAllStatus = searchParams.get(RESULTS_SHARE_ALL_STATUS_KEY) ?? undefined
    const voucherStatus = searchParams.get(RESULTS_VOUCHER_STATUS_KEY) ?? undefined

    const voucherMutation = voucherPoolId ? useMutation({
      mutationKey: ['voucher'],
      mutationFn: async () => {
          const data = await upsertSubscriptionUsingVoucher({
            voucherPoolId
          })
          return data
      }
    }) : undefined

    const shareAllMutation = shareAllVisionOrgId ? useMutation({
      mutationKey: ['shareAll'],
      mutationFn: async () => {
          const data = await createShareAll({
            visionOrgId: shareAllVisionOrgId,
            userId: authStore.user?.uid ?? '',
            requiredUntil: voucherPoolId ? dayjs().add(1, 'year').toISOString() : undefined
          })
          return data
      }
    }) : undefined

    useEffect(() => {
      function setStatus (responseData: any, resultKey: typeof RESULTS_VOUCHER_STATUS_KEY | typeof RESULTS_SHARE_ALL_STATUS_KEY) {
        if (responseData instanceof Error) {
          if (resultKey === RESULTS_SHARE_ALL_STATUS_KEY && (responseData.cause as string).includes('A Link Share All already exists')) {
            searchParams.set(resultKey, Status.AlreadyShared)
          } else {
            searchParams.set(resultKey, Status.Failed)
            rollbar.error('ShareAll Or Voucher Upgrade Failed', {
              errorDetails: convertUnknownErrortoStringable(responseData),
            })            
          }
        } else if (responseData) {
          searchParams.set(resultKey, Status.Success)
        }
      }

      async function handleActions () {
        if (voucherMutation && !voucherStatus) {
          const voucherData = await voucherMutation.mutateAsync()
          setStatus(voucherData, RESULTS_VOUCHER_STATUS_KEY)
        } 

        if (shareAllMutation && !shareAllStatus) {
          const shareAllData = await shareAllMutation.mutateAsync()
          setStatus(shareAllData, RESULTS_SHARE_ALL_STATUS_KEY)
        }
        // slow things down a smidge
        await new Promise(r => setTimeout(r, 1000))
        setSearchParams(searchParams)
      }

      handleActions()
    }, [])

    if (!shareAllVisionOrgId && !voucherPoolId) {
      return children
    }

    const allRequestsReady = []
    if (shareAllVisionOrgId) allRequestsReady.push(shareAllMutation?.isPending)
    if (voucherPoolId) allRequestsReady.push(voucherMutation?.isPending)

    const allStatusReady = []
    if (shareAllVisionOrgId) allStatusReady.push(shareAllStatus !== undefined)
    if (voucherPoolId) allStatusReady.push(voucherStatus !== undefined)

    if (allStatusReady.every(r => r)) {
      return children
    }

    return <Screen>
        <LoadingPlaceholder />
    </Screen>
}
