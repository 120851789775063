import { usePopupStore } from "../../state/popup_store";
import MainButton from "../buttons/main_button";
import { Row } from "../styled_layout";

export default function ExternalLinkPopup({
    content,
    buttonText,
    href,
    target,
    width,
    disabled
}: {
    content: JSX.Element
    buttonText: string
    href: string
    target: string
    width?: string
    disabled?: boolean
}) {
    const popupStore = usePopupStore();

    return (
        <div
            style={{
                width: width ?? "464px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "20px",
            }}
        >
            {content}
            <Row>
              <a href={href} target={target}>
                <MainButton
                    size="small"
                    disabled={disabled ?? false}
                    onClick={() => {
                        popupStore.closePopup();
                    }}
                >
                    {buttonText}
                </MainButton>
              </a>
            </Row>
        </div>
    );
}
