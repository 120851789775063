import { PropsWithChildren } from "react";
import { ruminatiColors } from "../../utilities/colors";
import Button from "./button";

type MainButtonProps = {
    colorScheme?: "green" | "orange" | "transparent";
    size?: "large" | "medium" | "small";
    width?: string;
    disabled?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    type?: "submit" | "button" | "reset";
};

/**
 * A large action button. Typically used for the main action on the page.
 * @param colorScheme optional. The set of colors to use for the button.
 * Defaults to orange.
 * @param size optional. The named size of the button
 * @param width optional. The width of the button in px
 * @param disabled optional. Prevents click interaction and greys out
 * button. Defaults to false
 * @param children optional. The content of the button
 * @param onClick optional. The function called when the button is clicked
 */
export default function MainButton(props: PropsWithChildren<MainButtonProps>) {
    const _backgroundColor =
        props.colorScheme === "transparent"
            ? ruminatiColors.transparent
            : props.colorScheme === "green" ? ruminatiColors.green_3 : ruminatiColors.orange;
    const _hoverBackgroundColor =
        props.colorScheme === "transparent"
            ? ruminatiColors.transparent
            : props.colorScheme === "green" ? ruminatiColors.dark_green : ruminatiColors.orange;
    const _activeBackgroundColor =
        props.colorScheme === "transparent"
            ? ruminatiColors.transparent
            : props.colorScheme === "green" ? ruminatiColors.effective_black : ruminatiColors.orange;

    return (
        <Button
            onClick={props.onClick}
            backgroundColor={
                props.disabled ? ruminatiColors.bone : _backgroundColor
            }
            hoverBackgroundColor={
                props.disabled ? ruminatiColors.bone : _hoverBackgroundColor
            }
            activeBackgroundColor={
                props.disabled ? ruminatiColors.bone : _activeBackgroundColor
            }
            textColor={
                props.disabled
                    ? ruminatiColors.effective_black_30
                    : props.colorScheme === "transparent" ? `1px solid ${ruminatiColors.green_3}` : ruminatiColors.bone
            }
            hoverTextColor={
                props.disabled
                    ? ruminatiColors.effective_black_30
                    : props.colorScheme === "transparent" ? `1px solid ${ruminatiColors.green_3}` : ruminatiColors.bone
            }
            activeTextColor={
                props.disabled
                    ? ruminatiColors.effective_black_30
                    : ruminatiColors.bone
            }
            border={
                props.disabled
                    ? `1px solid ${ruminatiColors.effective_black_30}`
                    : props.colorScheme === "transparent" ? `1px solid ${ruminatiColors.green_3}` : ruminatiColors.bone
            }
            borderRadius="48px"
            disabled={props.disabled}
            fontSize={
                props.size === "large"
                    ? "20px"
                    : props.size === "medium"
                        ? "16px"
                        : "14px"
            }
            height={props.size === "large" ? "64px" : "40px"}
            width={props.width ? props.width : "auto"}
            padding={
                props.size === "large"
                    ? "0px 80px 0 80px"
                    : props.size === "medium"
                        ? "0px 40px 0 40px"
                        : "0px 16px 0 16px"
            }
            type={props.type}
        >
            {props.children}
        </Button>
    );
}
