import { calculateSumOfArray, sumOfProducts } from './maths'
import { monthsForSelecting } from './dates'
import { 
  DseClass, 
  BeefBreedingSystemParameters,
  TradeMonth,
  TradingSystemParameters,
  AnySheepSystemParameters,
  PrimeLambSystemParameters,
  DualPurposeSystemParameters,
  WoolSystemParameters
 } from '../models/dse'

export enum DSEUnit {
  DSE = "DSE",
  AE = "AE"
}

export const convertDSEtoAE = (dse: number): number => dse / 8.4
export const convertAEtoDSE = (ae: number): number => ae * 8.4

export const BeefClasses: DseClass[] = [
  {
    name: 'Weaners',
    value: 'weaners-beef',
    dseRating: 8
  },
  {
    name: 'Steers',
    value: 'steers',
    dseRating: 10
  },
  {
    name: 'Heifers',
    value: 'heifers',
    dseRating: 10
  },
  {
    name: 'Breeders',
    value: 'breeders-beef',
    dseRating: 13.2
  },
  {
    name: 'Other',
    value: 'other-beef',
    dseRating: 10
  }
]

export const SheepClasses: DseClass[] = [
  {
    name: 'Weaners',
    value: 'weaners-sheep',
    dseRating: 1.5
  },
  {
    name: 'Rams',
    value: 'rams',
    dseRating: 2
  },
  {
    name: 'Wethers',
    value: 'wethers',
    dseRating: 1.2
  },
  {
    name: 'Ewe Hoggets',
    value: 'ewe-hoggets',
    dseRating: 1.1
  },
  {
    name: 'Breeders',
    value: 'breeders-sheep',
    dseRating: 1.5
  },
]

function getClassByValue (value: string): DseClass | undefined {
  let sheepOrBeefClass = undefined
  sheepOrBeefClass = BeefClasses.find(f => f.value === value)
  if (sheepOrBeefClass === undefined) sheepOrBeefClass = SheepClasses.find(f => f.value === value)
  return sheepOrBeefClass === undefined ? undefined : sheepOrBeefClass
} 

function getDseRatingByClass(className: string): number {
  const dseClass = getClassByValue(className)
  if (dseClass !== undefined) return dseClass.dseRating
  return 0
}

export function getClassNameByValue (value: string): string | undefined {
  const dseClass = getClassByValue(value)
  if (dseClass !== undefined) return dseClass.name
  return undefined
} 

export function calculateBeefDseBreedingSystem(parameters: BeefBreedingSystemParameters): number {
  const D5 = parameters.pticAdults
  const D6 = parameters.pticHeifers
  const D22 = (D6 / sumOfProducts([
    [D5, D6],
    [parameters.joiningRateAdults / 100, parameters.joiningRateHeifers / 100]
  ])) * 100

  const D24 = parameters.pticHeifers / (parameters.joiningRateHeifers / 100)
  const D25 = parameters.pticAdults / (parameters.joiningRateAdults / 100)
  const D26 = D24 + D25

  const D28 = sumOfProducts([
    [D5, D6],
    [parameters.pticWeaningRateHeifers / 100, parameters.pticWeaningRateAdults / 100]
  ])

  const D31 = ((12 - parameters.avgAgeCalvesWeaning) / 12) * 100,
    E31 = 9,
    D32 = (3 / 12) * 100,
    E32 = 15,
    D33 = ((parameters.avgAgeCalvesWeaning - 3) / 12) * 100,
    E33 = 17,
    //   D34 = sumOfProducts([
    //     [D31 / 100, D32 / 100, D33 / 100],
    //     [E31, E32, E33],
    //   ]),
    D35 = sumOfProducts([
      [D31 / 100, D32 / 100, D33 / 100],
      [E31, E32, E33],
      [D5, D5, D5]
    ]) + sumOfProducts([
      [D31 / 100, D32 / 100, D33 / 100],
      [E31, E32, E33],
      [D6, D6, D6]
    ])

  const I31 = 9,
    J31 = D25 - parameters.pticAdults,
    K31 = (parameters.monthsNonLactatingAdultRetained / 12) * 100,
    I34 = sumOfProducts([
      [I31], [J31], [K31 / 100]
    ])

  const D39 = 10

  const D43 = (12 - parameters.avgAgeCalvesWeaning) / 12,
    E43 = 6.5,
    D16 = parameters.avgAgeSaleSteers,
    D44 = D16 >= 12 && D16 >= 24 ? 12 / 12 : D16 >= 12 && D16 <= 24 ? (D16 - 12) / 12 : 0,
    E44 = 8.5,
    D45 = D16 >= 24 ? (D16 - 24) / 12 : 0,
    E45 = 10,
    //   D46 = sumOfProducts([
    //     [D43 / 100, D44 / 100, D45 / 100],
    //     [E43, E44, E45]
    //   ]),
    D48 = 0.5,
    F43 = D28 * D48,
    F44 = F43,
    F45 = F43,
    //   OTH = parameters.selfReplacing ? J46 * J48 : 0,
    //   D50 = (D46 * D48) + (G46 * G48) + OTH,
    D52 = sumOfProducts([
      [D43, D44, D45],
      [E43, E44, E45],
      [F43, F44, F45]
    ])

  const D18 = parameters.avgAgeCalvingHeifers,
    J44 = D18 >= 12 && D18 >= 24 ? (12 / 12) * 100 : D18 >= 12 && D18 <= 24 ? (D18 - 12) / 12 : 0,
    J45 = D18 >= 24 ? (D18 - 24) / 12 : 0,
    K45 = 9,
    J47 = parameters.selfReplacing ? D22 : 0,
    L43 = D28 * 0.5 * (J47 / 100),
    L44 = L43,
    L45 = L43

  const G43 = ((12 - parameters.avgAgeCalvesWeaning) / 12) * 100,
    H43 = 6.5,
    J43 = G43,
    K43 = H43,
    D17 = parameters.avgAgeSaleHeifers,
    G44 = D17 >= 12 && D17 >= 24 ? 12 / 12 : D17 >= 12 && D17 <= 24 ? ((D17 - 12) / 12) * 100 : 0,
    H44 = 8.5,
    K44 = H44,
    G45 = D17 >= 24 ? (D17 - 24) / 12 : 0,
    H45 = 10,
    G47 = (100 - J47),
    I43 = (D28 * 0.5 * (G47 / 100)),
    I44 = I43,
    I45 = I43,
    G52 = sumOfProducts([
      [G43 / 100, G44 / 100, G45 / 100],
      [H43, H44, H45],
      [I43, I44, I45]
    ]),
    J52 = sumOfProducts([
      [J43 / 100, J44 / 100, J45 / 100],
      [K43, K44, K45],
      [L43, L44, L45]
    ])
  return D35 + I34 + D52 + G52 + J52 + ((D39 * 0.002 * D26) * 10)
}

function createEmptyYearOfTrades(): TradeMonth[] {
  return monthsForSelecting.map((m) => {
    return { month: m.value, purchases: 0, sales: 0 }
  })
}

// Same logic for both Beef and Sheep
export function calculateDseTradingSystem(parameters: TradingSystemParameters): number {
  const classDSEs = parameters.classes.map((c) => {
    const fullTrades = createEmptyYearOfTrades()
    c.trades.forEach(t => {
      const matchingMonth = fullTrades.find(tt => tt.month === t.month)
      if (matchingMonth) {
        matchingMonth.purchases = t.purchases
        matchingMonth.sales = t.sales
      }
    })
    let prevTotal = c.opening
    const runningTotals = fullTrades.map((tradeMonth) => {
      const runningTotal = prevTotal + tradeMonth.purchases - tradeMonth.sales
      prevTotal = runningTotal
      return runningTotal
    })

    const propotionOfYear = fullTrades.map((_t) => 1 / 12)
    const dseRating = getDseRatingByClass(c.class)
    return sumOfProducts([
      runningTotals,
      propotionOfYear
    ]) * dseRating - (c.deaths * dseRating)
  })

  return calculateSumOfArray(classDSEs)
}

interface SheepClass {
  name: string;
  dsePerHead: number;
  percentageYearOn: number;
  count: number;
}

const calcSurvivalRate = (parameters: AnySheepSystemParameters): number => 100 - parameters.mortalityRate
const calcPercentOldestWetherGroupRetained = (parameters: WoolSystemParameters | DualPurposeSystemParameters): number => parameters.monthsOldestWetherRetained / 12

const calcEweCount = (parameters: AnySheepSystemParameters): number => parameters.numberEwesJoined
function calcSaleWeanerCount (parameters: AnySheepSystemParameters): number {
  const proportionLambsEwesJoinedAsPerc = parameters.proportionLambsEwesJoined / 100
  return (parameters.numberEwesJoined * proportionLambsEwesJoinedAsPerc * 0.5 * (parameters.proportionEweLambsSoldUnder12Months / 100))
}
const calcSaleWetherCount = (parameters: AnySheepSystemParameters): number => (parameters.numberEwesJoined * (parameters.proportionLambsEwesJoined / 100) * 0.5 * (parameters.proportionWetherLambsSoldUnder12Months / 100))

function calcRetainedWeanerCount (parameters: AnySheepSystemParameters, type: string): number {
  const multiplier = type === 'primeLamb' ? parameters.proportionEweLambsSoldUnder12Months : parameters.proportionEweLambsSoldUnder12Months
  return parameters.numberEwesJoined * (parameters.proportionLambsEwesJoined / 100) * 0.5 * (1 - (multiplier / 100))
}

function calcRetainedWetherWeanerCount (parameters: AnySheepSystemParameters, type: string): number {
  const multiplier = type === 'primeLamb' ? parameters.proportionWetherLambsSoldUnder12Months : parameters.proportionWetherLambsSoldUnder12Months
  return parameters.numberEwesJoined * (parameters.proportionLambsEwesJoined / 100) * 0.5 * (1 - (multiplier / 100))
}

function calcEweHoggerCount(parameters: AnySheepSystemParameters, type: string): number {
  if (type === 'wool') return calcRetainedWeanerCount(parameters, type)
  if (type === 'dualPurpose') return (calcRetainedWeanerCount(parameters, type) * (calcSurvivalRate(parameters) / 100))
  if (type === 'primeLamb') {
    const params = parameters as PrimeLambSystemParameters
    return params.eweLambsJoinedAt12Months ? 0 : (calcRetainedWeanerCount(params, type) * (calcSurvivalRate(parameters) / 100))
  }
  return 0
}

function calcWetherHoggerCount(parameters: AnySheepSystemParameters, type: string): number {
  const percentYearOn = calcWetherHoggetPercentYearOn(parameters, type)
  if (type === 'wool') return percentYearOn === 0 ? 0 : calcRetainedWeanerCount(parameters, type) * (calcSurvivalRate(parameters) / 100)
  if (type === 'dualPurpose') return percentYearOn === 0 ? 0 : calcRetainedWeanerCount(parameters, type)
  if (type === 'primeLamb') {
    const params = parameters as PrimeLambSystemParameters
    return calcRetainedWetherWeanerCount(params, type) * (calcSurvivalRate(params) / 100)
  }
  return 0
}

function calcWether2to3YearsCount(parameters: WoolSystemParameters | DualPurposeSystemParameters, type: string): number {
  const countPrevWetherHoggart = calcWetherHoggerCount(parameters, type)
  return countPrevWetherHoggart * (calcSurvivalRate(parameters) / 100)
}

function calcWether3to4YearsCount(parameters: WoolSystemParameters | DualPurposeSystemParameters, type: string): number {
  const countPrevWetherHoggart = calcWether2to3YearsCount(parameters, type)
  return countPrevWetherHoggart * (calcSurvivalRate(parameters) / 100)
}

function calcWether4to5YearsCount(parameters: WoolSystemParameters | DualPurposeSystemParameters, type: string): number {
  if (calculateOlderHoggetPercentYearOn(parameters as WoolSystemParameters | DualPurposeSystemParameters, 4) === 0) return 0
  const countPrevWetherHoggart = calcWether3to4YearsCount(parameters, type)
  return countPrevWetherHoggart * (calcSurvivalRate(parameters) / 100)
}

function calcWether5to6YearsCount(parameters: WoolSystemParameters | DualPurposeSystemParameters, type: string): number {
  if (calculateOlderHoggetPercentYearOn(parameters as WoolSystemParameters | DualPurposeSystemParameters, 5) === 0) return 0
  const countPrevWetherHoggart = calcWether4to5YearsCount(parameters, type)
  return countPrevWetherHoggart * (calcSurvivalRate(parameters) / 100)
}

function calcWether6to7YearsCount(parameters: WoolSystemParameters | DualPurposeSystemParameters, type: string): number {
  if (calculateOlderHoggetPercentYearOn(parameters as WoolSystemParameters | DualPurposeSystemParameters, 6) === 0) return 0
  const countPrevWetherHoggart = calcWether5to6YearsCount(parameters, type)
  return countPrevWetherHoggart * (calcSurvivalRate(parameters) / 100)
}

function calcRamCount (parameters: AnySheepSystemParameters, type: string): number {
  if (type !== 'primeLamb') return parameters.numberEwesJoined * 0.015
  return (parameters.numberEwesJoined + (parameters as PrimeLambSystemParameters).numberOfEweLambsJoined) * 0.015
} 

const calcSaleEweWeanerPercentYearOn = (parameters: AnySheepSystemParameters): number => (parameters.avgAgeMonthsEweLambsSale - 3) / 12
const calcSaleEweWetherPercentYearOn = (parameters: AnySheepSystemParameters): number => (parameters.avgAgeMonthsWetherLambsSale - 3) / 12

function calcEweHoggetPercentYearOn(parameters: AnySheepSystemParameters, type: string): number {
  if (type === 'wool') {
    return 8 / 12
  }
  if (type === 'dualPurpose') {
    return ((parameters as DualPurposeSystemParameters).avgAgeMonthsEweHoggetsSale - 12) / 12
  }
  if (type === 'primeLamb') {
    return ((parameters as PrimeLambSystemParameters).avgAgeMonthsEweHoggetsSale - 12) / 12
  }
  return 0
}

function calcWetherHoggetPercentYearOn(parameters: AnySheepSystemParameters, type: string): number {
  const dseHead = 1
  const classId = 1
  if (type === 'wool') {
    const params = parameters as WoolSystemParameters
    return classId === params.matureWetherAgeGroups ? dseHead * (params.monthsOldestWetherRetained / 12) : classId < params.matureWetherAgeGroups ? dseHead * 1 : 0
  }
  if (type === 'dualPurpose') {
    const params = parameters as DualPurposeSystemParameters
    return classId === params.matureWetherAgeGroups ? dseHead * (params.monthsOldestWetherRetained / 12) : classId < params.matureWetherAgeGroups ? dseHead * 1 : 0
  }
  if (type === 'primeLamb') {
    const params = parameters as PrimeLambSystemParameters
    return (params.avgAgeMonthsWetherHoggetsSale - 12) / 12
  }
  return 0
}

function calculateOlderHoggetPercentYearOn(parameters: WoolSystemParameters | DualPurposeSystemParameters, classId: number): number {
  const dseHead = 1
  return classId === parameters.matureWetherAgeGroups ? dseHead * calcPercentOldestWetherGroupRetained(parameters) : classId < parameters.matureWetherAgeGroups ? dseHead * 1 : 0
}

function calcEweLatePregDse (parameters: AnySheepSystemParameters, type: string): number {
  const proportionLambsEwesJoinedAsPerc = parameters.proportionLambsEwesJoined / 100
  if (type !== 'primeLamb') {
    if (proportionLambsEwesJoinedAsPerc > 1) {
      return ((proportionLambsEwesJoinedAsPerc - 1) * woolDualPurposeFactors.LP.Twinning) + ((1 - (proportionLambsEwesJoinedAsPerc - 1)) * woolDualPurposeFactors.LP.Single)
    }
    return woolDualPurposeFactors.LP.Single
  }

  if (proportionLambsEwesJoinedAsPerc > 1) {
    return ((proportionLambsEwesJoinedAsPerc - 1) * primeLambFactors.LP.Twinning) + ((1 - (proportionLambsEwesJoinedAsPerc - 1)) * primeLambFactors.LP.Single)
  }
  return primeLambFactors.LP.Single
} 

function calcEweLactatingDse (parameters: AnySheepSystemParameters, type: string): number {
  const proportionLambsEwesJoinedAsPerc = parameters.proportionLambsEwesJoined / 100
  const factors = type !== 'primeLamb' ? woolDualPurposeFactors : primeLambFactors
  if (proportionLambsEwesJoinedAsPerc > 1) {
    return ((proportionLambsEwesJoinedAsPerc - 1) * factors.Lactating.Twinning) + ((1 - (proportionLambsEwesJoinedAsPerc - 1)) * factors.Lactating.Single)
   } 
   return (proportionLambsEwesJoinedAsPerc * factors.Lactating.Single) + (1 - proportionLambsEwesJoinedAsPerc) * factors.Dry.Single
} 

function calcElLatePregDse (parameters: PrimeLambSystemParameters): number {
  const proportionLambsWeanedToEwesJoinedEweLambsAsPerc = parameters.proportionLambsWeanedToEwesJoinedEweLambs / 100
  if (proportionLambsWeanedToEwesJoinedEweLambsAsPerc > 1) {
    return ((proportionLambsWeanedToEwesJoinedEweLambsAsPerc - 1) * primeLambFactors.LP.Twinning) + ((1 - (proportionLambsWeanedToEwesJoinedEweLambsAsPerc - 1)) * primeLambFactors.LP.Single)
  }
  return primeLambFactors.LP.Single
}

function calcElLactatingDse (parameters: PrimeLambSystemParameters): number {
  const proportionLambsWeanedToEwesJoinedEweLambsAsPerc = parameters.proportionLambsWeanedToEwesJoinedEweLambs / 100
  if (proportionLambsWeanedToEwesJoinedEweLambsAsPerc > 1) {
    return ((proportionLambsWeanedToEwesJoinedEweLambsAsPerc - 1) * primeLambFactors.Lactating.Twinning) + ((1 - (proportionLambsWeanedToEwesJoinedEweLambsAsPerc - 1)) * primeLambFactors.Lactating.Single)
  } 
  return (proportionLambsWeanedToEwesJoinedEweLambsAsPerc * primeLambFactors.Lactating.Single) + (1 - proportionLambsWeanedToEwesJoinedEweLambsAsPerc) * primeLambFactors.Dry.Single
} 




function createDefaultSheepClasses(parameters: AnySheepSystemParameters, type: string): SheepClass[] {

  const classes = [
    {
      name: 'ewesDry',
      dsePerHead: type !== 'primeLamb' ? 1.1 : 1.3,
      percentageYearOn: 7 / 12,
      count: calcEweCount(parameters)
    },
    {
      name: 'ewesLatePregnancy',
      dsePerHead: calcEweLatePregDse(parameters, type),
      percentageYearOn: 2 / 12,
      count: calcEweCount(parameters)
    },
    {
      name: 'ewesLactating',
      dsePerHead: calcEweLactatingDse(parameters, type),
      percentageYearOn: 4 / 12,
      count: calcEweCount(parameters)
    },
    {
      name: 'saleEweWeaners',
      dsePerHead: type !== 'primeLamb' ? 1 : 1.6,
      percentageYearOn: calcSaleEweWeanerPercentYearOn(parameters),
      count: calcSaleWeanerCount(parameters)
    },
    {
      name: 'saleWetherWeaners',
      dsePerHead: type !== 'primeLamb' ? 1 : 1.6,
      percentageYearOn: calcSaleEweWetherPercentYearOn(parameters),
      count: calcSaleWetherCount(parameters)
    },
    {
      name: 'retainedEweWeaners',
      dsePerHead: type !== 'primeLamb' ? 1 : 1.6,
      percentageYearOn: 9 / 12,
      count: calcRetainedWeanerCount(parameters, type)
    },
    {
      name: 'retainedWetherWeaners',
      dsePerHead: type !== 'primeLamb' ? 1 : 1.6,
      percentageYearOn: 9 / 12,
      count: calcRetainedWetherWeanerCount(parameters, type)
    },
    {
      name: 'eweHoggets',
      dsePerHead: 1,
      percentageYearOn: calcEweHoggetPercentYearOn(parameters, type),
      count: calcEweHoggerCount(parameters, type)
    },
    {
      name: 'wetherHoggets',
      dsePerHead: 1,
      percentageYearOn: calcWetherHoggetPercentYearOn(parameters, type),
      count: calcWetherHoggerCount(parameters, type)
    },
    {

      name: 'rams',
      dsePerHead: 2,
      percentageYearOn: 1,
      count: calcRamCount(parameters, type)
    }
  ]

  if (type === 'wool' || type === 'dualPurpose') {
    classes.push(
      {
        name: 'wethers2-3Yrs',
        dsePerHead: 1,
        percentageYearOn: calculateOlderHoggetPercentYearOn(parameters as WoolSystemParameters | DualPurposeSystemParameters, 2),
        count: calcWether2to3YearsCount(parameters as WoolSystemParameters | DualPurposeSystemParameters, type)
      },
      {
        name: 'wethers3-4Yrs',
        dsePerHead: 1,
        percentageYearOn: calculateOlderHoggetPercentYearOn(parameters as WoolSystemParameters | DualPurposeSystemParameters, 3),
        count: calcWether3to4YearsCount(parameters as WoolSystemParameters | DualPurposeSystemParameters, type)
      },
      {
        name: 'wethers4-5Yrs',
        dsePerHead: 1,
        percentageYearOn: calculateOlderHoggetPercentYearOn(parameters as WoolSystemParameters | DualPurposeSystemParameters, 4),
        count: calcWether4to5YearsCount(parameters  as WoolSystemParameters | DualPurposeSystemParameters, type)
      },
      {
        name: 'wethers5-6Yrs',
        dsePerHead: 1,
        percentageYearOn: calculateOlderHoggetPercentYearOn(parameters as WoolSystemParameters | DualPurposeSystemParameters, 5),
        count: calcWether5to6YearsCount(parameters  as WoolSystemParameters | DualPurposeSystemParameters, type)
      },
      {
        name: 'wethers6-7Yrs',
        dsePerHead: 1,
        percentageYearOn: calculateOlderHoggetPercentYearOn(parameters as WoolSystemParameters | DualPurposeSystemParameters, 6),
        count: calcWether6to7YearsCount(parameters  as WoolSystemParameters | DualPurposeSystemParameters, type)
      })
  } else if (type === 'primeLamb') {
    const plParams = parameters as PrimeLambSystemParameters

    classes.push(
      {
        name: 'elDry',
        dsePerHead: 1.3,
        percentageYearOn:  7 / 12,
        count: plParams.numberOfEweLambsJoined
      },
      {
        name: 'elLatePreg',
        dsePerHead: calcElLatePregDse(plParams),
        percentageYearOn:  2 / 12,
        count: plParams.numberOfEweLambsJoined
      },
      {
        name: 'elLactating',
        dsePerHead: calcElLactatingDse(plParams),
        percentageYearOn:  4 / 12,
        count: plParams.numberOfEweLambsJoined
      }
    )
  }

  return classes
}

interface SheepState {
  Single: number;
  Twinning: number;
}

const woolDualPurposeFactors: { Dry: SheepState, LP: SheepState, Lactating: SheepState } = {
  Dry: {
    Single: 1.1,
    Twinning: 1.1
  },
  LP: {
    Single: 1.3,
    Twinning: 1.5
  },
  Lactating: {
    Single: 3.1,
    Twinning: 3.5
  }
}

const primeLambFactors: { Dry: SheepState, LP: SheepState, Lactating: SheepState } = {
  Dry: {
    Single: 1.3,
    Twinning: 1.3
  },
  LP: {
    Single: 1.7,
    Twinning: 1.9
  },
  Lactating: {
    Single: 3.3,
    Twinning: 3.8
  }
}

export function caclulateSheepDse(parameters: AnySheepSystemParameters, type: string): number {
  const sheepClasses = createDefaultSheepClasses(parameters, type)
  const dses = sheepClasses.map(c => c.dsePerHead)
  const percYearon = sheepClasses.map(c => c.percentageYearOn)
  const counts = sheepClasses.map(c => c.count)
  return sumOfProducts([
    dses,
    percYearon,
    counts
  ])
}
