import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { ruminatiColors } from "../../utilities/colors";
import { ruminatiImages } from "../../utilities/images";
import Button from "../buttons/button";
import MainButton from "../buttons/main_button";
import Heading from "../heading";
import Icon from "../icon";
import { Column, Row } from "../styled_layout";
import { ParagraphText } from "../styled_text";

import { useAuthStore } from "../../state/auth_store";
import { SubscriptionType } from "../../models/subscription_model";
import { useDatabaseStore } from "../../state/database_store";
import { CountryCode } from "../../utilities/countries";
import { HelpDrawerAndTab, HelpDrawerContentWrapper, HelpDrawerExplanatoryContentContainer, HelpDrawerExplanatoryContentText, HelpDrawerExplanatoryContentTitle } from "../HelpDrawer";
import { Accordion, AccordionItem, AccordionItemContentText } from "../Accordion";

export enum EnterpriseType {
    Farm,
    Feedlot,
    ProcessingFacility,
    Dairy
}

export enum OptionState {
    Selected,
    Unselected,
    Upgrade,
    ComingSoon,
}

export default function GetStartedEnterprise(props: {
    onSubmit: (type: EnterpriseType) => void 
}) {
    const [type, setType] = useState<EnterpriseType | null>(null);
    const user = useAuthStore((state) => state.user);

    const isPrime = user?.subscription?.type === SubscriptionType.PRIME;
    const isNZUser = user?.country === CountryCode.NewZealand;
    const properties = useDatabaseStore().properties;

    const onSubmit = () => {
        if (type != null) {
            props.onSubmit(type);
        }
    };

    const existingProperties = properties !== undefined && properties.length > 0;

    return <Row>
        <Column>
            <Row 
            style={{
                columnGap: '10px',
                alignItems: 'stretch',
                marginBottom: '40px'
            }}>
                <EnterpriseOption
                    name="Cattle, Sheep & Crops"
                    image={ruminatiImages.enterpriseFarm}
                    description={"Track your emissions if you are a cattle, sheep or grain producer."}
                    disabledDescription="Multiple farms available with a Ruminati PRIME subscription."
                    state={
                        (existingProperties && !isPrime) ? OptionState.Upgrade :
                            (type === EnterpriseType.Farm ? OptionState.Selected : OptionState.Unselected)
                    }
                    onClick={() => setType(type === EnterpriseType.Farm ? null : EnterpriseType.Farm)} />
                <EnterpriseOption
                    name="Dairy"
                    image={ruminatiImages.enterpriseDairy}
                    description="Track your emissions if you are operating a dairy enterprise."
                    disabledDescription="Only available with a Ruminati PRIME subscription"
                    state={
                        isNZUser ? OptionState.ComingSoon :
                            !isPrime ? OptionState.Upgrade :
                                (type === EnterpriseType.Dairy ? OptionState.Selected : OptionState.Unselected)
                    }
                    onClick={() => setType(type === EnterpriseType.Dairy ? null : EnterpriseType.Dairy)} />
                <EnterpriseOption
                    name="Feedlotters"
                    image={ruminatiImages.enterpriseFeedlot}
                    description="Record your feedlot emissions based on stock numbers and rations."
                    disabledDescription="Only available with a Ruminati PRIME subscription"
                    state={
                        isNZUser ? OptionState.ComingSoon :
                            !isPrime ? OptionState.Upgrade :
                                (type === EnterpriseType.Feedlot ? OptionState.Selected : OptionState.Unselected)
                    }
                    onClick={() => setType(type === EnterpriseType.Feedlot ? null : EnterpriseType.Feedlot)} />
                <EnterpriseOption
                    name="Processors"
                    image={ruminatiImages.enterpriseProcessor}
                    description="Capture whole-of-business emissions for processors and packers."
                    disabledDescription="Only available with a Ruminati PRIME subscription"
                    state={
                        isNZUser ? OptionState.ComingSoon :
                            !isPrime ? OptionState.Upgrade :
                                (type === EnterpriseType.ProcessingFacility ? OptionState.Selected : OptionState.Unselected)
                    }
                    onClick={() => setType(type === EnterpriseType.ProcessingFacility ? null : EnterpriseType.ProcessingFacility)} />
            </Row>

            <MainButton size="large" colorScheme="green" disabled={type === null} onClick={onSubmit}>Continue</MainButton>
        </Column>
        <HelpDrawerAndTab
            renderContent={() => {
                return (
                    <HelpDrawerContentWrapper>
                        <HelpDrawerExplanatoryContentContainer>
                            <HelpDrawerExplanatoryContentTitle>Select your Enterprise Type</HelpDrawerExplanatoryContentTitle>
                            <HelpDrawerExplanatoryContentText>
                                Use this page to select your enterprise type. The Producer category covers beef, sheep (meat and wool), and grain. The Feedlot category is for beef and sheep feedlots. The Processor category includes abattoirs, primary processors, and grinders. The Dairy category is specifically for dairy producers.
                            </HelpDrawerExplanatoryContentText>
                        </HelpDrawerExplanatoryContentContainer>
                        <Accordion>
                            <AccordionItem
                                renderTitle="Which agricultural sectors does the Ruminati platform service?"
                                renderContent={() => (
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                                        <AccordionItemContentText>
                                            Ruminati services the following sectors:
                                        </AccordionItemContentText>
                                        <ul style={{ margin: 0 }}>
                                            <li>
                                                <AccordionItemContentText>
                                                    Producer: Includes beef, sheep (meat), sheep (wool) and grain
                                                </AccordionItemContentText>
                                            </li>
                                            <li>
                                                <AccordionItemContentText>
                                                    Feedlot
                                                </AccordionItemContentText>
                                            </li>
                                            <li>
                                                <AccordionItemContentText>
                                                    Processor
                                                </AccordionItemContentText>
                                            </li>
                                            <li>
                                                <AccordionItemContentText>
                                                    Dairy
                                                </AccordionItemContentText>
                                            </li>
                                        </ul>
                                        <AccordionItemContentText>
                                            We are constantly expanding our tool to service additional sectors and plan to include additional commodities in future.
                                        </AccordionItemContentText>
                                    </div>
                                )}
                            />
                        </Accordion>
                    </HelpDrawerContentWrapper>
                )
            }}
        />
    </Row>;
}

function EnterpriseOption(props: { name: string, image: string, description: string, disabledDescription: string, state: OptionState, onClick: () => void }) {
    const navigate = useNavigate();

    const color = props.state === OptionState.Selected ? ruminatiColors.orange : ruminatiColors.green_3;

    // Button colors
    const buttonBackground = props.state === OptionState.Selected ? ruminatiColors.orange : ruminatiColors.bone;
    const buttonTextColor = props.state === OptionState.Selected ? "#ffffff" : ruminatiColors.green_3;
    const buttonBorderColor = props.state === OptionState.Selected ? ruminatiColors.orange : ruminatiColors.green_3;

    const handleClick = () => {
        if (props.state === OptionState.Upgrade) {
            navigate("/account?tab=subscription");
        } else if (props.state === OptionState.Unselected || props.state === OptionState.Selected) {
            return props.onClick();
        }
    }

    let buttonText = "Select";
    let descriptionText = props.description;

    if (props.state === OptionState.Selected) {
        buttonText = "Selected";
    } else if (props.state === OptionState.Upgrade) {
        buttonText = "Upgrade";
        descriptionText = props.disabledDescription;
    } else if (props.state === OptionState.ComingSoon) {
        buttonText = "Coming Soon";
        // descriptionText = props.disabledDescription;
    }

    return <EnterpriseOptionContainer
        borderColor={color}
        onClick={handleClick}
        style={{
            cursor: props.state === OptionState.ComingSoon ? 'not-allowed' : 'pointer',
            opacity: props.state === OptionState.ComingSoon ? 0.6 : 1
        }}
    >
        <Row>
            <img src={props.image} width={'100%'} />
        </Row>

        <EnterpriseOptionContent>
            <Row style={{ 
                width: "100%", 
                paddingBottom: "8px", 
                borderBottom: `1px solid ${props.state === OptionState.Selected ? ruminatiColors.orange_40 : ruminatiColors.green_3_30}` 
            }}>
                <Heading level={5} color={color}>{props.name}</Heading>
            </Row>

            <Row style={{ padding: "8px 0 16px 0", flexGrow: 1 }}>
                <ParagraphText style={{ color: color, fontSize: "14px", lineHeight: "24px", whiteSpace: "pre-wrap" }}>
                    {descriptionText}
                </ParagraphText>
            </Row>

            <Button
                height="40px"
                padding="10px 16px"
                borderRadius="48px"
                textColor={buttonTextColor}
                backgroundColor={buttonBackground}
                border={`1px solid ${buttonBorderColor}`}
                disabled={props.state === OptionState.ComingSoon}
            >
                {props.state === OptionState.Selected ? 
                    <div style={{ paddingRight: "10px" }}>
                        <Icon icon="tick" />
                    </div> : undefined
                }
                {buttonText}
            </Button>
        </EnterpriseOptionContent>

    </EnterpriseOptionContainer>;
}

const EnterpriseOptionContainer = styled.div<{ borderColor: string }>`
    padding: 10px 0px 20px 0px;
    border: 1px solid ${props => props.borderColor};
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    user-select: none;
`;

const EnterpriseOptionContent = styled.div`
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
`
