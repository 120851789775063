import calcBbox from '@turf/bbox'
import { BBox, FeatureCollection, MultiPolygon, Polygon, Feature } from 'geojson'
import pointInPolygon from '@turf/boolean-point-in-polygon'
import combine from "@turf/combine"
import calculateDistance from "@turf/distance"
import { feature, point, AllGeoJSON, convertArea, AreaUnits } from '@turf/helpers'
import { coordEach } from '@turf/meta'
import centroid from '@turf/centroid'
import calculateArea from '@turf/area'
import { LngLat } from 'maplibre-gl'

export function calculateBoundingBoxFromGeojson(geojson: AllGeoJSON) {
  return calcBbox(geojson)
}

export function calculateAreaOfGeojson (geojson: AllGeoJSON, units?: AreaUnits) {
  const areaSqm = calculateArea(geojson)
  return convertArea(areaSqm, 'meters', units ?? 'hectares' )
}

export function createEmptyFeatureCollection(): FeatureCollection<Polygon|MultiPolygon> {
  return {
    type: 'FeatureCollection',
    features: []
  }
}

export function createFeatureCollectionFromMultiPolygon(multipolygon: MultiPolygon): FeatureCollection<Polygon> {
  return {
    type: 'FeatureCollection',
    features: multipolygon.coordinates.map((polygon, index) => ({
      type: 'Feature',
      id: `${index}`,
      properties: { id: `${index}` },
      geometry: {
        type: 'Polygon',
        coordinates: polygon
      }
    }))
  }
}

export function createFeatureCollectionFromMultiPolygons(multipolygons: MultiPolygon[]): FeatureCollection {
  return {
    type: "FeatureCollection",
    features: multipolygons.map(mp => {
        return {
          type: "Feature",
          properties: {},
          geometry: mp
        } as Feature
      })
    }
}

export function createEmptyBounds(): BBox {
  return [Infinity, Infinity, -Infinity, -Infinity]
}

export function isPointInFeatureCollection(coords: [number, number], fc: FeatureCollection<Polygon|MultiPolygon>): boolean {
  let foundMatch = false
  fc.features.forEach(f => {
    if (pointInPolygon(coords, f)) foundMatch = true
  })
  return foundMatch
}

export function removeFeatureFromFeatureCollectionAtCoords(coords: [number, number], fc: FeatureCollection<Polygon|MultiPolygon>) {
  for (let index = 0; index < fc.features.length; index++) {
    const f = fc.features[index];
    if (pointInPolygon(coords, f)) {
      fc.features.splice(index, 1)
      index--
    }    
  }
}

export function convertFeatureCollectionToMultiPolygon(fc: FeatureCollection<Polygon|MultiPolygon>): MultiPolygon | null {
  const combined = combine(fc);
  if (combined.features.length > 0 && combined.features[0] !== undefined && combined.features[0].geometry.type === 'MultiPolygon') {
    return combined.features[0].geometry
  }
  return null
}

export function convertPolygonsToMultiPolygon(polygons: Polygon[]): MultiPolygon | null {
  const fc: FeatureCollection<Polygon> = {
    type: "FeatureCollection",
    features: polygons.map(p => feature(p))
  }
  return convertFeatureCollectionToMultiPolygon(fc)
}

export function coordsAreInValidRange (geojson: AllGeoJSON): boolean {
  let isValid = true
  coordEach(geojson, (currentCoord) => {
    if (
      (currentCoord[0] === undefined || currentCoord[0] < -180 || currentCoord[0] > 180) || 
      (currentCoord[1] === undefined || currentCoord[1] < -90 || currentCoord[1] > 90)
    ) {
      isValid = false
      return isValid
    }
  })
  return isValid
}

export function sortFeaturesByDistanceToLngLat (features: Feature<Polygon|MultiPolygon>[], lngLat: LngLat): Feature<Polygon|MultiPolygon>[] {
  return features.sort((a, b) => {
    const centroidA = centroid(a)
    const centroidB = centroid(b)
    const distanceToA = calculateDistance(centroidA, point([lngLat.lng, lngLat.lat]))
    const distanceToB = calculateDistance(centroidB, point([lngLat.lng, lngLat.lat]))
    if (distanceToA < distanceToB) return -1
    return 1
  })
}