import styled from "styled-components";
import { Pie } from "react-chartjs-2";

import {
    bluePieTheme,
    generatePieChartData,
    orangePieTheme,
} from "../utilities/graph";
import Heading, { HeadingWrapper } from "./heading";
import { ruminatiColors } from "../utilities/colors";
import { formatReportPercent } from "../utilities/functions";

export type PieValue = {
    name: string;
    value: number;
};

type PieChartDisplayProps = {
    title: string;
    data: PieValue[];
    colorScheme: "orange" | "blue";
    chartWidth?: number;
    shouldAnimate?: boolean;
};

/**
 * Display a pie chart with labels for each data point
 * @param title the title that appears above the pie chart
 * @param data a list of labels and percentages to display in the chart
 * @param colorScheme an id for the color theme of the pie chart. Currently
 * only have blue and orange as color themes.
 * @param chartWidth chart width in pixels
 * @param shouldAnimate
 */
export default function PieChartDisplay(props: PieChartDisplayProps) {
    const colors =
        props.colorScheme === "orange" ? orangePieTheme : bluePieTheme;

    const chartData = generatePieChartData({
        labels: props.data.map((v) => v.name),
        data: props.data.map((v) => v.value),
        theme: colors,
    });

    if (import.meta.env.NODE_ENV === 'test') return

    const chartWidth = props.chartWidth ? props.chartWidth : 240
    return (
        <ChartWrapper>
            {/**
             * Pie chart title
             */}
            <HeadingWrapper>
                <Heading level={5}>{props.title}</Heading>
            </HeadingWrapper>

            {/**
             * Pie chart
             */}
            <div style={{ width: `${chartWidth}px`, padding: "16px 0 0 0" }}>
                <Pie
                    data={chartData}
                    options={{
                        animation: props.shouldAnimate === false? props.shouldAnimate : undefined,
                        events: [],
                        plugins: {
                            legend: {
                                display: false
                            }
                        }
                    }}
                />
            </div>

            {/**
             * Pie chart labels
             */}
            <div
                style={{
                    padding: "12px 0 16px 0",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                {props.data.map((v, index) => {
                    return (
                        <div
                            key={v.name}
                            style={{ display: "flex", padding: "4px 0 0 0" }}
                        >
                            <ValueTag
                                className={'chartLabel'}
                                style={{
                                    backgroundColor: colors[index],
                                    margin: "0 4px 0 0",
                                }}
                            >
                                <TagText>{v.name}</TagText>
                            </ValueTag>
                            <ValueTag
                                className={'chartLabel'}
                                style={{ backgroundColor: colors[index] }}
                            >
                                <TagText>{formatReportPercent(v.value)}</TagText>
                            </ValueTag>
                        </div>
                    );
                })}
            </div>
        </ChartWrapper>
    );
}

const ChartWrapper = styled.div`
    padding: 16px 0 0 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 314px;
    width: 100%;
`;

const ValueTag = styled.div`
    padding: 5px 8px 3px;
    border-radius: 8px;
    font-family: "TTInterfaces";
    font-weight: 500;
    font-size: 16px;
    color: ${ruminatiColors.bone};
    line-height: 24px;
    text-align: center;
`;

const TagText = styled.div`
    position: relative;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`
