import { MouseEvent, useState } from "react";
import { ConfigProvider, Tooltip } from "antd"

import questionImage from "/icons/question-14.svg";
import { ruminatiColors } from "@/utilities/colors";


export interface TipProps {
  tip: string;
}

export default function Tip(props: TipProps) {
  const [isHover, setIsHover] = useState(false);

  return (
    <div style={{ display:'flex', padding: "0 4px 0 12px" }}>
      <ConfigProvider
        theme={{
          components: {
            Tooltip: {
              fontSize: 15,
              fontFamily: "TTInterfaces",
              lineHeight: 1.7,
              boxShadowSecondary: "transparent"
            }
          }
        }}>
        <Tooltip
          title={props.tip}
          color={ruminatiColors.orange}
          placement="top"
          trigger="click"
          overlayStyle={{
            fontWeight: 500
          }}
        >
          {/* Use a custom button rather than SubButton
          because otherwise there were errors about findDomNode */}
          <button
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onClick={(e: MouseEvent) => {
              // prevents the tooltip from opening a select box
              e.stopPropagation()
            }}
            style={{
              background: isHover ? ruminatiColors.orange : ruminatiColors.green_3,
              borderRadius: '4px',
              height: "24px",
              padding: "0 5px",
              lineHeight: "1em",
              cursor: "pointer",
              transition: '0.5s',
              border: "none"
            }}>
              <img src={questionImage} alt="Question Mark" />
          </button>
        </Tooltip>
      </ConfigProvider>
    </div>
  )
}
