// query client for react-query
// https://tanstack.com/query/latest/docs/reference/QueryClient

import { QueryClient } from "@tanstack/react-query"

// use with useQueryClient hook inside components to access query client to interact with the cache
const queryClient = new QueryClient({
  defaultOptions: {
      queries: {
          staleTime: Infinity,
      }
  }
})
// set defaults for queries with queryClient, so defaults can be configured differently in tests
queryClient.setQueryDefaults(['properties'], {
  retry: (failureCount, err) => {
      // TODO: should be err.name once throwing is fixed in requestWithRetries in api_service.ts
      if (err.message === 'TimeoutError') {
          // don't retry if the query timed out
          return false
      } else if (failureCount > 3) {
          return false
      }
      return true
  }
})

export default queryClient