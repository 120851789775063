import Screen from "./screen";
import styled from "styled-components";
import Heading from "../components/heading";
import MainButton from "../components/buttons/main_button";
import { BaseText, TextLink } from "../components/styled_text";
import HorizontalDivider from "../components/horizontal_divider";
import { ruminatiColors } from "../utilities/colors";
import { Link } from "react-router-dom";
import { Illustration, Wrapper } from "../components/styled_layout";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { sendForgotPassword } from "../services/auth_service";
import { Form, FormData } from "../models/form/form";
import { EmailFormField } from "../models/form/form_fields/basic_form_fields";

const passwordResetData: FormData<{ email: string }> = {
    fields: {
        email: new EmailFormField({
            required: true,
            placeholder: "Email address",
        })
    }
}

/**
 * A screen allowing the user to send a password reset email.
 * @returns the Forgot Password Screen component
 */
export default function ForgotPasswordScreen() {
    const [sentEmail, setSentEmail] = useState<boolean>(false);
    const navigate = useNavigate();

    const sendReset = async (value: { email: string }) => {
        try {
            await sendForgotPassword(value.email);
            setSentEmail(true);
        } catch (err) {
            setSentEmail(false);
        }
    };

    const goToLogin = () => {
        navigate("/login");
    };

    return (
        <Screen pageTitle="Forgot Password">
            <ForgotPasswordWrapper>
                <Wrapper>
                    <Heading level={2}>
                        {!sentEmail
                            ? "Forgot your password?"
                            : "Quick, check your email"}
                    </Heading>
                </Wrapper>

                <Wrapper style={{ padding: "0 0 24px 0" }}>
                    {!sentEmail ? (
                        <BaseText
                            style={{
                                textAlign: "center",
                                color: ruminatiColors.green_3,
                            }}
                        >
                            If you've forgotten your password, enter your email
                            address below and follow the reset instructions
                        </BaseText>
                    ) : (
                        <BaseText
                            style={{
                                textAlign: "center",
                                color: ruminatiColors.green_3,
                            }}
                        >
                            Open the email we just sent you to reset your password.
                            <br />
                            Didn't see the email? Check your spam folder.
                        </BaseText>
                    )}
                </Wrapper>
                {!sentEmail ? (
                    <ForgotPasswordContainer>
                        <Form
                            data={passwordResetData}
                            initialValue={{}}
                            onSubmit={sendReset}
                            buttonWidth="100%"
                            submitText="Send"
                        />
                        <Wrapper
                            style={{
                                padding: "16px 0 0 0",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Link to="/login">
                                <BaseText style={{ padding: "2px 0 0 0" }}>
                                    <TextLink>Return to log in page</TextLink>
                                </BaseText>
                            </Link>
                        </Wrapper>

                        <Wrapper style={{ padding: "4.5px 0" }}>
                            <HorizontalDivider />
                        </Wrapper>

                        <Wrapper
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <BaseText
                                style={{
                                    padding: "2px 8px 0 0",
                                    fontSize: "18px",
                                    lineHeight: "24px",
                                    color: ruminatiColors.green_3,
                                }}
                            >
                                Don't have an account?
                            </BaseText>
                            <Link to="/register">
                                <MainButton size="small" colorScheme="green">
                                    Sign up
                                </MainButton>
                            </Link>
                        </Wrapper>
                    </ForgotPasswordContainer>
                ) : (
                    <ForgotPasswordContainer>
                        <Wrapper style={{ padding: "16px 0" }}>
                            <MainButton
                                colorScheme="green"
                                onClick={goToLogin}
                                size="large"
                                width="100%"
                            >
                                Return to login
                            </MainButton>
                        </Wrapper>
                    </ForgotPasswordContainer>
                )}
            </ForgotPasswordWrapper>
            <Illustration
                src="/images/feeding.png"
                alt="Farmer feeding two brown cows"
            />
        </Screen >
    );
}

const ForgotPasswordWrapper = styled.div`
    flex-grow: 1;

    width: 100%;
    min-height: 507px;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    padding: 112px 0 0 0;
`;

const ForgotPasswordContainer = styled.div`
    width: 464px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
