import { MultiPolygon } from 'geojson'
import { ReactElement } from 'react';
import { ReportTrees } from "../../../models/report"
import createGeojsonLayer from '../layer_types/geojson_lyr'
import { createLegendItem } from '../legend'
import { combineHexColorAndOpacityToHexWithOpacity } from '../../../utilities/colors';

export function createExistingTreePlantingLayerAndLegendFromReportInfo(treeReportInfo: ReportTrees[] | undefined) {
  const plantings = treeReportInfo ? treeReportInfo.filter(t=> 'geom' in t).map(t => t.geom) as MultiPolygon[] : undefined
  return createExistingTreePlantingLayerAndLegend(plantings, 'Existing Tree Plantings')
}

export function createExistingTreePlantingLayerAndLegend (treePlantingAreas: MultiPolygon[] | undefined, legendLabel = 'Existing Trees'): {
  layer: ReactElement,
  legend: JSX.Element
} {
  const baseId = 'existing-tree-planting'
  const fillColor = '#728975'
  const fillOpacity = 0.7
  const lineColor = '#456449'

  return {
    layer: createGeojsonLayer(treePlantingAreas, {
      dataSourceId: `${baseId}-src`,
      fillLayerId: `${baseId}-fill-lyr`,
      lineLayerId: `${baseId}-line-lyr`,
      fillColor,
      fillOpacity,
      lineColor,
    }),
    legend: createLegendItem(
      `${baseId}-legend-item`,
      legendLabel,
      combineHexColorAndOpacityToHexWithOpacity(fillColor, fillOpacity),
      lineColor
    )
  }
}

export function createProposedTreePlantingLayerAndLegend (treePlantingAreas: MultiPolygon[] | undefined, legendLabel = 'Plant Trees'): {
  layer: ReactElement,
  legend: JSX.Element
} {
  const baseId = 'proposed-tree-planting'
  const fillColor = '#82C870'
  const fillOpacity = 0.4
  const lineColor = '#59AE43'

  return {
    layer: createGeojsonLayer(treePlantingAreas, {
      dataSourceId: `${baseId}-src`,
      fillLayerId: `${baseId}-fill-lyr`,
      lineLayerId: `${baseId}-line-lyr`,
      fillColor,
      fillOpacity,
      lineColor,
    }),
    legend: createLegendItem(
      `${baseId}-legend-item`,
      legendLabel,
      combineHexColorAndOpacityToHexWithOpacity(fillColor, fillOpacity),
      lineColor
    )
  }
}

export function createProposedAllowRevegLayerAndLegend (allowRevegAreas: MultiPolygon[] | undefined): {
  layer: ReactElement,
  legend: JSX.Element
} {
  const baseId = 'proposed-allow-reveg'
  const fillColor = '#466684'
  const fillOpacity = 0.4
  const lineColor = '#24424D'

  return {
    layer: createGeojsonLayer(allowRevegAreas, {
      dataSourceId: `${baseId}-src`,
      fillLayerId: `${baseId}-fill-lyr`,
      lineLayerId: `${baseId}-line-lyr`,
      fillColor,
      fillOpacity,
      lineColor,
    }),
    legend: createLegendItem(
      `${baseId}-legend-item`,
      'Allow Reveg',
      combineHexColorAndOpacityToHexWithOpacity(fillColor, fillOpacity),
      lineColor
    )
  }
}