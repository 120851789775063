import { FieldSize, FormField, State } from "./form_field";

export default class HiddenFormField implements FormField<string> {

    size = FieldSize.Full;
    displayValue = () => undefined;
    required = (_: any) => true;

    validate(): boolean {
        return true;
    }

    render(
        _id: string,
        _onChange: (value?: string | undefined) => void,
        _state: State<any>,
        _value?: string,
        _error?: boolean
    ): JSX.Element {
        return <></>;
    }
}