import styled from "styled-components";
import RuminatiLogo from "/icons/ruminati-logo.svg";

export type PrintPageLayoutProps = {
  propertyName?: string;
  reportDate?: string;
  content?: JSX.Element[];
};

/**
* A little wrapper for print reports
* @param content the content shown on the report
*/
export default function PrintPageLayout({
  content
}: PrintPageLayoutProps) {
  return <PrintContainer>
    <img src={RuminatiLogo} style={{height: '40px', marginBottom: '20px'}} />
    {content}
  </PrintContainer>
}

const PrintContainer = styled.div`
    width: 790px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;

    .reportHeader {
      margin-top: 5px!important;
      margin-bottom: 25px!important;

      > p:first-child {
        font-size: 20px!important;
        line-height: 22px!important;
      }
    }
    
    .report-inner-section {
      padding: 8px 8px;
      margin: 8px 0;
    }
`;

