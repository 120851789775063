import { initializeApp } from "firebase/app";
import { getAuth, Auth } from "firebase/auth";

export function useFirebaseIdentityPlatform (identityPlatformApiKey: string | null): null | Auth {
  if (identityPlatformApiKey === null) return null
  const config = {
    apiKey: identityPlatformApiKey
  }
  const app = initializeApp(config)
  const auth = getAuth(app)
  return auth
}