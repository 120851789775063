import { PropsWithChildren, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";

import LoadingPlaceholder from '../components/loading_placeholder';
import Screen from '../screens/screen'
import { getVisionOrg } from "../services/vision_service";
import { usePartnerStore } from '@/state/partners';

export default function VisionOrgGuard({
    children,
}: PropsWithChildren<unknown>) {
    const partnerStore = usePartnerStore();
    const [searchParams, _setSearchParams] = useSearchParams()
    const orgIdToFind = searchParams.get('visionOrgId')
    if (!orgIdToFind) return children

    const partnerRecord = partnerStore.miscOrgs[orgIdToFind]
    useEffect(() => {
        if (partnerRecord === undefined && orgIdToFind) {
          partnerStore.addMiscOrg(orgIdToFind, 'loading')
          getVisionOrg(orgIdToFind)
          .then((org) => {
              if (org) partnerStore.addMiscOrg(orgIdToFind, org)
          })
          .catch((err) => console.log(err));
        }
    }, [partnerRecord, partnerStore]);

    if (!partnerRecord || partnerRecord === 'loading') {
        return <Screen>
            <LoadingPlaceholder />
        </Screen>
    } else {
        return children
    }
}
