import { MultiPolygon } from 'geojson'
import { ReductionPlan, ReductionPlanInputDTO, InitiativeType} from '../../../models/reduction_plans_models';
import { State } from "../../../models/form/form_fields/form_field";
import { createSoilCarbonActivityAndLegend } from "./soil_carbon_activity_layer";
import { 
    createExistingTreePlantingLayerAndLegend,
    createProposedTreePlantingLayerAndLegend,
    createProposedAllowRevegLayerAndLegend
 } from "./tree_layers";
import { ReportTrees } from '../../../models/report';

export function setupReductionPlannerLayers (initiativeType: InitiativeType, state: State<any>, otherReductionPlans: ReductionPlan[] | undefined, existingTrees?: ReportTrees[] | undefined) {
  const otherDrawnGeoms: MultiPolygon[] | undefined = state.getAll?.filter((_f, i) => i !== state.index).map(f => f.geom)

  const proposedScGeoms: MultiPolygon[] = []
  const proposedTreePlantingGeoms: MultiPolygon[] = []
  const proposedRevegGeoms: MultiPolygon[] = []

  otherReductionPlans?.forEach((reductionPlan) => {
    // @ts-ignore
      const geoms = (reductionPlan.inputs as ReductionPlanInputDTO[]).filter(p2 => 'geom' in p2).map(p2 => p2.geom) as MultiPolygon[]
      if (initiativeType !== reductionPlan.initiativeType) {

        if (reductionPlan.initiativeType === InitiativeType.PlantTrees) {
            proposedTreePlantingGeoms.push(...geoms)
        } else if (reductionPlan.initiativeType === InitiativeType.ImproveSoilCarbon) {
            proposedScGeoms.push(...geoms)
        } else if (reductionPlan.initiativeType === InitiativeType.AllowRevegetation) {
            proposedRevegGeoms.push(...geoms)
        }
      }
  })

  const proposedSoilCarbonLayerAndLegend = createSoilCarbonActivityAndLegend(initiativeType === InitiativeType.ImproveSoilCarbon ? otherDrawnGeoms : proposedScGeoms)
  const proposedTreePlantingsLayerAndLegend = createProposedTreePlantingLayerAndLegend(initiativeType === InitiativeType.PlantTrees ? otherDrawnGeoms : proposedTreePlantingGeoms)
  const proposedAllowRevegLayerAndLegend = createProposedAllowRevegLayerAndLegend(initiativeType === InitiativeType.AllowRevegetation ? otherDrawnGeoms : proposedRevegGeoms)
  
  const existingTreeLayerAndLegend = setupExistingTreesLayers(existingTrees)

  return {
    proposedSoilCarbonLayerAndLegend,
    proposedTreePlantingsLayerAndLegend,
    proposedAllowRevegLayerAndLegend,
    existingTreeLayerAndLegend
  }
}

export function setupExistingTreesLayers (existingReportTrees: ReportTrees[] | undefined) {
  const existingTreeGeoms = existingReportTrees ? existingReportTrees.filter(t => t.geom !== undefined).map(f => f.geom) as MultiPolygon[] : []
  const existingTreeLayerAndLegend = createExistingTreePlantingLayerAndLegend(existingTreeGeoms)
  return existingTreeLayerAndLegend
}