import styled from "styled-components";
import { ruminatiColors } from "../utilities/colors";

// This file contains shared text-based styled elements

export const BaseText = styled.p`
    font-family: "TTInterfaces", sans-serif;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.02em;
`;

export const BaseSpan = styled.span`
    font-family: "TTInterfaces", sans-serif;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.02em;
`;

export const ButtonText = styled.p`
    font-family: "TTInterfaces", sans-serif;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.02em;
    cursor: pointer;
`;

export const ParagraphText = styled(BaseText)`
    font-family: "TTInterfaces", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${ruminatiColors.green_3};
`;

export const HeadlineFigureText = styled(ParagraphText)`
    font-family: "Approach", sans-serif;
    font-weight: 600;
    font-size: 48px;
    letter-spacing: -0.01em;
    line-height: 48px;
`;

export const ErrorText = styled(BaseText)`
    font-family: "Approach", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: ${ruminatiColors.red};
`;

export const TextLink = styled.span`
    color: ${ruminatiColors.orange};
    text-decoration: underline;
    cursor: pointer;
`;

export const BodyText = styled(BaseText)`
    color: ${ruminatiColors.green_3};
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
`;

export const BodyTextIntegration = styled(BaseText)`
    color: ${ruminatiColors.green_3};
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
`;

export const InlineBodyText = styled(BodyText)`
    display: inline-block;
`;

export const PaddedInlineBodyText = styled(InlineBodyText)`
    margin-left: 20px;
    font-weight: 500;
`;
export const TableText = styled(BaseText)`
    color: ${ruminatiColors.green_3};
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
`;
