import Screen from "../screen";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getUserProfile } from "../../services/auth_service";
import LoadingPlaceholder from "../../components/loading_placeholder";
import { useAuthStore } from "../../state/auth_store";

/**
 * A screen to refresh the user's token after updating subscription.
 * Redirects user to AccountDetailsScreen afterwards.
 * @returns the Account Refresh Screen component
 */
export default function AccountRefreshScreen() {
    const navigate = useNavigate();

    const authStore = useAuthStore();
    useEffect(() => {
        refreshAndRedirect();
    });

    const refreshAndRedirect = async () => {
        try {
            const user = await getUserProfile();
            if (user) authStore.signIn(user);
        } catch (err) {
            console.error(err);
        }
        navigate("/account/");
    };

    return (
        <Screen>
            <LoadingPlaceholder />
        </Screen>
    );
}
