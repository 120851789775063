import Heading from "@/components/heading";
import Screen from "./screen";
import { Column, Illustration } from "@/components/styled_layout";
import { BodyText } from "@/components/styled_text";

/**
 * The error screen when the API appears to be down
 * @returns the API Error Screen component
 */
export default function ApiErrorScreen() {
  return (
    <Screen pageTitle="Error">
      <Column style={{marginTop: '120px', rowGap: '10px'}}>
        <Heading level={3}>We're currently experiencing some issues</Heading>
        <BodyText style={{fontSize: '16px'}}>Please check back again later</BodyText>
        <Illustration style={{ margin: 0 }} src="/images/pasture.png" />
      </Column>
    </Screen>
  )
}