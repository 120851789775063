import {
  DropdownFormField,
  NumberFormField,
  TextFormField,
} from "../../../models/form/form_fields/basic_form_fields";
import {
  ConcatMethod,
  FieldSize,
  State,
} from "../../../models/form/form_fields/form_field";
import { ReportFormPage } from "../../../models/form/form_page";
import { cropTypes, cropProductionSystems } from "../../../models/form_options";
import {
  ReportGrains,
  ReportProduction,
  ReportProductionForm,
  ReportProductionLabel,
  ConsumableAllocation,
  ReportGrains_Post3_3,
  ReportGrains_Post3_3Form,
} from "../../../models/report";
import {
  ReportFormPageId,
  inputPercent,
  outputPercent,
} from "../../../utilities/forms";
import {
  CustomFieldWithState,
  LabelField,
  SeparatorField,
} from "../../../models/form/form_fields/decoration_form_fields";

import { PercentageSplitFormField } from "../../../models/form/form_fields/percentage_split_form_field";
import { Report } from "../../../models/property_models";
import HiddenFormField from "../../../models/form/form_fields/hidden_form_field";
import { MultiInputFormField } from "@/models/form/form_fields/multi_input_form_field";
import FertiliserCalculator from "@/components/buttons/calculate-fertiliser";
import { Column, Row } from "@/components/styled_layout";
import { BodyText } from "@/components/styled_text";
import { InfoCard } from "@/components/card";
import SmallButton from "@/components/buttons/small_button";
import { fertiliserOptions } from "@/models/form_chemical_lookup";
import Icon from "@/components/icon";
import { calculateActiveIngredients } from "@/utilities/chemicals";
import { HelpDrawerExplanatoryContentText } from "@/components/HelpDrawer";
import { createHerbicidePesticideCalculator } from "./shared";

export const grains: ReportFormPage<ReportGrains> = {
  id: ReportFormPageId.GrainInfo,
  title: "Crops",
  description:
    "For farms with livestock enterprises, this section is for crops only.\nYou will have been asked to enter the details for your pastures in another section",
  repeatableConfig: {
    repeatableSectionName: "Crop Type",
    repeatableSectionCallback: (crop: ReportGrains) => {
      if (crop.cropType === undefined) return "Crop";
      const type = cropTypes.find((ct) => ct.value === crop.cropType);
      if ((!crop.name || crop.name === null) && type) return type.name;
      return `${type?.name} - ${crop.name}`;
    },
  },
  image: "grains.png",
  formData: {
    fields: {
      stableId: new HiddenFormField(),
      cropType: new DropdownFormField({
        required: true,
        concatMethod: ConcatMethod.StringList,
        label: "Crop Type",
        options: cropTypes,
        size: FieldSize.Half,
        tip: "The type of crop grown on your farm. If you have more than one crop type, please select 'add another crop' below.",
      }),
      name: new TextFormField({
        required: false,
        label: "Identifying/Paddock Name",
        size: FieldSize.Half,
        tip: "Use this to allocate a descriptive name that you'll see elsewhere in reports (eg you might use a buyer name)",
      }),
      productionSystem: new DropdownFormField({
        required: true,
        concatMethod: ConcatMethod.Hide,
        label: "Production System",
        options: cropProductionSystems,
        size: FieldSize.Half,
        tip: "The production system used to produce this crop.",
      }),
      areaSown: new NumberFormField({
        required: true,
        concatMethod: ConcatMethod.Total,
        label: "Area sown",
        unit: "ha",
        size: FieldSize.Half,
        tip: "The total area of this crop type sown in the baseline year.",
      }),
      averageYield: new NumberFormField({
        required: true,
        concatMethod: ConcatMethod.Average,
        label: "Average yield",
        unit: "tonnes/ha",
        size: FieldSize.Half,
        tip: "The average yield per hectare of this crop type on your property for the baseline year.",
      }),
      mapApplied: new NumberFormField({
        required: true,
        concatMethod: ConcatMethod.Total,
        label: "MAP applied",
        unit: "kg/ha",
        size: FieldSize.Half,
        tip: "The amount of MAP fertiliser applied per hectare to this crop type on your property for the baseline year.",
      }),
      dapApplied: new NumberFormField({
        required: true,
        concatMethod: ConcatMethod.Total,
        label: "DAP applied",
        unit: "kg/ha",
        size: FieldSize.Half,
        tip: "The amount of DAP fertiliser applied per hectare to this crop type on your property for the baseline year.",
      }),
      soaApplied: new NumberFormField({
        required: true,
        concatMethod: ConcatMethod.Total,
        label: "SOA applied",
        unit: "kg/ha",
        size: FieldSize.Half,
        tip: "The amount of Sulphate of Ammonia (SOA) fertiliser applied per hectare to this crop type on your property for the baseline year.",
      }),
      ureaApplied: new NumberFormField({
        required: true,
        concatMethod: ConcatMethod.Total,
        label: "Urea applied",
        unit: "kg/ha",
        size: FieldSize.Half,
        tip: "The amount of urea fertiliser applied per hectare to this crop type on your property for the baseline year.",
      }),
      singleSuperphosphateApplied: new NumberFormField({
        required: true,
        concatMethod: ConcatMethod.Total,
        label: "Single Superphosphate applied",
        unit: "kg/ha",
        size: FieldSize.Half,
        tip: "The amount of Single Superphosphate applied per hectare to this crop type on your property for the baseline year.",
      }),
      herbicidePesticideApplied: new NumberFormField({
        required: true,
        concatMethod: ConcatMethod.Total,
        label: "Herbicide/Pesticide applied",
        unit: "total litres",
        size: FieldSize.Half,
        tip: "The amount of pesticide and herbicide (excluding glyphosate) purchased for use on this crop type in the baseline year. This includes any crop herbicides (excluding glyphosate), pesticides, fungicides and insecticides that are applied to this crop type.",
      }),
      glyphosateApplied: new NumberFormField({
        required: true,
        concatMethod: ConcatMethod.Total,
        label: "Glyphosate applied",
        unit: "total litres",
        size: FieldSize.Half,
        tip: "The amount of glyphosate purchased for use on this crop type in the baseline year. Please enter this as litres of product.",
      }),
      limeApplied: new NumberFormField({
        required: true,
        concatMethod: ConcatMethod.Total,
        label: "Lime applied",
        unit: "total tonnes",
        size: FieldSize.Half,
        tip: "The amount of lime applied on this crop type in the baseline year. Please enter this as tonnes of product.",
      }),
      fractionAnnualBurntCrop: new NumberFormField({
        required: true,
        concatMethod: ConcatMethod.Average,
        label: "Percentage of crop burnt",
        unit: "%",
        size: FieldSize.Half,
        validator: (value) => value !== undefined && value <= 100,
        tip: "Enter a number between 0 and 100% that reflects the percentage of this crop type that is burnt.",
      }),
    },
    transformer: {
      in: (value) => ({
        ...value,
        fractionAnnualBurntCrop: inputPercent(value.fractionAnnualBurntCrop),
      }),
      out: (value) => ({
        ...value,
        fractionAnnualBurntCrop: outputPercent(value.fractionAnnualBurntCrop),
      }),
    },
  },
};

export const grainsPost3_3 = (): ReportFormPage<
  ReportGrains_Post3_3Form,
  ReportGrains_Post3_3
> => {
  return {
    id: ReportFormPageId.GrainInfoPost3_3,
    title: "Crops",
    description:
      "For farms with livestock enterprises, this section is for crops only.\nYou will have been asked to enter the details for your pastures in another section",
    repeatableConfig: {
      repeatableSectionName: "Crop Type",
      repeatableSectionCallback: (crop: ReportGrains_Post3_3) => {
        if (crop.cropType === undefined) return "Crop";
        const type = cropTypes.find((ct) => ct.value === crop.cropType);
        if ((!crop.name || crop.name === null) && type) return type.name;
        return `${type?.name} - ${crop.name}`;
      },
    },
    image: "grains.png",
    formData: {
      fields: {
        stableId: new HiddenFormField(),
        cropType: new DropdownFormField({
          required: true,
          concatMethod: ConcatMethod.StringList,
          label: "Crop Type",
          options: cropTypes,
          size: FieldSize.Half,
          tip: "The type of crop grown on your farm. If you have more than one crop type, please select 'add another crop' below.",
        }),
        name: new TextFormField({
          required: false,
          label: "Identifying/Paddock Name",
          size: FieldSize.Half,
          tip: "Use this to allocate a descriptive name that you'll see elsewhere in reports (eg you might use a buyer name)",
        }),
        productionSystem: new DropdownFormField({
          required: true,
          concatMethod: ConcatMethod.Hide,
          label: "Production System",
          options: cropProductionSystems,
          size: FieldSize.Half,
          tip: "The production system used to produce this crop.",
        }),
        areaSown: new NumberFormField({
          required: true,
          concatMethod: ConcatMethod.Total,
          label: "Area sown",
          unit: "ha",
          size: FieldSize.Half,
          placeholder: "eg 1000",
          tip: "The total area of this crop type sown in the baseline year.",
        }),
        averageYield: new NumberFormField({
          required: true,
          concatMethod: ConcatMethod.Average,
          label: "Average yield",
          unit: "tonnes/ha",
          size: FieldSize.Half,
          placeholder: "eg 2.5",
          tip: "The average yield per hectare of this crop type on your property for the baseline year.",
        }),
        urea: new NumberFormField({
          required: true,
          concatMethod: ConcatMethod.Total,
          label: "Urea applied",
          unit: "kg urea/ha",
          size: FieldSize.Half,
        }),
        uan: new NumberFormField({
          required: true,
          concatMethod: ConcatMethod.Total,
          label: "Urea-Ammonium Nitrate (UAN) applied",
          unit: "kg product/ha",
          size: FieldSize.Half,
        }),
        fractionAnnualBurntCrop: new NumberFormField({
          required: true,
          concatMethod: ConcatMethod.Average,
          label: "Percentage of crop burnt",
          unit: "%",
          size: FieldSize.Half,
          validator: (value) => value !== undefined && value <= 100,
          tip: "Enter a number between 0 and 100% that reflects the percentage of this crop type that is burnt.",
        }),
        lime: new NumberFormField({
          required: true,
          concatMethod: ConcatMethod.Total,
          label: "Lime applied",
          unit: "total tonnes",
          size: FieldSize.Half,
          tip: "The amount of lime applied on this crop type in the baseline year. Please enter this as tonnes of product.",
        }),
        limeFraction: new NumberFormField({
          required: true,
          concatMethod: ConcatMethod.Total,
          label: "Percentage of Lime as limestone (vs dolomite)",
          unit: "%",
          size: FieldSize.Half,
        }),
        seperator: new SeparatorField(),
        herbicidesPesticidesUsed: createHerbicidePesticideCalculator(),
        herbicide: new NumberFormField({
          required: true,
          concatMethod: ConcatMethod.Total,
          label: "Herbicide applied (eg Paraquat, Glyphosate)",
          unit: "kg active ingredient",
          size: FieldSize.Half,
          tip: "The amount of herbicide used on this crop type in the baseline year. Please enter this as kg of active ingredient in the product.",
        }),
        herbicidePesticideApplied: new NumberFormField({
          required: true,
          concatMethod: ConcatMethod.Total,
          label: "Herbicide/Pesticide applied",
          unit: "kg active ingredient",
          size: FieldSize.Half,
          tip: "The amount of pesticide and herbicide (excluding glyphosate) purchased for use on this crop type in the baseline year. This includes any crop herbicides (excluding glyphosate), pesticides, fungicides and insecticides that are applied to this crop type.",
        }),
        seperator2: new SeparatorField(),
        fertilisers: new CustomFieldWithState({
          content: (state: State<ReportGrains_Post3_3Form>) => {
            const fertilisers = state.get.fertilisers ?? [];
            return (
              <Row>
                <Column>
                  <Row
                    style={{
                      justifyContent: "flex-start",
                      width: "100%",
                      marginBottom: "10px",
                    }}
                  >
                    <BodyText style={{ fontSize: "16px" }}>
                      Fertilisers
                    </BodyText>
                  </Row>
                  <Row>
                    <InfoCard content="Enter the fertiliser/s you use below, this will calculate the Active Ingredient amounts for you. If you can't find the fertiliser used you'll need to enter the active ingredient figures yourself below." />
                  </Row>
                  <Row style={{ columnGap: "8px", marginTop: "20px" }}>
                    {fertilisers.map((f, idx) => {
                      const fert = fertiliserOptions.find(
                        (fo) => fo.id === f.id,
                      );
                      return (
                        <SmallButton
                          key={idx}
                          colorScheme="outline"
                          onClick={() => {
                            const newFertilisers = fertilisers.slice();
                            newFertilisers.splice(idx, 1);
                            const activeIngredients =
                              calculateActiveIngredients(newFertilisers);
                            state.set({
                              ...state.get,
                              fertilisers: newFertilisers,
                              activeIngredients,
                            });
                          }}
                        >
                          <span style={{ marginRight: "8px" }}>
                            <Icon icon="bin" />
                          </span>
                          {f.rateApplied} kg/ha of {fert?.name}
                        </SmallButton>
                      );
                    })}
                    <FertiliserCalculator state={state} />
                  </Row>
                </Column>
              </Row>
            );
          },
        }),
        activeIngredients: new MultiInputFormField({
          options: [
            {
              key: "nonUreaNitrogen",
              label: "Non-Urea Nitrogen",
              unit: "kg N/ha",
            },
            { key: "phosphorus", label: "Phosphorus", unit: "kg P/ha" },
            { key: "potassium", label: "Potassium", unit: "kg K/ha" },
            { key: "sulfur", label: "Sulfur", unit: "kg S/ha" },
          ],
          columnWidth: FieldSize.Quarter,
          useLabelsAbove: true,
          required: true,
        }),
      },
      transformer: {
        in: (value) => ({
          ...value,
          activeIngredients: {
            nonUreaNitrogen: value.nonUreaNitrogen,
            phosphorus: value.phosphorus,
            potassium: value.potassium,
            sulfur: value.sulfur,
          },
          limeFraction: inputPercent(value.limeFraction),
          fractionAnnualBurntCrop: inputPercent(value.fractionAnnualBurntCrop),
        }),
        out: (value) => ({
          ...value,
          nonUreaNitrogen: value.activeIngredients.nonUreaNitrogen,
          phosphorus: value.activeIngredients.phosphorus,
          potassium: value.activeIngredients.potassium,
          sulfur: value.activeIngredients.sulfur,
          limeFraction: outputPercent(value.limeFraction),
          fractionAnnualBurntCrop: outputPercent(value.fractionAnnualBurntCrop),
        }),
      },
    },
    helpDrawerContent: {
      title: "Adding Cropping Data",
      content: (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <HelpDrawerExplanatoryContentText style={{ fontWeight: 700 }}>
            Scope 1 & Scope 3
          </HelpDrawerExplanatoryContentText>
          <HelpDrawerExplanatoryContentText>
            Use this page to enter your cropping data and relevant fertiliser
            information. If you produced more than one crop in the year’s
            rotation, click on the ‘Add Crop Type’ button again to add any
            additional crops.
          </HelpDrawerExplanatoryContentText>
          <HelpDrawerExplanatoryContentText>
            <strong>Ruminati Tip:</strong> Automatically calculate the active
            ingredient amounts by clicking on the ‘Add Fertilisers’ button to
            and choosing the relevant fertiliser. You can do this as many times
            as needed.
          </HelpDrawerExplanatoryContentText>
        </div>
      ),
      videoLink:
        "https://www.youtube.com/embed/gl97Cn3B2eE?si=eOlGqfTO0AyQmh-3&loop=1&rel=0",
      accordionItems: [
        {
          title:
            "Why are Paraquat, Diquat and Glyphosate separated out from 'herbicides'?",
          content:
            "Paraquat, Diquat and Glyphosate are separated out because they have a different emissions factor compared to other herbicides. This is due to the higher energy required to manufacture them, resulting in a higher emissions factor.",
        },
      ],
    },
  };
};

interface AllocationForm {
  beef?: number | undefined;
  sheep?: number | undefined;
  grain?: number | undefined;
  [k: `grainAllocations_${string}`]: number | undefined;
  // Wool now deprecated
  wool?: number | undefined;
}

export const production = (
  hasCattle: boolean,
  hasSheep: boolean,
  hasGrain: boolean,
  report?: Report,
): ReportFormPage<
  ReportProductionForm & ReportProductionLabel,
  ReportProduction
> => {
  const consumables = report?.consumablesInformation;
  const petrol: number = consumables?.petrol ?? 0;
  const diesel: number = consumables?.diesel ?? 0;
  const petrolDieselTotal: number = petrol + diesel;
  const electricityTotal: number = consumables?.electricityUsage ?? 0;

  const reportAlgorithmVersion2x =
    report && "algorithmVersion" in report && report?.algorithmVersion < 3;
  const reportAlgorithmVersion3 =
    report && "algorithmVersion" in report && report?.algorithmVersion === 3;
  const reportAlgorithmVersion31orHigher =
    report && "algorithmVersion" in report && report?.algorithmVersion >= 3.1;

  const grainRecords =
    report?.grainInformation || report?.grainInformationPostV3_3;
  const options = [
    ...(hasCattle ? [{ key: "beef", label: "Beef" }] : []),
    ...hasSheep ? [{ key: 'sheep', label: 'Sheep' }] : [],
    // Wool allocation now deprecated - retaining for old reports
    ...hasSheep && report?.productionInformation?.consumablesAllocation.electricity.wool ? [{ key: 'wool', label: 'Wool' }] : [],
    ...(hasGrain && (reportAlgorithmVersion2x || reportAlgorithmVersion3)
      ? [{ key: "grain", label: "Grain" }]
      : []),
    ...(hasGrain && reportAlgorithmVersion31orHigher && grainRecords
      ? [
          { key: "seperator", label: "", break: true },
          ...grainRecords.map((gi) => {
            const type = cropTypes.find((ct) => ct.value === gi.cropType);
            let label = type?.name ?? "";
            if (gi.name) label = `${label} - ${gi.name}`;
            return { key: `grainAllocations_${gi.stableId}`, label };
          }),
        ]
      : []),
  ];

  return {
    id: ReportFormPageId.Production,
    title: "Production",
    description: "",
    image: "resources.png",
    formData: {
      fields: {
        ...(hasSheep || hasCattle
          ? {
              redMeatLabel: new LabelField({
                label: "Red Meat Production",
              }),
              ...(hasCattle
                ? {
                    cattleLiveweightSoldKgs: new NumberFormField({
                      required: true,
                      label: "Cattle - Total Liveweight Sold",
                      unit: "kg lwt/yr",
                      size: hasSheep ? FieldSize.Half : FieldSize.Full,
                      tip: "Total beef sales this year (liveweight kilograms)",
                    }),
                  }
                : {}),
              ...(hasSheep
                ? {
                    sheepLiveweightSoldKgs: new NumberFormField({
                      required: true,
                      label: "Sheep - Total Liveweight Sold",
                      unit: "kg lwt/yr",
                      size: hasSheep ? FieldSize.Half : FieldSize.Full,
                      tip: "Total sheep sales this year (liveweight kilograms)",
                    }),
                  }
                : {}),
              redMeatSeperator: new SeparatorField(),
            }
          : {}),
        ...(report && hasSheep && reportAlgorithmVersion2x
          ? {
              woolLabel: new LabelField({
                label: "Wool Production",
              }),
              greasyWoolKgs: new NumberFormField({
                required: true,
                label: "Greasy wool yield",
                unit: "kg/year",
                size: FieldSize.Half,
                tip: "Greasy wool yield (kg/year)",
              }),
              cleanWoolYieldPercentage: new NumberFormField({
                required: true,
                label: "Clean wool yield",
                unit: "%",
                size: FieldSize.Half,
                tip: "Clean wool yield (%)",
                validator: (value?: number): boolean =>
                  (value ?? 0) >= 0 && (value ?? 0) <= 100,
              }),
              woolSeperator: new SeparatorField(),
            }
          : {}),
        petrolDieselSplit: new PercentageSplitFormField({
          title: "Petrol & Diesel Usage Allocation",
          total: petrolDieselTotal,
          units: "L",
          options,
          summaryLabel: "Petrol/Diesel",
          required: true,
        }),
        consumablesSeperator: new SeparatorField(),
        electricitySplit: new PercentageSplitFormField({
          title: "Electricity Usage Allocation",
          total: electricityTotal,
          units: "kWh",
          options,
          summaryLabel: "Electricity",
          required: true,
        }),
        electricitySeperator: new SeparatorField(),
      },
      transformer: {
        in: (value) => {
          if (value) {
            const petrolDieselSplit: AllocationForm = {
              beef: inputPercent(
                value.consumablesAllocation?.petrolDiesel?.cattle,
              ),
              sheep: inputPercent(
                value.consumablesAllocation?.petrolDiesel?.sheep,
              ),
              wool: inputPercent(
                value.consumablesAllocation?.petrolDiesel?.wool,
              ),
            };
            const electricitySplit: AllocationForm = {
              beef: inputPercent(
                value.consumablesAllocation?.electricity?.cattle,
              ),
              sheep: inputPercent(
                value.consumablesAllocation?.electricity?.sheep,
              ),
              wool: inputPercent(
                value.consumablesAllocation?.electricity?.wool,
              ),
            };

            if (reportAlgorithmVersion2x || reportAlgorithmVersion3) {
              petrolDieselSplit.grain = inputPercent(
                value.consumablesAllocation?.petrolDiesel?.grain,
              );
              electricitySplit.grain = inputPercent(
                value.consumablesAllocation?.electricity?.grain,
              );
            } else if (reportAlgorithmVersion31orHigher) {
              value.consumablesAllocation?.petrolDiesel?.grainAllocations?.forEach(
                (ga) => {
                  if (ga.grainRecordStableId)
                    petrolDieselSplit[
                      `grainAllocations_${ga.grainRecordStableId}`
                    ] = inputPercent(ga.allocation);
                },
              );
              value.consumablesAllocation?.electricity?.grainAllocations?.forEach(
                (ga) => {
                  if (ga.grainRecordStableId)
                    electricitySplit[
                      `grainAllocations_${ga.grainRecordStableId}`
                    ] = inputPercent(ga.allocation);
                },
              );
            }
            return {
              ...value,
              cleanWoolYieldPercentage:
                value.cleanWoolYieldPercentage !== undefined
                  ? value.cleanWoolYieldPercentage * 100
                  : undefined,
              petrolDieselSplit,
              electricitySplit,
            };
          }
          return {};
        },
        out: (value) => {
          const petrolDiesel: ConsumableAllocation = {
            cattle: hasCattle
              ? outputPercent(value.petrolDieselSplit?.beef)
              : undefined,
            wool: hasSheep
              ? outputPercent(value.petrolDieselSplit?.wool)
              : undefined,
            sheep: hasSheep
              ? outputPercent(value.petrolDieselSplit?.sheep)
              : undefined,
          };
          const electricity: ConsumableAllocation = {
            cattle: hasCattle
              ? outputPercent(value.electricitySplit?.beef)
              : undefined,
            wool: hasSheep
              ? outputPercent(value.electricitySplit?.wool)
              : undefined,
            sheep: hasSheep
              ? outputPercent(value.electricitySplit?.sheep)
              : undefined,
          };

          if (hasGrain) {
            if (reportAlgorithmVersion2x || reportAlgorithmVersion3) {
              petrolDiesel.grain = outputPercent(
                value.petrolDieselSplit?.grain,
              );
              electricity.grain = outputPercent(value.electricitySplit?.grain);
            } else if (reportAlgorithmVersion31orHigher) {
              petrolDiesel.grainAllocations = [];
              electricity.grainAllocations = [];
              if (grainRecords) {
                grainRecords.forEach((gi) => {
                  if (
                    gi.stableId &&
                    value.petrolDieselSplit &&
                    `grainAllocations_${gi.stableId}` in value.petrolDieselSplit
                  ) {
                    const allocation =
                      value.petrolDieselSplit[
                        `grainAllocations_${gi.stableId}`
                      ];
                    petrolDiesel.grainAllocations?.push({
                      grainRecordStableId: gi.stableId,
                      allocation: outputPercent(allocation ?? 0),
                    });
                  }
                  if (
                    gi.stableId &&
                    value.electricitySplit &&
                    `grainAllocations_${gi.stableId}` in value.electricitySplit
                  ) {
                    const allocation =
                      value.electricitySplit[`grainAllocations_${gi.stableId}`];
                    electricity.grainAllocations?.push({
                      grainRecordStableId: gi.stableId,
                      allocation: outputPercent(allocation ?? 0),
                    });
                  }
                });
              }
            }
          }
          return {
            cattleLiveweightSoldKgs: value.cattleLiveweightSoldKgs ?? 0,
            sheepLiveweightSoldKgs: value.sheepLiveweightSoldKgs ?? 0,
            greasyWoolKgs: value.greasyWoolKgs ?? 0,
            cleanWoolYieldPercentage:
              (value.cleanWoolYieldPercentage ?? 0) / 100,
            consumablesAllocation: {
              petrolDiesel,
              electricity,
            },
          };
        },
      },
    },
    helpDrawerContent: {
      title: "Adding Production Data",
      content: (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <HelpDrawerExplanatoryContentText style={{ fontWeight: 700 }}>
            Intensity & Total Emissions
          </HelpDrawerExplanatoryContentText>
          <HelpDrawerExplanatoryContentText>
            Use this page to enter your production data. If your answer is zero,
            make sure to enter 0 into the field.
          </HelpDrawerExplanatoryContentText>
        </div>
      ),
      videoLink:
        "https://www.youtube.com/embed/ZIyWEjwF4BU?si=Beqi6AkIbSNc2zxp&loop=1&rel=0",
    },
  };
};
