import { ReactElement } from 'react';
import { usePopupStore } from "../../state/popup_store";
import SubButton from "./sub_button";
import { MultiPolygon } from "geojson";
import { State } from '@/models/form/form_fields/form_field';

type CalculateAreaButtonProps = {
    state?: State<Record<string,unknown>>;
    geom?: MultiPolygon;
    footerText?: string;
    propertyGeom: MultiPolygon;
    additionalLayers?: ReactElement[] | undefined;
    additionalLegendItems?: JSX.Element[] | undefined; 
    onSubmit: (val: {
        area: number,
        geom: MultiPolygon | null
    }) => void;
};

/**
 * A button which shows the tools to calculate area popup on click
 */
export default function CalculateAreaButton(props: CalculateAreaButtonProps) {
    const popupStore = usePopupStore();
    let geom: MultiPolygon | undefined | null = undefined
    if (props.geom) geom = props.geom
    else if (props.state && 'geom' in props.state.get) geom = props.state.get.geom as MultiPolygon | null

    return (
        <div style={{ padding: "0 0 0 8px", whiteSpace: "nowrap" }}>
            <SubButton
                colorScheme="orange"
                onClick={() =>
                    popupStore.addPopup(
                        'DrawAndCalculateAreaPopup',
                        props.onSubmit,
                        {
                            geom,
                            propertyBoundary: props.propertyGeom,
                            additionalLayers: props.additionalLayers,
                            additionalLegendItems: props.additionalLegendItems,
                            footerText: props.footerText
                        }
                    )
                }
            >
                Calculate area from map
            </SubButton>
        </div>
    );
}
