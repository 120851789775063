import { FormData, FormState } from "../../../../models/form/form";
import CalculateAreaButton from "../../../buttons/calculate_area_button";
import { DrawAndCalculateAreaPopupReturns } from "../../draw_and_calculate_area_calculator";
import { InitiativePopUpRepeatable } from "../initiative_popup";
import { InitiativeType, PlantTreesDTO } from "../../../../models/reduction_plans_models";
import { MultiPolygon } from "geojson";
import { ReportTrees } from "../../../../models/report";
import { LegendItem, LegendSwatch, LegendGroupLabel } from '../../../maps/legend'
import { ReductionPlan } from "../../../../models/reduction_plans_models";
import { useAuthStore } from "../../../../state/auth_store";
import { DropdownFormField, TextFormField } from "../../../../models/form/form_fields/basic_form_fields";
import { MultiPolygonFormField } from "../../../../models/form/form_fields/multi_polygon_form_field";
import { 
    setupReductionPlannerLayers
 } from "../../../maps/layer_config/reduction_planner"
import { nzVegetationTypes } from "../../../../models/form_options";
import { ConcatMethod } from "../../../../models/form/form_fields/form_field";
import { CountryCode } from "../../../../utilities/countries";
import HiddenFormField from "../../../../models/form/form_fields/hidden_form_field";
import { getYearField, getDefaultReductionPlanYearOptions } from "../initiative_helpers";
import { FieldSize } from "../../../../models/form/form_fields/form_field";

interface PlantTreeFormData {
    name: string;
    geom: MultiPolygon;
    startYear: number;
    species?: number;
}

export default function PlantTreesPopup(props: {
    initialData?: PlantTreesDTO[];
    propertyBounds: MultiPolygon;
    existingTrees?: ReportTrees[] | undefined;
    otherReductionPlans: ReductionPlan[] | undefined;
    onSubmit: (value: PlantTreesDTO[]) => Promise<void>
}) {
    const repeatableSectionName = "Plantation Area";
    const user = useAuthStore(store => store.user);
    
    const yearOptions = getDefaultReductionPlanYearOptions()

    const formData: FormData<PlantTreeFormData, PlantTreesDTO> = {
        fields: {
            name: new TextFormField({
                required: true,
                label: "Plantation area name",
            }),
            species: user?.country === CountryCode.NewZealand ? new DropdownFormField({
                required: true,
                concatMethod: ConcatMethod.StringList,
                label:
                nzVegetationTypes().length > 0
                ? "Vegetation type (select the closest match)"
                : "No tree data available",
                summaryLabel: "Vegetation type",
                options: nzVegetationTypes(),
                tip: "Enter the nearest vegetation type from the list for the patch/s of vegetation. Only trees planted since 1990 should be entered.",
            }) : new HiddenFormField(),
            geom: new MultiPolygonFormField({
                required: true,
                label: "Area of planting",
                unit: 'Ha',
                children: (state) => {
                    const {
                        proposedTreePlantingsLayerAndLegend,
                        proposedSoilCarbonLayerAndLegend,
                        proposedAllowRevegLayerAndLegend,
                        existingTreeLayerAndLegend
                    } = setupReductionPlannerLayers(
                        InitiativeType.PlantTrees,
                        state,
                        props.otherReductionPlans,
                        props.existingTrees
                    )

                    return (
                        <CalculateAreaButton
                            state={state}
                            geom={state.get.geom}
                            propertyGeom={props.propertyBounds}
                            additionalLayers={[
                                existingTreeLayerAndLegend.layer,
                                proposedSoilCarbonLayerAndLegend.layer,
                                proposedAllowRevegLayerAndLegend.layer,
                                proposedTreePlantingsLayerAndLegend.layer
                            ]}
                            additionalLegendItems={[
                                existingTreeLayerAndLegend.legend,
                                <LegendGroupLabel key="planned-label">
                                    Planned Activities
                                </LegendGroupLabel>,
                                proposedSoilCarbonLayerAndLegend.legend,
                                proposedAllowRevegLayerAndLegend.legend,
                                proposedTreePlantingsLayerAndLegend.legend,
                                <LegendItem key="this-planned-legend">
                                    <LegendSwatch color={'#F3DA064D'} borderColor={'#F3DA06'} />
                                    This Activity
                                </LegendItem>,
                            ]}
                            footerText={"Draw areas where you intend on planting trees."}
                            onSubmit={(obj: DrawAndCalculateAreaPopupReturns) => {
                                if (obj.geom) {
                                    state.set({
                                        ...state.get,
                                        geom: obj.geom
                                    });
                                }
                            }}
                        />
                    )
                },
            }),
            startYear: getYearField('Year of planting', FieldSize.Full, yearOptions),
        },
    };

    return (
        <InitiativePopUpRepeatable
            repeatableSectionName={repeatableSectionName}
            formData={formData}
            initialFormValues={Array.from(props.initialData ?? [{}] as Partial<FormState>[])}
            onSubmit={(state) => props.onSubmit(state as PlantTreesDTO[])}
        />
    );
}