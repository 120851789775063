import { Link, useNavigate, redirect, useSearchParams } from "react-router-dom";
import { useState } from "react";
import styled from "styled-components";

import Screen from "./screen";
import Heading from "../components/heading";
import { BaseText, TextLink } from "../components/styled_text";
import { ruminatiColors } from "../utilities/colors";
import { Wrapper } from "../components/styled_layout";
import AuthFooter from "../components/footers/auth_footer";
import { loginWithEmailPassword } from "../services/auth_service";
import { useAuthStore } from "../state/auth_store";
import { LoginDTO } from "../models/api_models";
import { Form } from "../models/form/form";
import { EmailFormField, TextFormField } from "../models/form/form_fields/basic_form_fields";

const loginForm = {
    fields: {
        email: new EmailFormField({
            placeholder: "Email Address",
            required: true,
        }),
        password: new TextFormField({
            placeholder: "Password",
            required: true,
            obscured: true,
            validator: (value) => value !== undefined && value?.length >= 6,
        }),
    },
};

/**
 * A screen allows the user to log in to Ruminati
 * @returns the Login Screen component
 */
export default function LoginScreen() {
    const [remember, setRemember] = useState<boolean>(true);
    const [errorText, setErrorText] = useState<string | undefined>(undefined);
    const navigate = useNavigate();
    const authStore = useAuthStore();
    let [searchParams, _setSearchParams] = useSearchParams();

    const toggleRemember = () => {
        setRemember((r) => !r);
    };

    let redirectLocation = searchParams.get("redirect")
    if (redirectLocation) {
        const redirectHostName = new URL(redirectLocation).hostname
        const existingHostname = window.location.hostname
        if (redirectLocation && redirectHostName !== existingHostname) {
            redirectLocation = null
        }
    }

    const login = async (data: LoginDTO) => {
        try {
            const user = await loginWithEmailPassword(
                data.email,
                data.password,
                remember
            );
            if (user) {
                authStore.signIn(user);
                if (redirectLocation) {
                    redirect(redirectLocation)
                    return
                }
                navigate("/dashboard");
            }
        } catch (err) {
            if (err instanceof Error) {
                if (err.message === "INVALID_CREDENTIALS") {
                    setErrorText("Invalid email or password");
                } else {
                    setErrorText("An unknown error occurred.");
                }
            }
        }
    };

    return (
        <Screen pageTitle="Login">
            <LoginWrapper>
                <Wrapper style={{ padding: "0 0 24px 0" }}>
                    <Heading level={2}>Log in to Ruminati</Heading>
                </Wrapper>

                {/**
                 * Iterate over the login page data and display the fields
                 */}
                <Form<LoginDTO>
                    data={loginForm}
                    initialValue={{}}
                    onSubmit={(value) => login(value)}
                    error={errorText}
                    buttonWidth="100%"
                    submitText="Log in"
                />

                <Wrapper
                    style={{
                        padding: "8px 0 0 0",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <ToggleWrapper onClick={toggleRemember}>
                        <ToggleIndicator checked={remember} />
                        <BaseText
                            style={{
                                color: ruminatiColors.green_3,
                                padding: "2px 0 0 4px",
                            }}
                        >
                            Remember me
                        </BaseText>
                    </ToggleWrapper>

                    <Link to="/forgotpassword">
                        <BaseText style={{ padding: "2px 0 0 0" }}>
                            <TextLink>Forgot Password?</TextLink>
                        </BaseText>
                    </Link>
                </Wrapper>

                <AuthFooter type="login" />
            </LoginWrapper>
        </Screen>
    );
}

export const LoginWrapper = styled.div`
    flex-grow: 1;
    width: 464px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 112px 0;
`;

export const ToggleWrapper = styled.div`
    display: flex;
    align-items: center;

    cursor: pointer;
    user-select: none;

    height: 32px;
`;

export const ToggleIndicator = styled.div`
    position: relative;
    margin: 4px;

    height: 24px;
    width: 24px;
    border: 1px solid ${ruminatiColors.green_3};
    border-radius: 16px;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;

        opacity: ${(props: { checked: boolean }) => (props.checked ? 1 : 0)};
        transition: 0.3s ease opacity;

        transform: translate(-50%, -50%);

        height: 12px;
        width: 12px;
        border-radius: 8px;

        background-color: ${ruminatiColors.green_3};
    }
`;
