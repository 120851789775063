import { useMemo, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import styled from "styled-components";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";

import Screen from "./screen";
import Heading from "../components/heading";
import { ParagraphText } from "../components/styled_text";
import OutlineButton from "../components/buttons/outline_button";
import { Illustration, Wrapper } from "../components/styled_layout";
import { Form, FormData } from "../models/form/form";
import { TextFormField } from "../models/form/form_fields/basic_form_fields";
import { useAuthStore } from "../state/auth_store";
import { loginWithEmailPassword } from "../services/auth_service";
import { useFirebaseIdentityPlatform } from "../utilities/identityPlatform";

const newPasswordData: FormData<{ password: string, confirmPassword: string }> = {
    fields: {
      password: new TextFormField({
        placeholder: "New Password",
        required: true,
        obscured: true,
        useCleanStringValidator: false,
        validator: (value) => value !== undefined && value?.length >= 6,
    }),
    confirmPassword: new TextFormField({
        placeholder: "Confirm password",
        required: true,
        obscured: true,
        validator: (value, state) => value === state.get.password,
    }),
    }
}

function useQuery () {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}
/**
 * A screen allowing the user to reset their password.
 * Could be used by someone who's forgotten (using an OOB Code)
 * OR used by a signed in user who has a token
 * @returns the Forgot Password Screen component
 */
export default function PasswordResetScreen() {
    const navigate = useNavigate();
    const query = useQuery()
    const authStore = useAuthStore()

    const oobCode = query.get("oobCode") ?? undefined
    const firebaseAuth = useFirebaseIdentityPlatform(query.get("apiKey"))

    const operationType = oobCode !== undefined ? 'Reset' : 'Update'

    const [errorText, setErrorText] = useState<{
        title: string
        message: string,
        btnText?: string,
        link?: string
      } | undefined>(undefined);

    const sendPassword = async (value: {password: string, confirmPassword: string}) => {
        if (oobCode && firebaseAuth) {
            try {
            //    const actionCodeInfo = await checkActionCode(firebaseAuth, oobCode)
            //     if (actionCodeInfo.data.email !== authStore.user?.email) {
            //         setErrorText({
            //             title: 'Unable to reset your password',
            //             message: 'Action code doesnt match user'
            //         })
            //         return
            //     }
                const email = await verifyPasswordResetCode(firebaseAuth, oobCode)
                console.log(`Setting password for ${email}`)
                try {
                    await confirmPasswordReset(firebaseAuth, oobCode, value.password)
                    if (authStore.user) {
                        const user = await loginWithEmailPassword(email, value.password, true);
                        if (user) {
                            authStore.signIn(user)
                            navigate("/account");
                        }
                    }
                    navigate("/login");
                } catch (err2) {
                    console.log(err2)
                    setErrorText({
                        title: "Please try again",
                        message: "Your password was not strong enough"
                    })
                }
            } catch (err) {
                setErrorText({
                    title: "Please try again",
                    message: "Invalid or expired password reset code.",
                    btnText: 'Retry password reset',
                    link: '/forgotpassword'
                })
            }
        } else {
            setErrorText({
                title: "Something went wrong",
                message: "",
                btnText: 'Reset',
                link: '/'
            })
        }
    };
    
    return (<>
      <Screen>
            <ForgotPasswordWrapper>
                <Wrapper style={{marginBottom: '40px'}}>
                    <Heading level={2}>
                        {`${operationType} your password`}
                    </Heading>
                </Wrapper>
                {
                    errorText ? 
                        <>
                            <Heading level={3}>{errorText.title}</Heading>
                            <ParagraphText>{errorText.message}</ParagraphText>
                            <Link to={errorText.link ?? '/'} style={{marginTop: "40px"}}>
                            <OutlineButton width="200px">{errorText.btnText ?? 'Login'}</OutlineButton>
                            </Link>
                        </>
                        :
                    <ForgotPasswordContainer>
                        <Form
                        data={newPasswordData}
                        initialValue={{}}
                        onSubmit={sendPassword}
                        buttonWidth="100%"
                        submitText="Set Password"
                        />
                    </ForgotPasswordContainer>
                }         
            </ForgotPasswordWrapper>
            <Illustration
                src="/images/feeding.png"
                alt="Farmer feeding two brown cows"
            />
        </Screen >
    </>);
}

const ForgotPasswordWrapper = styled.div`
    flex-grow: 1;

    width: 100%;
    min-height: 507px;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    padding: 112px 0 0 0;
`;

const ForgotPasswordContainer = styled.div`
    width: 464px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
