import { Tooltip } from "antd"
import { SyncOutlined } from '@ant-design/icons'

import { Row, Column } from "./styled_layout"
import { BodyText } from "./styled_text"
import { ruminatiColors } from "@/utilities/colors"
import { LinkedFarmDTO, LinkedFarmStatus } from "@/models/vision"
import Icon, { IconType } from "./icon"
import { usePartnerStore } from "@/state/partners"
import { cancelFarmLinkage } from "@/services/vision_service"
import { formatDateTimestampAsPrettyString } from "@/utilities/dates"
import { VisionInvitation } from "@/models/vision_invitation"
import { useState } from "react"
import { usePopupStore } from "@/state/popup_store"
import { CorporatePartnerPopupId } from "@/screens/corporate_partners/corporate_partners"

export default function LinkedEnterpriseRow ({
  invite,
  linkedEnterprise,
  shareAllIsDisabled,
  shareAllRequiredUntil
}: {
  invite: VisionInvitation
  linkedEnterprise: LinkedFarmDTO
  shareAllIsDisabled: boolean | undefined
  shareAllRequiredUntil?: string
}) {
  const partnerStore = usePartnerStore()
  const popupStore = usePopupStore()
  const [deletingLink, setDeletingLink] = useState<boolean>(false)

  function getPendingOrConnectedStyled(status: LinkedFarmStatus, linkage: LinkedFarmDTO): JSX.Element {
    function makeContent(label: string, color?: string, iconStyle?: IconType): JSX.Element {
      return (
        <BodyText style={{ color: color ?? ruminatiColors.green_3 }}>
          {iconStyle ? <span style={{ paddingRight: '6px' }}><Icon icon={iconStyle} /></span> : undefined}
          {label}
        </BodyText>
      )
    }

    if (status === LinkedFarmStatus.SHARED_BY_OWNER) {
      return makeContent('Pending Partner Acceptance', ruminatiColors.orange);
    } else if (status === LinkedFarmStatus.ACCEPTED_BY_ORGANISATION) {
      return makeContent('Connected', undefined, 'tick-short')
    } else if (status === LinkedFarmStatus.UNLINKED_BY_OWNER) {
      return makeContent(linkage.partnershipRequestAccepted === null ? 'Cancelled by you' : 'Revoked by you')
    } else if (status === LinkedFarmStatus.UNLINKED_BY_ORGANISATION) {
      return makeContent(linkage.partnershipRequestAccepted === null ? 'Declined' : 'Cancelled by Organisation');
    }
    return <></>;
  }

  async function cancelConnection(linkageId: string) {
    popupStore.addPopup(CorporatePartnerPopupId.RemoveLinkedEnterprise, undefined, {
      enterpriseName: linkedEnterprise.propertyName,
      visionOrgName: linkedEnterprise.visionOrgName,
      onConfirm: async () => {
        setDeletingLink(true)
        const modifiedLinkage = await cancelFarmLinkage(linkageId);
        setDeletingLink(false)
        partnerStore.removeLinkedFarmById(linkageId)
        if (modifiedLinkage) partnerStore.addLinkedFarm(modifiedLinkage)        
      }
    })
  }

  const linkStatus = linkedEnterprise.status
  
  return (
    <Row
      key={linkedEnterprise.id}
      style={{
        justifyContent: 'flex-start',
        width: '100%',
        marginBottom: '10px',
        paddingLeft: '50px',
      }}>
      <Column style={{ width: '30%', alignItems: 'flex-start' }}>
        <BodyText>{linkedEnterprise.propertyName}</BodyText>
      </Column>
      <Column style={{ width: '25%', alignItems: 'flex-start' }}>
        <BodyText style={{ paddingLeft: '20px', color: ruminatiColors.green_3_50 }}>Shared {formatDateTimestampAsPrettyString(linkedEnterprise.partnershipRequestSent)}</BodyText>
      </Column>
      <Column style={{ width: '25%', alignItems: 'center' }}>
        {getPendingOrConnectedStyled(linkStatus, linkedEnterprise)}
      </Column>
      <Column style={{ width: '20%', alignItems: 'flex-end' }}>
        {(linkStatus === LinkedFarmStatus.SHARED_BY_OWNER || linkStatus === LinkedFarmStatus.ACCEPTED_BY_ORGANISATION) ? (
          <Tooltip title={shareAllIsDisabled && shareAllRequiredUntil ? `You must have sharing with ${invite.visionOrgName} enabled until ${formatDateTimestampAsPrettyString(shareAllRequiredUntil)}` : ""}>
            <Row>
              {deletingLink ? <SyncOutlined 
                spin={true} 
                style={{
                  color: ruminatiColors.dark_green,
                  marginRight: '8px'
                }}
              /> : undefined
              }
              <BodyText 
                style={{
                  borderBottom: `1px solid ${ruminatiColors.dark_green}`,
                  cursor: shareAllIsDisabled ? 'not-allowed' : 'pointer',
                  textAlign: 'right',
                  opacity: shareAllIsDisabled ? 0.4 : 1
                }}
                onClick={() => {
                  if (!shareAllIsDisabled) {
                    cancelConnection(linkedEnterprise.id)
                  }
                }}
              >
                {linkStatus === LinkedFarmStatus.SHARED_BY_OWNER ? 'Cancel' : 'Disconnect'}
              </BodyText>                      
            </Row>
        </Tooltip>
        ) : undefined}
      </Column>
    </Row>)
}