import { Column, Row } from "@/components/styled_layout";
import { MultiInputField } from "../../../components/form/multi_input_field"
import { BodyText } from "../../../components/styled_text"
import { displayFloat, numberCharacterValidator, parseNumberString } from "../../../utilities/numbers";

import { TypeOrFunctionOfState, InputFormField, InputFormFieldArgs, NumberType, State, Validator, FieldSize } from "./form_field"

export type VaryingInfo = { [key: string]: number | undefined };

export class MultiInputFormField extends InputFormField<VaryingInfo> {
    title: string | undefined; // If set to undefined, title won't show
    total: number | undefined; // If set to undefined, total won't be used (and unit values won't be used either)
    allUnits?: string;
    options: { key: string, label: string, unit?: string }[];
    singleValidator?: Validator<number>;
    useError?: boolean;
    numberType: NumberType;
    _useLabelsAbove?: boolean;
    _additionalAction?: TypeOrFunctionOfState<JSX.Element>
    columnWidth?: FieldSize
    _danglingZeroes: { [key: string]: number | undefined } = {};

    get count(): number {
        return this.options.length
    }

    get _default(): { [key: string]: undefined } {
        return Object.fromEntries(this.options.map((o) => [o.key, undefined]));
    }
    
    constructor(
        args: InputFormFieldArgs<VaryingInfo> & {
            options: { key: string, label: string, unit?: string }[],
            title?: string,
            total?: number,
            allUnits?: string,
            singleValidator?: Validator<number>,
            useError?: boolean,
            numberType?: NumberType,
            useLabelsAbove?: boolean,
            additionalAction?: TypeOrFunctionOfState<JSX.Element>
            columnWidth?: FieldSize
        }
    ) {
        super(args);
        this.title = args.title;
        this.options = args.options;
        this.total = args.total;
        this.allUnits = args.allUnits;
        this.singleValidator = args.singleValidator;
        this.useError = args.useError ?? true;
        this.numberType = args.numberType ?? NumberType.Float;
        this._useLabelsAbove = args.useLabelsAbove
        this._additionalAction = args.additionalAction ?? undefined
        this.columnWidth = args.columnWidth
    }

    additionalAction(state: State<any>) {
        return this._additionalAction && this._additionalAction instanceof Function ? this._additionalAction(state) : undefined;
    }

    transformSingle(value: string | undefined, key?: string, dp?: number): number | undefined {
        if (value !== undefined) {
            const { num, danglingZeroes } = parseNumberString(value, this.numberType, dp);
            if (key !== undefined) {
                if (dp !== undefined && dp > 0) {
                    this._danglingZeroes[key] = undefined;
                } else {
                    this._danglingZeroes[key] = danglingZeroes;
                }
            }
            return num;
        }
        return undefined;
    }

    transforms = (values?: { [key: string]: string | undefined }, dp?: number): { [key: string]: number | undefined } => {
        if (values) {
            return Object.fromEntries(Object.keys(values).map((k) => {
                const value = values[k];
                return [k, this.transformSingle(value, k, dp)];
            }));
        }
        return this._default;
    }

    validate(values?: { [key: string]: number | undefined }): boolean {
        if (values === undefined) return false
        if (Object.values(values).filter(v => v !== undefined).length < this.options.length) return false
        return true
    }

    // only check for required if showMissingFields is true
    validateSingle = (showMissingFields: boolean | undefined) => (value: string | undefined, state: State<any>): boolean => {
        if (showMissingFields && this.required(state) && !value) return false
        if (!value) return true
        const numValue = this.transformSingle(value);
        return this.singleValidator ? this.singleValidator(numValue, state) : true;
    }

    displaySingle = (value: number | undefined, key: string) => {
        if (value === undefined) return undefined;
        if (Number.isNaN(value)) return undefined;

        return displayFloat(value, this._danglingZeroes[key]);
    }

    displayValues = (values?: { [key: string]: number | undefined }): { [key: string]: string | undefined } => {
        if (values) {
            return Object.fromEntries(Object.keys(values).map((k) => {
                const value = values[k];
                if (value !== undefined && value !== null) {
                    return [k, this.displaySingle(value, k)];
                }
                return [k, undefined];
            }))
        }
        return this._default
    }

    placeholders = (values: { [key: string]: number | undefined } | undefined, _state: State<any>): { [key: string]: string } => {
        return Object.fromEntries(this.options.map((o) => {
            if (values && values[o.key] !== undefined) return [o.key, ""];
            return [o.key, "0"];
        }));
    }

    _labels = (): { [key: string]: string } => {
        return Object.fromEntries(this.options.map((o) => [o.key, o.label]))
    }

    _unitLabels = (): { [key: string]: string } => {
        return Object.fromEntries(this.options.map((o) => [o.key, o.unit ?? '']))
    }

    render(
        _id: string,
        onChange: (value?: { [key: string]: number | undefined }) => void,
        state: State<any>,
        value?: { [key: string]: number | undefined },
        error?: boolean,
        size?: "large" | "small",
        _label?: TypeOrFunctionOfState<string>,
        showMissingFields?: boolean
    ): JSX.Element {
        return <>
            <Row 
            style={{
                justifyContent: 'space-between',
                alignContent: 'center',
                // marginTop: "8px",
                marginBottom: this._useLabelsAbove ? "0px" : "8px",
            }}>
                <Column>
                    {this.title !== undefined && <BodyText
                        style={{
                            width: "100%",
                            fontSize: "16px",
                            lineHeight: "24px",
                            fontWeight: 500,
                        }}
                    >
                        {this.title} {this.total !== undefined && <>({this.total}{this.allUnits})</>}
                        {this.allUnits !== undefined && this.total === undefined && <>({this.allUnits})</>}
                    </BodyText>}
                </Column>
                <Column>
                    {this.additionalAction(state)}
                </Column>
            </Row>
            <MultiInputField
                keys={this.options.map(o => o.key)}
                labelsInside={this._useLabelsAbove ? undefined : this._labels()}
                labelsAbove={this._useLabelsAbove ? this._labels() : undefined}
                values={this.displayValues(value)}
                unit={this.allUnits ?? this._unitLabels()}
                onChange={(values, dp) => onChange(this.transforms(values, dp))}
                placeholders={this.placeholders(value, state)}
                error={this.useError ? error : undefined}
                singleValidator={this.validateSingle(showMissingFields)}
                size={size}
                state={state}
                characterValidator={numberCharacterValidator(this.numberType)}
                showMissingFields={showMissingFields}
                columnWidth={this.columnWidth}
            />
        </>
    }
}