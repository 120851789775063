import styled from "styled-components";
import { ruminatiColors } from "../../utilities/colors";

export type InstructionOverlayProps = {
  title: string
  content: JSX.Element
  footer?: JSX.Element
};


export default function InstructionOverlay (props: InstructionOverlayProps) {
  return (
    <InstructionOverlayWrapper>
      <InstructionOverlayTitle>{props.title}</InstructionOverlayTitle>
      {props.content}
      {
        props.footer ? 
        <div style={{
          marginTop: '10px'
        }}>
          {props.footer}
        </div> : undefined
      }
    </InstructionOverlayWrapper>
  )
}

const InstructionOverlayWrapper = styled.div`
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 5;

    padding: 10px;
    background-color: ${ruminatiColors.bone};
    border: 1px solid ${ruminatiColors.dark_green};
    width: 270px;
    border-radius: 8px;
`;

const InstructionOverlayTitle = styled.p`
    color: ${ruminatiColors.green_3_50};
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 2px;
`;