import { create } from 'zustand';
import { LinkShareAllDTO, LinkedFarmDTO, VisionOrganisationBasicDTO } from "../models/vision";
import { VisionInvitation } from "../models/vision_invitation";

interface PartnerState {
    linkedFarms: LinkedFarmDTO[] | undefined;
    setLinkedFarms: (r: LinkedFarmDTO[]) => void;
    addLinkedFarm: (r: LinkedFarmDTO) => void;
    removeLinkedFarmById: (r: string) => void;

    invitations: VisionInvitation[] | undefined;
    setInvitations: (r: VisionInvitation[]) => void;

    shareAlls: LinkShareAllDTO[] | undefined;
    setShareAlls: (r: LinkShareAllDTO[]) => void;
    removeShareAllById: (id: string) => void

    miscOrgs: Record<string, VisionOrganisationBasicDTO | 'loading'>
    addMiscOrg: (orgId: string, record: VisionOrganisationBasicDTO | 'loading') => void
    clear: () => void;
}

/**
 * Stores vision related partner data
 */
export const usePartnerStore = create<PartnerState>((set) => ({
  linkedFarms: undefined,
  setLinkedFarms: (linkedFarms: LinkedFarmDTO[]) => set({ linkedFarms }),
  addLinkedFarm: (linkedFarm: LinkedFarmDTO) => set((state) => ({
    linkedFarms: [
      ...(state.linkedFarms ?? []),
      linkedFarm
  ]
  })),
  removeLinkedFarmById: (id: string) => set((state) => ({
      linkedFarms: state.linkedFarms?.filter(lf => lf.id !== id)
  })),

  invitations: undefined,
  setInvitations: (invitations: VisionInvitation[]) => set({ invitations }),

  shareAlls: undefined,
  setShareAlls: (shareAlls: LinkShareAllDTO[]) => set({ shareAlls }),
  removeShareAllById: (shareAllId: string) => set((state) => ({
    shareAlls: [
        ...(state.shareAlls ?? []).filter((r) => r.id !== shareAllId)
    ]
  })),

  miscOrgs: {},
  addMiscOrg: (orgId: string, record: VisionOrganisationBasicDTO | 'loading') => set((state) => ({
    miscOrgs: Object.assign(state.miscOrgs, {
      [orgId]: record
    })
  })),
  clear: () => set({ linkedFarms: undefined, invitations: undefined, shareAlls: undefined })
})
);