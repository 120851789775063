import Heading from "./heading";
import { Column, Expanded, Illustration } from "./styled_layout";

export default function EmptyPlaceholder({ children }: { children?: string }) {
    return (
        <Expanded>
            <Column>
                <Illustration style={{ margin: 0 }} src="/images/pasture.png" />
                <Heading level={5}>{children}</Heading>
            </Column>
        </Expanded>
    );
}
