import React from "react";
import { Tooltip } from "antd"
import styled from "styled-components";
import { BodyText } from "./styled_text";
import { ruminatiColors } from "../utilities/colors";
import Icon from "./icon";

export type Step = {
  id: string
  label: string
  disabled?: boolean
  tooltip?: React.ReactNode
};

export type StepsProps = {
  steps: Step[]
  completedStepIds: string[]
  currentStepId: string
  onClick?: (step: Step) => void
  allowForwardClick?: boolean
};


export default function Steps (props: StepsProps) {

  function renderStepItem (step: Step, index: number): JSX.Element {
    const stepType = step.id === props.currentStepId ? 'ActiveStep' :
                    props.completedStepIds.findIndex((cs) => cs === step.id) !== -1 ? 'CompletedStep' : 
                    'Step'

    switch(stepType) {
      case 'ActiveStep':
          return (<>
          <ActiveStepNumber>{index}</ActiveStepNumber>
          <div>
            <StepText active={true}>{step.label}</StepText>
          </div>
        </>)
      case 'CompletedStep':
        return (<>
          <CompletedStepNumber>
            <Icon icon="tick" fillColor={ruminatiColors.bone}/>
          </CompletedStepNumber>
          <div>
            <StepText>{step.label}</StepText>
          </div>
        </>)
      default:
        return (<>
          <StepNumber>{index}</StepNumber>
          <div>
            <StepText style={{
              color: ruminatiColors.green_3_30
            }}>{step.label}</StepText>
          </div>
          </>
      )
    }
  }

  function isItemComplete (step: Step): boolean {
    return props.completedStepIds.findIndex(s => s === step.id) > -1
  }

  function isItemForward (step: Step): boolean {
    return props.steps.findIndex(s => s.id === step.id) > props.steps.findIndex(s => s.id === props.currentStepId)
  }

  function getCursorStyle (step: Step): string {
    if (step.disabled) return 'not-allowed'
    if (props.allowForwardClick) return 'pointer'
    if (isItemComplete(step)) return 'pointer'
    if (!props.allowForwardClick && isItemForward(step)) return 'not-allowed'
    return 'pointer'
  }

  return (
    <StepsWrapper className="steps">
      {props.steps.map((step, i) => {
        return (
          <Tooltip 
            key={step.id}
            title={step.tooltip}
            color={ruminatiColors.effective_black}
            placement="right"
          >
            <StepItem
              onClick={() => {
                if (step.disabled) return
                if (props.onClick === undefined) return
                if (props.currentStepId === step.id) return
                if (isItemComplete(step)) return props.onClick(step)
                if (!props.allowForwardClick && isItemForward(step)) return
                props.onClick(step)
              }}
              style={{
                cursor: getCursorStyle(step)
              }}
              className={(
                isItemComplete(step) ? 'step-item-complete' : 'step-item-incomplete'
              )}
            >
              {renderStepItem(step, i + 1)}
            </StepItem>
          </Tooltip>
        )
      })}
    </StepsWrapper>
  )
}

const StepsWrapper = styled.div`
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    .step-item-incomplete > p:hover {
      border-bottom-color: ${ruminatiColors.green_3_30};
    }    
    .step-item-complete > p:hover {
      border-bottom-color: ${ruminatiColors.green_3};
    }
`;

const StepItem = styled.div`
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: left;
    align-items: center;
    margin-bottom: 12px;
    align-self: flex-start;
    padding-right: 8px;
`;

const StepNumber = styled.div`
    width: 32px;
    height: 32px;
    padding: 8px;
    background-color: ${ruminatiColors.green_3_10};
    color: ${ruminatiColors.green_3_30};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-weight: 500;
`;

const ActiveStepNumber = styled(StepNumber)`
    background-color: ${ruminatiColors.dark_green};
    color: ${ruminatiColors.bone};
`;

const CompletedStepNumber = ActiveStepNumber

type StepTextProps = {
  active?: boolean
}
const StepText = styled(BodyText)<StepTextProps>`
  padding: 0px 0px 3px 0px;
  transition: 0.2s;
  position: relative;
  display: inline;
  &:after {
    position: absolute;
    left: 0; 
    bottom: 0;
    width: 100%;
    height: 1px; 
    border-bottom: 1px solid ${(props)=> props.active ? ruminatiColors.dark_green : 'transparent'}; 
    content: "";
  }
`