import { getAverageRateForLocalityAndYear } from "@/utilities/fuel";
import { Form, FormData } from "../../models/form/form";
import { NumberFormField } from "../../models/form/form_fields/basic_form_fields";
import { InfoCard } from "../card";
import { Row } from "../styled_layout";
import { formatFinancialYearStringToSimpleFinancialYearString } from "@/utilities/dates";


export interface CalculatorDTO {
    amountSpent: number;
    avgPrice: number
}

const calculatorForm = (fuelType: 'Petrol' | 'Diesel', year: number): FormData<CalculatorDTO> => ({
    fields: {
        amountSpent: new NumberFormField({
            label: `Annual ${fuelType} Bill`,
            unit: "$",
            required: true,
            validator: (value) => value !== undefined && value >= 0,
        }),
        avgPrice: new NumberFormField({
            label: `Average ${fuelType} Price - FY${formatFinancialYearStringToSimpleFinancialYearString(year.toString())}`,
            unit: "$",
            required: true,
            validator: (value) => value !== undefined && value >= 0,
        })
    },
});

/**
 * The Fuel Use Calculator form, intended to be shown inside a popup
 */
export default function FuelUseCalculator({
    onSubmit,
    metadata
}: {
    onSubmit: (val: number) => void;
    metadata: {
      localityId: number | string
      reportYear: number
      fuelType: 'Petrol' | 'Diesel'
    }
}) {
    const formData = calculatorForm(metadata.fuelType, metadata.reportYear);
    const avgPrice = getAverageRateForLocalityAndYear(metadata.localityId, metadata.fuelType, metadata.reportYear)
    return (
        <div style={{ padding: "20px", width: '450px' }}>
            <InfoCard 
                content={`Estimate your ${metadata.fuelType} usage in litres based on your annual bill and the average cost of ${metadata.fuelType} for your region in the ${formatFinancialYearStringToSimpleFinancialYearString(metadata.reportYear)} financial year`}
            />
            <Row style={{height: '20px'}}></Row>
            <Form
                data={formData}
                initialValue={{
                    avgPrice: avgPrice ?? 2.00
                }}
                fieldSize="small"
                submitText={`Calculate Litres Used`}
                buttonSize="medium"
                onSubmit={async (value: CalculatorDTO) => {
                    onSubmit(value.amountSpent / value.avgPrice);
                }}
            />
        </div>
    );
}
