import { FirebaseError } from "firebase/app"
import { serializeError, ErrorObject } from "serialize-error"

export function convertUnknownErrortoStringable (unknownErrorEvent: unknown): string | ErrorObject {
  if (typeof unknownErrorEvent === "string") {
    return unknownErrorEvent
  } else if (unknownErrorEvent instanceof FirebaseError) {
    return serializeError(unknownErrorEvent)
  } else if (unknownErrorEvent instanceof Error) {
    return serializeError(unknownErrorEvent)
  } 
  return ""
}