import { fertiliserOptions, herbicideOptions, HerbicideOrHerbicidePesticideOption, herbicidePesticideOptions } from "@/models/form_chemical_lookup"
import { FertiliserUse, HerbicideOrPesticideUse } from "@/models/report"
import { trimDecimalPlaces } from "./numbers"
import { calculateSumOfArray } from "./maths"

export function calculateActiveIngredients(fertilisers: FertiliserUse[]) {
  let nonUreaNitrogen = 0
  let phosphorus = 0
  let potassium = 0
  let sulfur = 0
  fertilisers.forEach((f) => {
    const fert = fertiliserOptions.find(fo => fo.id === f.id)
    if (fert) {
      nonUreaNitrogen = trimDecimalPlaces(nonUreaNitrogen + (fert.nonUreaNitrogen * f.rateApplied))
      phosphorus = trimDecimalPlaces(phosphorus + (fert.pPercent * f.rateApplied))
      potassium = trimDecimalPlaces(potassium + (fert.kPercent * f.rateApplied))
      sulfur = trimDecimalPlaces(sulfur + (fert.sPercent * f.rateApplied))
    }
  })
  return {
    nonUreaNitrogen,
    phosphorus,
    potassium,
    sulfur
  }
}

function processHerbicidesOrHerbicidesPesticides (used: HerbicideOrPesticideUse[], options: HerbicideOrHerbicidePesticideOption[]): number {
  return trimDecimalPlaces(
    calculateSumOfArray(used.map((f) => {
      const herbicide = options.find(ho => ho.id === f.id)
      if (herbicide) {
        return (herbicide.active_amount / 1000) * f.rateApplied * f.areaAppliedHectares
      }
      return 0
    }))
  )
}


export function calculateHerbicideActiveIngredients (herbicides: HerbicideOrPesticideUse[]): {
  herbicide: number
  herbicidePesticide: number
} {
  return {
    herbicide: processHerbicidesOrHerbicidesPesticides(herbicides.filter(h => h.id.indexOf("Herbicide_") > -1), herbicideOptions),
    herbicidePesticide: processHerbicidesOrHerbicidesPesticides(herbicides.filter(h => h.id.indexOf("HerbicidePesticide_") > -1), herbicidePesticideOptions)
  }
}