import MainButton from "../../components/buttons/main_button";
import { Row, Column } from "../../components/styled_layout";
import { CardBodyText } from "./farm";

export function EmptyCardContent({
    text,
    buttonText,
    buttonOnClick,
}: {
    text: string;
    buttonText: string;
    buttonOnClick: () => void;
}) {
    return (
        <Column style={{width: '80%'}}>
            <Row>
                <CardBodyText>
                    {text}
                </CardBodyText>
            </Row>
            <Row style={{marginTop: '12px'}}>
                <MainButton onClick={buttonOnClick}> 
                    {buttonText}
                </MainButton>
            </Row>
        </Column>
    );
}