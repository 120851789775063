enum EmissionUnit {
    Total = "t CO2-e",
    IntensityLivestock = "kg CO2-e/kg lwt",
    IntensityWool = "kg CO2-e/kg wool",
    IntensityGrain = "kg CO2-e/kg crop",
    IntensityNetPerDSE = "kg CO2-e/DSE",
    IntensityHscw = "kg CO2-e/tonne HSCW",
    IntensityProduct = "kg CO2-e/tonne Product",
    DairyIntensityFPCM = "kg CO2-e/kg FPCM",
    DairyIntensityMilkSolids = "kg CO2-e/kg MS",
    DairyIntensityLW = "kg CO2-e/kg LW",
    Change = "%"
}

export default EmissionUnit;
