import { useAuthStore } from "../state/auth_store";
import { useCallback, useEffect, useRef, PropsWithChildren } from "react";
import { usePopupStore } from "../state/popup_store";
import { countriesDropdownOptions } from "../utilities/countries";
import { getUserProfile } from "../services/auth_service";

export enum AppUserPopupId {
    CountrySelection = "country-selection-popup"
}

const APP_NZ_TOOL_UPDATE_TIMESTAMP = 1701736070385;

/**
 * Opens a Country selection dropdown if country is not set to user profile
 * @param children the react page component
 */
export default function UserCountryGuard({
    children,
}: PropsWithChildren<unknown>) {
    const { user, signIn } = useAuthStore();
    const { addPopup, popupStack } = usePopupStore();
    const hasRunOnce = useRef<boolean>(false);
    const checkIfCountryFieldExists = useCallback(async () => {
        let userRef = user;

        if (userRef && userRef.timestamp < APP_NZ_TOOL_UPDATE_TIMESTAMP) {
            // Update old user objects
            userRef = (await getUserProfile()); // Use user object retrieved from db
            if (userRef) signIn(userRef); // Update auth store updated user object
        }
        if (userRef && !userRef.country && popupStack.find(p => p.id === AppUserPopupId.CountrySelection) === undefined) {
            addPopup(AppUserPopupId.CountrySelection, undefined, { id: AppUserPopupId.CountrySelection, options: countriesDropdownOptions, placeholder: "Country" })
        }
    }, [addPopup, signIn, user])

    useEffect(() => {
        if (hasRunOnce.current === true) return;
        hasRunOnce.current = true;

        checkIfCountryFieldExists();
    }, [checkIfCountryFieldExists])

    return children;
}