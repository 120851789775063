import { useState } from 'react'

import { Column, Row } from "../../components/styled_layout";
import { AccountPageContainer } from './account_screen'
import { BodyText } from "../../components/styled_text";
import { useAuthStore } from "../../state/auth_store";
import { useIntegrationsStore } from "../../state/integrations";
import { disconnectAgriWebbIntegration, openAgriWebbOAuthFlow } from "../../services/agriwebb_service";
import { CountryCode, countryIsAustralia, countryIsNZ } from "../../utilities/countries";
import { ruminatiColors } from "../../utilities/colors";
import { GreenCard } from "../../components/card";
import Heading from "../../components/heading";
import SmallButton from "../../components/buttons/small_button";
import Icon from "../../components/icon";
import EmptyPlaceholder from "../../components/empty_placeholder";
import { patchUserProfile } from '@/services/auth_service';
import SwitchAntd from '@/components/switch_antd';

export default function AccountIntegrationsTab() {
    const authStore = useAuthStore();
    const integrationsStore = useIntegrationsStore();
    const integrations = integrationsStore.integrations;

    const [localState, setLocalState] = useState({
        auctionsPlus: integrations?.auctionsPlus ?? false,
        agriwebb: integrations?.agriwebb ?? false,
        // modifyingAuctionsPlus: false
    })

    const user = authStore.user;
    const userCountryCode = user?.country as CountryCode;

    async function connectToAgriWebb(): Promise<void> {
        await openAgriWebbOAuthFlow()
    }

    async function disconnectAgriWebbAccount(): Promise<void> {
        const newIntegrations = await disconnectAgriWebbIntegration()
        if (newIntegrations) integrationsStore.setIntegrations(newIntegrations)
        if (!newIntegrations?.agriwebb) {
            integrationsStore.clearAgriWebbFarms()
            setLocalState({
                ...localState,
                agriwebb: false
            })
        }
    }

    function createConnectButton (onClickAction: () => void): JSX.Element {
        return (
            <SmallButton
            colorScheme="outline"
            onClick={onClickAction}
            children={<>
                <Icon icon="chain"/>
                <BodyText style={{marginLeft: '10px'}}>Connect</BodyText>
            </>}
            />)
    }

    function getAuctionsPlusContent (): JSX.Element {
        if (!integrations) return <></>
        return <>
          <BodyText style={{marginRight: '10px'}}>
            Climate Assessment {localState.auctionsPlus === true ? 'Allowed' : 'Disallowed'}
          </BodyText>
          <Column style={{ paddingRight: '10px' }}>
          <SwitchAntd
            checked={localState.auctionsPlus} 
            // disabled={localState.modifyingAuctionsPlus}
            onChange={async (checked) => {
                setLocalState({
                    ...localState,
                    // modifyingAuctionsPlus: true,
                    auctionsPlus: checked
                })
                await patchUserProfile({
                    auctionsPlusStatusRetrievalApproved: checked
                })
                // setLocalState({
                //     ...localState,
                //     modifyingAuctionsPlus: false
                // })
            }}
          />
          </Column>
        </>
    }

    function getAgriwebbActionContent (): JSX.Element {
        if (localState.agriwebb) {
            return <>
                <BodyText style={{marginRight: '10px'}}>
                    Connected!
                </BodyText>
                <Column style={{ paddingRight: '10px' }}>
                    <SmallButton
                        colorScheme={'green'}
                        onClick={connectToAgriWebb}
                        children={<BodyText style={{color: ruminatiColors.bone}}>Adjust Integration</BodyText>}
                    />
                </Column>
                <Column>
                    <SmallButton
                        colorScheme={'orange'}
                        onClick={disconnectAgriWebbAccount}
                        children={<BodyText style={{color: ruminatiColors.bone}}>Disconnect</BodyText>}
                    />
                </Column>
            </>
        }
        return <>
            <BodyText style={{marginRight: '10px', textAlign: 'right'}}>Existing AgriWebb account needed with Organisation Admin permissions</BodyText>
            {createConnectButton(connectToAgriWebb)}
        </>
    }

    function createIntegrationCard (index: number, title: string, description: string,  action: JSX.Element, logo?: string): JSX.Element {
        return (
            <GreenCard
            id={`${title}_integration`}
            key={index}
            style={{
                borderWidth: '0px',
                padding: '24px'
            }}>
                <Row style={{marginBottom: '16px'}}>
                    <Column style={{ width: '20%', alignItems: 'flex-start' }}>
                        <Row>
                            <img src={logo} alt={title + "logo"} width="20px" style={{marginRight: '10px'}}/>
                            <Heading level={5}>{title}</Heading>
                        </Row>
                    </Column>
                    <Column style={{ width: '80%', alignItems: 'flex-end' }}>
                        <Row>
                            {action}
                        </Row>
                    </Column>
                </Row>
                <Row>
                    <Column style={{ width: '100%' }}>
                        <BodyText style={{lineHeight: '24px'}}>{description}</BodyText>
                    </Column>
                </Row>
            </GreenCard>
        )
    }

    function createIntegrationsSectionContent (): JSX.Element[] {
        const availableIntegrations = countryIsAustralia(userCountryCode) ? [
            {
                title: 'AgriWebb',
                connected: integrations?.agriwebb === true,
                logo: "/images/agriwebb.png",
                actions: getAgriwebbActionContent(),
                description: "Ruminati can import relevant data from an existing AgriWebb account to help fill in some of the information needed, such as your property boundaries and livestock information, to help determine your greenhouse emissions."
            },
            {
                title: 'AuctionsPlus',
                logo: "/images/ap.png",
                actions: getAuctionsPlusContent(),
                description: "Allow AuctionsPlus to detect if you've completed an emissions report based on your PIC number. Enabling this integration will allow you to add the Climate Assessed badge to your livestock sales listings within AuctionsPlus. Disabling this means AuctionsPlus is not able to request whether you've completed an emissions report."
            }
        ] : []
        if (availableIntegrations.length === 0) {
            const msg = countryIsNZ(userCountryCode) ? "We don't currently have any integrations available for NZ-based users." : "No integrations available"
            return [
                <EmptyPlaceholder children={msg} key="no-integrations"/>
            ]
        }
        return availableIntegrations.map((i, index) => createIntegrationCard(index, i.title, i.description, i.actions, i.logo))
    }

    return (
        <AccountPageContainer style={{ rowGap: "32px" }}>
            {createIntegrationsSectionContent()}
        </AccountPageContainer>
    );
}