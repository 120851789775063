import { ConfigProvider, Switch, Tooltip } from "antd"
import { useState } from "react"
import dayjs from "dayjs"

import { LinkShareAllDTO, LinkedFarmDTO } from "@/models/vision"
import { Card } from "../card"
import { Row, Column } from "../styled_layout"
import { BodyText } from "../styled_text"
import { VisionInvitation } from "@/models/vision_invitation"
import { ruminatiColors } from "@/utilities/colors"
import { formatDateTimestampAsPrettyString } from "@/utilities/dates"
import SmallButton from "../buttons/small_button"
import { usePopupStore } from "@/state/popup_store"
import { usePartnerStore } from "@/state/partners"
import { deleteShareAll, getPendingOrAcceptedFarmLinkages } from "../../services/vision_service";
import { CorporatePartnerPopupId } from "@/screens/corporate_partners/corporate_partners"
import LinkedEnterpriseRow from "../linked_enterprise_row"
import { composeIconUrl } from "@/utilities/partners"

export const apiUrl = import.meta.env.REACT_APP_API_BASE_URL;

export type CorporateInviteProps = {
  invite: VisionInvitation,
  propertyCount: number
  shareAll?: LinkShareAllDTO,
  showInviteDate?: boolean,
  action?: JSX.Element,
  linkedEnterprises?: LinkedFarmDTO[]
}

export default function CorporateInviteCard(props: CorporateInviteProps) {
  const popupStore = usePopupStore()
  const partnerStore = usePartnerStore()
  const [shareAllEnabled, setShareEnabled] = useState<boolean>(props.shareAll !== undefined)

  function goToLinkAddPage(shareAll: boolean, inviteId: string) {
    popupStore.addPopup(CorporatePartnerPopupId.ConnectPartner, undefined, {
      shareAll,
      inviteId,
      visionOrgName: props.invite.visionOrgName,
      onSubmit: async () => {
        if (shareAll) {
          setShareEnabled(true)
          const updatedLinkedFarms = await getPendingOrAcceptedFarmLinkages()
          if (updatedLinkedFarms) partnerStore.setLinkedFarms(updatedLinkedFarms)
        }
      }
    })
  }

  async function cancelShareAll() {
    if (props.shareAll) {
      setShareEnabled(false)
      await deleteShareAll(props.shareAll.id)
      partnerStore.removeShareAllById(props.shareAll.id)
    }
  }

  function shareSelectedButton() {
    return <SmallButton
      colorScheme="outline"
      height="30px"
      onClick={() => goToLinkAddPage(false, props.invite.id)}>
      Share additional enterprises
    </SmallButton>
  }

  const canDisableShares = props.shareAll && dayjs().isBefore(dayjs(props.shareAll.requiredUntil))
  const activeShareCount = props.linkedEnterprises ? props.linkedEnterprises.filter(le => le.status === 'accepted' || le.status === 'shared').length : 0

  return <Card
    id={props.invite.id}
    key={props.invite.id}
    style={{
      marginBottom: '24px'
    }}>
    <Row style={{
      paddingBottom: '12px',
      borderBottom: `1px solid ${ruminatiColors.green_3_30}`,
      marginBottom: '18px'
    }}>
      <Column style={{ width: '50px', alignItems: 'flex-start' }}>
        {props.invite.visionOrgIcon ?
          <img src={composeIconUrl(props.invite.visionOrgIcon)} alt={props.invite.visionOrgName + "logo"} height="36px" style={{ marginRight: '20px' }} />
          : undefined}
      </Column>
      <Column style={{ flexGrow: '1', alignItems: 'flex-start' }}>
        <Row>
          <BodyText style={{ fontWeight: 500, fontSize: '15px' }}>{props.invite.visionOrgName}</BodyText>
          {props.showInviteDate ? <BodyText style={{ paddingLeft: '20px', color: ruminatiColors.green_3_50 }}>Requested {formatDateTimestampAsPrettyString(props.invite.created)}</BodyText> : undefined}
        </Row>
      </Column>
      <Column style={{ width: '40%', alignItems: 'flex-end' }}>
        <ConfigProvider
          theme={{
            components: {
              Switch: {
                colorPrimary: ruminatiColors.green_3,
                colorPrimaryHover: ruminatiColors.green_4,
                handleBg: ruminatiColors.bone
              }
            },
          }}
        >
          <Row>
            <BodyText style={{ marginRight: '18px' }}>
              {
                props.shareAll !== undefined ? 'Sharing all current and future enterprises' : 'Share all current and future enterprises'
              }
            </BodyText>
            <Tooltip title={canDisableShares && props.shareAll && props.shareAll.requiredUntil ? `You must have sharing with ${props.invite.visionOrgName} enabled until ${formatDateTimestampAsPrettyString(props.shareAll.requiredUntil)}` : ""}>
              <Switch
                checked={shareAllEnabled}
                disabled={canDisableShares}
                onChange={async (checked) => {
                  if (checked) {
                    goToLinkAddPage(true, props.invite.id)
                  } else {
                    popupStore.addPopup(CorporatePartnerPopupId.RemoveShareAll, undefined, {
                      visionOrganisationName: props.invite.visionOrgName,
                      onConfirm: () => {
                        cancelShareAll()
                      }
                    })
                  }
                }} />
            </Tooltip>
          </Row>
        </ConfigProvider>
      </Column>
    </Row>
    {
      props.linkedEnterprises && props.linkedEnterprises.length > 0 ? <>
        {props.linkedEnterprises.map(linkedEnterprise => {
          return <LinkedEnterpriseRow
            key={linkedEnterprise.id}
            invite={props.invite}
            linkedEnterprise={linkedEnterprise}
            shareAllIsDisabled={canDisableShares}
            shareAllRequiredUntil={props.shareAll?.requiredUntil ?? undefined}
            />
        })}
        <Row style={{
          width: '100%',
          justifyContent: 'center',
          marginTop: '20px'
        }}>
          {activeShareCount < props.propertyCount ?
            shareSelectedButton()
            : <BodyText style={{ textAlign: 'center' }}>You've shared data from all your enterprises with {props.invite.visionOrgName}</BodyText>
          }</Row>
      </> : <>
        <BodyText style={{ textAlign: 'center' }}>No Enterprise Data shared with {props.invite.visionOrgName}</BodyText>
        <Row style={{
          width: '100%',
          justifyContent: 'center',
          marginTop: '20px'
        }}>
          <Row style={{
            width: '60%',
            columnGap: '20px'
          }}>
            {props.propertyCount > 0 ? shareSelectedButton() : undefined}
            {!props.shareAll ?
              <SmallButton
                colorScheme="outline"
                onClick={() => goToLinkAddPage(true, props.invite.id)}
                height="30px"
              >
                Share all current & future enterprises
              </SmallButton> : undefined
            }
          </Row>
        </Row>
      </>
    }
  </Card>
}
