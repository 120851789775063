import { TextFormField, NumberFormField, DropdownFormField, RadioBoxFormField } from "../../../models/form/form_fields/basic_form_fields";
import { ConcatMethod, FieldSize, NumberType, State } from "../../../models/form/form_fields/form_field";
import { ReportDairyProductionSystemInputsInformation, ReportDairyProductionSystemInputsForm, ReportDairyLivestock, ReportDairyProduction, ReportManureManagementForm, ReportManureManagement, ReportTrees, ReportDairyTransport, DairyStockSales, ManureTypes, AdditionalProductionSystemInputs, DairyProductionSystemInputsForm } from "../../../models/report";

import { PercentageSplitFormField } from "../../../models/form/form_fields/percentage_split_form_field";
import { DairyClasses, OtherNFertilisersClasses, dairyProductionSystems, truckTypes } from "../../../models/form_options";
import { ReportFormPageId, inputPercent, outputPercent } from "../../../utilities/forms";
import { RepeatableArrayFieldWrapper } from "@/models/form/form_fields/form_field_wrappers";

import { ReportFormPage, ReportPageMultipleForms } from "../../../models/form/form_page";
import { SeasonalInputFormField } from "@/models/form/form_fields/seasonal_input_fields";
import { consumables, supplementaryFeed, trees } from "./shared";
import { CountryCode } from "@/utilities/countries";
import { Report, Property } from "@/models/property_models";
import { MultiInputFormField } from "@/models/form/form_fields/multi_input_form_field";
import ManureManagementPrefillButton from "@/components/buttons/manure_management_prefill_button";
import MilkCalculatorButton from "@/components/buttons/milk_calculator";
import { DairyProductionSystemType } from "@/models/property_models";
import { SeparatorField } from "@/models/form/form_fields/decoration_form_fields";
import LiveweightGainCalculator from "@/components/buttons/liveweight_gain_calculator"

export function completeDairyFormSet (property: Property, report: Report, existingTrees: ReportTrees[] | undefined):(ReportFormPage<any> | ReportPageMultipleForms)[] {
  return [
    consumables(CountryCode.Australia),
    dairyLivestock(report.financialYear),
    manureManagement(property),
    dairyProductionSystemInputs(property.dairyProductionSystem!),
    dairyTransport,
    supplementaryFeed(),
    trees(property.region, property.geom, existingTrees ?? [], report.id, property.country),
    dairyProduction(report.dairyCattleClassInformation)
  ]
}

function dairyLivestock (reportFinancialYear: number): ReportFormPage<ReportDairyLivestock> {
  return {
    id: ReportFormPageId.DairyLivestock,
    title: "Dairy Cattle",
    repeatableConfig: {
      repeatableSectionName: "Cattle Class",
      summaryScreenCountName: "Dairy Cattle Classes",
      repeatableSectionCallback: (formData: Partial<ReportDairyLivestock>) => {
        const cattleClass = DairyClasses.find(c => c.value === formData.classId)
        const ccName = cattleClass ? cattleClass?.name : "Cattle Class"
        return `${ccName}${'name' in formData && formData.name ? ' - ' + formData.name : ''}`
      },
      minimumRequired: 1
    },
    image: "dairy.png",
    formData: {
      fields: {
        classId: new DropdownFormField({
            required: true,
            label: 'Cattle Class',
            placeholder: "Cattle class",
            options: DairyClasses,
            concatMethod: ConcatMethod.StringList,
            size: FieldSize.Half
        }),
        name: new TextFormField({
            label: "Mob Name",
            size: FieldSize.Half,
            required: false
        }),
        headcount: new SeasonalInputFormField({
            title: 'Average headcount for season',
            numberType: NumberType.Int,
            financialYear: reportFinancialYear
        }),
        liveweight: new SeasonalInputFormField({
            title: 'Average liveweight for season',
            numberType: NumberType.Float,
            allUnits: 'kg',
            financialYear: reportFinancialYear,
            singleValidator: (value) => value !== undefined && value <= 1500
        }),
        liveweightGain: new SeasonalInputFormField({
            title: 'Average daily liveweight gain',
            numberType: NumberType.Float,
            allUnits: 'kg/hd/day',
            financialYear: reportFinancialYear,
            singleValidator: (value) => value !== undefined && value <= 3,
            additionalAction: (state: State<ReportDairyLivestock>) => {
              return <LiveweightGainCalculator state={state} />
            }
        }),
        crudeProteinIntake: new SeasonalInputFormField({
          title: 'Crude Protein Intake',
          numberType: NumberType.Float,
          allUnits: '%',
          financialYear: reportFinancialYear,
          singleValidator: (value) => value !== undefined && value >= 5 && value <= 25,
        }),
        dryMatterDigestability: new SeasonalInputFormField({
          title: 'Dry Matter Digestability',
          numberType: NumberType.Float,
          allUnits: '%',
          singleValidator: (value) => value !== undefined && value >= 50 && value <= 90,
          financialYear: reportFinancialYear
        })
    }
    }
  }
}

export const manureProportionLabels: {key: ManureTypes, label: string}[] = [
  { key: "Pasture", label: "Pasture" },
  { key: "Lagoon", label: "Anaerobic Lagoon" },
  { key: "SumpDispersal", label: "Sump & Dispersal" },
  { key: "DrainToPaddock", label: "Drain to Paddocks" },
  { key: "SolidStorage", label: "Solid Storage" },
];

export function manureManagement (property: Property): ReportFormPage<ReportManureManagementForm, ReportManureManagement> {
  return {
    id: ReportFormPageId.ManureManagement,
    title: "Manure Management",
    image: "resources.png",
    formData: {
      fields: {
        milkingCows: new PercentageSplitFormField({
          title: "Milking Cows",
          options: manureProportionLabels,
          concatMethod: ConcatMethod.Average,
          units: '',
          columnWidth: FieldSize.Fifth,
          additionalAction: (state) => {
            return <ManureManagementPrefillButton stateId={property.stateId} state={state} />
          },
          required: true
        }),
        otherCows: new PercentageSplitFormField({
          title: "Other Cows",
          options: manureProportionLabels,
          concatMethod: ConcatMethod.Average,
          units: '',
          columnWidth: FieldSize.Fifth,
          required: true
        })
      },
      transformer: {
        in: (value) => ({
          milkingCows: {
            Pasture: inputPercent(value.milkingPasture) ?? undefined,
            Lagoon: inputPercent(value.milkingLagoon) ?? undefined,
            SumpDispersal: inputPercent(value.milkingSumpDispersal) ?? undefined,
            DrainToPaddock: inputPercent(value.milkingDrainToPaddock) ?? undefined,
            SolidStorage: inputPercent(value.milkingSolidStorage) ?? undefined
          },
          otherCows: {
            Pasture: inputPercent(value.otherPasture) ?? undefined,
            Lagoon: inputPercent(value.otherLagoon) ?? undefined,
            SumpDispersal: inputPercent(value.otherSumpDispersal) ?? undefined,
            DrainToPaddock: inputPercent(value.otherDrainToPaddock) ?? undefined,
            SolidStorage: inputPercent(value.otherSolidStorage) ?? undefined
          }
        }),
        out: (value) => ({
          milkingPasture: outputPercent(value.milkingCows.Pasture),
          milkingLagoon: outputPercent(value.milkingCows.Lagoon),
          milkingSumpDispersal: outputPercent(value.milkingCows.SumpDispersal),
          milkingDrainToPaddock: outputPercent(value.milkingCows.DrainToPaddock),
          milkingSolidStorage: outputPercent(value.milkingCows.SolidStorage),
          otherPasture: outputPercent(value.otherCows.Pasture),
          otherLagoon: outputPercent(value.otherCows.Lagoon),
          otherSumpDispersal: outputPercent(value.otherCows.SumpDispersal),
          otherDrainToPaddock: outputPercent(value.otherCows.DrainToPaddock),
          otherSolidStorage: outputPercent(value.otherCows.SolidStorage),
        })
      }
    }
  }
}

const productionSystemInputs = [
  { key: "area", label: "Area", unit: 'Ha' },
  { key: "nFertiliser", label: "N Fertiliser", unit:' kg N/ha' },
  { key: "urea", label: "Urea", unit:' Tonnes' },
];

function getProductionSystemInputDataByKey(inputData: ReportDairyProductionSystemInputsInformation, systemKey: DairyProductionSystemType): DairyProductionSystemInputsForm {
  if (systemKey === 'irrigated-crop') {
    return {
      area: inputData.irrigated?.areaCropped ?? undefined,
      nFertiliser: inputData?.irrigated?.nFertiliserCrops ?? undefined,
      urea: inputData.irrigated?.ureaFertiliserCrops ?? undefined
    }
  } else if (systemKey === 'irrigated-pasture') {
    return {
      area: inputData.irrigated?.areaImprovedPasture ?? undefined,
      nFertiliser: inputData.irrigated?.nFertiliserPasture ?? undefined,
      urea: inputData.irrigated?.ureaFertiliserPasture ?? undefined
    }
  } else if (systemKey === 'non-irrigated-pasture') {
    return {
      area: inputData.dryland?.areaImprovedPasture ?? undefined,
      nFertiliser: inputData.dryland?.nFertiliserPasture ?? undefined,
      urea: inputData.dryland?.ureaFertiliserPasture ?? undefined
    }
  }
  return {
    area: inputData.dryland?.areaCropped ?? undefined,
    nFertiliser: inputData.dryland?.nFertiliserCrops ?? undefined,
    urea: inputData.dryland?.ureaFertiliserCrops ?? undefined
  }
}

function getProductionSystemInfoIfDataExists (inputData: ReportDairyProductionSystemInputsInformation, otherProductionSystemOptions: typeof dairyProductionSystems): AdditionalProductionSystemInputs[] {
  const out: AdditionalProductionSystemInputs[] = []
  otherProductionSystemOptions.forEach((s) => {
    if (s.value === "irrigated-pasture" && inputData.irrigated?.areaImprovedPasture) {
      out.push({
        systemId: "irrigated-pasture",
        data: getProductionSystemInputDataByKey(inputData, "irrigated-pasture")
      })
    } else if (s.value === "irrigated-crop" && inputData.irrigated?.areaCropped) {
      out.push({
        systemId: "irrigated-crop",
        data: getProductionSystemInputDataByKey(inputData, "irrigated-crop")
      })
    } else if (s.value === "non-irrigated-crop" && inputData.dryland?.areaCropped) {
      out.push({
        systemId: "non-irrigated-crop",
        data: getProductionSystemInputDataByKey(inputData, "non-irrigated-crop")
      })
    } else if (s.value === "non-irrigated-pasture" && inputData.dryland?.areaImprovedPasture) {
      out.push({
        systemId: "non-irrigated-pasture",
        data: getProductionSystemInputDataByKey(inputData, "non-irrigated-pasture")
      })
    }
  })
  return out
}

function getProductionSystemValueFromFormData(formData: ReportDairyProductionSystemInputsForm, systemKey: DairyProductionSystemType, primaryProductionSystem: DairyProductionSystemType): Partial<DairyProductionSystemInputsForm> {
  if (primaryProductionSystem === systemKey) {
    return formData.primaryProductionSystemInputs
  }
  const otherRecord = formData.otherProductionSystemInputs.find(i => i.systemId === systemKey)
  if (otherRecord) return otherRecord.data
  return {
    area: undefined,
    nFertiliser: undefined,
    urea: undefined
  }
}

function dairyProductionSystemInputs (primaryProductionSystem: DairyProductionSystemType): ReportFormPage<ReportDairyProductionSystemInputsForm, ReportDairyProductionSystemInputsInformation> {
  const otherProductionSystemOptions = dairyProductionSystems.filter(o => o.value !== primaryProductionSystem)
  const primaryProductionSystemOption = dairyProductionSystems.find(o => o.value === primaryProductionSystem)

  return {
    id: ReportFormPageId.DairyProductionSystemInputs,
    title: "Production System Inputs",
    image: "pasture.png",
    formData: {
      fields: {
        limestone: new NumberFormField({
          required: true,
          label: "Limestone",
          size: FieldSize.Half,
          unit: "Tonnes",
          validator: (value) => value !== undefined && value >= 0,
        }),
        singleSuperphosphate: new NumberFormField({
          required: true,
          label: "Single Superphosphate",
          size: FieldSize.Half,
          unit: "Tonnes",
          validator: (value) => value !== undefined && value >= 0,
        }),
        herbicide: new NumberFormField({
          required: true,
          label: "Herbicides (Paraquat, Diquat, Glyphosate)",
          size: FieldSize.Half,
          unit: "Litres",
          validator: (value) => value !== undefined && value >= 0,
        }),
        herbicidePesticide: new NumberFormField({
          required: true,
          label: "Herbicides & Pesticides",
          size: FieldSize.Half,
          unit: "Litres",
          validator: (value) => value !== undefined && value >= 0,
        }),
        sep: new SeparatorField(),
        primaryProductionSystemInputs: new MultiInputFormField({
          title: `Primary Production System - ${primaryProductionSystemOption?.name}`,
          options: productionSystemInputs,
          useLabelsAbove: true,
          required: true
        }),
        otherProductionSystemInputs: new RepeatableArrayFieldWrapper({
          useSeperatorAbove: false,
          repeatableConfig: {
            maximumAllowed: otherProductionSystemOptions.length,
            repeatableSectionName: 'Additional Production System',
            repeatableSectionCallback: (formData: Partial<AdditionalProductionSystemInputs>) => {
              if (!formData.systemId) return 'Additional Production System'
              const system = otherProductionSystemOptions.find(c => c.value === formData.systemId)
              return `Additional Production System - ${system ? system.name : ''}`
            }
          },
          data: {
            fields: {
              systemId: new DropdownFormField({
                required: true,
                label: 'Production System Type',
                placeholder: "Production System Type",
                options: (state: State<AdditionalProductionSystemInputs>) => {
                  const usedOptions = state.getAll?.filter((_ga, idx) => idx !== state.index).map(system => system.systemId).filter(c => c !== undefined)
                  if (!usedOptions) return otherProductionSystemOptions
                  return otherProductionSystemOptions.filter(ao => usedOptions.find(c => c === ao.value) === undefined)
                },
                concatMethod: ConcatMethod.StringList,
              }),
              data: new MultiInputFormField({
                options: productionSystemInputs,
                useLabelsAbove: true,
                required: true
              })
            }
          }
        }),
        otherNFertilisers: new RepeatableArrayFieldWrapper({
          label: 'Other Nitrogen Purchased',
          repeatableConfig: {
            repeatableSectionName: 'Other Nitrogen Purchase'
          },
          data: {
            fields: {
              type: new DropdownFormField({
                required: true,
                label: 'N Fertiliser',
                placeholder: "N Fertiliser",
                options: OtherNFertilisersClasses,
                concatMethod: ConcatMethod.StringList,
                size: FieldSize.Half
            }),
            amountUsed: new NumberFormField({
              required: true,
              label: 'Amount Used',
              unit: 'Tonnes',
              size: FieldSize.Half,
            })
            }
          }
        })
      },
      transformer: {
        in: (value) => ({
          ...value,
          primaryProductionSystemInputs: getProductionSystemInputDataByKey(value, primaryProductionSystem),
          otherProductionSystemInputs: getProductionSystemInfoIfDataExists(value, otherProductionSystemOptions)
        }),
        out: (value) => {
          const drylandPasture = getProductionSystemValueFromFormData(value, 'non-irrigated-pasture', primaryProductionSystem)
          const drylandCrop = getProductionSystemValueFromFormData(value, 'non-irrigated-crop', primaryProductionSystem)
          const irrigatedPasture = getProductionSystemValueFromFormData(value, 'irrigated-pasture', primaryProductionSystem)
          const irrigatedCrops = getProductionSystemValueFromFormData(value, 'irrigated-crop', primaryProductionSystem)
          return {
            ...value,
            otherNFertilisers: value.otherNFertilisers ?? [],
            irrigated: {
              areaCropped: irrigatedCrops && irrigatedCrops.area ? irrigatedCrops.area : 0,
              areaImprovedPasture: irrigatedPasture && irrigatedPasture.area ? irrigatedPasture.area : 0,
              nFertiliserCrops: irrigatedCrops && irrigatedCrops.nFertiliser ? irrigatedCrops.nFertiliser : 0,
              nFertiliserPasture: irrigatedPasture && irrigatedPasture.nFertiliser ? irrigatedPasture.nFertiliser : 0,
              ureaFertiliserCrops: irrigatedCrops && irrigatedCrops.urea ? irrigatedCrops.urea : 0,
              ureaFertiliserPasture: irrigatedPasture && irrigatedPasture.urea ? irrigatedPasture.urea : 0
            },
            dryland: {
              areaCropped: drylandCrop && drylandCrop.area ? drylandCrop.area : 0,
              areaImprovedPasture: drylandPasture && drylandPasture.area ? drylandPasture.area : 0,
              nFertiliserCrops: drylandCrop && drylandCrop.nFertiliser ? drylandCrop.nFertiliser : 0,
              nFertiliserPasture: drylandPasture && drylandPasture.nFertiliser ? drylandPasture.nFertiliser : 0,
              ureaFertiliserCrops: drylandCrop && drylandCrop.urea ? drylandCrop.urea : 0,
              ureaFertiliserPasture: drylandPasture && drylandPasture.urea  ? drylandPasture.urea : 0
            }
          }
        }
      }
    }
  }
}

export const dairyTransport: ReportFormPage<ReportDairyTransport> = {
  id: ReportFormPageId.DairyTransport,
  title: "Cattle Transport",
  description: "For transport of cattle between farms of the same business (not to market)",
  repeatableConfig: { repeatableSectionName: "Cattle Transportation" },
  image: "cattle-purchases.png",
  formData: {
      fields: {
          distance: new NumberFormField({
              required: true,
              label: "Distance transported",
              unit: "Km",
              concatMethod: ConcatMethod.Average,
              size: FieldSize.Half,
          }),
          truckType: new DropdownFormField({
              required: true,
              label: "Type of truck used",
              options: truckTypes,
              size: FieldSize.Half,
          }),
      },
  },
};

function dairyProduction(dairyCattleClassInformation: ReportDairyLivestock[] | undefined): ReportFormPage<ReportDairyProduction> {
  const availableOptions = DairyClasses.filter(dc => dairyCattleClassInformation?.find(c => c.classId === dc.value) !== undefined)
  availableOptions.push({
    value: 7,
    name: 'Calves'
  })
  const dairyClass = dairyCattleClassInformation?.find(dc => dc.classId === 1)

  return {
  id: ReportFormPageId.DairyProduction,
  title: "Production",
  image: "processing-production.png",
  formData: {
      fields: {
        milkProduction: new SeasonalInputFormField({
            title: 'Milk Production',
            allUnits: 'l/hd/day',
            singleValidator: (value) => value !== undefined && value <= 50,
            additionalAction: (state) => {
              return <MilkCalculatorButton state={state} dairyHeadcount={dairyClass?.headcount ?? {spring: 0, summer: 0, autumn: 0, winter: 0}} />
          },
        }),
        sales: new RepeatableArrayFieldWrapper({
          label: 'Stock Sales',
          repeatableConfig: {
            repeatableSectionName: 'Stock Sale',
            repeatableSectionCallback: (formData: Partial<ReportDairyLivestock>) => {
              if (!formData.classId) return 'Stock Sale'
              const cattleClass = availableOptions.find(c => c.value === formData.classId)
              return `Stock Sale - ${cattleClass ? cattleClass.name : ''}`
            }
          },
          data: {
            fields: {
              classId: new DropdownFormField({
                required: true,
                label: 'Cattle Class',
                options: (state: State<DairyStockSales>) => {
                  const usedClassIds = state.getAll?.filter((_ga, idx) => idx !== state.index).map(c => c.classId).filter(c => c !== undefined)
                  if (!usedClassIds) return availableOptions
                  return availableOptions.filter(ao => usedClassIds.find(c => c === ao.value) === undefined)
                },
                concatMethod: ConcatMethod.StringList,
                size: FieldSize.Third
              }),
              numberSold: new NumberFormField({
                required: true,
                label: 'Number Sold',
                size: FieldSize.Third
              }),
              avgLiveweight: new NumberFormField({
                required: true,
                label: 'Avg Liveweight at Sale',
                unit: 'kg/hd',
                size: FieldSize.Third
              }),
              stillWeaningAtSale: new RadioBoxFormField({
                label: 'Still weaning at time of sale?',
                required: false,
                hide: (state: State<DairyStockSales>) => {
                  if (state.get.classId !== 7) return true
                  return false
                },
                options: [
                  {
                    name: "False",
                    value: false
                  },
                  {
                    name: "True",
                    value: true
                  }
                ]
              })
            }
          }
        })
      },
      transformer: {
        in: value => value,
        // TODO: db constraint is that sales is non-null - empty array if no sales, is there a better long-term fix?
        out: value => ({ ...value, sales: value.sales || [] })
      }
    }
  }
}
