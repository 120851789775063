import type { LayerProps }  from 'react-map-gl/maplibre';
import { Source, Layer }  from 'react-map-gl/maplibre';
import { ReactElement } from 'react';
import { VectorTileLayerOptions } from '../layer_config/styling_options';

export default function createVectorTileLayer(tileUrl: string, layerOptions: VectorTileLayerOptions): ReactElement {

  const lineLayerStyle: LayerProps = {
    id: layerOptions.lineLayerId,
    source: layerOptions.dataSourceId,
    "source-layer": layerOptions.vtLayerId,
    type: 'line',
    minzoom: layerOptions.minZoom ?? 0,
    paint: {
      'line-color': layerOptions.lineColor,
      'line-width': layerOptions.lineWidth ?? 1.5,
      'line-opacity': layerOptions.lineOpacity ?? 1
    }
  };

  const fillLayerStyle: LayerProps = {
    id: layerOptions.fillLayerId,
    source: layerOptions.dataSourceId,
    "source-layer": layerOptions.vtLayerId,
    type: 'fill',
    minzoom: layerOptions.minZoom ?? 0,
    paint: {
      'fill-color': layerOptions.fillColor,
      'fill-opacity': layerOptions.fillOpacity ?? 0.25
    }
  }

  return (
    <Source id={layerOptions.dataSourceId} key={`${layerOptions.dataSourceId}-lyr`} type="vector" tiles={[tileUrl]} promoteId={layerOptions.promoteId}>
      <Layer {...fillLayerStyle} />
      <Layer {...lineLayerStyle} />
    </Source>
  )
}