import { LinkedFarmDTO, CreateLinkedFarmsDTO, LinkShareAllDTO, CreateLinkShareAll, VisionOrganisationBasicDTO } from '../models/vision';
import { VisionInvitation } from '../models/vision_invitation'
import { baseUrl, get, post, deleteHttp } from './api_service';
import { getTokens } from './auth_service';

/**
 * Return a list of the Vision Organisations that a user can share data with
 * @returns a promise resolving to a list of vision organisations
 */
export async function getVisionInvitations(): Promise<VisionInvitation[] | undefined> {
  const response = await get(`${baseUrl}/link_invitations`, getTokens().token);
  return response;
}

/**
 * Return a Vision Organisation based on an ID
 * @returns a promise resolving a vision organisations
 */
export async function getVisionOrg(id: string): Promise<VisionOrganisationBasicDTO | undefined> {
  const response = await get(`${baseUrl}/vision_organisations_public/${id}`);
  return response;
}

/**
 * Return a list of the Pending Or Accepted Farm Linkages that a user has initiated
 * @returns a promise resolving to a list of the farm linkages
 */
export async function getPendingOrAcceptedFarmLinkages(): Promise<LinkedFarmDTO[] | undefined> {
  const response = await get(`${baseUrl}/linked_farms`, getTokens().token);
  return response;
}

/**
 * Cancels a Pending Or Accepted Farm Linkages
 * @returns a promise
 */
export async function cancelFarmLinkage(linkageId: string): Promise<LinkedFarmDTO | undefined> {
  const response = await deleteHttp(`${baseUrl}/linked_farms/${linkageId}`, getTokens().token);
  return response;
}

/**
 * Creates a Pending Farm Linkages
 * @returns a promise
 */
export async function createFarmLinkage(body: CreateLinkedFarmsDTO): Promise<LinkedFarmDTO[] | undefined> {
  const response = await post(`${baseUrl}/linked_farms`, body, getTokens().token);
  return response;
}

/**
 * Return a list of existing Share All Arrangements
 */
export async function getShareAll(): Promise<LinkShareAllDTO[] | undefined> {
  const response = await get(`${baseUrl}/share_all`, getTokens().token);
  return response;
}

/**
 * Creates a new Share All Arrangements
 */
export async function createShareAll(createShareAll: CreateLinkShareAll): Promise<LinkShareAllDTO | Error | undefined> {
  try {
    const response: LinkShareAllDTO = await post(`${baseUrl}/share_all`, createShareAll, getTokens().token);
    return response;    
  } catch (error) {
    return error as Error
  }
}

/**
 * Deletes a Share All Arrangements
 */
export async function deleteShareAll(shareAllId: string): Promise<LinkShareAllDTO | undefined> {
  const response = await deleteHttp(`${baseUrl}/share_all/${shareAllId}`, getTokens().token);
  return response;
}