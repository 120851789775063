import { useState, useEffect, PropsWithChildren } from 'react';
import LoadingPlaceholder from '../components/loading_placeholder';
import Screen from '../screens/screen'
import { getAgriWebbFarms } from "../services/agriwebb_service";
import { useIntegrationsStore } from "../state/integrations";

/**
 * Gets the users agriwebb farms if they have not been loaded 
 */
export default function AgriWebbFarmsGuard({
    children,
}: PropsWithChildren<unknown>) {
    const integrationsStore = useIntegrationsStore()
    const [loadingAgriwebb, setLoadingAgriwebb] = useState(false)

    // Fetch the agriwebb farms if not loaded yet
    useEffect(() => {
        if (integrationsStore.agriWebbFarms === undefined) {
            if (integrationsStore.integrations?.agriwebb && !loadingAgriwebb) {
                setLoadingAgriwebb(true)
                getAgriWebbFarms()
                    .then((farms) => {
                        if (farms) integrationsStore.setAgriWebbFarms(farms)
                        else integrationsStore.setAgriWebbFarms([])
                    })
                    .catch(() => integrationsStore.setAgriWebbFarms([]))
            } else {
                integrationsStore.setAgriWebbFarms([])
            }
        }
    }, [integrationsStore, loadingAgriwebb])


    if (integrationsStore.agriWebbFarms === undefined) {
        return <Screen>
            <LoadingPlaceholder />
        </Screen>
    } else {
        return children
    }
}
