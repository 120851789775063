import { flatten } from "lodash-es"
import { FormData, FormState } from "../../../../models/form/form";
import { getCurrentFinancialYear } from "../../../../utilities/dates";
import { InitiativePopUp } from "../initiative_popup";
import { ManageDairyLivestockProduction, ManageDairyLivestockProductionForm } from "../../../../models/reduction_plans_models";
import { Report } from "../../../../models/property_models";
import { RelativeChangeOfAbsoluteValueSliderFormField } from "../../../../models/form/form_fields/basic_form_fields";
import { getYearField, getDefaultReductionPlanYearOptions, addExistingReductionPlanYearsToOptions } from "../initiative_helpers";
import { FieldSize } from "../../../../models/form/form_fields/form_field";
import { calculateAverageOfArray } from "@/utilities/maths";
import { ReportDairyLivestock } from "@/models/report";
import { InfoCardText } from "@/components/card";
import { outputPercent } from "@/utilities/forms";

function getAllHeadcountValues (livestockClasses: ReportDairyLivestock[]): number[] {
  return flatten(livestockClasses?.map(mc => [mc.headcount.spring, mc.headcount.summer, mc.headcount.autumn, mc.headcount.winter]))
}

export default function ManageDairyLivestockProductionPopUp(props: {
    initialData?: ManageDairyLivestockProduction;
    compareReport?: Report;
    onSubmit: (data: FormState) => Promise<void>
}) {
    const yearOptions = getDefaultReductionPlanYearOptions()
    const existingReductionPlanYears = [
      props.initialData?.dairyProduction?.targetYear,
    ].filter(v => v !== undefined) as number[]
    addExistingReductionPlanYearsToOptions(existingReductionPlanYears, yearOptions)

    const milkingCows = props.compareReport?.dairyCattleClassInformation?.filter(c => c.classId === 1)
    const avgHeadcountMilkingCows = calculateAverageOfArray(getAllHeadcountValues(milkingCows ?? []))
    const avgMilkProduced = props.compareReport?.dairyProductionInformation?.milkProduction ? calculateAverageOfArray([
      props.compareReport?.dairyProductionInformation?.milkProduction.spring,
      props.compareReport?.dairyProductionInformation?.milkProduction.summer,
      props.compareReport?.dairyProductionInformation?.milkProduction.autumn,
      props.compareReport?.dairyProductionInformation?.milkProduction.winter,
    ]) : 0

    const initialData: ManageDairyLivestockProduction = props.initialData ?? {
      dairyProduction: {
        dairyHeadcountChange: 0,
        milkProductionChange: 0,
        targetYear: getCurrentFinancialYear()
      }
    }

    const formData: FormData<ManageDairyLivestockProductionForm, ManageDairyLivestockProduction> = {
        fields: {
          dairyHeadcountChange: new RelativeChangeOfAbsoluteValueSliderFormField({
            required: true,
            sliderProps: {
              initialValue: initialData.dairyProduction.dairyHeadcountChange ?? 0,
              defaultRange: {min: 0, max: 50000},
              prevReportValue: avgHeadcountMilkingCows,
              label: 'Headcount Change for Milking Cows',
              unitLabel: 'Headcount',
            }
          }),
          milkProductionChange: new RelativeChangeOfAbsoluteValueSliderFormField({
            required: true,
            sliderProps: {
              defaultRange: { min: 0, max: 50 },
              initialValue: initialData.dairyProduction.milkProductionChange ?? 0,
              prevReportValue: avgMilkProduced,
              label: 'Milk Production',
              unitLabel: 'Litres'
            }
          }),
          targetYear: getYearField('Year of completion', FieldSize.Full, yearOptions)
        },
        transformer: {
          in: (value) => {
            return {
              ...value.dairyProduction
            }
          },
          out: (value) => {
            return {
              dairyProduction: {
                ...value,
                dairyHeadcountChange: value.dairyHeadcountChange ? outputPercent(value.dairyHeadcountChange) : 0,
                milkProductionChange: value.milkProductionChange ? outputPercent(value.milkProductionChange) : 0
              }
            }
          },
        }
    };
    return (
        <>
        <InitiativePopUp
            formData={formData}
            initialFormValues={initialData}
            onSubmit={(state) => props.onSubmit(state)}
            infoAbove={
              <>
                <InfoCardText>Use the sliders below to increase or decrease your Milking Cow headcount or Milk Production. <u>The Relative Change from your previously completed report is applied to each season.</u></InfoCardText>
                <InfoCardText style={{marginTop: '8px'}}><strong>Note:</strong> Changes will likely be seen against your Total Emissions as well as your Emissions Intensity.</InfoCardText>
              </>}
        />
    </>);
}
