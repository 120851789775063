import { FormData } from "../../../../models/form/form";
import { getCurrentFinancialYear } from "../../../../utilities/dates";
import { InitiativePopUp } from "../initiative_popup";
import { ManageConsumablesDTO } from "../../../../models/reduction_plans_models";
import { Report } from "../../../../models/property_models";
import { PecentageSliderFormField, SliderFormField } from "../../../../models/form/form_fields/basic_form_fields";
import { getYearField, getDefaultReductionPlanYearOptions, addExistingReductionPlanYearsToOptions } from "../initiative_helpers";
import { FieldSize } from "../../../../models/form/form_fields/form_field";
import { inputPercent, outputPercent } from "@/utilities/forms";

interface ConsumablesFormData {
    petrolDiesel: number;
    petrolDieselTargetYear: number;
    energyUsage: number;
    energyUsageTargetYear: number;
    renewableEnergyUsage: number;
    renewableEnergyUsageTargetYear: number;
}

export default function ManageConsumablesPopup(props: {
    initialData?: ManageConsumablesDTO;
    compareReport?: Report;
    onSubmit: (data: ManageConsumablesDTO) => Promise<void>;
}) {
    const yearOptions = getDefaultReductionPlanYearOptions()

    const existingReductionPlanYears = [
        props.initialData?.petrolDiesel?.targetYear,
        props.initialData?.energyUsage?.targetYear,
        props.initialData?.renewableUsage?.targetYear,
    ].filter(v => v !== undefined) as number[]
    addExistingReductionPlanYearsToOptions(existingReductionPlanYears, yearOptions)

    const petrolDiesel = props.compareReport?.consumablesInformation ? (props.compareReport?.consumablesInformation?.petrol + props.compareReport?.consumablesInformation?.diesel) : undefined

    const initialData: ManageConsumablesDTO = props.initialData ?? {
        petrolDiesel: {
            targetValue: petrolDiesel ?? 0,
            targetYear: getCurrentFinancialYear()
        },
        energyUsage: {
            targetValue: props.compareReport?.consumablesInformation?.electricityUsage ?? 0,
            targetYear: getCurrentFinancialYear()
        },
        renewableUsage: {
            targetValue: props.compareReport?.consumablesInformation?.percentRenewables ?? 0,
            targetYear: getCurrentFinancialYear()
        }
    }

    const formData: FormData<ConsumablesFormData, ManageConsumablesDTO> = {
        fields: {
            petrolDiesel: new SliderFormField({
                required: true,
                sliderProps: {
                    defaultRange: { min: 0, max: 10000 },
                    prevReportValue: petrolDiesel,
                    initialValue: initialData.petrolDiesel?.targetValue ?? 0,
                    unitLabel: "Litres",
                    label: "Petrol & Diesel (L/year)",
                },
            }),
            petrolDieselTargetYear: getYearField('Year of completion', FieldSize.Full, yearOptions),
            energyUsage: new SliderFormField({
                required: true,
                sliderProps: {
                    defaultRange: { min: 0, max: 10000 },
                    prevReportValue: props.compareReport?.consumablesInformation?.electricityUsage,
                    initialValue: initialData.energyUsage?.targetValue ?? 0,
                    unitLabel: "kwh",
                    label: "Energy Usage (kwh/year)",
                },
            }),
            energyUsageTargetYear: getYearField('Year of completion', FieldSize.Full, yearOptions),
            renewableEnergyUsage: new PecentageSliderFormField({
                required: true,
                sliderProps: {
                    prevReportValue: inputPercent(props?.compareReport?.consumablesInformation?.percentRenewables) ?? 0,
                    initialValue: inputPercent(initialData.renewableUsage?.targetValue ?? props?.compareReport?.consumablesInformation?.percentRenewables ?? 0) as number,
                    label: "Renewable Energy Usage (% per year)",
                }
            }),
            renewableEnergyUsageTargetYear: getYearField('Year of completion', FieldSize.Full, yearOptions),
        },
        transformer: {
            in: (value) => ({
                petrolDiesel: value.petrolDiesel?.targetValue,
                petrolDieselTargetYear: value.petrolDiesel?.targetYear,
                energyUsage: value.energyUsage?.targetValue,
                energyUsageTargetYear: value.energyUsage?.targetYear,
                renewableEnergyUsage: inputPercent(value.renewableUsage?.targetValue),
                renewableEnergyUsageTargetYear: value.renewableUsage?.targetYear
            }),
            out: (value) => ({
                petrolDiesel: {
                    targetValue: value.petrolDiesel,
                    targetYear: value.petrolDieselTargetYear
                },
                energyUsage: {
                    targetValue: value.energyUsage,
                    targetYear: value.energyUsageTargetYear
                },
                renewableUsage: {
                    targetValue: outputPercent(value.renewableEnergyUsage),
                    targetYear: value.renewableEnergyUsageTargetYear
                }
            })
        }
    };
    return (
        <InitiativePopUp
            formData={formData}
            initialFormValues={initialData}
            onSubmit={(state) => props.onSubmit(state)}
        />
    );
}
