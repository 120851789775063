import { create } from 'zustand';
import { Property, Report } from '../models/property_models';
import { Initiative, ReductionPlan } from '../models/reduction_plans_models';
import { ReportTrees } from '../models/report'
import omit from 'lodash-es/omit'

interface DatabaseState {
    reports: Report[] | undefined;
    properties: Property[] | undefined;
    reductionPlans: { [farmId: string]: ReductionPlan[] } | undefined;
    availableInitiatives: { [farmId: string]: Initiative[] } | undefined;
    trees: {
        [farmId: string]: ReportTrees[]
    };

    setReports: (r: Report[]) => void;
    updateReport: (r: Report) => void;
    newReport: (r: Report) => void;
    removeReportById: (reportId: string) => void;

    setProperties: (p: Property[]) => void;
    updateProperty: (p: Property) => void;
    removePropertyById: (farmId: string) => void;

    addTrees: (farmId: string, t: ReportTrees[]) => void;
    removeTreesForPropertyId: (farmId: string) => void;

    setReductionPlans: (reductionPlans: ReductionPlan[], farmId: string) => void;
    updateReductionPlan: (reductionPlan: ReductionPlan, farmId: string) => void;
    deleteReductionPlan: (planId: string, farmId: string) => void;

    setAvailableInitiatives: (initiatives: Initiative[], farmId: string) => void;

    clear: () => void;
}

/**
 * Stores non-form and non-auth data from the database. This mainly consists of
 * property and report data.
 */
export const useDatabaseStore = create<DatabaseState>((set) => ({
    reports: undefined,
    properties: undefined,
    reductionPlans: undefined,
    availableInitiatives: undefined,
    trees: {},

    setReports: (reports: Report[]) => set({ reports }),
    updateReport: (report: Report) => set((state) => ({
        reports: [
            ...(state.reports ?? []).filter((r) => r.id !== report.id),
            report
        ]
    })),
    removeReportById: (reportId: string) => set((state) => ({
        reports: [
            ...(state.reports ?? []).filter((r) => r.id !== reportId)
        ]
    })),
    // Overwrites any existing reports
    newReport: (report: Report) => set((state) => ({
        reports: [
            ...(state.reports ?? []).filter((r) => !(r.propertyId === report.propertyId && r.financialYear === report.financialYear)),
            report
        ]
    })),

    setProperties: (properties: Property[]) => set({ properties: properties }),
    updateProperty: (property: Property) => set((state) => ({
        properties: [
            ...(state.properties ?? []).filter((p) => p.id !== property.id),
            property
        ]
    })),
    removePropertyById: (farmId: string) => set((state) => ({
        properties: [
            ...(state.properties ?? []).filter((p) => p.id !== farmId)
        ]
    })),
    setReductionPlans: (plans: ReductionPlan[], farmId: string) =>
        set((state) => ({
            reductionPlans: {
                ...state.reductionPlans,
                [farmId]: plans
            }
        })),

    updateReductionPlan: (plan: ReductionPlan, farmId: string) =>
        set((state) => ({
            reductionPlans: {
                ...state.reductionPlans,
                [farmId]: [
                    ...(state.reductionPlans ? state.reductionPlans[farmId] : []).filter((p) => p.id !== plan.id),
                    plan
                ]
            }
        })),

    deleteReductionPlan: (planId: string, farmId: string) => set((state) => ({
        reductionPlans: {
            ...state.reductionPlans,
            [farmId]: (state.reductionPlans ? state.reductionPlans[farmId] : []).filter((p) => p.id !== planId)
        }
    })),

    setAvailableInitiatives: (initiatives, farmId) => set((state) => ({
        availableInitiatives: {
            ...state.availableInitiatives,
            [farmId]: initiatives
        }
    })),

    addTrees: (farmId: string, treeInfo: ReportTrees[]) => set((state) => ({
        trees: {
            ...state.trees,
            [farmId]: treeInfo
        }
    })),

    removeTreesForPropertyId: (farmId: string) => set((state) => omit(state, [`trees.${farmId}`]), true),

    clear: () => set({ reports: undefined, properties: undefined, reductionPlans: undefined })
})
);