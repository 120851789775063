import { LngLat } from "mapbox-gl";
import { Subscription } from "./subscription_model";
import { CountryCode } from "../utilities/countries";

export interface LoginDTO {
    email: string;
    password: string;
}

export interface SignUpDTO {
    firstName: string;
    lastName: string;
    email: string;
    company: string | undefined;
    password: string;
    country: CountryCode;
    confirmPassword: string;
    signupCode?: string;
    shareAllVisionOrgId?: string
    voucherPoolId?: string
    promoCode?: string
}

/**
 * A response returned by authentication requests
 */
export class AuthResponseDTO {
    authToken: string;
    refreshToken: string;
    expiry: number;
    profile: UserProfileDTO | undefined;

    constructor(
        authToken: string,
        refreshToken: string,
        expiry: number,
        profile: UserProfileDTO | null
    ) {
        this.authToken = authToken;
        this.refreshToken = refreshToken;
        this.expiry = expiry;
        this.profile = profile ?? undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static fromJSON(json: any): AuthResponseDTO | null {
        // AuthResponseDTO has to have authToken
        if (!("token" in json)) {
            return null;
        }

        const _expiry = parseInt(json["expiresIn"]);

        return new AuthResponseDTO(
            json["token"],
            json["refreshToken"],
            Number.isNaN(_expiry) ? 0 : _expiry,
            UserProfileDTO.fromJSON(json["profile"])
        );
    }
}

export type UserPreferences = {
    notifications?: {
        dismissedCorporatePartners?: boolean,
        dismissedEnterpriseGetStarted?: boolean
    }
}

export class UserProfileDTO {
    uid: string;
    firstName: string;
    lastName: string;
    email: string;
    company: string | undefined;
    emailVerified: boolean;
    subscription: Subscription;
    country: CountryCode | null;
    userPreferences?: UserPreferences


    constructor(
        uid: string,
        firstName: string,
        lastName: string,
        email: string,
        company: string | undefined,
        emailVerified: boolean,
        subscription: Subscription,
        country: CountryCode | null,
        userPreferences?: UserPreferences
    ) {
        this.uid = uid;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.company = company;
        this.emailVerified = emailVerified;
        this.subscription = subscription;
        this.country = country;
        this.userPreferences = userPreferences
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static fromJSON(profileJson: any): UserProfileDTO | null {
        if (!profileJson || !("id" in profileJson)) {
            return null;
        }

        return new UserProfileDTO(
            profileJson["id"],
            profileJson["firstName"],
            profileJson["lastName"],
            profileJson["email"],
            profileJson["company"],
            !!profileJson["emailVerified"],
            profileJson["subscription"],
            profileJson["country"],
            profileJson["userPreferences"]
        );
    }
}

export type PropertyDTO = {
    address: string;
    propertyId: string;
    name: string;
    cattle: boolean;
    sheep: boolean;
    grain: boolean;
    bounds: LngLat[][];
};

export class ExceptionDTO {
    code: string;
    message: string;
    data: string[] | undefined;

    constructor(code: string, message: string, data: string[] | undefined) {
        this.code = code;
        this.message = message;
        this.data = data;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static fromJSON(json: any): ExceptionDTO {
        return new ExceptionDTO(
            json["code"] ?? "500",
            json["message"] ?? "UNKNOWN_ERROR",
            json["data"] ?? []
        );
    }
}
