import styled from "styled-components";
import type { MapRef } from 'react-map-gl';
import { FeatureCollection } from "geojson";
import { Component } from 'react';

import { ruminatiColors } from "../../utilities/colors";
import Icon from "../icon";
import { BaseText } from "../styled_text";
import DrawingTools from '../../libs/drawing-tools/index.js'
import '../../libs/drawing-tools/index.css'

import { withHooksHOC } from '../maps/map_hoc'
import MapFooter from "../maps/map_footer";
import MainButton from "../buttons/main_button";

export enum EditBoundsTools {
    Move,
    Draw,
    Erase,
    Clear,
    Undo,
    None
}

type EditBoundsMenuProps = {
    map: MapRef;
    geojsonToEdit: FeatureCollection;
    selectedTool: EditBoundsTools;
    setTool: (tool: EditBoundsTools) => void;
    finishEditing: (fc: FeatureCollection) => void;
    drawingColor?: string;
    drawnColor?: string;
    footerText?: string;
    footerBtnText?: string;
};

type EditBoundsMenuState = {
    drawControl: undefined | DrawingTools
    mapHeight: number
};

class EditBoundsMenu extends Component<EditBoundsMenuProps, EditBoundsMenuState> {

    state: EditBoundsMenuState = {
        drawControl: undefined,
        mapHeight: 500
    }

    componentDidMount(): void {
        // TO DO - This is being called multiple times but I'm not sure why ....
        if (this.mapIsMountedAndReadyForDrawingTools(this.props.map)) {
            this.setupDrawControl()
        }
    }

    componentDidUpdate(prevProps: EditBoundsMenuProps) {
        if (this.mapIsMountedAndReadyForDrawingTools(this.props.map) && (!this.mapIsMountedAndReadyForDrawingTools(prevProps.map) || this.state.drawControl === undefined)) {
            if (this.props.map.isStyleLoaded()) {
                this.setupDrawControl()
            } else {
                this.props.map.on('load', () => {
                    this.setupDrawControl()
                })
            }
        }
    }

    mapIsMountedAndReadyForDrawingTools(map: MapRef | undefined): boolean {
        if (map === undefined) return false
        return true
    }

    setupDrawControl() {
        // It's important to make sure we only create a drawing tools once
        if (this.props.map.getSource('drawing-tools-drawn-polygon-src') === undefined) {
            const drawControl = new DrawingTools({
                drawControls: ['polygon'],
                updateModes: ['move', 'delete'],
                showToolbar: false,
                showHelpText: false,
                styles: {
                  drawingColor: this.props.drawingColor ?? "#A8C8DA",
                  drawnColor: this.props.drawnColor ?? "#FFAD73"
                }
            })

            this.props.map.addControl(drawControl, 'top-left')
            drawControl.addGeojson(this.props.geojsonToEdit)
            this.setState({
                drawControl,
                mapHeight: this.props.map.getCanvas().clientHeight
            })
        }
    }

    startDrawPolygon() {
        if (this.state.drawControl === undefined) return

        this.props.setTool(EditBoundsTools.Draw)
        this.state.drawControl.setActiveMode('polygon')
        this.state.drawControl.on('dt:draw-finished', () => {
            this.props.setTool(EditBoundsTools.None)
        })
    }

    startMovePolygon() {
        if (this.state.drawControl === undefined) return

        this.props.setTool(EditBoundsTools.Move)
        this.state.drawControl.setActiveMode('move')
        this.state.drawControl.on('dt:update-finished', () => {
            this.props.setTool(EditBoundsTools.None)
        })
    }

    startErasePolygon() {
        if (this.state.drawControl === undefined) return
        const currentFc = this.state.drawControl.getAll()
        this.props.setTool(EditBoundsTools.Erase)
        if (currentFc.features.length === 0) {
            this.props.setTool(EditBoundsTools.None)
            return
        }
        this.state.drawControl.setActiveMode('delete')
        this.state.drawControl.on('dt:update-finished', () => {
            this.props.setTool(EditBoundsTools.None)
        })
    }

    clearAllPolygon() {
        if (this.state.drawControl === undefined) return

        this.state.drawControl.deleteAll()
        this.props.map.removeControl(this.state.drawControl)
        this.setState({
            drawControl: undefined,
            mapHeight: this.props.map.getCanvas().clientHeight
        })
    }

    finishDrawingEdits() {
        if (this.state.drawControl === undefined) return

        this.props.finishEditing(this.state.drawControl.getAll())
        this.clearAllPolygon()
    }

    isDrawingEditsFinished() {
        if (this.props.selectedTool === EditBoundsTools.Draw 
            || this.state.drawControl === undefined 
            || this.state.drawControl.getAll().features.length === 0) return false
        return true
    }

    render() {
        const footerText = this.props.footerText ? this.props.footerText : 'Use the tools above to edit your boundary manually.'
        const footerBtnText = this.props.footerBtnText ? this.props.footerBtnText : 'Finish Editing'

        return <div>
            <OverlayWrapper mapHeight={this.state.mapHeight}>
                <EditBoundsWrapper>
                    <EditBoundsButton
                        className={
                            this.props.selectedTool === EditBoundsTools.Move ? "active" : ""
                        }
                        onClick={this.startMovePolygon.bind(this)}
                    >
                        <Icon icon="mouse" />
                        <BaseText>Move</BaseText>
                    </EditBoundsButton>

                    <EditBoundsButton
                        className={
                            this.props.selectedTool === EditBoundsTools.Draw ? "active" : ""
                        }
                        onClick={this.startDrawPolygon.bind(this)}
                    >
                        <Icon icon="draw" />
                        <BaseText>Draw</BaseText>
                    </EditBoundsButton>

                    <EditBoundsButton
                        onClick={this.startErasePolygon.bind(this)}
                        className={
                            this.props.selectedTool === EditBoundsTools.Erase ? "active" : ""
                        }
                    >
                        <Icon icon="connectedPoints" />
                        <BaseText>Erase</BaseText>
                    </EditBoundsButton>
                    <EditBoundsButton onClick={this.clearAllPolygon.bind(this)}>
                        <Icon icon="circleMinus" />
                        <BaseText>Clear All</BaseText>
                    </EditBoundsButton>

                </EditBoundsWrapper>
            </OverlayWrapper>

            <MapFooter footer={
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <BaseText
                        style={{
                            color: ruminatiColors.bone,
                            padding: "15px",
                            textAlign: "center",
                        }}
                    >
                        {footerText}
                    </BaseText>
                    <MainButton
                        size="small"
                        colorScheme="orange"
                        onClick={this.finishDrawingEdits.bind(this)}
                        disabled={!this.isDrawingEditsFinished()}
                    >
                        {footerBtnText}
                    </MainButton>
                </div>
            } />
        </div>
    }
}

export default withHooksHOC(EditBoundsMenu)


const OverlayWrapper = styled.div<{mapHeight: number}>`
    top: ${props => (props.mapHeight === 500 ? `65px` : `16px`)};
    left: 50%;
    transform: translateX(-50%);
    
    min-width: 414px;
    padding: 16px 0 0 0;
    justifycontent: center;
    position: absolute;
    zindex: 10;
`;

const EditBoundsWrapper = styled.div`
    border: 1px solid ${ruminatiColors.dark_green};
    background-color: ${ruminatiColors.green_3};
    border-radius: 8px;
    display: flex;
`;

const EditBoundsButton = styled.div`
    border-right: 1px solid ${ruminatiColors.dark_green};
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 4px 16px 4px 8px;

    color: white;
    transition: 0.3s ease;
    cursor: pointer;
    user-select: none;

    svg path {
        fill: white;
        transition: 0.2s ease;
    }

    &:hover {
        color: ${ruminatiColors.light_orange};
        svg path {
            fill: ${ruminatiColors.light_orange};
        }
    }

    &.active {
        color: ${ruminatiColors.orange};

        svg path {
            fill: ${ruminatiColors.orange};
        }
    }

    &:last-child {
        border-right: none;
    }
`;
