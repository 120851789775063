import { Accept, useDropzone } from 'react-dropzone';
import Icon from "../components/icon";
import { Column, Row } from "../components/styled_layout";
import { ParagraphText } from "../components/styled_text";
import OutlineButton from "../components/buttons/outline_button";
import { ruminatiColors } from '../utilities/colors';

export enum ParsingStep {
  NotYetStarted,
  Successful,
  Error,
}

type FileDropProps = {
  parsingStep: ParsingStep;
  accept: Accept;
  notYetStartedText: string;
  successText: string;
  errorText: string;
  handleDroppedFile: (file: File) => void;
  onRetry: () => void;
};

/**
 * A text box in the green Ruminati style
 * @param title optional. The text appearing in the left section.
 * @param subtitle optional. The text appearing in the right section. If not
 * given, the Header Tag will only have the left section
 */
export default function FileDrop(props: FileDropProps) {

  const {getRootProps, getInputProps, open} = useDropzone({
    onDrop: files =>{if(files && files[0]) processedDroppedFile(files[0])},
    multiple: false,
    noClick: true,
    accept: props.accept
  });

  async function processedDroppedFile (file: File) {
    props.handleDroppedFile(file)
  }

  return (
    <Row style={{ width: '100%' }}>
      <Column style={{ width: '100%' }}>

        {props.parsingStep === ParsingStep.NotYetStarted &&
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <Row style={{ marginTop: '10px' }}>
              <Icon icon="upload" />
            </Row>
            <ParagraphText style={{ fontSize: '14px' }}>{props.notYetStartedText}</ParagraphText>

            <Row style={{ marginTop: '10px' }}>
              <OutlineButton
                fontSize='14px'
                width='100px'
                height='40px'
                activeColor={ruminatiColors.dark_green}
                onClick={open}
              >
                Browse
              </OutlineButton>
            </Row>
          </div>
        }
        {props.parsingStep === ParsingStep.Successful &&
          <Row style={{ marginTop: '10px' }} className='dropzone dropzone-success'>
            <Icon icon="tick" />
            <ParagraphText style={{ fontSize: '16px', marginLeft: '10px' }}>{props.successText}</ParagraphText>
          </Row>
        }

        {props.parsingStep === ParsingStep.Error &&
          <Row style={{ marginTop: '10px' }} className='dropzone dropzone-error'>
            <Column style={{ width: '46%' }}>
              <Row style={{ marginTop: '10px' }}>
                <ParagraphText style={{ fontSize: '16px' }}>{props.errorText}</ParagraphText>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <OutlineButton onClick={() => props.onRetry()} width={'180px'}>
                  Retry
                </OutlineButton>
              </Row>
            </Column>
          </Row>
        }
      </Column>
    </Row>
  );
}
