import { useNavigate, useParams } from "react-router-dom";
import { EmissionCardHeader } from "./emission_card_header";
import { useDatabaseStore } from "../../state/database_store";
import { formatFinancialYearStringToShortPrettyFinancialYearString, getCurrentFinancialYear } from "../../utilities/dates";
import { getReportOfYear } from "../../utilities/reports";
import { Column, Row } from "../../components/styled_layout";
import { HeadlineFigureText } from "../../components/styled_text";
import { formatReportNumber } from "../../utilities/functions";
import EmissionUnit from "../../models/emission-unit";
import { Card, EmissionUnitText } from "./farm";
import { Report } from "../../models/property_models";
import { EmptyCardContent } from "./empty_card_content";

export function TotalEmissionCard({ report }: { report?: Report }) {
    const isEmpty =
        report === undefined || report?.results?.totals.total === undefined;

    const header = (
        <EmissionCardHeader
            title={'Total Net Emissions'}
            year={isEmpty ? undefined : report?.financialYear}
        />
    );

    const { propertyId } = useParams();

    const navigate = useNavigate();

    const goToReportPage = (id: string) => navigate(`/summary/${id}`)

    const goToCreateReportPage = () => navigate(`/farm/${propertyId}/create-report?year=${previousFY}`)

    const reports: Report[] | undefined = useDatabaseStore().reports?.filter(
        (r) => r.propertyId === propertyId
    );
    const previousFY = getCurrentFinancialYear() - 1;

    const previousYearReport = getReportOfYear(reports ?? [], previousFY);

    const body = isEmpty ? (
        <EmptyCardContent
            text={`${formatFinancialYearStringToShortPrettyFinancialYearString(previousFY.toString())} Emissions Report is in progress`}
            buttonText="Complete Now"
            buttonOnClick={previousYearReport ? () => goToReportPage(previousYearReport.id) : goToCreateReportPage}
        />
    ) : (
        <Column
            style={{
                width: "100%",
                alignItems: "start",
                gap: "8px",
            }}
        >
            <Row style={{ alignItems: "end", gap: "8px" }}>
                {" "}
                <HeadlineFigureText>
                    {report?.results?.totals.net !== undefined
                        ? formatReportNumber(report.results?.totals.net as number)
                        : "N/A"}
                </HeadlineFigureText>
                <EmissionUnitText>{EmissionUnit.Total}</EmissionUnitText>
            </Row>
        </Column>
    );

    return <Card header={header} body={body} id="totalEmissionsCard"/>
}