import styled from "styled-components";
import { ruminatiColors } from "../utilities/colors";

export enum TableRowType {
    Header,
    Subheader,
    PrimaryData,
    SecondaryData,
}

interface TableRow {
    type: TableRowType,
    data: (string|JSX.Element)[]
}

export type SimpleTableProps = {
    rows: TableRow[];
    rowHeight?: number;
    id?: string
};
/**
 * A visual table to display 2D array data.
 */
export default function SimpleTable(props: SimpleTableProps) {
    const backgroundColour = (rowType: TableRowType) => {
        switch (rowType) {
            case TableRowType.Header:
                return ruminatiColors.green_3
            case TableRowType.Subheader:
                return ruminatiColors.light_green
            case TableRowType.PrimaryData:
            case TableRowType.SecondaryData:
                return ruminatiColors.bone_0
        }
    }

    const textColour = (rowType: TableRowType) => {
        switch (rowType) {
            case TableRowType.Header:
            case TableRowType.Subheader:
                return ruminatiColors.bone
            case TableRowType.PrimaryData:
                return ruminatiColors.green_3
            case TableRowType.SecondaryData:
                return ruminatiColors.green_3_50
        }
    }

    return (
        <SimpleTableWrapper id={props.id}>
            <tbody style={{ width: "100%" }}>
                {/**
                 * Show each row
                 */}
                {props.rows.map((row, index) => {
                    return (
                        <SimpleTableRow
                            key={`row-${index}`}
                            style={{
                                borderRadius: `${index === 0 ? 8 : 0}px ${index === 0 ? 8 : 0}px ${index === props.rows.length - 1 ? 8 : 0}px ${index === props.rows.length - 1 ? 8 : 0}px`,
                                backgroundColor: backgroundColour(row.type),
                                color: textColour(row.type),
                                height: props.rowHeight ?? 48
                            }}
                        >
                            {/**
                             * Show each cell inside each row
                             */}
                            {row.data.map((value, cellIndex) => {
                                return (
                                    <SimpleTableCell
                                        key={`cell-${cellIndex}`}
                                        style={{
                                            flex: cellIndex === 0 ? "5 1 0" : "1 1 0",
                                            textAlign: cellIndex === 0 ? "left" : "right",
                                            ...row.type === TableRowType.SecondaryData && cellIndex === 0 ? { paddingLeft: "32px" } : {}
                                        }}
                                    >
                                        {value}
                                    </SimpleTableCell>
                                );
                            })}
                        </SimpleTableRow>
                    );
                })}
            </tbody>
        </SimpleTableWrapper>
    );
}

const SimpleTableWrapper = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

const SimpleTableRow = styled.tr`
    display: flex;
    width: 100%;
    border-left: 1px solid ${ruminatiColors.green_3_50};
    border-right: 1px solid ${ruminatiColors.green_3_50};
    border-bottom: 1px solid ${ruminatiColors.green_3_50};
    color: ${ruminatiColors.green_3};
    align-items: center;
`;

const SimpleTableCell = styled.td`
    padding: 8px 16px;
`;
