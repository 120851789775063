export const ruminatiImages = {
    enterpriseFarm: "/images/enterprise-farm.png",
    enterpriseFeedlot: "/images/enterprise-feedlot.png",
    enterpriseProcessor: "/images/enterprise-processor.png",
    enterpriseDairy: "/images/dairy.png",
    cattlePurchases: "/images/cattle-purchases.png",
    consumables: "/images/consumables.png",
    feeding: "/images/feeding.png",
    grains: "/images/grains.png",
    livestock: "/images/livestock.png",
    pasture: "/images/pasture.png",
    resources: "/images/resources.png",
    sheepPurchases: "/images/sheep-purchases.png",
    supplementaryFeed: "/images/supplementary-feed.png",
    trees: "/images/trees.png",
    plantTrees: "/images/plant-trees.png",
    allowRevegetation: "/images/allow-revegetation.png",
    improveSoilCarbon: "/images/improve-soil-carbon.png",
    feedlotCohorts: "/images/feedlot-cohorts.png",
    processingEmissions: "/images/processing-emissions.png",
    processingProduction: "/images/processing-production.png",
    rations: "/images/rations.png",
    refrigerants: "/images/refrigerants.png",
    purchasedGoods: "/images/resources.png"
};

