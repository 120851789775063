import styled from "styled-components";
import { ruminatiColors } from "../utilities/colors";

import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from "@tanstack/react-table";

/**
 * Props required by {@link Table}.
 *
 * @param data an array of objects of any given generic type `T`.
 * @param headers an array of column headers.
 * @param customHeader optional. Custom header element that is rendered instead
 * of `headers`.
 * @param builder a function that takes a `T` object and return an array
 * containing the representations of the object's attributes in the table.
 *
 * To learn more about creating columns,
 * @see https://tanstack.com/table/v8/docs/guide/column-defs.
 */
type TableProps<T> = {
    data: T[];
    headers?: JSX.Element[];
    customHeader?: JSX.Element;
    footer?: JSX.Element;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder: (element: T) => any[];
    screen?: string;
    id?: string
};

/**
 * Optional styling props for {@link Table}.
 */
type TableStylingProps = {
    border: string;
    borderTop: string;
    borderRight: string;
    borderBottom: string;
    borderLeft: string;
    borderRadius: string;
    rowBorder: string;
    headerHeight: string;
    headerPadding: string;
    headerBackgroundColor: string;
    headerBorder: string;
    cellHeight: string;
    cellPadding: string;
};

/**
 * A styled, responsive table that can display any given data.
 * To use this table, see {@link TableProps<T>}.
 *
 * To learn more about creating tables,
 * @see https://tanstack.com/table/v8/docs/api/core/table.
 */
export default function Table<T>(
    props: TableProps<T> & Partial<TableStylingProps>
) {
    const objectShape = props.builder(props.data[0]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: ColumnDef<T, any>[] = [];
    for (let i = 0; i < objectShape.length; i++) {
        const header = props.headers ? props.headers[i] : <></>;
        columns.push({
            id: header ? header.toString() : `null header ${i}`,
            header: () => header,
        });
    }

    const table = useReactTable<T>({
        data: props.data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <TableContainer
            id={props.id}
            border={props.border}
            borderTop={props.borderTop}
            borderRight={props.borderRight}
            borderBottom={!props.data || props.data.length === 0 ? "none" : props.borderBottom}
            borderLeft={props.borderLeft}
            borderRadius={props.borderRadius}
        >
            {props.customHeader && (
                <TableHeader>{props.customHeader}</TableHeader>
            )}
            {!props.customHeader && (
                    <TableHeader
                        height={props.headerHeight}
                        border={props.headerBorder}
                    >
                        {table.getHeaderGroups().map((headerGroup, index) => (
                            <TableRow
                                key={`${headerGroup.id}_${index}`}
                                border={props.rowBorder}
                                style={{ height: props.headerHeight ?? "72px" }}
                            >
                                {headerGroup.headers.map((header, index) => (
                                    <HeaderCell
                                        key={`${header.id}_${index}`}
                                        color={props.headerBackgroundColor}
                                        padding={props.headerPadding}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef
                                                    .header,
                                                header.getContext()
                                            )}
                                    </HeaderCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHeader>
                )}
            <StyledTable>
                {table.getRowModel().rows.map((row, rowIndex) => (
                    <TableRow
                        key={`${row.id}_${rowIndex}`}
                    >
                        {row.getVisibleCells().map((cell, cellIndex) => (
                            <BodyCell
                                key={`${cell.id}_${cellIndex}`}
                                height={props.cellHeight}
                                padding={props.cellPadding}
                                border={rowIndex > 0 ? props.rowBorder : 'transparent'}
                                >
                                {flexRender(
                                    props.builder(props.data[rowIndex])[
                                    cellIndex
                                    ],
                                    cell.getContext()
                                )}
                            </BodyCell>
                        ))}
                    </TableRow>
                ))}
            </StyledTable>
            {props.footer && <Footer>{props.footer}</Footer>}
        </TableContainer>
    );
}

const TableContainer = styled.table.attrs(
    (props: {
        border: string;
        borderTop: string;
        borderRight: string;
        borderBottom: string;
        borderLeft: string;
        borderRadius: string;
    }) => props
)`
    border: ${(props) => props.border ?? `1px solid ${ruminatiColors.green_3}`};
    border-top: ${(props) => props.borderTop ?? "auto"};
    border-right: ${(props) => props.borderRight ?? "auto"};
    border-bottom: ${(props) => props.borderBottom ?? "auto"};
    border-left: ${(props) => props.borderLeft ?? "auto"};
    border-radius: ${(props) => props.borderRadius ?? "8px"};
    min-width: 100%;
    overflow-x: auto;
    border-collapse: separate;
`;

const StyledTable = styled.tbody`
    border-collapse: collapse;
    width: 100%;
    color: ${ruminatiColors.dark_green};
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.01em;
    line-height: 24px;
    tr:first-child {
        border: none;
    }
`;

const TableHeader = styled.thead.attrs(
    (props: { height: string; border: string, borderRadius: string }) => props
)`
    border-radius: ${(props) => props.borderRadius ?? "0px"};
    font-size: 16px;
    height: ${(props) => props.height ?? "72px"};
`;

const HeaderCell = styled.th.attrs(
    (props: { color: string; padding: string; border: string }) => props
)`
    font-weight: 500;
    padding: ${(props) => props.padding ?? "0 24px 0 24px"};
    background-color: ${(props) => props.color ?? "inherit"};
    width: 210px;
    border-bottom: ${(props) => props.border ?? `1px solid ${ruminatiColors.green_3}`};
`;


const TableRow = styled.tr.attrs((props: { border: string }) => props)`
    border-top: ${(props) => props.border ?? `1px solid ${ruminatiColors.green_3_30}`};
`;

const BodyCell = styled.td.attrs(
    (props: { height: string; padding: string; border: string }) => props
)`
    border-top: ${(props) => props.border ?? `1px solid ${ruminatiColors.green_3_30}`};
    padding: ${(props) => props.padding ?? "0 24px 0 24px"};
    height: ${(props) => props.height ?? "72px"};
`;

const Footer = styled.tfoot`
    border-top: 1px solid ${ruminatiColors.green_3_30};
`;
