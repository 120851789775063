import styled from "styled-components";
import { BodyText } from "../styled_text";
import { ruminatiColors } from "../../utilities/colors";
import {useMap} from 'react-map-gl/maplibre';
import { useState } from "react"

export default function ZoomInOverlay (props: {minZoom: number}) {
  const {myMapA} = useMap();
  const [msgIsRequired, setMsgIsRequired] = useState(false);

  myMapA?.on('load', () => {
    myMapA.on('zoom', () => {
      setMsgIsRequired(myMapA.getZoom() < props.minZoom)
    })
  })

  return (
      <ZoomInOverlayWrapper
        style={{
          visibility: msgIsRequired ? 'visible' : 'hidden',
          opacity: msgIsRequired ? 1 : 0
        }}
      >
         <BodyText><strong>Tip:</strong> Zoom in a bit closer and property boundaries will appear on the map.</BodyText>
      </ZoomInOverlayWrapper>
  )
}

const ZoomInOverlayWrapper = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 10;

    padding: 10px;
    background-color: ${ruminatiColors.bone};
    border: 1px solid ${ruminatiColors.dark_green};
    width: 225px;
    border-radius: 8px;
    transition: 0.2s;
`;