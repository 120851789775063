import { post, get, patch, deleteHttp } from "./api_service";
import {
    Initiative,
    ReductionPlansRequest,
    ReductionPlan,
} from "../models/reduction_plans_models";
import { getTokens } from "./auth_service";
import { basePropertyUrl } from "./property_service";

export async function getAvailableInitiatives(
    farmId: string
): Promise<Initiative[]> {
    const response = await get(
        `${basePropertyUrl}/${farmId}/reduction_plans/available`,
        getTokens().token
    );

    return response.map((initiative: any) => Initiative.fromJSON(initiative));
}

// GET /properties/:farmId/reduction_plans - get reduction plans for farm
export async function getReductionPlans(
    farmId: string
): Promise<ReductionPlan[]> {
    const response = await get(
        `${basePropertyUrl}/${farmId}/reduction_plans`,
        getTokens().token
    );
    return (response.reduction_plans ?? []).map((plan: any) =>
        ReductionPlan.fromJSON(plan)
    );
}

// POST /properties/:farmId/reduction_plans - create a reduction plan for farm
export async function createReductionPlan(
    farmId: string,
    data: ReductionPlansRequest<any>
): Promise<ReductionPlan | undefined> {
    const response = await post(
        `${basePropertyUrl}/${farmId}/reduction_plans`,
        data,
        getTokens().token
    );
    return ReductionPlan.fromJSON(response);
}

// PATCH /properties/:farmId/reduction_plans/:planId - update reduction plan for farm
export async function updateReductionPlan(
    farmId: string,
    planId: string,
    request: ReductionPlansRequest<any>
): Promise<ReductionPlan | undefined> {
    const response = await patch(
        `${basePropertyUrl}/${farmId}/reduction_plans/${planId}`,
        request,
        getTokens().token
    );
    return ReductionPlan.fromJSON(response);
}

// DELETE /properties/:farmId/reduction_plans/:planId - delete a reduction plan for farm
export async function deleteReductionPlan(farmId: string, planId: string) {
    await deleteHttp(
        `${basePropertyUrl}/${farmId}/reduction_plans/${planId}`,
        getTokens().token
    );
}
