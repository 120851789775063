import MainButton from "../../components/buttons/main_button";
import Heading from "../../components/heading";
import { CardContainer } from "../../components/styled_container";
import { Column, Row } from "../../components/styled_layout";
import { ruminatiColors } from "../../utilities/colors";
import { CardBodyText } from "./farm";

export function EmptyEmissionReportCard({
    year,
    buttonOnClick,
}: {
    year: number;
    buttonOnClick: () => void;
}) {
    return (
        <CardContainer
            style={{ marginBottom: "8px", justifyContent: "center" }}
            padding={"28px 24px"}
        >
            <Row style={{ width: "100%", alignItems: "center" }}>
                <Column style={{ width: "20%", alignItems: "start" }}>
                    <Heading level={4}>
                        FY {year}
                    </Heading>{" "}
                </Column>
                <div style={{ width: "10%" }} />
                <Column
                    style={{
                        width: "70%",
                        alignItems: "end",
                    }}
                >
                    <Row style={{ alignItems: "center" }}>
                        <CardBodyText style={{ color: ruminatiColors.orange }}>
                            It’s the end of the financial year. Update your
                            Emission Report now.
                        </CardBodyText>
                        <div style={{ width: "16px" }} />

                        <MainButton
                            colorScheme="orange"
                            size="small"
                            onClick={buttonOnClick}
                        >
                            Update
                        </MainButton>
                    </Row>
                </Column>
            </Row>
        </CardContainer>
    );
}