import { FeatureCollection, MultiPolygon } from 'geojson'
import { ReactElement } from 'react';
import type { LayerProps }  from 'react-map-gl/maplibre';
import { Source, Layer }  from 'react-map-gl/maplibre';

import { LayerOptions } from '../layer_config/styling_options';
import { createEmptyFeatureCollection, createFeatureCollectionFromMultiPolygons } from '../../../utilities/geojson-helpers';

export default function createGeojsonLayer(geojson: FeatureCollection | undefined | MultiPolygon[], layerOptions: LayerOptions): ReactElement {
  let fc: FeatureCollection = createEmptyFeatureCollection()

  if (
    geojson !== undefined &&
    !Array.isArray(geojson) &&
    geojson.type === 'FeatureCollection'
  ) {
    fc = geojson as FeatureCollection
  } else if (Array.isArray(geojson)) {
    fc = createFeatureCollectionFromMultiPolygons(geojson)
  }

  const lineLayerStyle: LayerProps = {
    id: layerOptions.lineLayerId,
    type: 'line',
    paint: {
      'line-color': layerOptions.lineColor,
      'line-width': layerOptions.lineWidth ?? 1.5,
      'line-opacity': layerOptions.lineOpacity ?? 1
    }
  };

  const fillLayerStyle: LayerProps = {
    id: layerOptions.fillLayerId,
    type: 'fill',
    paint: {
      'fill-color': layerOptions.fillColor,
      'fill-opacity': layerOptions.fillOpacity ?? 0.25
    }
  }

  return (
    <Source id={layerOptions.dataSourceId} key={`${layerOptions.dataSourceId}-lyr`} type="geojson" data={fc} promoteId={layerOptions.promoteId}>
      <Layer {...fillLayerStyle} />
      <Layer {...lineLayerStyle} />
    </Source>
  )
}