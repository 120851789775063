import xssFilters from 'xss-filters'
import AbsoluteRangeSlider, { AbsoluteSliderProps } from "@/components/sliders/AbsoluteRangeSlider";
import { SuggestionField } from "@/components/form/suggestion_field";
import CheckBoxGroup from "../../../components/form/check_box";
import { DropdownField, TextField } from "../../../components/form/input_field";
import LabelledValue from "../../../components/form/labelled_value";
import RadioBox from "../../../components/form/radio_box";
import { displayFloat, numberCharacterValidator, parseNumberString } from "../../../utilities/numbers";
import { DropdownOptionData, FieldSize, InputFormField, InputFormFieldArgs, NumberType, State, evaluate, TypeOrFunctionOfState } from "./form_field";
import PercentageSlider, { PercentageSliderProps } from "@/components/sliders/PercentageSlider";
import RelativeChangeSlider, { RelativeSliderProps } from "@/components/sliders/RelativeChangeSlider";
import RelativeChangeOfAbsoluteValueSlider, { RelativeChangeOfAbsoluteValueProps } from "@/components/sliders/RelativeChangeOfAbsoluteValueSlider";

export class TextFormField extends InputFormField<string> {
    _useCleanStringValidator: boolean;

    constructor (args: InputFormFieldArgs<string> & {
        useCleanStringValidator?: boolean
    }) {
        super(args)
        this._useCleanStringValidator = args.useCleanStringValidator ?? true
    }

    transform(value?: string): string | undefined {
        return value;
    }

    validate(value: string | undefined, state: State<any>): boolean {
        // Uses the same logic as the CleanStringValidator on the server
        if (value && this._useCleanStringValidator) {
            const DISALLOWED_SYMBOLS = [";", "="]
            if (DISALLOWED_SYMBOLS.some(sym => value.includes(sym))) {
                return false
            }
        
            const clean = xssFilters.inHTMLData(value)
            if (clean !== value) return false
        }

        return super.validate(value, state);

    }
}

export class EmailFormField extends TextFormField {
    validate(value: string | undefined, state: State<any>): boolean {
        const validEmail = String(value)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );

        return super.validate(value, state) && !!validEmail;
    }
}

export class NumberFormField extends InputFormField<number> {
    type: NumberType;
    _danglingZeroes: number | undefined; // The number of zeroes at the end of the decimal
    _hadDanglingDecimal: boolean;

    constructor(args: InputFormFieldArgs<number> & { type?: NumberType }) {
        super(args);
        this._placeholder = args.placeholder ?? 'eg 0'
        this.type = args.type ?? NumberType.Float;
        this._hadDanglingDecimal = false;
    }

    displayValue = (value?: number) => {
        if (value === undefined) return undefined
        if (Number.isNaN(value)) return undefined

        return displayFloat(value, this._danglingZeroes);
    }

    validate(value: number | undefined, state: State<any>): boolean {
        return (
            super.validate(value, state) && !Number.isNaN(value) && value! >= 0
        );
    }

    transform(value?: string): number | undefined {
        if (value === undefined) {
            return undefined;
        }
        const { num, danglingZeroes } = parseNumberString(value, this.type);
        this._danglingZeroes = danglingZeroes;
        return num;
    }

    render(
        id: string,
        onChange: (value?: number) => void,
        state: State<any>,
        value?: number,
        error?: boolean,
        size?: "large" | "small"
    ): JSX.Element {
        return (
            <TextField
                id={id}
                value={this.displayValue(value)}
                error={error}
                onChange={(value) => onChange(this.transform(value))}
                characterValidator={numberCharacterValidator(this.type)}
                label={this.label(state)}
                placeholder={this.placeholder(state)}
                units={this.units(state)}
                tip={this.tip}
                width={FieldSize.toWidth(this.size)}
                size={size}
                obscure={this.obscured}
                footerElement={this.children(state)}
                number
            />
        );
    }
}

type SelectFormFieldArgs<T> = InputFormFieldArgs<T> & {
    options: TypeOrFunctionOfState<DropdownOptionData<T>[]>;
}

export abstract class SelectFormField<
    T extends string | number | boolean
> extends InputFormField<T> {
    options: TypeOrFunctionOfState<DropdownOptionData<T>[]>;
    
    constructor(args: SelectFormFieldArgs<T>) {
        super(args);
        this.options = args.options;
    }

    _options(state: State<any>): DropdownOptionData<T>[] {
        return evaluate(this.options, state);
    }

    render(
        _id: string,
        _onChange: (value?: T) => void,
        _state: State<any>,
        _value?: T,
        _error?: boolean,
        _size?: "large" | "small"
    ): JSX.Element {
        throw Error("Unimplemented");
    }
}

export class DropdownFormField<
    T extends string | number
> extends SelectFormField<T> {
    useSuggestion: boolean
    constructor(
        args: InputFormFieldArgs<T> & { 
            options: TypeOrFunctionOfState<DropdownOptionData<T>[]>
            useSuggestion?: boolean
        }
    ) {
        super(args);
        this.useSuggestion = args.useSuggestion ?? false
    }

    render(
        id: string,
        onChange: (value?: T) => void,
        state: State<any>,
        value?: T,
        error?: boolean,
        size?: "large" | "small"
    ): JSX.Element {
        return (
            this.useSuggestion ? <SuggestionField 
            value={value}
            suggestions={this._options(state)}
            label={this.label(state)}
            onChange={(v) => onChange(v as T)}
            />
            :
            <DropdownField
                id={id}
                value={value}
                error={error}
                options={this._options(state)}
                size={size}
                label={this.label(state)}
                placeholder={this.placeholder(state)}
                tip={this.tip}
                onChange={onChange}
                disabled={this._disabled}
            />
        );
    }
}

export class SliderFormField extends InputFormField<number> {
    sliderProps: AbsoluteSliderProps;

    constructor(
        args: InputFormFieldArgs<number> & { sliderProps: AbsoluteSliderProps }
    ) {
        super(args);
        this.sliderProps = args.sliderProps;
    }

    render(
        _id: string,
        onChange: (value?: number) => void,
        _state: State<any>,
        _value?: number,
        _error?: boolean,
        _size?: "large" | "small"
    ): JSX.Element {
        return <AbsoluteRangeSlider 
          {...this.sliderProps}
          onMove={onChange} 
        />;
    }
}

export class PecentageSliderFormField extends InputFormField<number> {
    sliderProps: PercentageSliderProps

    constructor(
        args: InputFormFieldArgs<number> & { sliderProps: PercentageSliderProps }
    ) {
        super(args);
        this.sliderProps = args.sliderProps;
    }

    render(
        _id: string,
        onChange: (value?: number) => void,
        _state: State<any>,
        _value?: number,
        _error?: boolean,
        _size?: "large" | "small"
    ): JSX.Element {
        return <PercentageSlider
          {...this.sliderProps}
          onMove={(val) => {onChange(val)}}
        />;
    }
}

export class RelativeChangeSliderFormField extends InputFormField<number> {
    sliderProps: RelativeSliderProps;

    constructor(
        args: InputFormFieldArgs<number> & { 
          sliderProps: RelativeSliderProps
        }
    ) {
        super(args);
        this.sliderProps = args.sliderProps;
    }

    render(
        _id: string,
        onChange: (value?: number) => void,
        _state: State<any>,
        _value?: number,
        _error?: boolean,
        _size?: "large" | "small"
    ): JSX.Element {
        return <RelativeChangeSlider
        {...this.sliderProps}
        onMove={(val) => {
            onChange(val)
        }}
      />;
    }
}

export class RelativeChangeOfAbsoluteValueSliderFormField extends InputFormField<number> {
    sliderProps: RelativeChangeOfAbsoluteValueProps;

    constructor(
        args: InputFormFieldArgs<number> & { 
          sliderProps: RelativeChangeOfAbsoluteValueProps
        }
    ) {
        super(args);
        this.sliderProps = args.sliderProps;
    }

    render(
        _id: string,
        onChange: (value?: number) => void,
        _state: State<any>,
        _value?: number,
        _error?: boolean,
        _size?: "large" | "small"
    ): JSX.Element {
        return <RelativeChangeOfAbsoluteValueSlider
        {...this.sliderProps}
        onMove={(val) => {
            onChange(val)
        }}
      />;
    }
}

export class RadioBoxFormField<
    T extends string | number | boolean
> extends SelectFormField<T> {

    render(
        id: string,
        onChange: (value?: T) => void,
        state: State<any>,
        value?: T,
        _error?: boolean,
        size?: "large" | "small"
    ): JSX.Element {
        if (this.hide(state)) return <></>

        const initialValue = value ?? this._options(state)[0]!.value

        // Automatically select the first available option
        // and notify the parent component
        if (!value && initialValue !== value) onChange(initialValue)

        return (
            <div
                style={{
                    width: "100%",
                    fontSize: "18px",
                }}
            >
                <LabelledValue
                    label={this.label(state)}
                    fontSize={size === "small" ? "16px" : "20px"}
                >
                    <RadioBox
                        id={id}
                        name={id}
                        initialValue={initialValue}
                        options={this._options(state)}
                        size={size}
                        onChange={onChange}
                        horizontal={true}
                    />
                </LabelledValue>
            </div>
        );
    }
}

export abstract class MultiSelectFormField<
    T extends string | number
> extends InputFormField<T[]> {
    options: DropdownOptionData<T>[];

    constructor(
        args: InputFormFieldArgs<T[]> & { options: DropdownOptionData<T>[] }
    ) {
        super(args);
        this.options = args.options;
    }

    validate(value?: T[]): boolean {
        return value !== undefined && value.length > 0;
    }

    render(
        _id: string,
        _onChange: (value?: T[]) => void,
        _state: State<any>,
        _value?: T[],
        _error?: boolean,
        _size?: "large" | "small"
    ): JSX.Element {
        throw Error("Unimplemented");
    }
}

export class CheckBoxFormField<
    T extends string | number
> extends MultiSelectFormField<T> {
    _getCheckbox(
        state: State<any>,
        onChange: (value?: T[]) => void,
        value?: T[]
    ): JSX.Element {
        return (
            <CheckBoxGroup
                name={this.placeholder(state)}
                horizontal={true}
                options={this.options}
                onChange={onChange}
                initialValue={value ?? []}
            />
        );
    }

    render(
        _id: string,
        onChange: (value?: T[]) => void,
        state: State<any>,
        value?: T[],
        _error?: boolean,
        _size?: "large" | "small"
    ): JSX.Element {
        const el =
            this._placeholder.length > 0 ? (
                <LabelledValue
                    spacerMargin="0 16px 0 13px"
                    label={this.placeholder(state)}
                >
                    {this._getCheckbox(state, onChange, value)}
                </LabelledValue>
            ) : (
                this._getCheckbox(state, onChange, value)
            );

        return (
            <div style={{ paddingBottom: "10px", width: "100%" }}>
                {el}
            </div>
        );
    }
}
