import Screen from "./screen";
import styled from "styled-components";
import { Column, Row } from "./../components/styled_layout";
import Heading from "./../components/heading";
import { ruminatiColors } from "../utilities/colors";
import { ParagraphText } from "../components/styled_text";
import MainButton from "../components/buttons/main_button";
import CollapsableWrapper from "../components/collapsable_wrapper";

export const baseUrl = import.meta.env.REACT_APP_API_BASE_URL;

/**
 * The error screen, which shows the an uncaught errors
 * @returns the Error Screen component
 */
export default function ErrorScreen({ 
  error, 
  resetError 
}: {
 error: Error | null, 
 resetError: () => void
}) {

  return (
    <Screen pageTitle="Error">
        <ErrorWrapper>
          <ErrorContainer>
          <Row
              style={{
                  marginBottom: "20px",
                  alignItems: "center"
              }}
          >
              <Column style={{ width: '80%' }}>
                  <Heading level={2} color={ruminatiColors.dark_green}>
                  Whoops, you hit a bug
                  </Heading>

                  <ParagraphText style={{marginTop: '20px', marginBottom: '20px'}}>
                     Our team hav been notified so that we can investigate further 🕵️
                  </ParagraphText>

                  <ParagraphText style={{marginTop: '20px', marginBottom: '40px'}}>
                     If the error keeps occuring making Ruminati unusable for you, please contact us at hello@ruminati.com.au and we'll look into a fix for you right away.
                  </ParagraphText>

                  <MainButton 
                    size="large"
                    colorScheme={'green'} 
                    onClick={() => {
                      resetError()
                      window.location.href = window.location.origin
                    }}
                  >
                    Restart Ruminati
                  </MainButton>


                  <Row
                      style={{
                          marginTop: "60px",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "60%"
                      }}
                  >
                    <Column style={{ width: '100%' }}>
                      <CollapsableWrapper
                          id="error"
                          children={
                            <ParagraphText>
                              {error?.name}: &nbsp;
                              {error?.message}
                            </ParagraphText>
                          }
                          label={'Error Details'}
                        />
                    </Column>
                  </Row>
                  
              </Column>
            </Row>
          </ErrorContainer>
        </ErrorWrapper>
      </Screen>
  )
}

const ErrorWrapper = styled.div`
    display: flex;
    align-items: center;

    flex-direction: column;

    width: 100%;
    flex-grow: 1;

    padding-top: 72px;
`;

const ErrorContainer = styled.div`
    width: 90%;
    max-width: 936px;
    text-align: left;
    padding-bottom: 60px;
`;
