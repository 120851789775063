import { DropdownFormField, NumberFormField } from "../../../models/form/form_fields/basic_form_fields";
import { ConcatMethod, FieldSize, NumberType } from "../../../models/form/form_fields/form_field";
import { ReportFormPage } from "../../../models/form/form_page";
import { nzSheepClasses, nzCattleClasses } from "../../../models/form_options";
import { ReportLivestockNZ } from "../../../models/report";
import { ReportFormPageId } from "../../../utilities/forms";

export const sheepNZ: ReportFormPage<ReportLivestockNZ> = {
  id: ReportFormPageId.livestockInformationSheepNZ,
  title: "Sheep",
  repeatableConfig: { repeatableSectionName: "Sheep Class", },
  image: "sheep-purchases.png",
  formData: {
      fields: {
          classId: new DropdownFormField({
              required: true,
              label: "Sheep class",
              options: nzSheepClasses(),
              concatMethod: ConcatMethod.StringList,
              size: FieldSize.Half,
          }),
          headCount: new NumberFormField({
              required: true,
              label: "Number",
              type: NumberType.Int,
              concatMethod: ConcatMethod.Total,
              summaryLabel: "Total number purchased",
              tip: "The average number of animals in this class on farm for the year.",
              size: FieldSize.Half,
          }),
          annualAvgLiveweight: new NumberFormField({
              required: true,
              label: "Annual average liveweight",
              type: NumberType.Float,
              unit: 'kg',
              concatMethod: ConcatMethod.Average,
              summaryLabel: "Annual average liveweight",
              tip: "The average liveweight of all animals in this class for the year. ",
              size: FieldSize.Half,
              validator: (value) => value !== undefined && value <= 1200
          }),
          annualAvgLiveweightGain: new NumberFormField({
              required: true,
              type: NumberType.Float,
              unit: 'kg/head/day',
              concatMethod: ConcatMethod.Average,
              label: "Annual average liveweight gain",
              tip: "The average daily weight gain of animals in this class for the year.",
              validator: (value) => value !== undefined && value <= 4,
              size: FieldSize.Half,
          }),
      }
  },
};

export const cattleNZ: ReportFormPage<ReportLivestockNZ> = {
  id: ReportFormPageId.livestockInformationCattleNZ,
  title: "Cattle",
  repeatableConfig: { repeatableSectionName: "Cattle Class", },
  image: "cattle-purchases.png",
  formData: {
      fields: {
          classId: new DropdownFormField({
              required: true,
              label: "Cattle class",
              options: nzCattleClasses(),
              concatMethod: ConcatMethod.StringList,
              // tip: "-",
              size: FieldSize.Half,
          }),
          headCount: new NumberFormField({
              required: true,
              label: "Number",
              type: NumberType.Int,
              concatMethod: ConcatMethod.Total,
              summaryLabel: "Total number purchased",
              tip: "The average number of animals in this class on farm for the year.",
              size: FieldSize.Half,
          }),
          annualAvgLiveweight: new NumberFormField({
              required: true,
              label: "Annual average liveweight",
              type: NumberType.Float,
              unit: 'kg',
              concatMethod: ConcatMethod.Average,
              summaryLabel: "Annual average liveweight",
              tip: "The average liveweight of all animals in this class for the year. ",
              size: FieldSize.Half,
              validator: (value) => value !== undefined && value <= 1200
          }),
          annualAvgLiveweightGain: new NumberFormField({
              required: true,
              type: NumberType.Float,
              unit: 'kg/head/day',
              concatMethod: ConcatMethod.Average,
              label: "Annual average liveweight gain",
              tip: "The average daily weight gain of animals in this class for the year.",
              validator: (value) => value !== undefined && value <= 4,
              size: FieldSize.Half,
          }),
      }
  },
};
