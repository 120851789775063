import styled from "styled-components";

import HorizontalDivider from "../../components/horizontal_divider";
import { Column, Row } from "../../components/styled_layout";
import {
    Initiative,
    InitiativeType,
    ReductionPlan,
    ReductionPlanInputDTO,
    ReductionPlanResultDTO,
    initiativeFieldNamesMap,
    initiativeNamesMap,
} from "../../models/reduction_plans_models";
import {
    createReductionPlan,
    deleteReductionPlan,
    updateReductionPlan,
} from "../../services/reduction_plans_service";
import { ruminatiImages } from "../../utilities/images";
import { BodyText } from "../../components/styled_text";
import DisplayCard, {
    DisplayCardImageStyle,
} from "../../components/display_card";
import { ruminatiColors } from "../../utilities/colors";
import MainButton from "../../components/buttons/main_button";
import ChangeIndicator, {
    ChangeIndicatorType, getDefaultColorForChangeIndicator,
} from "../../components/change_indicator";
import { formatReportNumber } from "../../utilities/functions";
import { useDatabaseStore } from "../../state/database_store";
import LoadingButton from "../../components/buttons/loading_button";
import { Property, Report } from "../../models/property_models";
import { DisplayInitiativePopupFunction } from "./reduction_planner_screen";
import EmissionUnit from "../../models/emission-unit";
import { usePopupStore } from "../../state/popup_store";
import { DSEUnit } from "../../utilities/dse";
import { CardBodyText } from "../farm/farm";
import Icon from "@/components/icon";

const initiativeIllustrations = {
    [InitiativeType.ImproveSoilCarbon]: ruminatiImages.improveSoilCarbon,
    [InitiativeType.PlantTrees]: ruminatiImages.plantTrees,
    [InitiativeType.PlantTreesNZ]: ruminatiImages.plantTrees,
    [InitiativeType.AllowRevegetation]: ruminatiImages.allowRevegetation,
    [InitiativeType.ManageLivestock]: ruminatiImages.livestock,
    [InitiativeType.ManageLivestockClassesProduction]: ruminatiImages.livestock,
    [InitiativeType.ManageLivestockClassesProductionNZ]: ruminatiImages.livestock,
    [InitiativeType.ManagePasture]: ruminatiImages.pasture,
    [InitiativeType.ManagePasture_Post_V3_4]: ruminatiImages.pasture,
    [InitiativeType.ManageConsumables]: ruminatiImages.consumables,
    [InitiativeType.ManageSupplement]: ruminatiImages.supplementaryFeed,
    [InitiativeType.ManageCrops]: ruminatiImages.resources,
    [InitiativeType.DairyRations]: ruminatiImages.feedlotCohorts,
    [InitiativeType.DairyManure]: ruminatiImages.resources,
    [InitiativeType.DairyLivestockProduction]: ruminatiImages.enterpriseDairy,
    [InitiativeType.CattlePurchaseAverageIntensity]: ruminatiImages.cattlePurchases,
    [InitiativeType.FeedAdditives]: ruminatiImages.feeding
};

const initiativeSubtypes: Map<string, InitiativeType[]> = new Map([
    ["Soil Sequestration", [InitiativeType.ImproveSoilCarbon]],
    [
        "Vegetation Sequestration",
        [InitiativeType.PlantTrees, InitiativeType.PlantTreesNZ, InitiativeType.AllowRevegetation],
    ],
    [
        "Improve Farming Practices",
        [
            InitiativeType.ManageLivestock,
            InitiativeType.ManageLivestockClassesProduction,
            InitiativeType.ManageLivestockClassesProductionNZ,
            InitiativeType.ManagePasture,
            InitiativeType.ManagePasture_Post_V3_4,
            InitiativeType.ManageConsumables,
            InitiativeType.ManageSupplement,
            InitiativeType.ManageCrops,
            InitiativeType.DairyRations,
            InitiativeType.DairyLivestockProduction,
            InitiativeType.DairyManure,
            InitiativeType.CattlePurchaseAverageIntensity,
            InitiativeType.FeedAdditives
        ],
    ],
]);

export enum InitiativesPopupId {
    NoPreviousCrops = 'no-previous-crops'
}

export default function InitiativesPage({
    initiatives,
    reductionPlans,
    previousReport,
    property,
    displayPopup
}: {
    initiatives: Initiative[];
    reductionPlans: ReductionPlan[];
    previousReport: Report;
    property: Property;
    displayPopup: DisplayInitiativePopupFunction
}) {
    const propertyId = property.id
    const databaseStore = useDatabaseStore()

    async function _createReductionPlan(type: InitiativeType, data: ReductionPlanInputDTO): Promise<void> {
        try {
            const reductionPlan = await createReductionPlan(propertyId, {
                initiativeType: type,
                data: data,
            });
            if (reductionPlan) {
                databaseStore.updateReductionPlan(reductionPlan, reductionPlan.propertyId);
            }
        } catch (error) {
            console.error(`error creating reduction plan: ${error}`);
        }
    }

    const _updateReductionPlan = async (
        plan: ReductionPlan,
        update: ReductionPlanInputDTO
    ): Promise<void> => {
        try {
            const reductionPlan = await updateReductionPlan(
                propertyId,
                plan.id,
                {
                    initiativeType: plan.initiativeType,
                    data: update,
                }
            );
            if (reductionPlan) {
                databaseStore.updateReductionPlan(reductionPlan, reductionPlan.propertyId);
            }
        } catch (error) {
            console.error(`error updating reduction plan: ${error}`);
        }
    };

    const _deleteReductionPlan = async (plan: ReductionPlan): Promise<void> => {
        try {
            await deleteReductionPlan(plan.propertyId, plan.id);
            databaseStore.deleteReductionPlan(plan.id, plan.propertyId);
        } catch (error) {
            console.error(`error deleting reduction plan: ${error}`);
        }
    };

    const preferredLivestockUnit: DSEUnit | undefined = property.cattleFarm ? previousReport.livestockInformation?.beefUnits as DSEUnit : previousReport?.livestockInformation?.sheepUnits as DSEUnit

    return (
        <Column
            style={{
                width: "100%",
                alignItems: "center",
                marginTop: "40px",
                gap: "40px",
            }}
        >
            {[...initiativeSubtypes.keys()].map((key, index) =>
                    initiatives.filter((initiative) =>
                        initiativeSubtypes.get(key)?.includes(initiative.id)
                    ).length > 0 && (
                        <div style={{ width: "100%" }} key={`${key}_${index}`}>
                            <HorizontalDivider>
                                <DividerText>{key}</DividerText>
                            </HorizontalDivider>

                            <div style={{ height: "24px" }} />

                            <Column style={{ gap: "8px" }}>
                                {initiatives
                                    .filter((initiative) =>
                                        initiativeSubtypes
                                            .get(key)
                                            ?.includes(initiative.id)
                                    )
                                    .map((initiative) => {
                                        const plan = reductionPlans.find(
                                            (r) =>
                                                r.initiativeType ===
                                                initiative.id
                                        );
                                        return (
                                            <InitativeCard 
                                              key={initiative.id}
                                              reductionPlan={plan}
                                              initiative={initiative}
                                              previousReport={previousReport}
                                              preferredLivestockUnit={preferredLivestockUnit}
                                              onClickToInitiate={async () => {
                                                displayPopup(initiative.id, async (value) => {
                                                    await _createReductionPlan(initiative.id, value)
                                                })
                                             }}
                                             onRemoveActive={async () => {
                                                 if (plan) await _deleteReductionPlan(plan)
                                              }}
                                              onManageActive={() => {
                                                if (plan) {
                                                    displayPopup(initiative.id, async (value) => {
                                                        await _updateReductionPlan(plan, value)
                                                    }, plan.inputs)
                                                }
                                              }}
                                            />
                                        )
                                    })}
                            </Column>
                        </div>
                    )
            )}
        </Column>
    );
}

function InactiveContent({
    initiative,
    onClick,
    previousReport
}: {
    initiative: Initiative;
    onClick?: () => Promise<void>;
    previousReport: Report;
}) {
    const popupStore = usePopupStore();

    return ([
        <Row 
            key={`${initiative.id}_description`}
            style={{marginBottom: '10px'}}>
            <Column>
                <CardBodyText style={{textAlign: 'left'}}>
                    {initiative.description}
                </CardBodyText>
            </Column>
        </Row>,
        <Row
            key={`${initiative.id}_${initiative.name}`}
            style={{ justifyContent: "space-between", width: "100%" }}
        >
            <Row>
                <MainButton
                  colorScheme="orange"
                  onClick={() => {
                    if (initiative.id === InitiativeType.ManageCrops &&
                        (
                            (previousReport.algorithmVersion < 3.4 && (previousReport.grainInformation === undefined || previousReport.grainInformation.length === 0)) ||
                            (previousReport.algorithmVersion > 3.4 && (previousReport.grainInformationPostV3_3 === undefined || previousReport.grainInformationPostV3_3.length === 0))
                        )
                    ) {
                        popupStore.addPopup(InitiativesPopupId.NoPreviousCrops)
                        return
                    }
                    if (onClick) onClick()
                }}>
                    <Icon icon="add" />
                    <div style={{ width: "8.5px" }} />
                    Add Initiative
                </MainButton>
            </Row>
        </Row>
    ]);
}

function ActiveContent({
    initiative,
    reductionPlan,
    onRemove,
    onManage,
    preferredLivestockUnit
}: {
    initiative: Initiative;
    reductionPlan: ReductionPlan;
    onRemove: () => Promise<void>;
    onManage: () => void;
    preferredLivestockUnit: DSEUnit | undefined;
}) {
    let items: JSX.Element[] = [];
    if (reductionPlan.results) {
        if (reductionPlan.inputs instanceof Array) {
            items = reductionPlan.inputs.map((input: any, index) => {
                const result = (
                    reductionPlan.results as ReductionPlanResultDTO[]
                )[index];
                return (
                    <InitiativeItem
                        initiativeTypeId={initiative.id}
                        key={index}
                        name={input.name ?? initiativeNamesMap[reductionPlan.initiativeType]}
                        year={input.startYear}
                        estimatedReduction={result!.totals.net}
                    />
                );
            });
        } else {
            items = Object.keys(reductionPlan.results).map((key) => {
                const label = initiativeFieldNamesMap[key]
                if (!label) return <></>
                return (
                    <InitiativeItem
                        initiativeTypeId={initiative.id}
                        key={key}
                        name={label}
                        year={(reductionPlan.inputs as any)[key]["targetYear"]}
                        estimatedReduction={
                            (reductionPlan.results as any)[key].totals.net
                        }
                        preferredLivestockUnit={preferredLivestockUnit}
                    />
                );
            });
        }
    }

    return ([
        <Column 
            style={{ width: '100%', rowGap: "4px", marginTop: "4px" }}
            key={`${initiative.id}_items`} 
        >
            {items}
        </Column>,
        <Row 
            key={`${initiative.id}_${initiative.name}`}
            style={{
            marginTop: '16px',
            gap: '0px 8px'
        }}
        >
            <LoadingButton
                size="small"
                colorScheme="orange"
                outline={true}
                onClick={onRemove}
                prefix={<Icon icon="minus" />}
            >
                Remove
            </LoadingButton>
            <MainButton
                colorScheme="orange"
                onClick={onManage}
            >
                Manage
            </MainButton>
        </Row>
    ])
}

function InitativeCard ({
    initiative, 
    reductionPlan,
    onClickToInitiate,
    previousReport,
    onRemoveActive,
    onManageActive,
    preferredLivestockUnit
}: {
    initiative: Initiative, 
    reductionPlan: ReductionPlan | undefined
    onClickToInitiate?: () => Promise<void>;
    previousReport: Report;
    onRemoveActive: () => Promise<void>;
    onManageActive: () => void;
    preferredLivestockUnit: DSEUnit | undefined;
}) {
    const content = reductionPlan !== undefined ? 
          <ActiveContent 
            key={initiative.id}
            initiative={initiative}
            reductionPlan={reductionPlan}
            onManage={onManageActive}
            onRemove={onRemoveActive}
            preferredLivestockUnit={preferredLivestockUnit}
         /> 
        : <InactiveContent 
          key={initiative.id}
          initiative={initiative}
          onClick={onClickToInitiate}
          previousReport={previousReport}
        />
    return (
        <DisplayCard
            id={`${initiative.id}_card`}
            key={`${initiative.id}_${initiative.name}`}
            title={initiative.name}
            illustration={initiativeIllustrations[initiative.id]}
            imageStyle={
                initiative.category === 0
                    ? DisplayCardImageStyle.Fill
                    : DisplayCardImageStyle.Floating
            }
            contentArea={[content]}
        />
    );
}

type InitiativeItemProps = {
    initiativeTypeId: InitiativeType;
    name: string;
    year: string | number;
    estimatedReduction: number;
    preferredLivestockUnit?: DSEUnit | undefined;
};

function InitiativeItem(props: InitiativeItemProps) {
    const getChangeIndicatorType = (estimate: number): ChangeIndicatorType => {
        if (estimate > 0) return ChangeIndicatorType.Increase;
        if (estimate < 0) return ChangeIndicatorType.Decrease;
        return ChangeIndicatorType.Same;
    };

    let name = props.name
    if (props.initiativeTypeId === InitiativeType.ManageLivestock && props.preferredLivestockUnit === DSEUnit.AE) {
        name = props.name.replace('DSE', 'AE')
    }

    const indicator = getChangeIndicatorType(props.estimatedReduction);
    const colour = getDefaultColorForChangeIndicator(indicator)
    return (
        <Row
            style={{ 
                width: "100%", 
                justifyContent: "start", 
                whiteSpace: "nowrap",
             }}
        >
            <Row style={{ width: "60%" }} key={0}>
                <BodyText style={{ paddingBottom: "5px" }}>
                    {name}
                </BodyText>
                <Spacer />
            </Row>
            <Row style={{ width: "15%" }} key={1}>
                <BodyText>
                    FY {props.year}
                </BodyText>
                <Spacer />
            </Row>
            <Row style={{ width: "25%", justifyContent: "start", gap: "4px"}} key={2}>
                <ChangeIndicator type={indicator} />
                <BodyText style={{ color: colour }}>
                    {formatReportNumber(Math.abs(props.estimatedReduction), { maxDecimalPlaces: 0 })}
                    {" "}
                    {EmissionUnit.Total}
                </BodyText>
            </Row>
        </Row>
    );
}

const Spacer = styled.div`
    height: 11px;
    width: 100%;
    border-bottom: 1px solid ${ruminatiColors.green_3_30};
    margin: 0px 4px;
`;

const DividerText = styled(BodyText)`
    padding: 0 0 8px 0;
    font-weight: 500;
    font-size: 16px;
`;
