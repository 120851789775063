import styled from "styled-components";
import { PropsWithChildren } from "react";

type ButtonProps = {
    textColor?: string;
    hoverTextColor?: string;
    activeTextColor?: string;
    backgroundColor?: string;
    hoverBackgroundColor?: string;
    hoverBorderColor?: string;
    activeBackgroundColor?: string;
    padding?: string;
    borderRadius?: string;
    border?: string;
    height?: string;
    width?: string;
    fontSize?: string;
    bottomBorderWidth?: number;
    lineHeight?: string;
    disabled?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
    type?: "button" | "submit" | "reset";
};

/**
 * A base component for buttons, used under other wrappers for each specific button type
 * @param props styling parameters for the button
 */
export default function Button(props: PropsWithChildren<ButtonProps>) {
    return (
        <BaseButton {...props}>
            <div
                style={{
                    margin: "0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {props.children}
            </div>
        </BaseButton>
    );
}

const BaseButton = styled.button`
    ${(props: ButtonProps) => `
        color: ${props.textColor};
        background-color: ${props.backgroundColor};
        border: ${props.border};
        border-radius: ${props.borderRadius};
        line-height: ${props.lineHeight ?? "1em"};
        width: ${props.width ?? "auto"};
        height: ${props.height};
        padding: ${props.padding};
        cursor: ${props.disabled ? "no-drop" : "pointer"};
        min-width: ${props.height};
        font-size: ${props.fontSize};
        ${(props.bottomBorderWidth ?? 0) > 0
            ? `border-bottom: ${props.bottomBorderWidth}px solid ${props.textColor}; padding-bottom: 5px`
            : ""
        };
        
        svg path {
            fill: ${props.textColor};
            transition: 0.3s ease;
        }
    `};

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "TTInterfaces", sans-serif;
    font-weight: 500;
    letter-spacing: 0.02em;
    transition: 0.5s ease background-color, 0.3s ease color,
        0.3s ease border-bottom, 0.3s ease border;

    &:hover {
        ${(props: ButtonProps) => `
            color: ${props.hoverTextColor};
            border: 1px solid ${props.hoverBorderColor};
            background-color: ${props.hoverBackgroundColor};
            ${(props.bottomBorderWidth ?? 0) > 0
            ? `border-bottom: ${props.bottomBorderWidth}px solid ${props.hoverTextColor}`
            : ""
        };
            svg path {
                fill: ${props.hoverTextColor};
            }
        `};
    }

    &:active {
        ${(props: ButtonProps) => `
            color: ${props.activeTextColor};
            background-color: ${props.activeBackgroundColor};
            ${(props.bottomBorderWidth ?? 0) > 0
            ? `border-bottom: ${props.bottomBorderWidth}px solid ${props.activeTextColor}`
            : ""
        };
        `};
    }
`;
