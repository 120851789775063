import styled from "styled-components";
import { ruminatiColors } from "../utilities/colors";

export enum ChangeIndicatorType {
    Increase='Increase',
    Same='Same',
    Decrease='Decrease',
}

type ChangeIndicatorProps = {
    type: ChangeIndicatorType;
    color?: string;
};

export default function ChangeIndicator(props: ChangeIndicatorProps) {
    const color = props.color ?? getDefaultColorForChangeIndicator(props.type)
    if (props.type === ChangeIndicatorType.Increase)
        return <ArrowUp color={color} />;

    if (props.type === ChangeIndicatorType.Decrease)
        return <ArrowBottom color={color} />;

    return <SameLine color={color} />;
}

export function getDefaultColorForChangeIndicator (indicator: ChangeIndicatorType) {
    switch (indicator) {
        case ChangeIndicatorType.Decrease:
            return ruminatiColors.approval_green
        case ChangeIndicatorType.Increase:
            return ruminatiColors.red
        case ChangeIndicatorType.Same:
            return ruminatiColors.light_green
        default:
            return ruminatiColors.light_green
    }
}

const ArrowUp = styled.div`
    width: 0;
    height: 0;
    margin: 0 4px;

    border-style: solid;
    border-width: 0 7.5px 9.33px 7.5px;
    border-color: transparent transparent
        ${(props) => props.color ?? ruminatiColors.green_3} transparent;
`;

const ArrowBottom = styled(ArrowUp)`
    border-width: 9.33px 7.5px 0 7.5px;
    border-color: ${(props) => props.color ?? ruminatiColors.green_3}
        transparent transparent transparent;
`;

const SameLine = styled.div`
    margin: 2.9px 6.5px 0px 6.5px;
    height: 2px;
    width: 15px;
    background-color: ${(props) => props.color ?? ruminatiColors.green_3};
`;
