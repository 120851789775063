import { DropdownOptionData, FieldSize } from "../../../models/form/form_fields/form_field"
import { DropdownFormField } from "../../../models/form/form_fields/basic_form_fields"
import { getNextFinancialYears, formatFinancialYearStringToShortPrettyFinancialYearString } from "../../../utilities/dates";

export function getDefaultReductionPlanYearOptions (): DropdownOptionData<number>[] {
    const years = getNextFinancialYears(10);
    return years.map((y) => {
        return formatYearToOption(y)
    });
}

export function addExistingReductionPlanYearsToOptions (existingReductionPlanYears: number[], yearOptions: DropdownOptionData<number>[]) {
    existingReductionPlanYears.forEach((year) => {
        if (!yearOptions.find((option) => option.value === year)) {
          yearOptions.push(formatYearToOption(year))
        }
    })
}

function formatYearToOption (year: number): DropdownOptionData<number> {
  return {
    name: formatFinancialYearStringToShortPrettyFinancialYearString(year.toString()),
    value: year,
  }
}

export function getYearField (label: string, width: FieldSize, yearOptions: DropdownOptionData<number>[]) {
  return new DropdownFormField({
    required: true,
    label,
    size: width, 
    options: yearOptions,
  })
}