import styled from "styled-components";
import { ruminatiColors } from "../utilities/colors";

// This file contains shared layout-focussed styled elements

export const Row = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
}`;

export const FlexRow = styled(Row).attrs(
    (props: { threshold: string }) => props
)`
    @media screen and (max-width: ${(props) => props.threshold ?? `600px`}) {
        display: inline-flex;
        flex-direction: column;
        row-gap: 6px;
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Flex = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 964px) {
        flex-direction: column;
    }
`;

export const ScreenWrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${ruminatiColors["bone"]};
`;

export const ScreenInnerWrapper = styled.div`
    width: 100%;

    padding: 72px 0 0 0;
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const HeaderWrapper = styled.div`
    font-family: "TTInterfaces", sans-serif;
    width: 100%;
    height: 72px;

    background-color: ${ruminatiColors.bone};

    padding: 12px 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const FixedHeaderWrapper = styled(HeaderWrapper)`
    position: fixed;
    top: 0;
    z-index: 99;
`;

export const HeaderSection = styled.div`
    padding: 8px;
    display: flex;
    align-items: center;
`;

export const Wrapper = styled.div`
    padding: 0 0 8px 0;
    width: 100%;
`;

export const Illustration = styled.img`
    width: 80%;
    max-width: 700px;

    display: block;
    margin: 55px 0 112px 0;
`;

export const Expanded = styled.div`
    width: 100%;
    height: 100%;
    flex-grow: 1;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LeftExpanded = styled(Expanded)`
    justify-content: flex-start;
`;

export const LeftAligned = styled.div`
    display: flex;
    justify-content: start;
`;

export const Centre = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

export const RightAligned = styled.div`
    display: flex;
    justify-content: end;
`;

export const ScrollableBody = styled.div`
    padding: 20px;
    overflow-y: auto;
    flex: 1;
    max-height: calc(100vh - 300px);
    width: 100%;
`;