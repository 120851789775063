import styled from "styled-components";
import { PropsWithChildren } from "react";
import { ruminatiColors } from "../utilities/colors";
import { clamp } from "../utilities/functions";
import { BaseText } from "./styled_text";

/**
 * A basic text heading class that can be used for H1, H2, H3 & H4
 * @param level a number from 1 to 5 representing the size and style
 * @param color optional. The color of the heading
 * @param children optional. The content of the heading (typically text)
 */
export default function Heading({
    level,
    color,
    children,
    textAlign,
}: PropsWithChildren<{
    level: number;
    color?: string;
    textAlign?: "center" | "start"
}>) {
    const _level = clamp(level, 1, 5);

    const fontSizes = ["56px", "34px", "24px", "20px", "18px"];
    const lineHeights = ["64px", "40px", "32px", "32px", "24px"];

    return (
        <HeadingText
            style={{
                fontSize: fontSizes[_level - 1],
                lineHeight: lineHeights[_level - 1],
                color: color ?? ruminatiColors.green_3,
                fontWeight: _level >= 4 ? 500 : 600,
                // whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontFamily:
                    _level === 5
                        ? "TTInterfaces, sans-serif"
                        : "Approach, sans-serif",
                textAlign: textAlign ?? "center",
                textTransform: 'capitalize',
            }}
        >
            {children}
        </HeadingText>
    );
}

export const HeadingWrapper = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid ${ruminatiColors.green_3_30};
`;

const HeadingText = styled(BaseText)`
    font-family: "Approach", sans-serif;
    font-weight: 600;
    text-align: center;
    color: ${ruminatiColors.green_3};
`;