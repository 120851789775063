import { PropsWithChildren } from "react";
import { ruminatiColors } from "../../utilities/colors";
import Button from "./button";

type SubButtonProps = {
    colorScheme?: "green" | "orange";
    padding?: string;
    enforceHover?: boolean;
    disabled?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
};

/**
 * A small button. Typically used for optional actions on the page.
 * @param colorScheme optional. The set of colors to use for the button.
 * Defaults to orange.
 * @param padding optional. The value given to the padding property
 * @param enforceHover optional. When true, the button will remain in its
 * hover state. Deafults to false
 * @param disabled optional. Prevents click interaction and greys out
 * button. Defaults to false
 * @param children optional. The content of the button
 * @param onClick optional. The function called when the button is clicked
 * @param onBlur optional. The function called when the button loses focus
 */
export default function SubButton(props: PropsWithChildren<SubButtonProps>) {
    let _backgroundColor =
        props.colorScheme === "green"
            ? ruminatiColors.green_3
            : ruminatiColors.orange;
    const _hoverBackgroundColor =
        props.colorScheme === "green"
            ? ruminatiColors.orange
            : ruminatiColors.green_3;
    const _activeBackgroundColor =
        props.colorScheme === "green"
            ? ruminatiColors.orange
            : ruminatiColors.green_3;

    if (props.enforceHover) _backgroundColor = _hoverBackgroundColor;

    return (
        <Button
            type="button"
            onClick={props.onClick}
            onBlur={props.onBlur}
            backgroundColor={
                props.disabled ? ruminatiColors.bone : _backgroundColor
            }
            hoverBackgroundColor={
                props.disabled ? ruminatiColors.bone : _hoverBackgroundColor
            }
            activeBackgroundColor={
                props.disabled ? ruminatiColors.bone : _activeBackgroundColor
            }
            textColor={
                props.disabled
                    ? ruminatiColors.effective_black_30
                    : ruminatiColors.bone
            }
            hoverTextColor={
                props.disabled
                    ? ruminatiColors.effective_black_30
                    : ruminatiColors.bone
            }
            activeTextColor={
                props.disabled
                    ? ruminatiColors.effective_black_30
                    : ruminatiColors.bone
            }
            border={
                props.disabled
                    ? `1px solid ${ruminatiColors.effective_black_30}`
                    : "none"
            }
            borderRadius="4px"
            disabled={props.disabled}
            fontSize="14px"
            height="24px"
            padding={props.padding ?? "0 8px"}
        >
            {props.children}
        </Button>
    );
}
