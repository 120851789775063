import { Dispatch, SetStateAction } from "react";
import styled from 'styled-components'

import { InitiativeCategory, ReductionPlan } from "../../models/reduction_plans_models";
import { ruminatiColors } from "../../utilities/colors";
import ToggleButton from "../buttons/toggle_button";
import {  Row, Column } from "../styled_layout";
import Icon from "../icon";
import { BodyText } from "../styled_text";
import { ToggleState } from "./reduction_graph";

export type ToggleTableDatum = {
    label: string;
    plan: ReductionPlan;
    onEdit?: (plan: ReductionPlan) => void;
    showToggle: boolean;
    isOn: boolean;
    onToggle?: () => void;
    isChild?: boolean;
    disabled?: boolean;
};

type GraphOptionsTableProps = {
    showTable?: boolean;
    absorbEmissionsTableData: ToggleTableDatum[];
    avoidEmissionsTableData: ToggleTableDatum[];
    innovativeSolutionsTableData: ToggleTableDatum[];
    initiativeCategoriesMap: Record<InitiativeCategory, string>;
    categoryToggleMap: any;
    setCategoryToggleMap: Dispatch<SetStateAction<any>>;
    onExplore: (category: InitiativeCategory) => void;
};

export default function GraphOptionsTable(props: GraphOptionsTableProps) {

    function makeColumn (initiativeCategory: InitiativeCategory, showBorderRight: boolean, tableData: ToggleTableDatum[]): JSX.Element {
        const title = props.initiativeCategoriesMap[initiativeCategory]

        const isCategoryDisabled = props.categoryToggleMap[initiativeCategory]

        return (
            <Column 
            style={{
                width: '33.333%',
                borderRight: showBorderRight ? `1px solid ${ruminatiColors.green_3_30}` : undefined,
            }}>
                <Row style={{
                    width: '100%',
                    background: ruminatiColors.green_3_5,
                    justifyContent: 'space-between',
                    padding: '8px',
                    borderBottom: `1px solid ${ruminatiColors.green_3_30}`
                }}>
                    <Column style={{ flexGrow: 2, alignItems: 'flex-start' }}>
                        <DataText>{title}</DataText>
                    </Column>
                    <Column style={{ flexGrow: 1, alignItems: 'flex-end' }}>
                        {tableData.length > 0 ? 
                            <ToggleButton
                            isOn={!props.categoryToggleMap[initiativeCategory]}
                            label={""}
                            onChange={() => {
                                props.setCategoryToggleMap({
                                ...props.categoryToggleMap,
                                [initiativeCategory]: ToggleState.toggle(props.categoryToggleMap[initiativeCategory])
                            })
                            }}/>
                        : undefined}
                    </Column>
                </Row>

                <Row style={{
                    flexWrap: 'wrap',
                    gap: '4px',
                    padding: '8px',
                    width: '100%'
                }}>
                    {tableData.length === 0 ? 
                        <Row style={{
                            width: '100%'
                        }}>
                            <Column style={{ flexGrow: 2, alignItems: 'flex-start' }}>
                                <DataText>No Initiatives planned</DataText>
                            </Column>
                            <Column style={{ flexGrow: 1, justifyContent: 'flex-end', flexDirection: 'row' }}>
                                <DataTextButton 
                                onClick={() => props.onExplore(initiativeCategory)}
                                >
                                    Explore
                                </DataTextButton>
                            </Column>
                        </Row>
                    : tableData.map((td, index) => {
                            return <Row 
                            key={index}
                            style={{
                                width: '100%',
                                transition: '0.2s',
                                pointerEvents: isCategoryDisabled ? 'none' : 'all',
                                opacity: isCategoryDisabled ? 0.5 : 1
                            }}>
                                <Column style={{ flexGrow: 2, alignItems: 'flex-start' }}>
                                    <DataText>
                                        {td.isChild ? 
                                            <Icon icon="subDirectory" />
                                        : undefined}
                                        {td.label}
                                    </DataText>
                                </Column>
                                <Column style={{ 
                                    flexGrow: 1, 
                                    justifyContent: 'flex-end',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    {!td.isChild ? 
                                        <DataTextButton 
                                        style={{justifySelf: 'flex-end'}}
                                        onClick={() => {
                                            if (td.onEdit) td.onEdit(td.plan)
                                        }}
                                        >
                                            Edit
                                        </DataTextButton>
                                    : undefined}
                                    {td.showToggle ?
                                        <ToggleButton
                                        isOn={td.isOn}
                                        label={""}
                                        onChange={() => {
                                            if (td.onToggle) td.onToggle()
                                        }}/> : undefined
                                    }                        
                                </Column>

                            </Row>                
                        })
                    }
                </Row>
            </Column>
        )
    }

    // Transition effect from
    // https://keithjgrant.com/posts/2023/04/transitioning-to-height-auto/#with-grid
    return (
        <div
          style={{
            width: '100%',
            display: 'grid',
            gridTemplateRows: props.showTable ? '1fr' : '0fr',
            opacity: props.showTable ? 1 : 0,
            transition: '0.3s ease'
        }}>
            <Row
            style={{
                width: '100%',
                marginTop: '24px',
                border: `1px solid ${ruminatiColors.green_3_30}`,
                borderRadius: '8px',
                alignItems: 'stretch',
                overflow: 'hidden',
            }}>
                {makeColumn(InitiativeCategory.AvoidEmissions, true, props.avoidEmissionsTableData)}
                {makeColumn(InitiativeCategory.AbsorbEmissions, true, props.absorbEmissionsTableData)}
                {makeColumn(InitiativeCategory.FutureSolutions, false, [])}
        </Row>
    </div>)
}

export const DataText = styled(BodyText)`
    overflow: hidden;
    color: ${ruminatiColors.light_green};
    font-size: 12px;
    font-family: TTInterfaces;
    font-weight: 500;
`;

export const DataTextButton = styled(DataText)`
    cursor: pointer;
`;