import { PropsWithChildren } from "react";
import { ruminatiColors } from "../../utilities/colors";
import Icon, { IconType } from "../icon";
import { HeaderSection } from "../styled_layout";
import TextButton from "./text_button";

type HeaderButtonProps = {
    onClick?: () => void;
    icon?: IconType;
    iconPos?: "left" | "right";
};

/**
 * A button styled for use in headers. Shows an icon along with text.
 * @param children the content of the button to show
 * @param onClick callback on function when clicked
 * @param icon the id of the icon
 * @param iconPos the position of the icon with respect to the text
 */
export default function HeaderButton(props: PropsWithChildren<HeaderButtonProps>) {
    return (
        <HeaderSection
            style={{
                justifyContent:
                    props.iconPos === "right" ? "flex-end" : "flex-start",
            }}
            onClick={props.onClick}
        >
            <TextButton
                fontSize="16px"
                textColor={ruminatiColors.green_3}
                hoverTextColor={ruminatiColors.orange}
                activeTextColor={ruminatiColors.orange}
            >
                {props.iconPos === "left" && (
                    <div style={{ margin: "0 8px 0 0", display: "flex" }}>
                        <Icon icon={props.icon} />
                    </div>
                )}

                <div style={{ padding: "2px 0 0 0" }}>{props.children}</div>

                {props.iconPos === "right" && (
                    <div style={{ margin: "0 0 0 8px", display: "flex" }}>
                        <Icon icon={props.icon} />
                    </div>
                )}
            </TextButton>
        </HeaderSection>
    );
}
