import { useState, PropsWithChildren } from "react";
import Icon from "../icon";
import styled, { keyframes } from "styled-components";
import Button from "./button";
import { ruminatiColors } from "../../utilities/colors";

type LoadingButtonProps = {
    size?: "large" | "medium" | "small";
    width?: string;
    disabled?: boolean;
    onClick?: () => void;
    colorScheme?: "green" | "orange";
    outline?: boolean;
    prefix?: JSX.Element;
    type?: "button" | "submit" | "reset";
};

/**
 * A button which displays a loading spinner while waiting for the onClick
 * function to resolve
 * @param size optional. The named size of the button
 * @param width optional. The width of the button in px
 * @param disabled optional. Prevents click interaction and greys out
 * button. Defaults to false
 * @param children optional. The content of the button
 * @param onClick optional. An async function which eventually resolves
 * @param colorScheme optional. Defaults to green.
 */
export default function LoadingButton(props: PropsWithChildren<LoadingButtonProps>) {
    const [loading, setLoading] = useState(false);

    const onClick = async () => {
        setLoading(true);
        if (props.onClick) await props.onClick();
        setLoading(false);
    };

    const _size = props.size ?? "medium";

    const _backgroundColor =
        loading || props.disabled || props.outline
            ? ruminatiColors.bone
            : props.colorScheme === "orange"
                ? ruminatiColors.orange
                : ruminatiColors.green_3;
    const _hoverColor =
        loading || props.disabled
            ? ruminatiColors.bone
            : props.colorScheme === "orange"
                ? ruminatiColors.orange_50
                : props.colorScheme === 'green'
                    ? ruminatiColors.green_3_10
                    : ruminatiColors.orange;

    const _textColor = loading ||
        (props.outline && props.colorScheme === "orange")
        ? ruminatiColors.orange :
        (props.outline && props.colorScheme === "green")
            ? ruminatiColors.green_3
            : props.colorScheme === "orange"
                ? ruminatiColors.bone
                : "#ffffff";

    const borderColor = props.colorScheme === 'green' ? ruminatiColors.dark_green : ruminatiColors.orange
    const _border = loading || props.outline ? `1px solid ${borderColor}` : "none";

    const _padding =
        _size === "large"
            ? "16px 40px"
            : _size === "medium"
                ? "8px 32px"
                : "12px 24px";
    const _height =
        _size === "large" ? "64px" : _size === "medium" ? "48px" : "40px";

    return (
        <Button
            type={props.type}
            onClick={loading ? undefined : onClick}
            backgroundColor={_backgroundColor}
            hoverBackgroundColor={_hoverColor}
            activeBackgroundColor={_hoverColor}
            textColor={props.disabled ? ruminatiColors.green_3_30 : _textColor}
            hoverTextColor={
                props.disabled ? ruminatiColors.green_3_30 : _textColor
            }
            activeTextColor={
                props.disabled ? ruminatiColors.green_3_30 : _textColor
            }
            border={
                props.disabled ? `1px solid ${ruminatiColors.green_3}` : _border
            }
            borderRadius="48px"
            disabled={props.disabled}
            fontSize={_size === "small" ? "14px" : _size === "medium" ? "16px" : "20px"}
            height={_height}
            width={props.width ? props.width : "auto"}
            padding={_padding}
        >
            <LoadingWrapper
                style={{ width: loading ? 40 : 0, opacity: loading ? 1 : 0 }}
            >
                <RotatingIcon>
                    <Icon icon="loading" />
                </RotatingIcon>
                <div style={{ width: "8px" }}></div>
            </LoadingWrapper>
            <LoadingWrapper
                style={{ width: !loading && props.prefix ? 24 : 0, opacity: !loading && props.prefix ? 1 : 0, fill: _textColor }}
            >
                {props.prefix}
                <div style={{ width: "8px" }}></div>
            </LoadingWrapper>

            {props.children}
        </Button>
    );
}

const rotationAnimation = keyframes`
 0% { transform: rotate(0deg); }
 50% { transform: rotate(180deg); }
 100% { transform: rotate(360deg); }
`;

const LoadingWrapper = styled.div`
    display: flex;
    transition: 0.3s ease width, 0.3s ease opacity;
    overflow: hidden;
`;

export const RotatingIcon = styled.div`
    height: 32px;
    width: 32px;

    animation-name: ${rotationAnimation};
    animation-duration: 700ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
`;
