import { ConfigProvider, Drawer } from "antd"
import { Link } from "react-router-dom";
import styled from "styled-components";

import Icon from "./icon";

import { useConfigStore } from "@/state/config_store";

import { ruminatiColors } from '@/utilities/colors'
import { BaseText, BodyText } from "./styled_text";

interface HelpDrawerProps {
  open: boolean
  onClose: () => void
  renderContent: () => React.ReactNode
  antDTheme?: Record<string, any>
}

export function HelpDrawer({
  open,
  onClose,
  renderContent,
  antDTheme
}: HelpDrawerProps) {
  return (
    <ConfigProvider
      theme={{
        ...antDTheme,
        token: {
          colorBgMask: ruminatiColors.bone_60,
          colorTextHeading: ruminatiColors.green_3,
          ...antDTheme?.token
        },
      }}
    >
      <Drawer
        open={open}
        onClose={onClose}
        width={480}
        drawerRender={() => {
          return (
            <HelpDrawerContainer role="dialog" aria-modal="true">
              <HelpDrawerInnerContainer>
                <DrawerHeaderContainer>
                  <DrawerHeader>Help & Support</DrawerHeader>
                  <DrawerCloseButton onClick={onClose}>
                    <Icon icon="x" fillColor={ruminatiColors.green_3} />
                  </DrawerCloseButton>
                </DrawerHeaderContainer>

                {renderContent()}
              </HelpDrawerInnerContainer>

              <DrawerFooterContainer>
                <BodyText>Still need help?{" "}<DrawerFooterLink to="mailto:hello@ruminati.com.au">Get in touch.</DrawerFooterLink></BodyText>
              </DrawerFooterContainer>
            </HelpDrawerContainer>
          )
        }}
      />
    </ConfigProvider>
  )
}

interface HelpTabProps {
  setHelpDrawerOpen: (open: boolean) => void
}

export function HelpTab ({ setHelpDrawerOpen }: HelpTabProps) {
  return (
    <HelpButton
      onClick={() => setHelpDrawerOpen(true)}
    >
      <Icon icon="help" fillColor={ruminatiColors.green_3}/>
      <HelpText>Help</HelpText>
    </HelpButton>
  )
}

// a combination component that renders both the help tab and the help drawer, expectation is this will be the regularly used approach
// and uses a hook to manage the state of the help drawer
// TODO: consider whether the tab should not be fixed to a specific position (could be the caller's responsibility to provide a container)
interface HelpDrawerAndTabProps {
  renderContent: () => React.ReactNode
}

export function HelpDrawerAndTab ({ renderContent }: HelpDrawerAndTabProps) {
  const { isHelpDrawerOpen, setIsHelpDrawerOpen } = useConfigStore()

  return (
    <>
      <HelpTab setHelpDrawerOpen={setIsHelpDrawerOpen} />
      <HelpDrawer
        open={isHelpDrawerOpen}
        onClose={() => setIsHelpDrawerOpen(false)}
        renderContent={renderContent}
      />
    </>
  )
}


const HelpDrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${ruminatiColors.bone};
  height: 100%;
  border-left: 1px solid ${ruminatiColors.green_3_30};
  pointer-events: auto;
`

const HelpDrawerInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;
  gap: 32px;
`

const DrawerHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

// N.B. heading.tsx results in <p> markup
const DrawerHeader = styled.h2`
  font-family: "Approach", sans-serif;
  font-size: 34px;
  line-height: 40px;
  font-weight: 600;
  color: ${ruminatiColors.green_3};
  margin: 0;
`

const DrawerCloseButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; cursor: pointer;
  background: none;
  border: none;
  width: 32px;
  height: 32px;
  padding: 0;

  &:hover {
    opacity: 0.8;
  }

  > svg {
    width: 16px;
    height: 16px;
  }
`

const DrawerFooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 480px;
  height: 72px;
  background-color: ${ruminatiColors.bone};
  border-top: 1px solid ${ruminatiColors.green_3_30};
  padding: 24px;
` 

const DrawerFooterLink = styled(Link)`
  display: inline;
  color: ${ruminatiColors.green_3};
  text-decoration: underline;
  &:hover {
    color: ${ruminatiColors.orange};
    text-decoration: underline;
  }
`

const HelpButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  position: fixed;
  right: -24px;
  top: calc(50% - 80px);
  transform: rotate(-90deg);
  padding: 6px;
  padding-right: 10px;
  background-color: ${ruminatiColors.green_3_10};
  border-top-style: solid;
  border-bottom-style: none;
  border-left-style: solid;
  border-right-style: solid;
  border-width: 1px;
  border-color: ${ruminatiColors.green_3};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;

  > svg {
    transform: rotate(90deg);
  }
`;

const HelpText = styled(BaseText)`
  color: ${ruminatiColors.green_3};
`;

// common styles that are useful for passing in as content
export const HelpDrawerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 72px;
  gap: 32px;
  height: 100%;
  overflow-y: scroll;
`

export const HelpDrawerExplanatoryContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

// N.B. heading.tsx results in <p> markup
export const HelpDrawerExplanatoryContentTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  font-family: "Approach", sans-serif;
  color: ${ruminatiColors.green_3};
  line-height: 32px;
  margin: 0;
`

export const HelpDrawerExplanatoryContentText = styled(BodyText)`
  line-height: 24px;
`

// correct aspect ratio for embedded youtube videos
export const HelpDrawerVideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`