import { MultiPolygon } from "geojson";
import GetStartedMap from '../property/get_started_map';
import { GetStartedStep } from '../../screens/get_started_screen';
import { useState } from "react";

export type EditPropetyBoundsReturns = {
  geom: MultiPolygon | null;
}

/**
 * The Edit Property Bounds Popup
 */
export default function EditPropertyBoundsPopup(props: {
  onSubmit: (obj: EditPropetyBoundsReturns) => void;
  geom: MultiPolygon;
}) {

  const [propertyBoundary, setPropertyBounds] = useState(props.geom)

  return (
    <div style={{ marginBottom: "20px", width: "60vw" }}>
      <GetStartedMap
        step={GetStartedStep.SelectProperty}
        initialGeom={propertyBoundary}
        setPropertyBoundary={(boundary) => {
          if (boundary) setPropertyBounds(boundary)
        }}
        onSubmit={() => {
          props.onSubmit({
            geom: propertyBoundary
          })
        }}
        hideUpload={true}
      />
    </div>
  )
}

