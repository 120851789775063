import { useEffect, PropsWithChildren } from 'react';
import LoadingPlaceholder from '../components/loading_placeholder';
import Screen from '../screens/screen'
import { getReports } from '../services/property_service';
import { useDatabaseStore } from "../state/database_store";

/**
 * Gets the users reports if they have not been loaded 
 */
export default function ReportGuard({
    children,
}: PropsWithChildren<unknown>) {
    const reports = useDatabaseStore((state) => state.reports);
    const setReports = useDatabaseStore((state) => state.setReports)

    useEffect(() => {
        if (!reports) {
            getReports().then((r) => setReports(r))
        }
    }, [reports, setReports])

    if (!reports) {
        return <Screen>
            <LoadingPlaceholder />
        </Screen>
    } else {
        return children
    }
}
