import Heading from "../../components/heading";
import { Column, Row } from "../../components/styled_layout";
import { BodyText } from "../../components/styled_text";
import { ruminatiColors } from "../../utilities/colors";
import { formatFinancialYearStringToShortPrettyFinancialYearString } from "../../utilities/dates";

export function EmissionCardHeader({
    title,
    afterTitle,
    year
}: {
    title: string;
    afterTitle?: JSX.Element;
    year?: number;
}) {
    return (
        <Row style={{
             width: "100%",
             justifyContent: 'space-between',
             borderBottom: `1px solid ${ruminatiColors.green_3_30}`,
             paddingBottom: '12px'
        }}>
            <Column>
                <Row>
                    <Column>
                        <Heading level={4}>{title}</Heading>
                    </Column>
                    <Column>{afterTitle ?? undefined}</Column>
                </Row>
            </Column>

            {year ? <Column>
                <BodyText style={{color: ruminatiColors.light_green}}>
                    {formatFinancialYearStringToShortPrettyFinancialYearString(year.toString())}
                </BodyText>
            </Column>
            : undefined}

        </Row>
    );
}
