import { ReportInnerSection, ValueText } from "./report_screen";
import { formatReportNumber, formatReportPercent } from "../../utilities/functions";
import { PropertyType, ReportResults } from "../../models/property_models";
import EmissionUnit from "../../models/emission-unit";
import LabelledValue from "../../components/form/labelled_value";
import { ruminatiColors } from "../../utilities/colors";
import { Column } from "../../components/styled_layout";
import { Property } from "../../models/property_models";
import PieChartDisplay from "../../components/pie_chart_display";
import SimpleTable, { TableRowType } from "../../components/simple_table";

export default function ReportSummaryTab(props: {
  property: Property
  resultsSummary: ReportResults,
  isPrime: boolean,
  isInPrint: boolean,
  animateCharts: boolean
}) {

  function getEmissionsSummaryStats() {
      const { totals, intensity } = props.resultsSummary;

      const formatValue = (value: number | null | undefined) => {
          return (value != null || value !== undefined) ? formatReportNumber(value) : "N/A";
      };

      const createLabelledValue = (label: string, value: number | null | undefined, unit: string) => (
          <LabelledValue labelColor={ruminatiColors.green_3} label={`${label} (${unit})`}>
              <ValueText>
                  {formatValue(value)}
              </ValueText>
          </LabelledValue>
      );

    return props.isPrime ? (
          <ReportInnerSection>
              <Column style={{ height: "100%" }}>
                  {createLabelledValue("Total emissions", totals.total, EmissionUnit.Total)}
                  {createLabelledValue("Sequestration", totals.sequestration, EmissionUnit.Total)}
                  {createLabelledValue("Net emissions", totals.net, EmissionUnit.Total)}
              </Column>
              <Column style={{ height: "100%" }}>
                  {(props.property.cattleFarm ?? false) &&
                      createLabelledValue("Beef Intensity", intensity.kgCo2PerKgBeefMeatSold, EmissionUnit.IntensityLivestock)}
                  {(props.property.sheepFarm ?? false) &&
                      createLabelledValue("Sheep Intensity", intensity.kgCo2PerKgSheepMeatSold, EmissionUnit.IntensityLivestock)}
                  {(props.property.sheepFarm ?? false) &&
                      createLabelledValue("Wool Intensity", intensity.kgCo2PerKgGreasyWool, EmissionUnit.IntensityLivestock)}
                  {(props.property.grainFarm ?? false) &&
                      createLabelledValue("Crop Intensity", intensity.kgCo2PerKgGrainProduced, EmissionUnit.IntensityGrain)}
                  {(props.property.beefProcessor ?? false) &&
                      createLabelledValue(`kg CO2e/t ${props.property.propertyType === PropertyType.Processor ? "HSCW" : "Product"} (Scope 1 and 2)`, intensity.kgCO2ePerTonneHCSWScope1and2, "Scope 1 and 2")}
                  {(props.property.beefProcessor ?? false) &&
                      createLabelledValue(`kg CO2e/t ${props.property.propertyType === PropertyType.Processor ? "HSCW" : "Product"} (All Scopes)`, intensity.kgCO2ePerTonneHCSW, "All Scopes")}
                  {(props.property.beefProcessor ?? false) &&
                      createLabelledValue(`Energy use (MJ/t ${props.property.propertyType === PropertyType.Processor ? "HSCW" : "Product"})`, intensity.energyUseMJPerTonneHSCW, `MJ/t ${props.property.propertyType === PropertyType.Processor ? "HSCW" : "Product"}`)}
                  {props.property.propertyType === PropertyType.Feedlot &&
                    createLabelledValue("Beef Intensity (Scope 1 & 2)", intensity.kgCo2PerKgMeatFedScope1and2, EmissionUnit.IntensityLivestock)}
                  {props.property.propertyType === PropertyType.Feedlot &&
                      createLabelledValue("Beef Intensity (All Scopes)", intensity.kgCo2PerKgMeatFed, EmissionUnit.IntensityLivestock)}  
                  {props.property.propertyType === PropertyType.Dairy &&
                      createLabelledValue("Fat & Protein corrected milk", intensity.kgCo2PerKgMilkFPCM, EmissionUnit.DairyIntensityFPCM)}
                  {props.property.propertyType === PropertyType.Dairy &&
                      createLabelledValue("Milk Solids", intensity.kgCo2PerKgMilk, EmissionUnit.DairyIntensityMilkSolids)}
                  {props.property.propertyType === PropertyType.Dairy &&
                      createLabelledValue("Meat", intensity.kgCo2PerKgDairyMeat, EmissionUnit.DairyIntensityLW)}
              </Column>
          </ReportInnerSection>
      ) : <ReportInnerSection>
          {createLabelledValue("Total emissions", totals.total, EmissionUnit.Total)}
          {createLabelledValue("Sequestration", totals.sequestration, EmissionUnit.Total)}
          {createLabelledValue("Net emissions", totals.net, EmissionUnit.Total)}
      </ReportInnerSection>;
  }

  function getEmissionsChart() {
      const scope1EmissionsData = [
          {
              name: "Carbon dioxide",
              value: props.resultsSummary.scope1ByGas.carbonDioxide / (props.resultsSummary.byScopes.scope1 || 1),
          },
          {
              name: "Methane",
              value: props.resultsSummary.scope1ByGas.methane / (props.resultsSummary.byScopes.scope1 || 1),
          },
          {
              name: "Nitrous oxide",
              value: props.resultsSummary.scope1ByGas.nitrousOxide / (props.resultsSummary.byScopes.scope1 || 1),
          },
      ];

      const scope13EmissionsData = [
          {
              name: "Scope 1 - Farm related",

              value: (props.resultsSummary.byScopes.scope1 / (props.resultsSummary.totals.total || 1)),
          },
          {
              name: "Scope 2 - Indirect energy",
              value: (props.resultsSummary.byScopes.scope2 / (props.resultsSummary.totals.total || 1)),
          },
          {
              name: "Scope 3 - Pre-farm",
              value: (props.resultsSummary.byScopes.scope3 / (props.resultsSummary.totals.total || 1)),
          },
      ];

      return (
          <ReportInnerSection>
              <div>
                  <PieChartDisplay
                      chartWidth={props.isInPrint ? 150 : undefined}
                      colorScheme="orange"
                      title="Scope 1 Emissions"
                      shouldAnimate={props.animateCharts}
                      data={scope1EmissionsData}
                  />
              </div>
              <div>
                  <PieChartDisplay
                      chartWidth={props.isInPrint ? 150 : undefined}
                      colorScheme="blue"
                      title="Scope 1-3 Emissions"
                      shouldAnimate={props.animateCharts}
                      data={scope13EmissionsData}
                  />
              </div>
          </ReportInnerSection>
      );
  }

  function getEmissionsSummaryTable() {
      const dataRow = (
          title: string,
          value: number,
          total: number,
          decimalPlaces?: number,
      ): string[] => [
              title,
              formatReportPercent(value / (total || 1)),
              value
                  ? formatReportNumber(value, { maxDecimalPlaces: decimalPlaces ?? 2 })
                  : "0",
          ];

      return (
          <div
              style={{
                  margin: `${props.isInPrint ? "8px" : "32px"} 0`,
                  width: "100%",
              }}
          > 

              <SimpleTable
                  rows={[
                      {
                          type: TableRowType.Header,
                          data: ["Emissions summary", "% of total", `${EmissionUnit.Total}`],
                      },
                      {
                          type: TableRowType.PrimaryData,
                          data: dataRow("Scope 1", props.resultsSummary.byScopes.scope1, props.resultsSummary.totals.total)
                      }, {
                          type: TableRowType.SecondaryData,
                          data: dataRow("Carbon dioxide", props.resultsSummary.scope1ByGas.carbonDioxide, props.resultsSummary.totals.total)
                      }, {
                          type: TableRowType.SecondaryData,
                          data: dataRow("Methane", props.resultsSummary.scope1ByGas.methane, props.resultsSummary.totals.total)
                      }, {
                          type: TableRowType.SecondaryData,
                          data: dataRow("Nitrous oxide", props.resultsSummary.scope1ByGas.nitrousOxide, props.resultsSummary.totals.total)
                      }, {
                          type: TableRowType.PrimaryData,
                          data: dataRow("Scope 2", props.resultsSummary.byScopes.scope2, props.resultsSummary.totals.total)
                      }, {
                          type: TableRowType.PrimaryData,
                          data: dataRow("Scope 3", props.resultsSummary.byScopes.scope3, props.resultsSummary.totals.total)
                      }, {
                          type: TableRowType.Subheader,
                          data: dataRow("Total emissions", props.resultsSummary.totals.total, props.resultsSummary.totals.total)
                      }, {
                          type: TableRowType.PrimaryData,
                          data: dataRow("Trees (includes relevant trees mapped in other reports)", props.resultsSummary.sequestration.trees, props.resultsSummary.totals.sequestration)
                      }, {
                          type: TableRowType.Subheader,
                          data: dataRow("Total sequestration", props.resultsSummary.totals.sequestration, props.resultsSummary.totals.sequestration)
                      }, {
                          type: TableRowType.Header,
                          data: ["Net emissions", "", props.resultsSummary.totals.net ? formatReportNumber(props.resultsSummary.totals.net) : "0"]
                      }
                  ]} />
          </div>
      );
  }
  
  return (
    <>
    {getEmissionsSummaryStats()}
    {getEmissionsChart()}
    {getEmissionsSummaryTable() }
    </>
  )
}