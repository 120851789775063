import { useState } from "react"

import ExternalLinkPopup from "../external-link-popup"
import { BodyText } from "@/components/styled_text"
import { ruminatiColors } from "@/utilities/colors"
import SharingPermissionsCard from "@/components/cards/sharing_permissions"
import { VisionInvitation } from "@/models/vision_invitation"

export default function VoucherAndSubscriptionPopup({
  invite,
  href,
}: {
  invite: VisionInvitation
  href: string
}) {
  const [permissionGranted, setPermissionGranted]= useState<boolean>(false)

  return (<ExternalLinkPopup
    width="800px"
    content={
      <>
        <BodyText style={{
          color: ruminatiColors.green_3,
          textAlign: "center",
          letterSpacing: "0.28px",
          padding: "0 24px",
        }}>
          You're about to be taken to our secure payment gateway to complete the upgrade of your subscription.
        </BodyText>
        <BodyText style={{
          color: ruminatiColors.green_3,
          textAlign: "center",
          letterSpacing: "0.28px",
          padding: "0 24px",
        }}>
          You can turn off your subscription at any time and your card will not be charged on your renewal date.
        </BodyText>
        <SharingPermissionsCard
          usingVoucher={true}
          invite={invite}
          checkboxChanged={(isChecked) => {
            setPermissionGranted(isChecked)
        }}
      />
      </>
    }
    buttonText="Proceed"
    href={href}
    target={'_self'}
    disabled={!permissionGranted}
  />)
}