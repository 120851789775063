import styled from "styled-components";
import { ruminatiColors } from "../utilities/colors";
import Icon from "./icon";
import { Column, Row } from "./styled_layout";
import { BodyText } from "./styled_text";

export const Card = styled.div`
    border-radius: 8px;
    border: 1px solid ${ruminatiColors.green_3};
    box-sizing: border-box;
    padding: 24px;
    width: 100%;
`;

export const FaintCard = styled(Card)`
    border-color: ${ruminatiColors.green_3_30}
`

export const SlimCard = styled(Card)`
    padding: 12px;
`;

export const GreenCard = styled(Card)`
    padding: 12px 24px;
    background-color: ${ruminatiColors.green_3_5};
`;

type InfoCardProps = {
    content: JSX.Element | string
    padding?: string
};
export function InfoCard (props: InfoCardProps) {
    return (
      <Card style={{
        backgroundColor: ruminatiColors.green_3_5,
        border: 'none',
        padding: props.padding ?? '12px 24px'
      }}>
        <Row>
            <Column style={{
                width: '40px',
                alignSelf: 'flex-start',
                alignItems: 'flex-start',
                paddingTop: '6px'
            }}>
                <Icon icon="info-circle" fillColor={ruminatiColors.green_3} />
            </Column>
            <Column style={{flexGrow: 1}}>
                {typeof props.content === 'string' ? 
                    <InfoCardText dangerouslySetInnerHTML={{__html: props.content}}></InfoCardText>
                 : props.content
                }
            </Column>
        </Row>
      </Card>
    )
}

export const InfoCardText = styled(BodyText)`
    margin-left: 16px;
    text-align: left;
    color: ${ruminatiColors.green_3};
    line-height: 24px;
    width: 100%;
`