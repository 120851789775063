import styled from "styled-components";
import { ruminatiColors } from "../../utilities/colors";

export default function GraphTag(props: { label: string, value: string, color: string }) {
    return <GraphTagContainer color={props.color} className={`${props.label}_tag`}>
        <GraphTagDot color={props.color} />
        <GraphTagText color={props.color}>{props.label}</GraphTagText>
        <GraphTagDash color={props.color} />
        <GraphTagText color={props.color}>{props.value}</GraphTagText>
    </GraphTagContainer>;
}

const GraphTagContainer = styled.div`
    color: ${(props: { color: string }) => props.color};
    border: 1px solid ${(props: { color: string }) => props.color};
    background-color: ${ruminatiColors.bone};
    border-radius: 4px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
`;

const GraphTagDot = styled.div`
    height: 6px;
    width: 6px;
    border-radius: 100%;
    background-color: ${(props: { color: string }) => props.color};
    margin-right: 4px;
`;

const GraphTagText = styled.p`
    font-family: "Approach";
    font-size: 12px;
    color: ${(props: { color: string }) => props.color};
    letter-spacing: 1%;
    white-space: nowrap;
`;

const GraphTagDash = styled.div`
    height: 1px;
    width: 16px;
    background-color: ${(props: { color: string }) => props.color};
    margin: 0px 4px;
`;