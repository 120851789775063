import type { LayerProps }  from 'react-map-gl/maplibre';
import { Source, Layer }  from 'react-map-gl/maplibre';
import { FeatureCollection, MultiPolygon } from 'geojson'

import { calculateBoundingBoxFromGeojson, createEmptyFeatureCollection } from '../../../utilities/geojson-helpers';
import { ruminatiColors } from '../../../utilities/colors'

export default function getPropertyLayerInformation({
  gj,
  hidePropertyFill,
  lineWidth
}: {
  gj?: FeatureCollection | MultiPolygon | undefined;
  hidePropertyFill?: boolean;
  lineWidth?: number
}) {
  let mapData: FeatureCollection | MultiPolygon = createEmptyFeatureCollection()
  if (gj !== undefined) mapData = gj

  const lineLayerStyle: LayerProps = {
    id: 'property-line-lyr',
    type: 'line',
    paint: {
      'line-color': ruminatiColors.orange,
      'line-width': lineWidth ?? 3.5
    }
  };

  const fillLayerStyle: LayerProps = {
    id: 'property-fill-lyr',
    type: 'fill',
    paint: {
      'fill-color': ruminatiColors.orange,
      'fill-opacity': hidePropertyFill ? 0 : 0.4
    }
  }

  const bbox = calculateBoundingBoxFromGeojson(mapData)

  return {
    layer: (
      <Source id="property-src" key="property-src-lyr" type="geojson" data={mapData}>
        <Layer {...lineLayerStyle} />
        <Layer {...fillLayerStyle} />
      </Source>
    ),
    bounds: bbox
  };
}

export const backgroundPropertyLayerStyling = {
  lineColor: ruminatiColors.orange,
  lineWidth: 1.2,
  lineOpacity: 0.9,
  fillColor: ruminatiColors.orange,
  fillOpacity: [
    'case',
    ['boolean', ['feature-state', 'hover'], false],
    0.2,
    0
  ],
  minZoom: 11.8
}