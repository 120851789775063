import { GetStartedDairy, GetStartedFarm, GetStartedFeedlot, GetStartedProcessor } from "../../screens/get_started_screen";
import Heading from "../heading";
import BasicMap from "../maps/basic_map";
import { Row, Column } from "../styled_layout";
import getPropertyLayerInformation from "../maps/layer_config/property_layer";
import ValuesList from "../values_list";
import { calculateAreaOfGeojson } from '../../utilities/geojson-helpers'
import { formatReportNumber } from "../../utilities/functions";
import { EnterpriseType } from "../property/get_started_enterprise";
import { FeedlotSystemType } from "../../models/property_models";
import { dairyProductionSystems } from "@/models/form_options";
import LoadingButton from "../buttons/loading_button";

type GetStartedReviewProps = {
  property: GetStartedFarm | GetStartedFeedlot | GetStartedProcessor | GetStartedDairy;
  onSubmit: () => void;
};

export default function GetStartedReview(props: GetStartedReviewProps) {
  const propertyLayerInfo = getPropertyLayerInformation({
    gj: props.property?.geom,
    hidePropertyFill: true
  });

  const area = props.property.geom ? calculateAreaOfGeojson(props.property.geom) : 0

  const valuesToDisplay = [
    {
      name: 'Approximate Area',
      value: `${formatReportNumber(area, {
        maxDecimalSigFig: 0
      })} hectares`
    }
  ]

  if (props.property.type === EnterpriseType.Farm) {
    const property = props.property as GetStartedFarm
    const producing = []
    if (property.cattleFarm) producing.push('Cattle')
    if (property.sheepFarm) producing.push('Sheep')
    if (property.grainFarm) producing.push('Grain')
    valuesToDisplay.push({
      name: 'Producing',
      value: producing.join()
   })

   if (property.ngr) {
     valuesToDisplay.push({
        name: 'NGR',
        value: property.ngr
      })
   }
  }
  if (props.property.pic) {
    valuesToDisplay.push({
      name: 'PIC',
      value: props.property.pic
   })
  }
  if (props.property.type === EnterpriseType.Feedlot) {
    valuesToDisplay.push({
      name: 'Production System',
      value: (props.property as GetStartedFeedlot).productionSystem as FeedlotSystemType
    })
  }

  if (props.property.type === EnterpriseType.Dairy) {
    const dairy = props.property as GetStartedDairy
    valuesToDisplay.push({
      name: 'Primary Production System',
      value: dairy.dairyProductionSystem ? dairyProductionSystems.find(dps => dps.value === dairy.dairyProductionSystem)?.name ?? "" : ""
    })
  }

  if (props.property.type === EnterpriseType.ProcessingFacility) {
    const processing = []
    if ((props.property as GetStartedProcessor).beefProcessor) processing.push('Beef')
    if ((props.property as GetStartedProcessor).sheepProcessor) processing.push('Sheep')

    valuesToDisplay.push({
      name: 'Processing',
      value: processing.join()
    })
  }

  return (
    <>
      <Row style={{
        width: '800px',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        marginBottom: '40px'
      }}>
        <Column style={{width: '250px'}}>
        <Row
          style={{
              width: "100%",
              pointerEvents: "none",
              borderRadius: " 8px",
              overflow: "hidden"
          }}
        >
          <BasicMap
            height="200px"
            attributionControl={false}
            zoomPadding={15}
            layers={[propertyLayerInfo.layer]}
            bounds={propertyLayerInfo.bounds}
          />
          </Row>
        </Column>
        <Column style={{
          flexGrow: 1,
          paddingLeft: '40px',
          alignItems: 'flex-start'
        }}>
          <div style={{marginBottom: '10px'}}>
            <Heading level={3} textAlign="start" >{props.property.name}</Heading>
          </div>
          <ValuesList 
            values={valuesToDisplay}
          />
        </Column>
      </Row>

      <LoadingButton colorScheme="green" onClick={props.onSubmit} size="large">
        Save and Continue
      </LoadingButton>
    </>
  )
}
