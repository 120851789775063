import BaseRangeSlider, { SharedRangeSliderProps, SliderType } from "./range_slider";

export type AbsoluteSliderProps = Omit<SharedRangeSliderProps, "sliderType"> & {
    initialValue: number
}

export default function AbsoluteRangeSlider(props: AbsoluteSliderProps) {
    return <BaseRangeSlider
        {...props}
        sliderType={SliderType.AbsoluteValues}
        />;
}
