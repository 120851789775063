import BaseRangeSlider, { SharedRangeSliderProps, SliderType } from "./range_slider";

export type RelativeSliderProps = Partial<SharedRangeSliderProps> & {
    initialValue: number
}

export default function RelativeChangeSlider(props: RelativeSliderProps) {

    function calculatePercentDifference(originalValue: number, change: number) {
        return originalValue + (originalValue * change);
    }

    function reverseCalculatePercentDifference (originalValue: number, newValue: number) {
        return (newValue - originalValue) / originalValue
    }

    return <BaseRangeSlider
        {...props}
        sliderType={SliderType.RelativeChangeOfPercentage}
        initialValue={calculatePercentDifference(props.prevReportValue ?? 0, props.initialValue ?? 0)}
        defaultRange={{"min": 0, "max": 100}}
        limits={{"min": 0, "max": 100}}
        onMove={(val) => {
            if (props.onMove) props.onMove(reverseCalculatePercentDifference(props.prevReportValue ?? 0, val))}
        }
        />;
}
