import styled from "styled-components";
import { ruminatiColors } from "../../utilities/colors";

type GraphButtonProps = {
    active: boolean;
    text: string;
    x: number;
    y: number;
    handleClick: () => void;
};

export default function GraphButton(props: GraphButtonProps) {
    return <StyledGraphButton {...props} onClick={props.handleClick}>
        {props.text}
    </StyledGraphButton>;
}

const StyledGraphButton = styled.button`
    width: 20px;
    height: 20px;
    border-radius: 100%;
    
    color: ${(p: GraphButtonProps) => p.active ? '#ffffff' : `${ruminatiColors.orange}`};
    border: ${(p: GraphButtonProps) => p.active ? 'none' : `1px solid ${ruminatiColors.orange_40_opaque}`};
    background-color: ${(p: GraphButtonProps) => p.active ? ruminatiColors.orange : ruminatiColors.bone};

    position: absolute;
    left: ${(p: GraphButtonProps) => p.x}px;
    top: ${(p: GraphButtonProps) => p.y}px;

    transform: translateX(-50%);

    font-family: TTInterfaces;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;

    transition: 0.5s ease background-color, color;

    @media screen and (max-width: 600px) {
        font-size: 8px;
        width: 15px;
        height: 15px;
      }
    `;
