import { useState } from "react";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";

import Screen from "../screen";
import Tabs from "../../components/tabs";
import AccountProfileTab from "./account_profile_tab";
import AccountSubscriptionTab from "./account_subscription_tab";
import AccountIntegrationsTab from "./account_integrations_tab";
import { Column, Row } from "../../components/styled_layout";
import Heading from "../../components/heading";
import OutlineButton from "../../components/buttons/outline_button";
import Icon from "../../components/icon";
import { ruminatiColors } from "../../utilities/colors";
import { logOut } from "../../services/auth_service";

type PageDetails = {
    id: string
    tabLabel: string
    tabIndex: number
    content: JSX.Element
}
const pages: PageDetails[] = [
    {   
        id: 'profile',
        tabLabel: 'Profile',
        tabIndex: 0,
        content: <AccountProfileTab />
    },
    {   id: 'subscription',
        tabLabel: 'Subscription',
        tabIndex: 1,
        content: <AccountSubscriptionTab />,
    },
    {   id: 'integrations',
        tabLabel: 'Integrations',
        tabIndex: 2,
        content: <AccountIntegrationsTab />
    }
]

function getPageById (id: string): PageDetails | undefined {
    return pages.find(p => p.id === id)
}

export default function AccountDetailsScreen() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedPage, setSelectedPage] = useState<PageDetails>(searchParams.has('tab') && getPageById(searchParams.get('tab') as string) !== undefined ? getPageById(searchParams.get('tab') as string) as PageDetails : pages[0])
    const navigate = useNavigate()

    function logoutUser () {
        logOut()
        navigate("/login");
    }

    return (
        <Screen pageTitle="Account">
            <AccountMasterContainer>
                <Row style={{ justifyContent: "space-between", marginBottom: '20px'}}>
                    <Heading level={3} >Account</Heading>
                    <OutlineButton
                        height="40px"
                        width="120px"
                        activeColor={ruminatiColors.dark_green}
                        onClick={logoutUser}
                        children={<>
                            <Icon icon="logout"/>
                            Logout
                        </>}
                    />
                </Row>
                <Row>
                    <Column style={{ alignSelf: "center", width: "100%" }}>
                        <Tabs
                            selected={selectedPage.tabIndex}
                            tabs={pages.map((v) => {
                                return {text: v.tabLabel}
                            })}
                            onTabChange={(index: number) => { 
                                const newTab = pages.find(p => p.tabIndex === index) as PageDetails
                                setSelectedPage(newTab)
                                setSearchParams({
                                    tab: newTab.id
                                })
                            }}
                            layoutType="Line"
                        />
                        <AccountPageContainer id={"account-page"}>
                            {selectedPage.content}
                        </AccountPageContainer>
                    </Column>
                </Row>
            </AccountMasterContainer>
        </Screen>
    );
}

export const AccountPageContainer = styled.div`
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
`;

export const AccountMasterContainer = styled.div`
    width: 936px;
    display: inline-flex;
    flex-direction: column;
    padding-top: 60px;
    margin-bottom: 40px;
`;
