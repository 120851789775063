import { useState } from "react";

import { Form } from "../../../models/form/form";
import { FieldSize } from "../../../models/form/form_fields/form_field";
import {
  BeefBreedingSystemParametersPage1,
  BeefBreedingSystemParametersPage2,
} from '../../../models/dse'

import CheckBoxGroup from '../../form/check_box'
import OutlineButton from "../../buttons/outline_button";
import { Row } from '../../styled_layout'
import {
  calculateBeefDseBreedingSystem,
  BeefClasses
} from '../../../utilities/dse'
import TradeCalculator from "./trade-calculator";
import { NumberFormField, RadioBoxFormField } from "../../../models/form/form_fields/basic_form_fields";
import { LabelField } from "../../../models/form/form_fields/decoration_form_fields";

export enum BeefDseStep {
  SystemType,
  BreedingSystemPage1,
  BreedingSystemPage2,
  Trading
}


const BeefBreedingSystemParametersFormPage1 = {
  fields: {
    selfReplacing: new RadioBoxFormField({
      required: true,
      placeholder: "Is your beef herd self-replacing?",
      tip: "If you retain females as replacements, select 'yes', if you purchase replacement females, select 'no'",
      options: [{
        name: "Yes",
        value: true
      }, {
        name: "No",
        value: false
      }]
    }),
    label: new LabelField({
      label: 'How many PTIC females did you have this year?'
    }),
    pticHeifers: new NumberFormField({
      required: true,
      placeholder: 'Heifers',
      size: FieldSize.Half,
      tip: "Enter the number of heifers (young females that haven't previously been joined) that were PTIC (Pregnancy Tested in Calf) this year.",
    }),
    pticAdults: new NumberFormField({
      required: true,
      placeholder: 'Cows',
      size: FieldSize.Half,
      tip: "Enter the number of cows (typically older females that have conceived or calved previously)that were PTIC (Pregnancy Tested in Calf) this year.",
    }),
    label2: new LabelField({
      label: 'What were your PTIC to Weaning Rates?'
    }),
    pticWeaningRateHeifers: new NumberFormField({
      required: true,
      placeholder: 'Heifers',
      size: FieldSize.Half,
      validator: (value) => value !== undefined && value <= 100,
      tip: "Enter the percentage of calves weaned from PTIC heifers.",
    }),
    pticWeaningRateAdults: new NumberFormField({
      required: true,
      placeholder: 'Cows',
      size: FieldSize.Half,
      validator: (value) => value !== undefined && value <= 100,
      tip: "Enter the percentage of calves weaned from PTIC cows.",
    }),
    label3: new LabelField({
      label: 'What were your joining to PTIC Rates?'
    }),
    joiningRateHeifers: new NumberFormField({
      required: true,
      placeholder: 'Heifers',
      size: FieldSize.Half,
      validator: (value) => value !== undefined && value <= 100,
      tip: "Enter the percentage of total heifers (young females that haven't previously been joined) joined that were PTIC.",
    }),
    joiningRateAdults: new NumberFormField({
      required: true,
      placeholder: 'Cows',
      size: FieldSize.Half,
      validator: (value) => value !== undefined && value <= 100,
      tip: "Enter the percentage of total cows (typically older females that have conceived or calved previously) joined that were PTIC.",
    })
  }
};

const BeefBreedingSystemParametersFormPage2 = {
  fields: {
    label: new LabelField({
      label: 'How many months after joining did you retain non-lactating cattle?'
    }),
    monthsNonLactatingHeiferRetained: new NumberFormField({
      required: true,
      placeholder: 'Heifers',
      size: FieldSize.Half,
      tip: "Enter the number of months you kept preg tested empty heifers after joining. (In a well managed herd this would be approximately 6 months).",
    }),
    monthsNonLactatingAdultRetained: new NumberFormField({
      required: true,
      placeholder: 'Cows',
      size: FieldSize.Half,
      tip: "Enter the number of months you kept preg tested empty cows after joining. (In a well managed herd this would be approximately 6 months).",
    }),
    label2: new LabelField({
      label: 'Average ages in months'
    }),
    avgAgeCalvesWeaning: new NumberFormField({
      required: true,
      placeholder: 'Average age of calves at weaning (months)',
      tip: "Enter the average age of calves at weaning. Enter age in months.",
    }),
    avgAgeSaleSteers: new NumberFormField({
      required: true,
      placeholder: 'Average age of steers at sale (months)',
      tip: "Enter the average age of steers at sale. Enter age in months.",
    }),
    avgAgeSaleHeifers: new NumberFormField({
      required: true,
      placeholder: 'Average age of heifers at sale (months)',
      tip: "Enter the average age of heifers at sale. Enter age in months.",
    }),
    avgAgeCalvingHeifers: new NumberFormField({
      required: true,
      placeholder: 'Average age of heifers at calving (months)',
      tip: "Enter the average age of heifers at first calving. Enter age in months.",
    })
  }
};

/**
 * The Beef DSE Calculator form, intended to be shown inside a popup
 */
export default function DseBeefCalculator({
  onSubmit,
  metadata,
  isAE
}: {
  onSubmit: (val: number) => void;
  metadata?: any
  isAE: boolean
}) {

  const [step, setStep] = useState<BeefDseStep>(BeefDseStep.SystemType);
  const [selectedSystems, setSelectedSystems] = useState<string[]>([]);
  const [breedingFiguresPage1, setBreedingFiguresPage1] = useState<BeefBreedingSystemParametersPage1>({
    selfReplacing: true,
    pticAdults: 0,
    pticHeifers: 0,
    pticWeaningRateAdults: 0,
    pticWeaningRateHeifers: 0,
    joiningRateAdults: 0,
    joiningRateHeifers: 0,
  })
  const [breedingDse, setBreedingDse] = useState<number>(0)

  function breedingSystemSelected(): boolean {
    return selectedSystems.indexOf('breeding') > -1
  }

  function tradingSystemSelected(): boolean {
    return selectedSystems.indexOf('trading') > -1
  }

  function setBeefSystems(selectedValues: string[]) {
    setSelectedSystems(selectedValues)
  }

  return (
    <Row style={{ width: '100%' }}>
      {step === BeefDseStep.SystemType && (
        <div>
          <Row>
            <h3>What type of Beef Systems are you running?</h3>
          </Row>
          <Row>
            <CheckBoxGroup
              name={'Beef Systems'}
              horizontal={true}
              initialValue={selectedSystems}
              options={[
                {
                  name: 'Breeding',
                  value: 'breeding'
                },
                {
                  name: 'Trading',
                  value: 'trading'
                }
              ]}
              onChange={(val) => {
                if (val === undefined) return
                setBeefSystems(val)
              }}
            />
          </Row>


          <Row style={{
            marginTop: "40px"
          }}>
            <OutlineButton
              disabled={selectedSystems.length === 0}
              width={"250px"}
              children={
                'Continue'
              }
              onClick={() => {
                if (breedingSystemSelected()) return setStep(BeefDseStep.BreedingSystemPage1)
                else if (tradingSystemSelected()) return setStep(BeefDseStep.Trading)
              }}
            />
          </Row>
        </div>
      )}

      {step === BeefDseStep.BreedingSystemPage1 && (
        <Form<BeefBreedingSystemParametersPage1>
          data={BeefBreedingSystemParametersFormPage1}
          initialValue={{
            selfReplacing: true,
            pticWeaningRateHeifers: 92,
            pticWeaningRateAdults: 92,
            joiningRateAdults: 95,
            joiningRateHeifers: 90,
          }}
          onSubmit={async (formData) => {
            setBreedingFiguresPage1(formData)
            setStep(BeefDseStep.BreedingSystemPage2)
          }}
          submitText="Continue"
          buttonWidth="30%"
          buttonSize="small"
          fieldSize="small"
        />
      )}

      {step === BeefDseStep.BreedingSystemPage2 && (
        <Form<BeefBreedingSystemParametersPage2>
          data={BeefBreedingSystemParametersFormPage2}
          initialValue={{
            monthsNonLactatingAdultRetained: 7,
            monthsNonLactatingHeiferRetained: 7,
          }}
          onSubmit={async (formData) => {
            const dse = calculateBeefDseBreedingSystem({
              ...breedingFiguresPage1,
              ...formData
            })
            setBreedingDse(dse)
            tradingSystemSelected() ? setStep(BeefDseStep.Trading) : onSubmit(dse)
          }}
          submitText={tradingSystemSelected() ? 'Continue' : `Calculate ${isAE ? 'AE' : 'DSE'}`}
          buttonWidth="30%"
          buttonSize="small"
          fieldSize="small"
        />
      )}

      {step === BeefDseStep.Trading && (
        <TradeCalculator
          dseType={'cattle'}
          availableClasses={BeefClasses}
          financialYear={metadata.financialYear}
          onSubmit={(tradingDse) => {
            onSubmit(tradingDse + breedingDse)
          }}
          isAE={isAE}
        />
      )}

    </Row>
  )
}
