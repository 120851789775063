import styled from "styled-components";
import { PropsWithChildren } from "react";

import { ruminatiColors } from "../utilities/colors";

/**
 * A horizontal line to divide content.
 */
export default function HorizontalDivider({
    color,
    padding,
    height,
    children,
}: PropsWithChildren<{
    color?: string;
    padding?: string;
    height?: string
}>) {
    const _color = color ?? ruminatiColors.green_3_30;
    const _height = height ?? "40px"
    const _padding = padding ?? "0 16px";

    return (
        <DividerWrapper style={{ padding: _padding, height: _height }}>
            <Divider style={{ backgroundColor: _color }} />
            {children && (
                <DividerChildrenWrapper>{children}</DividerChildrenWrapper>
            )}
        </DividerWrapper>
    );
}

export const DividerWrapper = styled.div`
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const DividerChildrenWrapper = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    background-color: ${ruminatiColors.bone};
    padding: 5px 8px 0 8px;

    color: ${ruminatiColors.green_3};

    font-family: "TTInterfaces", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
`;

export const Divider = styled.div`
    height: 1px;
    width: 100%;
`;
