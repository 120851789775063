export enum SubscriptionType {
    BASE = "base",
    PRIME = "prime",
}

export type SubscriptionBase = {
    type: SubscriptionType.BASE;
};

export type SubscriptionPrimeType = {
    type: SubscriptionType.PRIME;
    startDate: number;
    endDate: number;
    autoRenew: boolean;
};

export class SubscriptionPrime {
    type: SubscriptionType.PRIME;
    startDate: number;
    endDate: number;
    autoRenew: boolean;

    constructor(
        type: SubscriptionType.PRIME,
        startDate: number,
        endDate: number,
        autoRenew: boolean
    ) {
        this.type = type;
        this.startDate = startDate;
        this.endDate = endDate;
        this.autoRenew = autoRenew;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static fromJSON(json: any): SubscriptionPrime | undefined {
        if (!json || !("type" in json)) {
            return undefined;
        }

        return new SubscriptionPrime(
            json.type,
            json.startDate,
            json.endDate,
            json.autoRenew
        );
    }
}

export type Subscription = SubscriptionBase | SubscriptionPrime;

/**
 ** Data sent to POST /user/subscription.
 **/
export type SubscriptionDTO = {
    subscriptionType: SubscriptionType;
    voucherCode?: string;
};

/**
 ** Data received after sending PRIME upgrade request.
 **/
export class SubscriptionUpgradeResponse {
    checkoutUrl: string;
    constructor(checkoutUrl: string) {
        this.checkoutUrl = checkoutUrl;
    }
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static fromJSON(json: any): SubscriptionUpgradeResponse | undefined {
        if (!json || !("checkoutUrl" in json)) {
            return undefined;
        }

        return new SubscriptionUpgradeResponse(json.checkoutUrl);
    }
}
