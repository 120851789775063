import { flatten } from "lodash-es"
import { FormData, FormState } from "../../../../models/form/form";
import { getCurrentFinancialYear } from "../../../../utilities/dates";
import { InitiativePopUp } from "../initiative_popup";
import { ManageRationsInputs, ManageRationsForm } from "../../../../models/reduction_plans_models";
import { Report } from "../../../../models/property_models";
import { RelativeChangeSliderFormField } from "../../../../models/form/form_fields/basic_form_fields";
import { getYearField, getDefaultReductionPlanYearOptions, addExistingReductionPlanYearsToOptions } from "../initiative_helpers";
import { FieldSize } from "../../../../models/form/form_fields/form_field";
import { calculateAverageOfArray } from "@/utilities/maths";
import { ReportDairyLivestock } from "@/models/report";
import { SeparatorField } from "@/models/form/form_fields/decoration_form_fields";
import { InfoCardText } from "@/components/card";

function getAllCrudeProteinValues (livestockClasses: ReportDairyLivestock[]): number[] {
  return flatten(livestockClasses?.map(mc => [mc.crudeProteinIntake.spring, mc.crudeProteinIntake.summer, mc.crudeProteinIntake.autumn, mc.crudeProteinIntake.winter]))
}

function getAllDMDValues (livestockClasses: ReportDairyLivestock[]): number[] {
  return flatten(livestockClasses?.map(mc => [mc.dryMatterDigestability.spring, mc.dryMatterDigestability.summer, mc.dryMatterDigestability.autumn, mc.dryMatterDigestability.winter]))
}

export default function ManageDairyRationsPopUp(props: {
    initialData?: ManageRationsInputs;
    compareReport?: Report;
    onSubmit: (data: FormState) => Promise<void>
}) {
    const yearOptions = getDefaultReductionPlanYearOptions()
    const existingReductionPlanYears = [
      props.initialData?.dairyRations?.targetYear
    ].filter(v => v !== undefined) as number[]
    addExistingReductionPlanYearsToOptions(existingReductionPlanYears, yearOptions)
    
    const rationData = props.initialData

    const milkingCows = props.compareReport?.dairyCattleClassInformation?.filter(c => c.classId === 1)
    const nonmilkingCows = props.compareReport?.dairyCattleClassInformation?.filter(c => c.classId !== 1)
    const avgProteinMilkingCows = calculateAverageOfArray(getAllCrudeProteinValues(milkingCows ?? []))
    const avgProteinNonMilkingCows = calculateAverageOfArray(getAllCrudeProteinValues(nonmilkingCows ?? []))
    const avgDmdMilkingCows = calculateAverageOfArray(getAllDMDValues(milkingCows ?? []))
    const avgDmdNonMilkingCows = calculateAverageOfArray(getAllDMDValues(nonmilkingCows ?? []))

    const initialData: ManageRationsInputs = rationData ?? {
      dairyRations: {
        avgProteinChangeMilkingCows: 0,
        avgProteinChangeNonMilkingCows: 0,
        avgDmdChangeMilkingCows: 0,
        avgDmdChangeNonMilkingCows: 0,
        targetYear: getCurrentFinancialYear()
      }
    }

    const formData: FormData<ManageRationsForm, ManageRationsInputs> = {
        fields: {
          avgProteinChangeMilkingCows: new RelativeChangeSliderFormField({
            required: true,
            sliderProps: {
              initialValue: initialData.dairyRations.avgProteinChangeMilkingCows,
              prevReportValue: avgProteinMilkingCows,
              topIndicatorPrefix: 'Season Avg: ',
              label: 'Crude Protein Intake for Milking Cows'
            }
          }),
          avgProteinChangeNonMilkingCows: new RelativeChangeSliderFormField({
            required: true,
            sliderProps: {
              initialValue: initialData.dairyRations.avgProteinChangeNonMilkingCows,
              prevReportValue: avgProteinNonMilkingCows,
              topIndicatorPrefix: 'Season Avg: ',
              label: 'Crude Protein Intake for Non-Milking Cows'
            }
          }),
          seperator: new SeparatorField(),
          avgDmdChangeMilkingCows: new RelativeChangeSliderFormField({
            required: true,
            sliderProps: {
              initialValue: initialData.dairyRations.avgDmdChangeMilkingCows,
              prevReportValue: avgDmdMilkingCows,
              topIndicatorPrefix: 'Season Avg: ',
              label: 'Dry Matter Digestability for Milking Cows'
            }
          }),
          avgDmdChangeNonMilkingCows: new RelativeChangeSliderFormField({
            required: true,
            sliderProps: {
              initialValue: initialData.dairyRations.avgDmdChangeNonMilkingCows,
              prevReportValue: avgDmdNonMilkingCows,
              topIndicatorPrefix: 'Season Avg: ',
              label: 'Dry Matter Digestability for Non-Milking Cows'
            }
          }),
          targetYear: getYearField('Year of completion', FieldSize.Full, yearOptions)
        },
        transformer: {
          in: (value) => {
            return value.dairyRations
          },
          out: (value) => {
            return {
              dairyRations: value
            }
          },
        }
    };
    return (
        <>
        <InitiativePopUp
            formData={formData}
            initialFormValues={initialData}
            onSubmit={(state) => props.onSubmit(state)}
            infoAbove={
              <>
                <InfoCardText>Use the sliders below to increase or decrease Crude Protein Intake or Dry Matter Digestability. <u>The Relative Change from your previously completed report is applied to each season.</u></InfoCardText>
                <InfoCardText style={{marginTop: '8px'}}><strong>Note:</strong> Increasing CPI or DMD will likely increase your Total Emissions but reduce your Emissions Intensity.</InfoCardText>
              </>}
        />
    </>);
}
