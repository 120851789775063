import { useSearchParams } from "react-router-dom";

import { PopupData, usePopupStore } from "../../state/popup_store";
import DSECalculator, {
    DSECalculatorType,
    isCalculatorType,
} from "./dse_calculator";
import DrawAndCalculateAreaCalculator, { DrawAndCalculateAreaPopupReturns } from "./draw_and_calculate_area_calculator";
import ElectricityUseCalculator from "./electricity_use_calculator";
import Popup from "./popup";
import ConfirmationPopup from "./confirmation-popup";
import InformationPopup from "./information-popup";
import { formatFinancialYearStringToSimpleFinancialYearString } from "../../utilities/dates";
import PlantTreesPopup from "./initiatives/absorb_emissions/plant_trees_popup";
import ManageLivestockPopUp from "./initiatives/avoid_emissions/manage_livestock_popup";
import ManageLiveStockClassProductionPopUp from "./initiatives/avoid_emissions/manage_livestockclasses_production_popup";
import ManagePasturePopUp from "./initiatives/avoid_emissions/manage_pasture_popup";
import ManageConsumablesPopUp from "./initiatives/avoid_emissions/manage_consumables_popup";
import ManagePasturePost_V3_4_Popup from "./initiatives/avoid_emissions/manage_pasture_post_V3_4_popup";

import {
    InitiativeType,
    initiativeNamesMap,
} from "../../models/reduction_plans_models";
import AllowRevegetationPopup from "./initiatives/absorb_emissions/allow_revegetation_popup";
import ManageSupplementaryFeedPopup from "./initiatives/avoid_emissions/manage_supplementary_feed_popup";
import ManageCropInputsPopup from "./initiatives/avoid_emissions/manage_crop_inputs_popup";
import ImproveSoilPopup from "./initiatives/absorb_emissions/improve_soil_popup";
import EditPropertyBoundsPopup from "./property_bounds_editor";
import { CorporatePartnerPopupId } from "../../screens/corporate_partners/corporate_partners";
import { InitiativesPopupId } from "../../screens/reduction_planner/initiatives_tab";
import { SubscriptionPopupId } from "../../screens/account/account_subscription_tab";
import { FarmsPopupId } from "../../screens/farms";
import { FarmReportsPopupId } from "../../screens/farm/farm";
import SharingSummary from "../sharing_summary";
import { BodyText } from "../styled_text";
import { Row, Column } from "../styled_layout";
import ExternalLinkPopup from "./external-link-popup";
import { ruminatiColors } from "../../utilities/colors";
import DropdownPopup from "./dropdown-popup";
import { CountryCode } from "../../utilities/countries";
import { addUserCountry } from "../../services/auth_service";
import { useAuthStore } from "../../state/auth_store";
import { AppUserPopupId } from "../../guards/UserCountryGuard";
import { AccountProfilePopupId } from "../../screens/account/account_profile_tab";
import UserProfilePopup from "./user-profile-popup";

import ConnectCorporatePartnerPopup from "./corporatePartners/connect-corporate-partner";
import LPGUseCalculator from "./lpg_usage_calculator";
import FuelUseCalculator from "./fuel_use_popup";
import VoucherAndSubscriptionPopup from "./corporatePartners/voucher-subscription-acknowledgement";
import DairyManureManagementCalculator, { DairyManureManagementCalculatorPopupID } from "./dairy_manure_management_popup";
import ManageDairyRationsPopUp from "./initiatives/avoid_emissions/manage_dairy_rations";
import ManageDairyManurePopUp from "./initiatives/avoid_emissions/manage_dairy_manure";
import ManageDairyLivestockProductionPopUp from "./initiatives/avoid_emissions/manage_dairy_livestock_production";
import MilkCalculatorPopup, { MilkCalculatorPopupID } from "./milk_calculator";
import { HerbicideOrPesticideUse, SeasonalFigures } from "@/models/report";
import FertiliserUsePopup, { FertiliserUserPopupId } from "./fertiliser-popup";
import { FertiliserUse } from "@/models/report";
import HerbicideUsePopup, { HerbicidePopupId } from "./herbicide-popup";
import ManageCattlePurchaseAverageIntensityPopup from "./initiatives/avoid_emissions/manage_cattle_purchase_average_intensity_popup";
import ManageFeedAdditivesPopup from "./initiatives/avoid_emissions/manage_feed_additives_popup";

/**
 * A wrapper component for the popup which handles the logic behind
 * displaying the popups.
 */

type PopupStoreContent = {
    title?: string
    content: JSX.Element,
    preventClose?: boolean,
    onClose?: () => void
}

export default function PopupManager() {
    const popupStore = usePopupStore();
    const authStore = useAuthStore();
    const [searchParams, setSearchParams] = useSearchParams()

    const buildPopup = (data: PopupData): PopupStoreContent | undefined => {
        if (data.id === "confirmation") {
            return {
                title:  data.metadata?.title ?? "Confirm",
                content: (
                <ConfirmationPopup
                    text={data.metadata?.text ?? "Please confirm the action"}
                    buttonText={data.metadata?.buttonText ?? "Submit"}
                    onConfirm={() => {
                        if (data.metadata?.onConfirm) data.metadata?.onConfirm()
                    }}
                />)
            };
        } else if (isCalculatorType(data.id ?? "")) {
            let title = "Calculate";
            title += data.id.startsWith("beef") ? " Beef" : " Sheep";
            title += data.id.endsWith("dse") ? " DSE" : " AE";
            const fyString = formatFinancialYearStringToSimpleFinancialYearString(data.metadata.financialYear);
            title += ` (${fyString} Financial Year)`;
            return {
                title,
                content: (
                    <DSECalculator
                        type={data.id as DSECalculatorType}
                        onSubmit={(val: number) => {
                            if (data.callback) data.callback(val);
                            popupStore.closePopup();
                        }}
                        metadata={data.metadata}
                    />
                )
            };
        } else if (data.id === "DrawAndCalculateAreaPopup") {
            return {
                title: "Draw & Calculate Area",
                content: (
                    <DrawAndCalculateAreaCalculator
                        onSubmit={(obj: DrawAndCalculateAreaPopupReturns) => {
                            if (data.callback) {
                                data.callback(obj);
                            }
                            popupStore.closePopup();
                        }}
                        metadata={data.metadata}
                    />
                )
            };
        } else if (data.id === DairyManureManagementCalculatorPopupID) {
            return {
                title: "Calculate Manure Management",
                content: (
                    <DairyManureManagementCalculator 
                      onSubmit={(val) => {
                        if (data.callback) {
                            data.callback(val);
                        }
                        popupStore.closePopup();
                    }}/>
                )
            }
        } else if (data.id === "ElectricityUsePopup") {
            return {
                title: "Calculate Electricity Use", 
                content: (
                    <ElectricityUseCalculator
                        onSubmit={(val: number) => {
                            if (data.callback) {
                                data.callback(val);
                            }
                            popupStore.closePopup();
                        }}
                        metadata={data.metadata}
                    />
                )
            };
        } else if (data.id === "LPGUsageCalculatorPopup") {
            return {
                title: "Calculate LPG Usage", 
                content: (
                    <LPGUseCalculator
                        onSubmit={(val: number) => {
                            if (data.callback) {
                                data.callback(val);
                            }
                            popupStore.closePopup();
                        }}
                        metadata={data.metadata}
                    />
                )
            };
        } else if (data.id === FertiliserUserPopupId) {
            return {
                title: "Add Fertilisers", 
                content: (
                    <FertiliserUsePopup
                        metadata={data.metadata}
                        onSubmit={(val: FertiliserUse[]) => {
                            if (data.callback) {
                                data.callback(val);
                            }
                            popupStore.closePopup();
                        }}
                    />
                )
            };
        } else if (data.id === HerbicidePopupId) {
            return {
                title: "Add Herbicides & Pesticides", 
                content: (
                    <HerbicideUsePopup
                        metadata={data.metadata}
                        onSubmit={(val: HerbicideOrPesticideUse[]) => {
                            if (data.callback) {
                                data.callback(val);
                            }
                            popupStore.closePopup();
                        }}
                    />
                )
            };
        } else if (data.id === "FuelUsePopup") {
            return {
                title: `Calculate ${data.metadata.fuelType} Use`, 
                content: (
                    <FuelUseCalculator
                        onSubmit={(val: number) => {
                            if (data.callback) {
                                data.callback(val);
                            }
                            popupStore.closePopup();
                        }}
                        metadata={data.metadata}
                    />
                )
            };
        } else if (data.id === MilkCalculatorPopupID) {
            return {
                title: `Calculate Milk Production`, 
                content: (
                    <MilkCalculatorPopup
                        onSubmit={(val: SeasonalFigures) => {
                            if (data.callback) {
                                data.callback(val);
                            }
                            popupStore.closePopup();
                        }}
                        metadata={data.metadata}
                    />
                )
            };
        } else if (data.id === InitiativeType.ImproveSoilCarbon) {
            return {
                title: initiativeNamesMap[InitiativeType.ImproveSoilCarbon],
                content: <ImproveSoilPopup
                    initialData={data.metadata?.initial}
                    repeatedInitialData={data.metadata?.repeatedInitial}
                    propertyBounds={data.metadata.propertyBounds}
                    maxAllowableOrganicCarbonValue={data.metadata.maxAllowableOrganicCarbonValue}
                    existingTrees={data.metadata.propertyTrees}
                    otherReductionPlans={data.metadata.otherReductionPlans}
                    onSubmit={data.metadata.onSubmit}
                />
            };
        } else if (data.id === InitiativeType.PlantTrees || data.id === InitiativeType.PlantTreesNZ) {
            return {
                title: initiativeNamesMap[data.id],
                content: <PlantTreesPopup
                    initialData={data.metadata?.initial}
                    propertyBounds={data.metadata.propertyBounds}
                    existingTrees={data.metadata.propertyTrees}
                    otherReductionPlans={data.metadata.otherReductionPlans}
                    onSubmit={data.metadata.onSubmit}
                />
            };
        } else if (data.id === InitiativeType.AllowRevegetation) {
            return {
                title: initiativeNamesMap[InitiativeType.AllowRevegetation],
                content: <AllowRevegetationPopup
                    initialData={data.metadata?.initial}
                    propertyBounds={data.metadata.propertyBounds}
                    existingTrees={data.metadata.propertyTrees}
                    otherReductionPlans={data.metadata.otherReductionPlans}
                    onSubmit={data.metadata.onSubmit} />
            };
        } else if (data.id === InitiativeType.ManageLivestock) {
            return {
                title: initiativeNamesMap[InitiativeType.ManageLivestock], 
                content: <ManageLivestockPopUp 
                initialData={data.metadata?.initial} 
                compareReport={data.metadata?.report} 
                onSubmit={data.metadata.onSubmit} 
                />
            };
        } else if (data.id === InitiativeType.ManageLivestockClassesProduction || data.id === InitiativeType.ManageLivestockClassesProductionNZ) {
            return {
                title: initiativeNamesMap[InitiativeType.ManageLivestockClassesProduction],
                content: <ManageLiveStockClassProductionPopUp
                    initialData={data.metadata?.initial}
                    compareReport={data.metadata?.report}
                    onSubmit={data.metadata.onSubmit} />
            };
        } else if (data.id === InitiativeType.ManagePasture) {
            return {
                title: initiativeNamesMap[InitiativeType.ManagePasture],
                content: <ManagePasturePopUp 
                initialData={data.metadata?.initial}
                compareReport={data.metadata?.report}
                onSubmit={data.metadata.onSubmit} 
                />
            };
        } else if (data.id === InitiativeType.ManagePasture_Post_V3_4) {
            return {
                title: initiativeNamesMap[InitiativeType.ManagePasture_Post_V3_4],
                content: <ManagePasturePost_V3_4_Popup 
                initialData={data.metadata?.initial}
                compareReport={data.metadata?.report}
                onSubmit={data.metadata.onSubmit} 
                />
            };
        } else if (data.id === InitiativeType.ManageConsumables) {
            return {
                title: initiativeNamesMap[InitiativeType.ManageConsumables],
                content: <ManageConsumablesPopUp 
                initialData={data.metadata?.initial}
                compareReport={data.metadata?.report}
                onSubmit={data.metadata.onSubmit} />
            };
        } else if (data.id === InitiativeType.ManageSupplement) {
            return {
                title: initiativeNamesMap[InitiativeType.ManageSupplement],
                content: <ManageSupplementaryFeedPopup 
                initialData={data.metadata?.initial}
                compareReport={data.metadata?.report}
                onSubmit={data.metadata.onSubmit} 
                />
            };
        } else if (data.id === InitiativeType.ManageCrops) {
            return {
                title: initiativeNamesMap[InitiativeType.ManageCrops],
                content: <ManageCropInputsPopup 
                initialData={data.metadata?.initial} 
                compareReport={data.metadata?.report} 
                onSubmit={data.metadata.onSubmit} />
            };
        } else if (data.id === InitiativeType.DairyRations) {
            return {
                title: initiativeNamesMap[InitiativeType.DairyRations],
                content: <ManageDairyRationsPopUp
                initialData={data.metadata?.initial} 
                compareReport={data.metadata?.report} 
                onSubmit={data.metadata.onSubmit}
                />
            }
        } else if (data.id === InitiativeType.DairyManure) {
            return {
                title: initiativeNamesMap[InitiativeType.DairyManure],
                content: <ManageDairyManurePopUp
                initialData={data.metadata?.initial} 
                compareReport={data.metadata?.report} 
                onSubmit={data.metadata.onSubmit}
                />
            }
        } else if (data.id === InitiativeType.DairyLivestockProduction) {
            return {
                title: initiativeNamesMap[InitiativeType.DairyLivestockProduction],
                content: <ManageDairyLivestockProductionPopUp
                initialData={data.metadata?.initial} 
                compareReport={data.metadata?.report} 
                onSubmit={data.metadata.onSubmit}
                />
            }
        } else if (data.id === InitiativeType.CattlePurchaseAverageIntensity) {
            return {
                title: initiativeNamesMap[InitiativeType.CattlePurchaseAverageIntensity],
                content: (
                    <ManageCattlePurchaseAverageIntensityPopup
                        initialData={data.metadata?.initial}
                        compareReport={data.metadata?.report}
                        onSubmit={data.metadata.onSubmit}
                    />
                ),
            }
        } else if (data.id === InitiativeType.FeedAdditives) {
            return {
                title: initiativeNamesMap[InitiativeType.FeedAdditives],
                content: (
                    <ManageFeedAdditivesPopup
                        initialData={data.metadata?.initial}
                        compareReport={data.metadata?.report}
                        onSubmit={data.metadata.onSubmit}
                    />
                ),
            }
        } else if (data.id === "property-bounds") {
            return {
                title: "Edit Property Bounds", 
                content: <EditPropertyBoundsPopup 
                    geom={data.metadata.geom} 
                    onSubmit={(bounds) => {
                        if (data.callback) data.callback(bounds)
                        popupStore.closePopup()
                    }} 
                />
            }
        } else if (data.id === FarmsPopupId.BasePlanLimit) {
            return {
                title: "You can't create any more Farms",
                content: <ConfirmationPopup
                    text={'Because you are on the Ruminati BASE Plan you can only create 1 Farm. Upgrade to Ruminati PRIME to create multiple farms.'}
                    buttonText="Got it"
                />
            };
        } else if (data.id === FarmsPopupId.PlayVideo) {
          return {
            content: <iframe width="800" height="450" src="https://www.youtube.com/embed/BAiaLO0fDXk?si=erQb3cj8qFywHL_z" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
          }
        } else if (data.id === FarmReportsPopupId.BasePlanReportLimit) {
            return {
                title: "You can't create any more Reports",
                content: <ConfirmationPopup
                    text={'Because you are on the Ruminati BASE Plan you can only create 1 Report. Upgrade to Ruminati PRIME to create multiple reports.'}
                    buttonText="Got it"
                />
            };
        } else if (data.id === CorporatePartnerPopupId.ConnectPartner) {
            return {
                title: `Share ${data.metadata.shareAll ? 'all' : ''} data with ${data.metadata.visionOrgName}`,
                content: <ConnectCorporatePartnerPopup 
                    inviteId={data.metadata.inviteId} 
                    shareAll={data.metadata.shareAll}
                    onSubmit={data.metadata.onSubmit}
                />,
                onClose: () => {
                    searchParams.delete('inviteId')
                    setSearchParams(searchParams)
                }
            };
        } else if (data.id === CorporatePartnerPopupId.RemoveShareAll) { 
            return {
            title: "Disable Share All?",
            content: <ConfirmationPopup
                text={`Disabling the Share All setting will prevent any newly created Enterprises from being shared with ${data.metadata.visionOrganisationName}. \n \n Existing enterprises that you've shared will need to be removed individually via the Corporate Partners page.`}
                buttonText="Continue"
                onConfirm={() => data.metadata.onConfirm()}
            />
            }; 
        } else if (data.id === CorporatePartnerPopupId.RemoveLinkedEnterprise) { 
            return {
            title: "Disable data sharing?",
            content: <ConfirmationPopup
                text={`Are you sure you want to stop sharing data from ${data.metadata.enterpriseName} with ${data.metadata.visionOrgName}?`}
                buttonText="Yes"
                onConfirm={() => data.metadata.onConfirm()}
            />
            }; 
        } else if (data.id === CorporatePartnerPopupId.NoPartners) {
            return {
                title: "No Corporate Partners",
                content: <ConfirmationPopup
                    text={'At this stage, none of our corporate partners have requested your data'}
                    buttonText="Got it"
                />
            };
        } else if (data.id === CorporatePartnerPopupId.ShareDetails) {
            return {
                title: "Share Details",
                content: <InformationPopup
                    children={
                        <Row>
                            <Column>
                                {SharingSummary(data.metadata.isAdvancedShare)}
                            </Column>
                        </Row>
                    }
                    width="880px"
                    buttonText="Got it"
                />
            };
        } else if (data.id === SubscriptionPopupId.PaymentStarting) {
            return {
                title: "Proceed to our Secure Payment Gateway",
                content: <ExternalLinkPopup
                    content={
                        <>
                            <BodyText style={{
                                color: ruminatiColors.green_3,
                                textAlign: "center",
                                letterSpacing: "0.28px",
                                padding: "0 24px",
                            }}>
                                You're about to be taken to our secure payment gateway to complete the upgrade of your subscription.
                            </BodyText>
                            <BodyText style={{
                                color: ruminatiColors.green_3,
                                textAlign: "center",
                                letterSpacing: "0.28px",
                                padding: "0 24px",
                            }}>
                                You can turn off your subscription at any time and your card will not be charged on your renewal date.
                            </BodyText>
                        </>
                    }
                    buttonText="Got it"
                    href={data.metadata.href}
                    target={'_self'}
                />
            };
        } else if (data.id === SubscriptionPopupId.PaymentAndShareStarting) {
            if (!('invite' in data.metadata)) return
            return {
                title: `Proceed with Upgrading and sharing with ${data.metadata.invite.visionOrgName}`,
                content: <VoucherAndSubscriptionPopup 
                    href={data.metadata.href}
                    invite={data.metadata.invite}
                />
            };
        } else if (data.id === InitiativesPopupId.NoPreviousCrops) {
            return {
                title: "Unable to use Crop Planner",
                content: <ConfirmationPopup
                    text={"The last report you completed didn't contain any crop information so at this stage you can't use the crop planner tool."}
                    buttonText="Got it"
                />};
        } else if (data.id === FarmsPopupId.DeleteFarm) {
            return {
                title: "Delete this Farm?",
                content: <ConfirmationPopup
                    text={`Are you sure you'd like to delete ${data.metadata?.propertyName}?`}
                    buttonText="Yes"
                    onConfirm={data.metadata?.onConfirm}
                />
            };
        } else if (data.id === FarmReportsPopupId.DeleteOrArchiveReport) {
            return {
                title: `${data.metadata?.removalType} this Report?`,
                content: <ConfirmationPopup
                    text={`Are you sure you'd like to ${data.metadata?.removalType.toLowerCase()} this report?`}
                    buttonText="Yes"
                    onConfirm={data.metadata?.onConfirm}
                />
            };
        } else if (data.id === AppUserPopupId.CountrySelection) {
            return {
                title: "Select your country",
                content: <DropdownPopup
                    buttonText="Continue"
                    preText="Select the country that your Enterprises are in."
                    onConfirm={async (selectedCountry?: CountryCode) => {
                        try {
                            if (!selectedCountry) {
                                throw new Error("[popup_manager.tsx] Cannot update country if not provided")
                            }
                            const updatedUser = await addUserCountry(selectedCountry);
                            if (updatedUser) authStore.signIn(updatedUser);
                        } catch (error) {
                            // TODO: Handle error?
                        }
                    }}
                    {...data.metadata}
                />,
                preventClose: true
            }
        } else if (data.id === AccountProfilePopupId.Edit) {
            return {
                title: `Edit User Profile`,
                content: <UserProfilePopup />
            }
        } else if (data.id === AccountProfilePopupId.ChangePassword) {
            return {
                title: 'Update Password',
                content: <ConfirmationPopup
                    text={
                        "We've just sent you an email with further instructions for changing your password."
                    }
                    buttonText="Continue"
                />
            };
        } 
    }

    function isWidePopup(popup: PopupData): boolean {
        const widePopupIds = ["DrawAndCalculateAreaPopup", "sheep-dse", "beef-dse", FarmsPopupId.PlayVideo]
        return widePopupIds.indexOf(popup.id) > -1
    }

    return <>{popupStore.popupStack.map((p, index) => {
        const popup = buildPopup(p)
        if (popup === undefined) return undefined

        return <Popup
            key={`${popup.title}${index}`}
            enabled={index < popupStore.popupStack.length}
            headerText={popup.title}
            onClose={() => {
                if (popup.onClose) popup.onClose()
                popupStore.closePopup();
            }}
            width={isWidePopup(p) ? 800 : undefined}
            preventClose={popup.preventClose}
        >
            {popup.content}
        </Popup>
    })}
    </>
}
