export enum DataRequestType {
  Standard = "standard",
  Advanced = "advanced"
}

/**
 * A DTO containing the required information to initiate a Farm Linkage
 * @typedef {object} VisionInvitation
 * @property {string} vision_org_id - The id of the vision organisation
 * @property {string} property_ids - The ids of the properties to share
 */
export type VisionInvitation = {
  id: string
  visionOrgId: string;
  visionOrgName: string;
  visionOrgIcon?: string;
  created: string;
  email: string;
  dataRequestType: DataRequestType
  otherVisionOrgs?: {id: string, name: string}[]
}