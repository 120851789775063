import { usePopupStore } from "../../state/popup_store";
import SubButton from "./sub_button";
import { useDatabaseStore } from "../../state/database_store";
import { useParams } from "react-router-dom";
import { Report } from "../../models/property_models";
import { getReport } from "../../utilities/functions";

type DSEButtonProps = {
    unit: "dse" | "ae";
    type: "beef" | "sheep";
    onSubmit: (val: number) => void;
};

/**
 * A button which shows the DSE popup on click
 */
export default function DSEButton(props: DSEButtonProps) {
    const popupStore = usePopupStore();
    const databaseStore = useDatabaseStore();
    const { reportId } = useParams();

    const report: Report | undefined = getReport(
        databaseStore.reports,
        reportId
    );

    return (
        <div style={{ padding: "0 0 0 8px" }}>
            <SubButton
                colorScheme="orange"
                onClick={() =>
                    popupStore.addPopup(
                        `${props.type}-${props.unit}`,
                        props.onSubmit,
                        {
                            financialYear: report?.financialYear
                        }
                    )
                }
            >
                Calculate {props.unit === "dse" ? "DSE" : "AE"}
            </SubButton>
        </div>
    );
}
