import { MultiPolygon } from "geojson";
import { FormData, FormState } from "../../../../models/form/form";
import CalculateAreaButton from "../../../buttons/calculate_area_button";
import { DrawAndCalculateAreaPopupReturns } from "../../draw_and_calculate_area_calculator";
import { InitiativePopUpRepeatable } from "../initiative_popup";
import { AllowRevegetationDTO, InitiativeType } from "../../../../models/reduction_plans_models";
import { ReportTrees } from "../../../../models/report";
import { LegendItem, LegendSwatch, LegendGroupLabel } from '../../../maps/legend'
import { ReductionPlan } from "../../../../models/reduction_plans_models";
import { TextFormField } from "../../../../models/form/form_fields/basic_form_fields";
import { MultiPolygonFormField } from "../../../../models/form/form_fields/multi_polygon_form_field";
 import { 
    setupReductionPlannerLayers,
 } from "../../../maps/layer_config/reduction_planner"
import { getYearField, getDefaultReductionPlanYearOptions } from "../initiative_helpers";
import { FieldSize } from "../../../../models/form/form_fields/form_field";

interface AllowRevegetationFormData {
    name: string;
    geom: MultiPolygon;
    startYear: number;
}

export default function AllowRevegetationPopup(props: {
    initialData?: AllowRevegetationDTO[];
    propertyBounds: MultiPolygon;
    existingTrees?: ReportTrees[] | undefined;
    otherReductionPlans: ReductionPlan[] | undefined;
    onSubmit: (value: AllowRevegetationDTO[]) => Promise<void>
}) {
    const repeatableSectionName = "Plantation Area";

    const yearOptions = getDefaultReductionPlanYearOptions()

    const formData: FormData<AllowRevegetationFormData, AllowRevegetationDTO> = {
        fields: {
            name: new TextFormField({
                required: true,
                label: "Revegetation area name",
            }),
            geom: new MultiPolygonFormField({
                required: true,
                label: "Area of planting",
                unit: 'Ha',
                children: (state) => {
                    const {
                        proposedTreePlantingsLayerAndLegend,
                        proposedSoilCarbonLayerAndLegend,
                        proposedAllowRevegLayerAndLegend,
                        existingTreeLayerAndLegend
                    } = setupReductionPlannerLayers(
                        InitiativeType.AllowRevegetation,
                        state,
                        props.otherReductionPlans,
                        props.existingTrees
                    )

                    return (
                        <CalculateAreaButton
                            state={state}
                            geom={state.get.geom}
                            propertyGeom={props.propertyBounds}
                            additionalLayers={[
                                existingTreeLayerAndLegend.layer,
                                proposedSoilCarbonLayerAndLegend.layer,
                                proposedTreePlantingsLayerAndLegend.layer,
                                proposedAllowRevegLayerAndLegend.layer
                            ]}
                            additionalLegendItems={[
                                existingTreeLayerAndLegend.legend,
                                <LegendGroupLabel key="planned-plantings-label">
                                    Planned Activities
                                </LegendGroupLabel>,
                                proposedSoilCarbonLayerAndLegend.legend,
                                proposedTreePlantingsLayerAndLegend.legend,
                                proposedAllowRevegLayerAndLegend.legend,
                                <LegendItem key="this-activity-legend">
                                    <LegendSwatch color={'#F3DA064D'} borderColor={'#F3DA06'} borderWidth={'2px'} />
                                    This Activity
                                </LegendItem>,
                            ]}
                            footerText={"Draw areas where you intend on allowing natural regeneration."}
                            onSubmit={(obj: DrawAndCalculateAreaPopupReturns) => {
                                if (obj.geom) {
                                    state.set({
                                        ...state.get,
                                        geom: obj.geom
                                    });
                                }
                            }}
                        />
                    )
                },
            }),
            startYear: getYearField('Year', FieldSize.Full, yearOptions),
        },
    };

    return (
        <InitiativePopUpRepeatable
            repeatableSectionName={repeatableSectionName}
            formData={formData}
            initialFormValues={Array.from(props.initialData ?? [{}] as Partial<FormState>[])}
            onSubmit={(state) => props.onSubmit(state as AllowRevegetationDTO[])}
        />
    );
}