import { baseUrl, get, post } from './api_service';
import { getTokens } from './auth_service';
import { AgriWebbFarm, SeasonalAnimalResults, SeasonalMobResults } from '../models/agriwebb'
import { Integrations } from '../models/integrations'

import { Polygon } from 'geojson';

export const baseAgriWebbUrl = `${baseUrl}/connect/agriwebb`;

export function openAgriWebbOAuthFlow(): void {
  window.location.assign(`${baseAgriWebbUrl}/install`)
}

export async function disconnectAgriWebbIntegration(): Promise<Integrations | undefined> {
  const response = await post(`${baseAgriWebbUrl}/disconnect`, undefined, getTokens().token)
  return response
}

export async function getAgriWebbBoundaries(farmId: string): Promise<Polygon[] | undefined> {
  const response = await get(`${baseAgriWebbUrl}/farm_boundaries?farmIds=${farmId}`, getTokens().token);
  return response;
}

export async function getAgriWebbAnimals(farmId: string, animalType: string, financialYear: number): Promise<SeasonalAnimalResults | undefined> {
  const response = await get(`${baseAgriWebbUrl}/animals?farmIds=${farmId}&animalType=${animalType}&financialYear=${financialYear}`, getTokens().token);
  return response;
}

export async function getAgriWebbAnimalGroups(farmId: string, financialYear: number): Promise<SeasonalMobResults | undefined> {
  const response = await get(`${baseAgriWebbUrl}/mobs-via-report?farmId=${farmId}&financialYear=${financialYear}`, getTokens().token);
  return response;
}

export async function getAgriWebbAnimalCount(farmId: string, animalType: string, financialYear: number): Promise<{nonPagedCount: number} | undefined> {
  const response = await get(`${baseAgriWebbUrl}/animal_count?farmIds=${farmId}&animalType=${animalType}&financialYear=${financialYear}`, getTokens().token);
  return response;
}

export async function getAgriWebbFarms(): Promise<AgriWebbFarm[] | undefined> {
  const response = await get(`${baseAgriWebbUrl}/farms`, getTokens().token);
  return response;
}
