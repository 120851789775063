import styled from "styled-components";
import { notification, type NotificationArgsProps } from "antd";

import { BaseText, BodyText } from "@/components/styled_text";

import { ruminatiColors } from "./colors";

interface OpenNotificationError {
  heading: string;
  message: string;
  cause?: string;
  opts?: NotificationArgsProps;
}

// N.B. using the "deprecated" notification API here
export function openNotificationError ({
  heading,
  message,
  cause,
  opts
}: OpenNotificationError) {
  notification.config({ top: 100 })

  notification.open({
    message: (
      <NotificationHeadingText>{heading}</NotificationHeadingText>
    ),
    description: <>
      <NotificationDescriptionText>{message}</NotificationDescriptionText>
      {cause && <><br/><NotificationDescriptionText><strong>Cause:</strong> {cause}</NotificationDescriptionText></>}
    </>,
    placement: 'topRight',
    duration: 0,
    type: 'error',
    style: {
      backgroundColor: ruminatiColors.bone,
      borderRadius: '8px'
    },
    ...opts
  })
}

// TODO: add more defaults

const NotificationHeadingText = styled(BaseText)`
  font-family: "Approach", sans-serif;
  color: ${ruminatiColors.effective_black};
  font-weight: 600;
  text-align: left;
`;

const NotificationDescriptionText = styled(BodyText)`
  color: ${ruminatiColors.effective_black};
  line-height: 1.4;
`;