import { FormData } from "../../../../models/form/form";
import { getCurrentFinancialYear } from "../../../../utilities/dates";
import { InitiativePopUp } from "../initiative_popup";
import { Report } from "../../../../models/property_models";
import { SliderFormField } from "../../../../models/form/form_fields/basic_form_fields";
import { getYearField, getDefaultReductionPlanYearOptions, addExistingReductionPlanYearsToOptions } from "../initiative_helpers";
import { FieldSize } from "../../../../models/form/form_fields/form_field";
import { ManageCattlePurchaseAverageIntensityDTO } from "@/models/reduction_plans_models";
import EmissionUnit from "@/models/emission-unit";

interface CattlePurchaseAverageIntensityFormData {
  averageIntensity: number;
  averageIntensityTargetYear: number;
}

export default function ManageCattlePurchaseAverageIntensityPopup(props: {
  initialData?: ManageCattlePurchaseAverageIntensityDTO;
  compareReport?: Report;
  onSubmit: (data: ManageCattlePurchaseAverageIntensityDTO) => Promise<void>;
}) {
  const yearOptions = getDefaultReductionPlanYearOptions()

  const existingReductionPlanYears = [
    props.initialData?.cattlePurchaseAverageIntensity?.targetYear,
  ].filter(v => v !== undefined) as number[]
  addExistingReductionPlanYearsToOptions(existingReductionPlanYears, yearOptions)

  // TODO: should be based on the compare report?
  // for now, national average
  const averageIntensity = 12.6

  const initialData: ManageCattlePurchaseAverageIntensityDTO = props.initialData ?? {
    cattlePurchaseAverageIntensity: {
      targetValue: averageIntensity,
      targetYear: getCurrentFinancialYear()
    }
  }

  const formData: FormData<CattlePurchaseAverageIntensityFormData, ManageCattlePurchaseAverageIntensityDTO> = {
    fields: {
      averageIntensity: new SliderFormField({
        required: true,
        sliderProps: {
          defaultRange: { min: 0, max: 32 },
          prevReportValue: averageIntensity,
          initialValue: initialData.cattlePurchaseAverageIntensity?.targetValue ?? averageIntensity,
          unitLabel: EmissionUnit.IntensityLivestock,
          label: `Average emissions intensity of Purchased Cattle`,
        },
      }),
      averageIntensityTargetYear: getYearField('Year of completion', FieldSize.Full, yearOptions),
    },
    transformer: {
      in: (value) => ({
        averageIntensity: value.cattlePurchaseAverageIntensity?.targetValue,
        averageIntensityTargetYear: value.cattlePurchaseAverageIntensity?.targetYear,
      }),
      out: (value) => ({
        cattlePurchaseAverageIntensity: {
          targetValue: value.averageIntensity,
          targetYear: value.averageIntensityTargetYear,
        }
      })
    }
  };

  return (
    <InitiativePopUp
      formData={formData}
      initialFormValues={initialData}
      infoAbove={"This reduction initiative allows you to trial the impact of purchasing cattle from suppliers with different emissions profiles which will impact your <strong><u>Scope 3 emissions</u></strong>. This will override the default regional averages that are used when calculating your emissions. For example if you purchase cattle from lower emissions suppliers your Scope 3 emissions will be less."}
      onSubmit={(state) => props.onSubmit(state)}
    />
  );
}
