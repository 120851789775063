import { RotatingIcon } from "./buttons/loading_button";
import Heading from "./heading";
import Icon from "./icon";
import { Expanded } from "./styled_layout";

export default function LoadingPlaceholder() {
    return (
        // <div style={{ display: "flex" }}>
        <Expanded id="loadingPlaceholder">
            <div style={{ padding: "0 4px 0 0" }}>
                <RotatingIcon>
                    <Icon icon="loading" />
                </RotatingIcon>
            </div>
            <Heading level={4}>Loading...</Heading>
        </Expanded>
        // </div>
    );
}
