import { usePopupStore } from "../../state/popup_store";
import MainButton from "../buttons/main_button";
import { Row } from "../styled_layout";

export default function InformationPopup({
    children,
    width,
    buttonText,
    onConfirm,
}: {
    children: JSX.Element;
    width: string;
    buttonText: string;
    onConfirm?: () => void;
}) {
    const popupStore = usePopupStore();

    return (
        <div
            style={{
                width,
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "20px",
            }}
        >
            {children}
            <Row>
                <MainButton
                    size="small"
                    onClick={() => {
                        if (onConfirm) onConfirm();
                        popupStore.closePopup();
                    }}
                >
                    {buttonText}
                </MainButton>
            </Row>
        </div>
    );
}
