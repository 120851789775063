import { useState } from "react";

import { DropdownOptionData } from "../../../models/form/form_fields/form_field";
import {
  ClassTradingParameters,
  TradingSystemParameters,
} from '../../../models/dse'

import DseTradeSelector, { TradingParametersPage1 } from './trade-selector'
import { calculateDseTradingSystem } from '../../../utilities/dse'
import DseTradeClassForm from "./trade-class";

export enum TradeStep {
  Page1,
  Page2
}

/**
 * The Trade Calculator form, used by both beef & sheep
 */
export default function TradeCalculator({
  onSubmit,
  dseType,
  availableClasses,
  financialYear,
  isAE
}: {
  onSubmit: (val: number) => void;
  dseType: string;
  availableClasses: DropdownOptionData<string>[];
  financialYear: string;
  isAE: boolean
}) {

  const [step, setStep] = useState<TradeStep>(TradeStep.Page1);
  const [tradingFigures, setTradingFigures] = useState<TradingSystemParameters>({
    classes: []
  })
  const [selectedCattleClasses, setSelectedCattleClasses] = useState<string[]>([]);
  const [selectedTradeMonths, setSelectedTradeMonths] = useState<string[]>([]);

  async function pushToTradingFigures(newTradingClass: ClassTradingParameters) {
    setTradingFigures({
      classes: [
        ...tradingFigures.classes,
        newTradingClass
      ]
    })
  }

  function findUnactionedTradingClass(): string | undefined {
    return selectedCattleClasses.find(c => {
      return tradingFigures.classes.filter(c2 => c2.class === c).length === 0
    })
  }

  function numberOfUnactionedTradingClasses(): number {
    return selectedCattleClasses.length - tradingFigures.classes.length
  }

  return (
    <>
      {step === TradeStep.Page1 && (
        <DseTradeSelector
          dseType={dseType}
          classes={availableClasses}
          financialYear={financialYear}
          onDone={(tradingParameters: TradingParametersPage1) => {
            setSelectedCattleClasses(tradingParameters.classes)
            setSelectedTradeMonths(tradingParameters.months)
            setStep(TradeStep.Page2)
          }}
        />
      )}

      {step === TradeStep.Page2 && numberOfUnactionedTradingClasses() > 0 && (
        <DseTradeClassForm
          className={findUnactionedTradingClass() as string}
          months={selectedTradeMonths}
          submitText={numberOfUnactionedTradingClasses() === 1 ? `Calculate ${isAE ? 'AE' : 'DSE'}` : 'Continue'}
          onSubmit={(newTradingClass) => {
            if (numberOfUnactionedTradingClasses() > 1) pushToTradingFigures(newTradingClass)
            else if (numberOfUnactionedTradingClasses() === 1) {
              const tradingDse = calculateDseTradingSystem({
                classes: [
                  ...tradingFigures.classes,
                  newTradingClass
                ]
              })
              onSubmit(tradingDse)
            }
          }}
        />
      )}
    </>
  )
}
