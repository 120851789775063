import { ReductionPlan } from "../models/reduction_plans_models";
import { Property, PropertyType, Report } from "../models/property_models"
import { getCurrentFinancialYear } from "./dates";

export type NextStep = {
  text: string,
  actionText: string,
  actionLink: string
}

export function getPropertyNextStep(userIsPrime: boolean, propertyReports: Report[], reductionPlans: ReductionPlan[] | undefined, property: Property): NextStep | undefined {
  const upgradeResponse = {
    text: "Upgrade to Ruminati PRIME to get access to more functionality",
    actionText: "Upgrade now",
    actionLink: `/account?tab=subscription`
  }

  if (propertyReports.length === 0) {
    return {
      text: "Complete your first Emissions Report",
      actionText: "Complete now",
      actionLink: `/farm/${property.id}/create-report/`
    }
  }

  if (!userIsPrime && propertyReports.filter(r => r.complete).length > 0) {
    return upgradeResponse
  }

  const prevFY = getCurrentFinancialYear() - 1
  const prevFYReport = propertyReports.find(r => r.financialYear === prevFY)

  if (prevFYReport === undefined) {
    return {
      text: `It's time to complete your Emissions Report for FY ${prevFY}`,
      actionText: "Complete now",
      actionLink: `/farm/${property.id}/create-report?year=${prevFY}`
    }
  } else if (prevFYReport !== undefined && !prevFYReport.complete) {
    return {
      text: `Complete your Emissions Report for FY ${prevFYReport.financialYear}`,
      actionText: "Complete now",
      actionLink: `/summary/${prevFYReport.id}/`
    }
  }

  if (
    userIsPrime &&
    reductionPlans &&
    !(PropertyType.Processor === property.propertyType || PropertyType.FurtherProcessor === property.propertyType)
  ) {
    const reductionPlannerLink = `/farm/${property.id}/reductionPlanner/`
    if (reductionPlans.length === 0) {
      return {
        text: "Discover how you can impact your emissions with the Reduction Planner",
        actionText: "Launch",
        actionLink: reductionPlannerLink
      }
    } else {
      return {
        text: "You’re all caught up. Keep utilising the Reduction Planner to make regular updates.",
        actionText: "Launch",
        actionLink: reductionPlannerLink
      }
    }

  }

  if (!userIsPrime) {
    return upgradeResponse
  }

  return undefined
}

export function getReportNextStep(userIsPrime: boolean, report: Report, property: Property): NextStep | undefined {
  if (!report.complete) {
    return {
      text: `Complete your Emissions Report for FY ${report.financialYear}`,
      actionText: "Complete now",
      actionLink: `/summary/${report.id}/`
    }
  }

  if (
    userIsPrime &&
    report.complete &&
    property.propertyType === PropertyType.Farm &&
    report.productionInformation === undefined
  ) {
    return {
      text: `Provide additional information to calculate production-specific intensity.`,
      actionText: "Complete now",
      actionLink: `/summary/${report.id}/`
    }
  }

  return undefined
}