import { Form, FormData } from "../../models/form/form";
import { InfoCard } from "../card";
import { Row } from "../styled_layout";
import { SeasonalFigures } from "@/models/report";
import { SeasonalInputFormField } from "@/models/form/form_fields/seasonal_input_fields";
import { convertKgsMilkSolidsToLitres } from "@/utilities/dairy";
import { trimDecimalPlaces } from "@/utilities/numbers";


export interface CalculatorDTO {
    milkSolids: SeasonalFigures;
}

const calculatorForm = (): FormData<CalculatorDTO> => ({
    fields: {
        milkSolids: new SeasonalInputFormField({
            title: 'Milk Solids Sold',
            allUnits: 'tonnes',
            required: true,
            singleValidator: (value) => value !== undefined && value >= 0,
        }),
    },
});

export const MilkCalculatorPopupID = 'MilkCalculator'

export default function MilkCalculatorPopup({
    onSubmit,
    metadata
}: {
    onSubmit: (val: SeasonalFigures) => void;
    metadata: {
        dairyHeadcount: SeasonalFigures
    }
}) {
    const formData = calculatorForm();
    return (
        <div style={{ padding: "20px", width: "780px" }}>
            <InfoCard 
                content={`Estimate the litres of milk each milking animal produces per day based on milk solids produced by the herd.`}
            />
            <Row style={{height: '20px'}}></Row>
            <Form
                data={formData}
                initialValue={{}}
                fieldSize="small"
                submitText={`Calculate Milk Production`}
                buttonSize="medium"
                onSubmit={async (value: CalculatorDTO) => {
                    onSubmit({
                        spring: trimDecimalPlaces(convertKgsMilkSolidsToLitres(value.milkSolids.spring * 1000) / metadata.dairyHeadcount.spring),
                        summer: trimDecimalPlaces(convertKgsMilkSolidsToLitres(value.milkSolids.summer * 1000) / metadata.dairyHeadcount.summer),
                        autumn: trimDecimalPlaces(convertKgsMilkSolidsToLitres(value.milkSolids.autumn * 1000) / metadata.dairyHeadcount.autumn),
                        winter: trimDecimalPlaces(convertKgsMilkSolidsToLitres(value.milkSolids.winter * 1000) / metadata.dairyHeadcount.winter)
                    });
                }}
            />
        </div>
    );
}
