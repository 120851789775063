import { MultiPolygon } from "geojson";
import { FieldSize, InputFormField, State } from "./form_field";
import { formatReportNumber } from "../../../utilities/functions";
import { TextField } from "../../../components/form/input_field";
import calculateArea from '@turf/area'
import { convertArea } from '@turf/helpers'

export class MultiPolygonFormField extends InputFormField<MultiPolygon> {
    displayValue = (value?: MultiPolygon): string | undefined => {
        if (value) {
            let area = calculateArea(value);
            if (area) area = convertArea(area, 'meters', 'hectares');
            return formatReportNumber(area, { maxDecimalPlaces: 2 });
        }
    }

    render(
        id: string,
        onChange: (value?: MultiPolygon | undefined) => void,
        state: State<any>,
        value?: MultiPolygon | undefined,
        error?: boolean | undefined,
        size?: "large" | "small" | undefined
    ): JSX.Element {
        return (
            <TextField
                id={id}
                disabled={true}
                value={this.displayValue(value)}
                error={error}
                placeholder="0"
                onChange={(value) => onChange(this.transform(value))}
                label={this.label(state)}
                units={this.unit(state)}
                tip={this.tip}
                width={FieldSize.toWidth(this.size)}
                size={size}
                obscure={this.obscured}
                footerElement={this.children(state)}
            />
        );
    }
}