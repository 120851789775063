import { FormData } from "../../../../models/form/form";
import { getCurrentFinancialYear } from "../../../../utilities/dates";
import { InitiativePopUp } from "../initiative_popup";
import { ManagePasturesDTO } from "../../../../models/reduction_plans_models";
import { Report } from "../../../../models/property_models";
import { SliderFormField } from "../../../../models/form/form_fields/basic_form_fields";
import { getYearField, getDefaultReductionPlanYearOptions, addExistingReductionPlanYearsToOptions } from "../initiative_helpers";
import { FieldSize } from "../../../../models/form/form_fields/form_field";

interface PastureFormData {
    urea: number;
    ureaTargetYear: number;
    lime: number;
    limeTargetYear: number;
    singleSuperphosphate: number;
    singleSuperphosphateTargetYear: number;
    pesticide: number;
    pesticideTargetYear: number;
}

export default function ManagePasturePopup(props: {
    initialData?: ManagePasturesDTO;
    compareReport?: Report;
    onSubmit: (data: ManagePasturesDTO) => Promise<void>
}) {

    const yearOptions = getDefaultReductionPlanYearOptions()
    const existingReductionPlanYears = [
        props.initialData?.lime?.targetYear,
        props.initialData?.pesticidePurchased?.targetYear,
        props.initialData?.singleSuperphosphate?.targetYear,
        props.initialData?.urea?.targetYear
    ].filter(v => v !== undefined) as number[]
    addExistingReductionPlanYearsToOptions(existingReductionPlanYears, yearOptions)

    const initialData: ManagePasturesDTO = props.initialData ?? {
        urea: {
            targetValue: props.compareReport?.pastureInformation?.urea ?? 0,
            targetYear: getCurrentFinancialYear()
        },
        lime: {
            targetValue: props.compareReport?.pastureInformation?.lime ?? 0,
            targetYear: getCurrentFinancialYear(),
        },
        singleSuperphosphate: {
            targetValue: props.compareReport?.pastureInformation?.singleSuperphosphate ?? 0,
            targetYear: getCurrentFinancialYear()
        },
        pesticidePurchased: {
            targetValue: props.compareReport?.pastureInformation?.pesticide ?? 0,
            targetYear: getCurrentFinancialYear()
        }
    }

    const formData: FormData<PastureFormData, ManagePasturesDTO> = {
        fields: {
            urea: new SliderFormField({
                required: true,
                sliderProps: {
                    defaultRange: { min: 0, max: 1000 },
                    prevReportValue: props.compareReport?.pastureInformation?.urea,
                    initialValue: initialData.urea?.targetValue ?? 0,
                    unitLabel: "tonnes",
                    label: "Urea (tonnes/year)",
                },
            }),
            ureaTargetYear: getYearField('Year of completion', FieldSize.Full, yearOptions),
            lime: new SliderFormField({
                required: true,
                sliderProps: {
                    defaultRange: { min: 0, max: 1000 },
                    prevReportValue: props.compareReport?.pastureInformation?.lime,
                    initialValue: initialData?.lime?.targetValue ?? 0,
                    label: "Lime (tonnes/year)",
                    unitLabel: "tonnes",
                },
            }),
            limeTargetYear: getYearField('Year of completion', FieldSize.Full, yearOptions),
            singleSuperphosphate: new SliderFormField({
                required: true,
                sliderProps: {
                    defaultRange: { min: 0, max: 1000 },
                    prevReportValue: props.compareReport?.pastureInformation?.singleSuperphosphate,
                    initialValue: initialData?.singleSuperphosphate?.targetValue ?? 0,
                    label: "Single Superphosphate (tonnes/year)",
                    unitLabel: "tonnes",
                },
            }),
            singleSuperphosphateTargetYear: getYearField('Year of completion', FieldSize.Full, yearOptions),
            pesticide: new SliderFormField({
                required: true,
                sliderProps: {
                    defaultRange: { min: 0, max: 1000 },
                    prevReportValue: props.compareReport?.pastureInformation?.pesticide,
                    initialValue: initialData?.pesticidePurchased?.targetValue ?? 0,
                    label: "Pesticide Purchased (litres/year)",
                    unitLabel: "litres",
                },
            }),
            pesticideTargetYear: getYearField('Year of completion', FieldSize.Full, yearOptions),
        },
        transformer: {
            in: (value) => ({
                urea: value.urea?.targetValue,
                ureaTargetYear: value.urea?.targetYear,
                lime: value.lime?.targetValue,
                limeTargetYear: value.lime?.targetYear,
                singleSuperphosphate: value.singleSuperphosphate?.targetValue,
                singleSuperphosphateTargetYear: value.singleSuperphosphate?.targetYear,
                pesticide: value.pesticidePurchased?.targetValue,
                pesticideTargetYear: value.pesticidePurchased?.targetYear,
            }),
            out: (value) => ({
                ...(value.ureaTargetYear) ? {
                    urea: {
                        targetValue: value.urea ?? 0,
                        targetYear: value.ureaTargetYear
                    }
                } : {},
                ...(value.limeTargetYear) ? {
                    lime: {
                        targetValue: value.lime ?? 0,
                        targetYear: value.limeTargetYear
                    }
                } : {},
                ...(value.singleSuperphosphateTargetYear) ? {
                    singleSuperphosphate: {
                        targetValue: value.singleSuperphosphate ?? 0,
                        targetYear: value.singleSuperphosphateTargetYear
                    }
                } : {},
                ...(value.pesticideTargetYear) ? {
                    pesticidePurchased: {
                        targetValue: value.pesticide ?? 0,
                        targetYear: value.pesticideTargetYear
                    }
                } : {}
            })
        }
    };
    return (
        <InitiativePopUp
            formData={formData}
            initialFormValues={initialData}
            onSubmit={(state) => props.onSubmit(state)}
        />
    );
}
