import ElectricityUseButton from "../../../components/buttons/electricityuse_button";
import { DropdownFormField, NumberFormField, RadioBoxFormField } from "../../../models/form/form_fields/basic_form_fields";
import { FieldSize } from "../../../models/form/form_fields/form_field";
import { FormEffluentManagement, ProportionEffluent, PurchasedGoodsTonnesCO2E, ReportEffluentManagement, ReportMethane, ReportMethaneForm, ReportProcessorEnergy, ReportProcessorProduction, ReportProcessorWaterUse, ReportRefrigerants } from "../../../models/report";
import { formatNumber } from "../../../utilities/functions";
import { ReportFormPage } from "../../../models/form/form_page";
import { ReportFormPageId, TransformType, applyNumberTransformerToObject, inputPercent, outputPercent } from "../../../utilities/forms";
import { Report } from "../../../models/property_models";
import { LabelField, SeparatorField } from "../../../models/form/form_fields/decoration_form_fields";
import FuelUseButton from "@/components/buttons/fueluse_button";
import LPGUseButton from "../../../components/buttons/lgpuse_button";
import { manureManagementTypes } from "../../../models/form_options";
import { PercentageSplitFormField } from "@/models/form/form_fields/percentage_split_form_field";

export const energySources: ReportFormPage<ReportProcessorEnergy> = {
    id: ReportFormPageId.EnergySources,
    title: "Energy Sources",
    image: "consumables.png",
    formData: {
        fields: {
            electricityUsage: new NumberFormField({
                required: true,
                label: "Electricity usage",
                unit: "kWh/year",
                size: FieldSize.Half,
                tip: "The total amount of electricity used on your farm in the baseline year. This would include electricity used for workshops, offices, shearing sheds and pumps.",
                children: (state) => (
                    <ElectricityUseButton
                        state={state}
                        onSubmit={(val) => {
                            val = formatNumber(val, { maxDecimalSigFig: 4 });
                            state.set({
                                ...state.get,
                                electricityUsage: val,
                            });
                        }}
                    />
                ),
            }),
            percentRenewables: new NumberFormField({
                required: true,
                label: "% of electricity sourced from renewables",
                size: FieldSize.Half,
                validator: (value) => value !== undefined && value <= 100,
                tip: "Enter a number between 0 and 100% that reflects the percentage of electricity sourced from renewables.",
            }),
            petrol: new NumberFormField({
                required: true,
                label: "Petrol",
                unit: "L",
                size: FieldSize.Half,
                tip: "The total amount of petrol used on your farm in the baseline year. This should include on road fuel use related to the farm business.",
                children: (state) => {
                    return (<FuelUseButton
                        fuelType='Petrol'
                        onSubmit={(val) => {
                            val = formatNumber(val, { maxDecimalSigFig: 4 });
                            state.set({
                                ...state.get,
                                petrol: val,
                            });
                        }}
                    />)
                },
            }),
            diesel: new NumberFormField({
                required: true,
                label: "Diesel",
                unit: "L",
                size: FieldSize.Half,
                tip: "The total amount of diesel used on your farm in the baseline year. This should include on road fuel use related to the farm business.",
                children: (state) => {
                    return (<FuelUseButton
                        fuelType='Diesel'
                        onSubmit={(val) => {
                            val = formatNumber(val, { maxDecimalSigFig: 4 });
                            state.set({
                                ...state.get,
                                diesel: val,
                            });
                        }}
                    />)
                },
            }),
            fuelOil: new NumberFormField({
                required: true,
                label: "Fuel oil",
                unit: "L",
                size: FieldSize.Half,
                // tip: "The total amount of petrol used on your farm in the baseline year. This should include on road fuel use related to the farm business.",
            }),
            coal: new NumberFormField({
                required: true,
                label: "Coal",
                unit: "tonnes",
                size: FieldSize.Half,
                // tip: "The total amount of diesel used on your farm in the baseline year. This should include on road fuel use related to the farm business.",
            }),
            lpg: new NumberFormField({
                required: true,
                label: "LPG",
                unit: 'Gj',
                size: FieldSize.Half,
                children: (state) => (
                    <LPGUseButton
                        state={state}
                        onSubmit={(val) => {
                            val = formatNumber(val, { maxDecimalSigFig: 4 });
                            state.set({
                                ...state.get,
                                lpg: val,
                            });
                        }}
                    />
                ),
                // tip: "The total amount of petrol used on your farm in the baseline year. This should include on road fuel use related to the farm business.",
            }),
            naturalGas: new NumberFormField({
                required: true,
                label: "Natural gas",
                unit: "Gj",
                size: FieldSize.Half,
                // tip: "The total amount of diesel used on your farm in the baseline year. This should include on road fuel use related to the farm business.",
            }),
        },
        transformer: {
            in: (value) => ({
                ...value,
                percentRenewables: inputPercent(value.percentRenewables),
            }),
            out: (value) => ({
                ...value,
                percentRenewables: outputPercent(value.percentRenewables),
            }),
        },
    },
};

export function methane(): ReportFormPage<ReportMethaneForm, ReportMethane> {
    return {
        id: ReportFormPageId.Methane,
        title: "Processing Emissions",
        image: "processing-emissions.png",
        formData: {
            fields: {
                methaneEmissions: new NumberFormField({
                    required: true,
                    label: "Methane used for energy",
                    unit: `m\u00B3`,
                    // tip: "The total tonnes of grain (excluding cottonseed) purchased by your farm for livestock feed in the baseline year.",
                }),
                percentageFlared: new NumberFormField({
                    required: true,
                    label: "% of methane flared",
                    size: FieldSize.Half,
                    validator: (value) => value !== undefined && value <= 100,
                    tip: "Enter a number between 0 and 100% that reflects the percentage of methane flared.",
                }),
                percentageCaptured: new NumberFormField({
                    required: true,
                    label: "% of methane recaptured",
                    size: FieldSize.Half,
                    validator: (value) => value !== undefined && value <= 100,
                    tip: "Enter a number between 0 and 100% that reflects the percentage of methane recaptured.",
                }),
            },
            transformer: {
                in: (value) => ({
                    ...value,
                    percentageFlared: inputPercent(value.percentageFlared),
                    percentageCaptured: inputPercent(value.percentageCaptured)
                }),
                out: (value) => ({
                    ...value,
                    percentageFlared: outputPercent(value.percentageFlared),
                    percentageCaptured: outputPercent(value.percentageCaptured)
                }),
            },
        },
    }
}

export function refrigerants(report: Report | undefined): ReportFormPage<ReportRefrigerants> {
    return {
        id: ReportFormPageId.Refrigerants,
        title: "Refrigerants Purchased",
        image: "refrigerants.png",
        formData: {
            fields: {
                ammonia: new NumberFormField({
                    required: true,
                    label: "Ammonia",
                    size: FieldSize.Half,
                    unit: `Kg`,
                    // tip: "The total tonnes of grain (excluding cottonseed) purchased by your farm for livestock feed in the baseline year.",
                }),
                carbonDioxide: new NumberFormField({
                    required: true,
                    label: "CO\u2082",
                    size: FieldSize.Half,
                    unit: `Kg`,
                    // tip: "The total tonnes of grain (excluding cottonseed) purchased by your farm for livestock feed in the baseline year.",
                }),
                liquidNitrogen: new NumberFormField({
                    required: true,
                    label: "Liquid Nitrogen",
                    size: FieldSize.Half,
                    unit: `Kg`,
                    // tip: "The total tonnes of grain (excluding cottonseed) purchased by your farm for livestock feed in the baseline year.",
                }),
                ...(
                    report
                        && 'algorithmVersion' in report
                        && report?.algorithmVersion > 2 ?
                        {
                            r22: new NumberFormField({
                                required: true,
                                label: "R22",
                                size: FieldSize.Half,
                                unit: `Kg`,
                            }),
                            r407C: new NumberFormField({
                                required: true,
                                label: "R407C",
                                size: FieldSize.Half,
                                unit: `Kg`,
                            }),
                        }
                        : {}
                ),
            }

        },
    }
}

export function purchasedGoods(): ReportFormPage<PurchasedGoodsTonnesCO2E> {
    return {
        id: ReportFormPageId.PurchasedGoods,
        title: "Purchased Goods",
        image: "resources.png",
        formData: {
            fields: {
                dry: new NumberFormField({
                    required: true,
                    label: "Dry",
                    size: FieldSize.Half,
                    unit: `t CO2-e`,
                }),
                pork: new NumberFormField({
                    required: true,
                    label: "Pork",
                    size: FieldSize.Half,
                    unit: `t CO2-e`,
                }),
                egg: new NumberFormField({
                    required: true,
                    label: "Egg",
                    size: FieldSize.Half,
                    unit: `t CO2-e`,
                }),
                plasticPackaging: new NumberFormField({
                    required: true,
                    label: "Plastic Packaging",
                    size: FieldSize.Half,
                    unit: `t CO2-e`,
                }),
                cardboardPackaging: new NumberFormField({
                    required: true,
                    label: "Cardboard Packaging",
                    size: FieldSize.Half,
                    unit: `t CO2-e`,
                }),
                processingAids: new NumberFormField({
                    required: true,
                    label: "Processing Aids",
                    size: FieldSize.Half,
                    unit: `t CO2-e`,
                }),
                canolaOil: new NumberFormField({
                    required: true,
                    label: "Canola Oil",
                    size: FieldSize.Half,
                    unit: `t CO2-e`,
                }),
            },
        }
    }
}

export function waterUse(): ReportFormPage<ReportProcessorWaterUse> {
    return {
        id: ReportFormPageId.WaterUse,
        title: "Water Use",
        image: "resources.png",
        formData: {
            fields: {
                townWater: new NumberFormField({
                    required: true,
                    label: "Town Water",
                    size: FieldSize.Half,
                    unit: "kL"
                }),
                boreWater: new NumberFormField({
                    required: true,
                    label: "Bore Water",
                    size: FieldSize.Half,
                    unit: "kL"
                }),
                riverWater: new NumberFormField({
                    required: true,
                    label: "River Water",
                    size: FieldSize.Half,
                    unit: "kL"
                }),
                recycledWater: new NumberFormField({
                    required: true,
                    label: "Recycled Water",
                    size: FieldSize.Half,
                    unit: "kL"
                }),
                wasteWater: new NumberFormField({
                    required: true,
                    label: "Waste Water",
                    size: FieldSize.Half,
                    unit: "kL"
                }),
                runoffWater: new NumberFormField({
                    required: true,
                    label: "On-site Runoff Water captured",
                    size: FieldSize.Half,
                    unit: "kL"
                })
            }
        }
    }
}

const proportionEffluentsLabels = [
    { key: "land", label: "Land" },
    { key: "recycling", label: "Recycling" },
    { key: "evaporation", label: "Evaporation" },
    { key: "sewer", label: "Released to Sewer" },
    { key: "pondOrDigester", label: "Covered Pond / Anaerobic Digester" },
    { key: "sludge", label: "Sludge" },
]

export function effluentManagement(isFurtherProcessor: boolean): ReportFormPage<FormEffluentManagement, ReportEffluentManagement> {
    const titleWord = isFurtherProcessor ? "Wastewater" : "Effluent"
    return {
        id: ReportFormPageId.EffluentManagement,
        title: `${titleWord} Management`,
        image: "resources.png",
        formData: {
            fields: {
                manureManagementSystem: new DropdownFormField({
                    required: true,
                    label: "Manure Management System",
                    options: manureManagementTypes,
                    size: FieldSize.Half,
                }),
                volumeEffluentLiters: new NumberFormField({
                    required: true,
                    label: `Volume of ${titleWord.toLowerCase()} produced`,
                    unit: "litres",
                    size: FieldSize.Half,
                }),
                codOfEffluent: new NumberFormField({
                    required: true,
                    label: `Chemical Oxygen Demand of ${titleWord.toLowerCase()}`,
                    unit: "mg/L",
                    size: FieldSize.Half,
                }),
                nitrogenOfEffluent: new NumberFormField({
                    required: true,
                    label: `Nitrogen concentration of ${titleWord.toLowerCase()}`,
                    unit: "mg/L",
                    size: FieldSize.Half,
                }),
                bioGasCaptured: new RadioBoxFormField({
                    required: true,
                    label: "Do you capture biogas to use as a fuel source?",
                    options: [{
                        name: "Yes",
                        value: 'true'
                    }, {
                        name: "No",
                        value: 'false'
                    }]
                }),
                proportionEffluent: new PercentageSplitFormField({
                    title: `What proportion of ${titleWord.toLowerCase()} went to different treatment options?`,
                    options: proportionEffluentsLabels,
                    units: "%",
                    columnWidth: FieldSize.Third
                })
            },
            transformer: {
                in: (value) => ({
                    ...value,
                    bioGasCaptured: value?.bioGasCaptured === false ? 'false' : 'true',
                    proportionEffluent: applyNumberTransformerToObject(value.proportionEffluent, TransformType.InputPercent) as ProportionEffluent
                }),
                out: (value) => ({
                    ...value,
                    bioGasCaptured: value?.bioGasCaptured === 'true' ? true : false,
                    proportionEffluent: applyNumberTransformerToObject(value.proportionEffluent, TransformType.OutputPercent) as ProportionEffluent
                }),
            },
        },
    }
}


export function processorProduction(beefProcessor: boolean, sheepProcessor: boolean, isFutherProcessor: boolean): ReportFormPage<ReportProcessorProduction> {
    return {
        id: ReportFormPageId.ProcessorProduction,
        title: "Production",
        image: "processing-production.png",
        formData: {
            fields: {
                ...(beefProcessor
                    ? {
                        beefMeatLabel: new LabelField({
                            label: "Beef Meat",
                        }),
                        totalBeefMeatSold: new NumberFormField({
                            required: true,
                            label: `Total ${isFutherProcessor ? "product" : "meat"} sold`,
                            unit: `tonnes`,
                            size: FieldSize.Half
                        }),
                        totalBeefByproductsSold: new NumberFormField({
                            required: true,
                            label: "Total byproducts sold",
                            unit: `tonnes`,
                            size: FieldSize.Half
                        }),
                    }
                    : {}),
                ...(sheepProcessor
                    ? {
                        ...(beefProcessor
                            ? {
                                sheepMeatSeperator: new SeparatorField()
                            } : {}
                        ),
                        sheepMeatLabel: new LabelField({
                            label: "Sheep Meat",
                        }),
                        totalSheepMeatSold: new NumberFormField({
                            required: true,
                            label: `Total ${isFutherProcessor ? "product" : "meat"} sold`,
                            unit: `tonnes`,
                            size: FieldSize.Half
                        }),
                        totalSheepByproductsSold: new NumberFormField({
                            required: true,
                            label: "Total byproducts sold",
                            unit: `tonnes`,
                            size: FieldSize.Half
                        }),
                    }
                    : {}),
            },
        },
    }
}
