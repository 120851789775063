import Screen from "./screen";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getUserProfile } from "../services/auth_service";
import { useAuthStore } from "../state/auth_store";

/**
 * A screen that checks to see if the user's email
 * has been verified
 * @returns the Check Email Verification Screen component
 */
export default function CheckEmailVerificationScreen() {
    const navigate = useNavigate();
    const authStore = useAuthStore();

    useEffect(() => {
        checkVerification()
    })

    const checkVerification = async () => {
        try {
            const user = await getUserProfile()
            if (user) authStore.signIn(user)
        } catch (err) {
            console.error(err)
        }
        navigate("/verifyEmail")
    };

    return (
        <Screen>
        </Screen>
    );
}