import { useParams } from 'react-router-dom';
import { useEffect, PropsWithChildren } from 'react';
import LoadingPlaceholder from '../components/loading_placeholder';
import Screen from '../screens/screen'
import { getAvailableInitiatives, getReductionPlans } from '../services/reduction_plans_service';
import { useDatabaseStore } from "../state/database_store";

/**
 * Gets the users properties if they have not been loaded 
 */
export default function ReductionPlanGuard({
    propertyId,
    children,
}: PropsWithChildren<{
    propertyId?: string;
}>) {
    const params = useParams()
    const id = propertyId ?? params.propertyId ?? ''

    const reductionPlans = useDatabaseStore((state) => (state.reductionPlans ?? {})[id]);
    const setReductionPlans = useDatabaseStore((state) => state.setReductionPlans);

    useEffect(() => {
        if (!reductionPlans) {
            getReductionPlans(id).then((plans) => setReductionPlans(plans, id))
        }
    }, [reductionPlans, setReductionPlans, id])

    const availableInitiatives = useDatabaseStore((state) => (state.availableInitiatives ?? {})[id]);
    const setInitiatives = useDatabaseStore((state) => state.setAvailableInitiatives);

    useEffect(() => {
        if (!availableInitiatives) {
            getAvailableInitiatives(id).then((initiatives) => setInitiatives(initiatives, id))
        }
    }, [availableInitiatives, setInitiatives, id])

    if (!reductionPlans || !availableInitiatives) {
        return <Screen>
            <LoadingPlaceholder />
        </Screen>
    } else {
        return children
    }
}
