import { useEffect, useRef, useState } from "react";

import { Line } from "react-chartjs-2";
import {
    Chart,
    ChartEvent,
    Chart as ChartJS,
    LinearScale,
    registerables,
} from "chart.js";
import annotationPlugin, { PartialEventContext } from 'chartjs-plugin-annotation';

import GraphButton from "./graph_button";
import { ruminatiColors } from "../../utilities/colors";
import { customTooltip } from "./custom_tooltip";
import { trimDecimalPlaces } from "@/utilities/numbers";

ChartJS.register(...registerables, annotationPlugin);

type InteractiveGraphProps = {
    startYear: number; // inclusive
    endYear: number; // inclusive
    currentYear?: string;
    nonProjectedYears?: number[];
    reportYears: number[];
    series: GraphSeries[];
    baseline?: number;
    readOnly?: boolean;
    showTooltip?: boolean;
    graphButtonOnClick?: (year: number) => void;
    gridlineOnClick?: (year: number) => void;
    yearCounts: Record<number, number>;
    yRange?: { max: number, min: number };
    graphButtonActive?: boolean;
    percentageChange: number;
};

export type GraphSeries = {
    id: string;
    label: string;
    color: string;
    fillColor: string | null;
    points: GraphPoint[];
};

type ButtonPoint = {
    x: number;
    y: number;
    year: string;
};

export type GraphPoint = {
    x: number;
    y: number;
    breakdown?: { [key: string]: number }; // a breakdown of y into multiple values.
    isProjection: boolean;
};

/**
 * Projection graph
 * @param startYear the graph's starting year, inclusive.
 * @param endYear the graph's ending year, inclusive.
 * @param currentYear initially selected year.
 * @param series the different series of the graph.
 * @param readOnly whether the graph is interactive.
 * @param showTooltip whether to show tooltip.
 * @param graphButtonOnClick callback when a graph button is clicked.
 * @param gridlineOnClick callback when a graph's gridline is clicked.
 * @param graphButtonActive the condition(s) under which graph button is in active state.
 */
export default function InteractiveGraph(props: InteractiveGraphProps) {
    const chartRef = useRef<ChartJS<"line", (number | null)[], number> | undefined>(null);

    const [selectedYear, setSelectedYear] = useState<string | undefined>(props.currentYear);

    const updateSelectedYear = (year: string): void => setSelectedYear(year);

    const currentYear = new Date().getFullYear();

    // Generate the years between the given range, which will become the x labels
    const xLabels = Array.from({ length: props.endYear - props.startYear + 1 }, (_v, k) => k + props.startYear);

    // Store the positions of the buttons
    const [buttonPoints, setButtonPoints] = useState<ButtonPoint[]>([]);

    /**
     * Handles a click event on the graph area.
     * @param event The click event.
     * @returns void
     */
    const handleGraphClick = (event: ChartEvent): void => {
        // Get the x position of the click
        const clickPixels: number | null = event.x;

        if (chartRef && chartRef.current && clickPixels != null && chartRef.current.scales.x) {

            // Iterate over each of the grid lines
            const xGridTicks: string[] = chartRef.current.scales.x.getTicks().map(tick => tick.value.toString());
            xGridTicks.forEach((_, index: number) => {
                const isLastElement: boolean = index === xGridTicks.length - 1;
                if (!isLastElement) {
                    const currentPixels: number = chartRef.current?.scales.x?.getPixelForTick(index) as number;
                    const nextPixels: number = chartRef.current?.scales.x?.getPixelForTick(index + 1) as number;

                    // Check if the value is between the current and next value
                    if (clickPixels > currentPixels && clickPixels < nextPixels) {
                        let year: number;
                        // If it is closer to next, select next
                        if (clickPixels - currentPixels > nextPixels - clickPixels) {
                            year = Number(xLabels[index + 1]);
                        } else {
                            // Otherwise, select current
                            year = Number(xLabels[index]);
                        }
                        updateSelectedYear(year.toString());
                        if (props.gridlineOnClick !== undefined) {
                            props.gridlineOnClick(year);
                        }
                    }
                }
            });
        }
    };

    // On resize, recalculate button points
    const handleResize = (): void => {
        if (!chartRef || !chartRef.current) {
            return; // Exit early if chartRef or chartRef.current is null/undefined
        }

        setButtonPoints([]);

        const chart = chartRef.current;
        const xScale = chart.scales.x;

        if (!xScale) {
            return; // Exit early if xScale is null/undefined
        }

        const xGridTicks = xScale.ticks;

        const xTickPixels: Array<{
            pixel: number;
            value: number;
            label: string;
        }> = xGridTicks.map((xTick, index) => {
            const label = Array.isArray(xTick.label)
                ? xTick.label[0] ?? ""
                : xTick.label ?? "";

            const pixel = xScale.getPixelForTick(index);
            return { pixel, value: xTick.value, label };
        });

        setButtonPoints((bps) => [
            ...bps,
            ...xTickPixels.map(({ pixel, label }) => ({
                x: pixel,
                y: chart.chartArea.bottom - 40,
                year: label,
            })),
        ]);
    };

    // Add event listener to resize event
    useEffect(() => {
        handleResize();

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        handleResize();
    }, [props.series])

    // Convert points to add null between points as needed
    const convertPointsForGraph = (points: GraphPoint[]) => {
        return xLabels.map((xLabel) => {
            return (
                points.find((p) => p.x.toString() === xLabel.toString()) ?? null
            );
        });
    };

    const isActive = (p: GraphPoint | null) => p && p.x.toString() === selectedYear?.toString();
    const selectedIndex = xLabels.findIndex((l) => l.toString() === selectedYear?.toString());
    const currentYearIndex = xLabels.findIndex((l) => l.toString() === currentYear?.toString());

    Chart.defaults.font.size = 12;
    Chart.defaults.font.weight = 400;
    Chart.defaults.font.lineHeight = "12px";
    Chart.defaults.font.family = '"TT Interfaces", sans-serif';
    Chart.defaults.color = ruminatiColors.dark_green;

    const getSelectedX = () => {
        return xLabels.findIndex((l) => l.toString() === selectedYear);
    }

    const getSelectedNet = () => {
        const selectedSeries = props.series[props.series.length - 1];
        const selectedPoint = selectedSeries?.points.find(p => p.x.toString() === selectedYear);

        return selectedPoint ? selectedPoint.y : 0;
    }

    const unitsPerPixel = (ctx: PartialEventContext): number => {
        if (!ctx.chart.scales.y || !ctx.chart.scales.y.height) {
            return 0;
        }

        const yScale: LinearScale = ctx.chart.scales.y as LinearScale;
        const unitHeight = Math.abs(yScale.max - yScale.min);
        const pixelHeight = ctx.chart.scales.y.height;

        return unitHeight / pixelHeight;
    }

    const getLineOffset = (ctx: PartialEventContext) => {
        return 6.0 * unitsPerPixel(ctx);
    }

    const getPixelDifference = (ctx: PartialEventContext) => {
        if (props.baseline === undefined) return 0;
        const unitDifference = Math.abs(props.baseline - getSelectedNet());
        const pixelDifference = unitDifference / unitsPerPixel(ctx);

        return pixelDifference;
    }

    const getLabelPos = (ctx: PartialEventContext) => {
        if (ctx.chart === undefined || ctx.chart.scales === undefined || ctx.chart.scales.y === undefined || props.baseline === undefined) {
            return getSelectedNet();
        }

        const middlePoint = (props.baseline + getSelectedNet()) / 2;

        const pixelDifference = getPixelDifference(ctx);
        if (pixelDifference !== undefined && pixelDifference < 15) {
            const lowerPoint = Math.min(props.baseline, getSelectedNet());
            const adjustment = 15 * unitsPerPixel(ctx);

            if (adjustment !== undefined) {
                return lowerPoint - adjustment;
            } else {
                return getSelectedNet();
            }
        }

        return middlePoint;
    }

    const visibleButtonPoints = buttonPoints.filter((b) => {
        const isNotProjected = !(props.nonProjectedYears ?? []).includes(parseInt(b.year));
        const year = props.yearCounts[parseInt(b.year)] ?? 0;
        const hasActiveInitiative = year > 0;
        return isNotProjected && hasActiveInitiative;
    });

    if (import.meta.env.MODE === 'test') return <></>

    return (
        <div style={{ width: "100%" }}>
            <div style={{ position: "relative", width: "100%", height: "420px" }}>
                {visibleButtonPoints.map((b) => {
                    const year = props.yearCounts[parseInt(b.year)] ?? b.year;

                    return (
                        props.graphButtonOnClick && !props.readOnly && (
                            <GraphButton
                                key={b.year}
                                active={
                                    selectedYear?.toString() === b.year.toString() && (props.graphButtonActive ?? true)
                                }
                                text={[year].toString() ?? ""}
                                x={b.x}
                                y={b.y}
                                handleClick={() => {
                                    updateSelectedYear(parseInt(b.year).toString());
                                    if (props.graphButtonOnClick) {
                                        props.graphButtonOnClick(parseInt(b.year));
                                    }
                                }}
                            />
                        )
                    );
                })}
                <Line
                    ref={chartRef}
                    data={{
                        labels: xLabels,
                        datasets: [
                            // Note: points, solid lines and dashed lines are separated to allow changing line type in the middle of the graph
                            // Lower Selected Year Dot
                            ...(props.baseline !== undefined ? [props.baseline] : []).map(() => {
                                const series = props.series[props.series.length - 1];
                                const points = series ? series.points : [];
                                const breakdowns = convertPointsForGraph(points).map(
                                    (p) => p?.breakdown ?? { Value: p?.y ?? "N/A", }
                                );
                                const data = convertPointsForGraph(points).map(
                                    (p) => isActive(p) ? p?.y ?? null : null
                                );
                                return {
                                    label: series ? series.label : undefined,
                                    color: series ? series.color : undefined,
                                    data: data,
                                    borderColor: ruminatiColors.data_orange,
                                    pointStyle: "circle",
                                    pointBackgroundColor: ruminatiColors.bone,
                                    pointBorderWidth: 2.0,
                                    pointRadius: 3.5,
                                    showLine: false,
                                    breakdowns: breakdowns,
                                };
                            }),
                            // Upper Selected Year Dot
                            ...[props.baseline].filter((x) => x !== undefined).map(() => {
                                return {
                                    label: 'baseline',
                                    data: xLabels.map(() => props.baseline !== undefined ? props.baseline : null),
                                    borderColor: ruminatiColors.data_orange,
                                    pointStyle: "circle",
                                    pointBackgroundColor: ruminatiColors.bone,
                                    pointBorderWidth: 2.0,
                                    pointRadius: xLabels.map((l: number) => l.toString() === selectedYear?.toString() ? 3.5 : 0),
                                    showLine: false,
                                };
                            }),
                            // Invisible Baseline Line (for fill)
                            ...[props.baseline].filter((x) => x !== undefined).map(() => {
                                return {
                                    label: 'baseline',
                                    data: Array.from({ length: xLabels.length }, () => props.baseline !== undefined ? props.baseline : null),
                                    borderWidth: 0,
                                    pointRadius: 0,
                                };
                            }),
                            // Data Points
                            ...props.series.map((s) => {
                                return {
                                    label: s.label,
                                    color: s.color,
                                    fill: s.fillColor != null ? {
                                        target: '-1',
                                        above: s.fillColor,
                                        below: s.fillColor,
                                    } : false,
                                    data: convertPointsForGraph(s.points).map(
                                        (p) => (p ? p.y : null)
                                    ),
                                    borderColor: s.color,
                                    pointStyle: "rect",
                                    pointBackgroundColor: convertPointsForGraph(s.points).map((p) =>
                                        !p?.isProjection
                                            ? s.color
                                            : ruminatiColors.bone,
                                    ),
                                    pointBorderWidth: 1.0,
                                    pointRadius: convertPointsForGraph(s.points).map((p) => {
                                        if (isActive(p)) return 0;

                                        const yearHasReport = props.reportYears.some(y => y === p?.x);
                                        const yearHasInitiative = visibleButtonPoints.some(b => parseInt(b.year) === p?.x);

                                        if (yearHasInitiative || yearHasReport) {
                                            return 3.0;
                                        }

                                        return 0;
                                    }),
                                    borderWidth: 2.0,
                                    showLine: false,
                                    breakdowns: convertPointsForGraph(s.points).map(
                                        (p) => p?.breakdown ?? { Value: p?.y ?? "N/A", }
                                    ),
                                    // animation: { duration: 1000 }, // Uncomment for points and fill animating
                                };
                            }),
                            // Baseline Line
                            ...[props.baseline].filter((x) => x !== undefined).map(() => {
                                return {
                                    label: 'baseline',
                                    data: Array.from({ length: xLabels.length }, () => props.baseline !== undefined ? props.baseline : 0),
                                    borderWidth: 2.0,
                                    pointRadius: 0,
                                    borderColor: ruminatiColors.data_orange,
                                };
                            }),
                            // Solid lines
                            ...props.series.map((s) => {
                                return {
                                    label: s.label,
                                    color: s.color,
                                    data: convertPointsForGraph(s.points).map(
                                        (p) =>
                                            p?.isProjection
                                                ? null
                                                : p
                                                    ? p.y
                                                    : null
                                    ),
                                    borderColor: s.color,
                                    pointStyle: "rect",
                                    fill: false,
                                    pointBackgroundColor: convertPointsForGraph(s.points).map((p) =>
                                        p?.isProjection
                                            ? ruminatiColors.bone
                                            : s.color
                                    ),
                                    pointBorderWidth: 1.0,
                                    pointRadius: 0,
                                    borderWidth: 2.0,
                                    breakdowns: convertPointsForGraph(s.points).map(
                                        (p) => p?.breakdown ?? { Value: p?.y ?? "N/A", }
                                    ),
                                };
                            }),
                            // Dashed lines
                            ...props.series.map((s) => {
                                const points = convertPointsForGraph(s?.points ?? []);
                                const data = points.map((p, index) => {
                                    if (p?.isProjection
                                        || (index < points.length - 1 && points[index + 1]?.isProjection)) {
                                        return p?.y ?? null;
                                    }
                                    return null;
                                });
                                const breakdowns = points.map((p) => p?.breakdown ?? { Value: p?.y ?? "N/A", });
                                return {
                                    label: s?.label ?? '',
                                    color: s?.color ?? '',
                                    data: data,
                                    borderColor: s?.color ?? '',
                                    pointStyle: "rect",
                                    fill: false,
                                    borderDash: [7, 3],
                                    pointBorderWidth: 1.0,
                                    pointRadius: 0,
                                    borderWidth: 1.0,
                                    breakdowns: breakdowns,
                                };
                            }),
                            // Baseline Point
                            ...[props.baseline].filter((x) => x !== undefined).map(() => {
                                return {
                                    label: 'baseline',
                                    data: [props.baseline !== undefined ? props.baseline : 0],
                                    borderWidth: 2.0,
                                    pointStyle: "rect",
                                    pointRadius: 3.0,
                                    borderColor: ruminatiColors.data_orange,
                                    pointBackgroundColor: ruminatiColors.data_orange,
                                };
                            }),
                        ],
                    }}
                    options={{
                        layout: { padding: -12 }, // Remove the intrinsic padding to match figma design more easily.
                        maintainAspectRatio: false,
                        responsive: true,
                        spanGaps: true,
                        onClick: handleGraphClick,
                        scales: {
                            x: {
                                beginAtZero: true,
                                border: {
                                    color: ruminatiColors.green_3_30,
                                },
                                grid: {
                                    color: (g) => {
                                        // Show gridline as orange if selected
                                        if (g.index === selectedIndex) return ruminatiColors.orange;

                                        // Current year has different color Green 30%
                                        return g.index === currentYearIndex
                                            ? ruminatiColors.green_3_30
                                            : ruminatiColors.green_3_10;
                                    },
                                    lineWidth: (g) => {
                                        // Increase width of selected year
                                        return g.index === selectedIndex ? 1.25 : 1;
                                    },
                                    tickLength: 12,
                                },
                                ticks: {
                                    // Change label color to also be orange while selected
                                    color: (g) => g.index === selectedIndex
                                        ? ruminatiColors.orange
                                        : ruminatiColors.green_3,
                                    padding: 12,
                                    align: "start",

                                },
                            },
                            y: {
                                // Using these suggested values to add some space at the top and bottom
                                suggestedMin: props.yRange?.min,
                                suggestedMax: props.yRange?.max,
                                border: {
                                    color: parseInt(selectedYear ?? "") === props.startYear ?
                                        ruminatiColors.orange : ruminatiColors.green_3_30
                                },
                                grid: {
                                    color: ruminatiColors.green_3_10,
                                    tickLength: 12,
                                },
                                ticks: {
                                    padding: 12,
                                    callback: (tickValue) => {
                                        return trimDecimalPlaces(tickValue as number, 2).toString()
                                    }
                                },
                            },
                        },
                        animation: { duration: 0 },
                        plugins: {
                            // Hide legend
                            legend: { display: false },
                            // Customise tooltip
                            tooltip: {
                                enabled: false,
                                padding: 0,
                                external: props.showTooltip ? customTooltip : () => <></>,
                            },
                            annotation: {
                                clip: false,
                                annotations:
                                    props.baseline !== undefined ? {
                                        line1: {
                                            type: 'line',
                                            xMin: getSelectedX(),
                                            xMax: getSelectedX(),
                                            yMax: (ctx) => {
                                                return (props.baseline ?? 0) - getLineOffset(ctx);
                                            },
                                            yMin: (ctx) => {
                                                return getSelectedNet() + getLineOffset(ctx);
                                            },
                                            // yMax: props.baseline,
                                            borderColor: ruminatiColors.data_orange,
                                            borderWidth: (ctx) => {
                                                if (getPixelDifference(ctx) < 12) return 0;
                                                return 2
                                            },
                                        },
                                        label1: {
                                            display: props.percentageChange !== 0,
                                            type: 'label',
                                            xValue: getSelectedX(),
                                            yValue: (ctx) => getLabelPos(ctx),
                                            yAdjust: 0,
                                            color: '#fff',
                                            backgroundColor: ruminatiColors.data_orange,
                                            borderRadius: 4,
                                            content: `${props.percentageChange}%`,
                                            padding: {
                                                x: 4,
                                                y: 3,
                                            },
                                            font: {
                                                size: 12,
                                                family: "TTInterfaces",
                                            }
                                        }
                                    } : {}
                            },
                        },
                    }}
                />
            </div>
        </div>
    );
}
