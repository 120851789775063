import { useState } from "react";
import { useNavigate } from "react-router-dom";

import ReductionGraph, { EmissionDataOption } from "../../components/graph/reduction_graph";
import DropdownSelect from "../../components/dropdown_select";
import { getBaseline, getBaselineUnit, getCompletedReports, getEarliestCompletedReport } from "../../utilities/reports";
import { Column, FlexRow, Row } from "../../components/styled_layout";
import { formatReportNumber } from "../../utilities/functions";
import { calculatePercentageDifferenceFromBaseline, calculateProjectedResultsForYear, getAvailableChartOptions } from "../../utilities/reduction_plans";
import { ruminatiColors } from "../../utilities/colors";
import EmissionUnit from "../../models/emission-unit";
import Heading from "../../components/heading";
import MainButton from "../../components/buttons/main_button";
import { getCurrentFinancialYear } from "../../utilities/dates";
import { Card } from "./farm";
import { Report } from "../../models/property_models";
import { EmptyCardContent } from "./empty_card_content";
import GraphTag from "../../components/graph/graph_tag";
import { ReductionPlan } from "../../models/reduction_plans_models";
import { Property } from "../../models/property_models";

export function ReductionPlannerCard({
    manageButtonOnClick,
    property,
    subscribed,
    reports,
    reductionPlans,
    year,
}: {
    manageButtonOnClick: () => void;
    property: Property;
    subscribed?: boolean;
    reports: Report[];
    reductionPlans: ReductionPlan[];
    year: number;
}) {
    const navigate = useNavigate();

    const [selectedData, setSelectedData] = useState<EmissionDataOption>(EmissionDataOption.EmissionsData);
    const [selectedYear, setSelectedYear] = useState<number>(year);

    const graphOptions = getAvailableChartOptions(property, (option) => setSelectedData(option))

    const earliestReport = getEarliestCompletedReport(reports)!;
    const completedReports = getCompletedReports(reports)

    const hasData = earliestReport.financialYear <= selectedYear;

    const baseline = getBaseline(reports, selectedData);
    const baselineUnit = getBaselineUnit(selectedData)

    const createSummaryElement = (label: string, value: number, color: string, unit: string, force: boolean = false) => {
        const valueString = formatReportNumber((!hasData && !force) ? 0 : value ?? 0, { maxDecimalPlaces: selectedData === EmissionDataOption.EmissionsData ? 0 : 2 });
        const unitSpacing = unit === EmissionUnit.Change ? "" : " ";

        return (
        <div key={label} style={{ paddingLeft: "8px" }}>
            <GraphTag color={color} label={label} value={`${valueString}${unitSpacing}${unit}`} />
        </div>)
    }

    const projectedResults = calculateProjectedResultsForYear(selectedYear, completedReports, reductionPlans)

    // Create graph summary based on selected data.
    const createSummary = () => {
        let stats: JSX.Element[] = [];
        if (selectedData === EmissionDataOption.EmissionsData) {
            stats.push(
                createSummaryElement(
                    "Avoided",
                    baseline.total - projectedResults.totals.total,
                    ruminatiColors.data_blue,
                    EmissionUnit.Total
                ),
                createSummaryElement(
                    "Absorbed",
                    projectedResults.totals.sequestration - baseline.sequestration,
                    ruminatiColors.data_green,
                    EmissionUnit.Total
                )
            )
        }

        return <Row>
            {createSummaryElement(
                "Baseline",
                baseline.total - baseline.sequestration,
                ruminatiColors.data_orange,
                baselineUnit,
                true
            )}
            <Row
                style={{
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    flexWrap: "wrap",
                    flexGrow: 1,
                }}
            >
                {stats}
                {createSummaryElement(
                    "Change",
                    calculatePercentageDifferenceFromBaseline(projectedResults, baseline, selectedData),
                    ruminatiColors.data_orange,
                    EmissionUnit.Change
                )}
            </Row>
        </Row>
    };

    const header = (
        <Row style={{ width: "100%", justifyContent: 'space-between' }}>
            <Column style={{alignItems: 'flex-start'}}>
                <Heading level={4}>
                    Reduction Planner
                </Heading>
            </Column>
            <Column style={{alignItems: 'flex-end'}}>
                {reductionPlans.length > 0 ?
                    <FlexRow>
                        <DropdownSelect
                            text={selectedData}
                            options={graphOptions}
                        />
                        <MainButton
                            colorScheme="orange"
                            size="small"
                            onClick={manageButtonOnClick}
                        >
                            Manage
                        </MainButton>
                    </FlexRow>
                : undefined}
            </Column>
        </Row>
    )

    function getCardBody (): JSX.Element {
      if (!subscribed) {
          return (
            <EmptyCardContent
            text={
                "Ruminati PRIME give you access to the Reduction Planner which helps you simulate how adjustments in your enterprise can affect your emissions, allowing you to build a plan for the future."
            }
            buttonText="Upgrade now"
            buttonOnClick={() => navigate("/account/subscription")}
          />)
      }
      if (subscribed && reductionPlans.length === 0) {
        return (
            <EmptyCardContent
            text={
                "The Reduction Planner helps you simulate how adjustments in your enterprise can affect your emissions, allowing you to build a plan for the future."
            }
            buttonText="Try it out now"
            buttonOnClick={() => navigate(`/farm/${property.id}/reductionPlanner/`)}
          />
        )
      }
      return <div style={{ position: "relative", width: "100%" }}>
                <ReductionGraph
                    showTable={false}
                    onYearSelect={setSelectedYear}
                    currentYear={getCurrentFinancialYear().toString()}
                    dataOption={selectedData}
                    baseline={baseline}
                />
                <div style={{ position: "absolute", top: 25, left: 55, width: "calc(100% - 55px)" }}>
                    {createSummary()}
                </div>
            </div>
    }

    return <Card header={header} body={getCardBody()}/>
}