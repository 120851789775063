import styled from "styled-components";
import { ruminatiColors } from "../utilities/colors";
import MainButton from "./buttons/main_button";
import { BaseText } from "./styled_text";

type EmptyCTAProps = {
    text: string;
    buttonText: string;
    onClick: () => void;
}

export default function EmptyCTA(props: EmptyCTAProps) {
    return <EmptyCTAWrapper>
        <EmptyCTAText>{props.text}</EmptyCTAText>
        <MainButton colorScheme="orange" size="small" onClick={props.onClick}>
            {props.buttonText}
        </MainButton>
    </EmptyCTAWrapper>;
}

const EmptyCTAText = styled(BaseText)`
    color: ${ruminatiColors.orange};

    font-size: 18px;
    line-height: 24px;

    padding-bottom: 16px;
`;

const EmptyCTAWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 16px;

    border: 1px solid ${ruminatiColors.orange};
    border-radius: 8px;
`;