import { Select, ConfigProvider } from "antd"

import { DropdownOptionData } from "@/models/form/form_fields/form_field"
import { OnChangeFunction } from "@/models/form/form_fields/form_field"
import { Row } from "../styled_layout"
import { BodyText } from "../styled_text"
import { ruminatiColors } from "@/utilities/colors"

interface SuggestionFieldProps<T extends (string | number | undefined)> {
    value?: T;
    label?: string;
    placeholder?: string;
    suggestions: DropdownOptionData<T>[];
    onChange: OnChangeFunction<T>;
    onSearch?: OnChangeFunction<T>;
}

export function SuggestionField(props: SuggestionFieldProps<string | number | undefined>) {
    return (
        <>
            {props.label && <Row
                style={{
                    justifyContent: 'flex-start',
                    width: '100%',
                    marginBottom: "8px"
                }}>
                <BodyText style={{ fontSize: "16px" }}>
                    {props.label}
                </BodyText>
            </Row>}
            <Row style={{ justifyContent: 'flex-start', width: "100%" }}>
                <ConfigProvider
                    theme={{
                        components: {
                            Select: {
                                zIndexPopup: 10000,
                                colorPrimary: ruminatiColors.orange_50,
                                colorPrimaryHover: ruminatiColors.orange_50,
                                boxShadow: 'transparent',
                                boxShadowSecondary: 'transpanent',
                                boxShadowTertiary: 'transparent',
                                optionPadding: "16px",
                                optionSelectedBg: 'transparent',
                                colorBorder: ruminatiColors.green_3,
                                selectorBg: ruminatiColors.bone,
                                colorBgElevated: ruminatiColors.bone,
                                controlHeightLG: 48,
                                fontSize: 16,
                                fontSizeIcon: 16,
                                optionActiveBg: 'transparent',
                                optionSelectedFontWeight: 500,
                                colorText: ruminatiColors.orange,
                                optionSelectedColor: ruminatiColors.orange,
                                optionFontSize: 20,
                                fontFamily: '"TTInterfaces", sans-serif',
                                lineHeight: 1.4
                            }
                        }
                    }}

                >
                    <Select
                        value={props.value}
                        placeholder={props.placeholder}
                        options={props.suggestions.map(s => {
                            return { label: s.name, value: s.value }
                        })}
                        size="large"
                        showSearch={true}
                        autoClearSearchValue={true}
                        style={{
                            width: '100%'
                        }}
                        optionFilterProp="label"
                        onChange={(v: any) => {
                            props.onChange(v)
                        }}
                        onSearch={(v: any) => {
                            if (props.onSearch) props.onSearch(v)
                        }}
                        notFoundContent={props.value ? "No matches found" : null}
                    />
                </ConfigProvider>
            </Row>
        </>
    );
}
