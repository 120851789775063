import {
    ForwardedRef,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useReducer
} from "react";
import { motion, AnimatePresence } from 'framer-motion'

import { FormState, FormData } from "./form";
import { Centre, Column, Row } from "../../components/styled_layout";
import Icon from "../../components/icon";
import LoadingButton from "../../components/buttons/loading_button";
import { FormFields } from "./form_fields/form_fields";
import { GreenCard } from "../../components/card";
import CollapsableWrapper from "../../components/collapsable_wrapper";
import { BaseText, ParagraphText } from "../../components/styled_text";
import { Divider } from "@/components/horizontal_divider";
import { ruminatiColors } from "@/utilities/colors";
import SmallButton from "@/components/buttons/small_button";

type ArrayFormAction =
    | { type: "update-index"; index: number; state: { [key: string]: any } }
    | { type: "delete-index"; index: number }
    | { type: "add-index"; data?: { [key: string]: any } }
    | { type: "overwrite"; state: any };

interface ArrayFormProps<T extends FormState, U = T> {
    data: FormData<T, U>;
    initialValue: Partial<U>[];
    onSubmit: (value: U[]) => Promise<void>;
    onChange: (formState: T[]) => Promise<void>;
    sectionName: string;
    sectionNameCallback?: (formState: FormData<T, U>) => string;
    hideSubmitButton?: boolean;
    hideAddButton?: boolean;
    fieldSize?: "small" | "large";
    minimumRequired?: number;
    maximumAllowed?: number;
    addMarginUnderneath?: boolean
    useRowStyle?: boolean;
    submitText?: string;
    startCollapsed?: boolean
    showMissingFields?: boolean;
    setShowMissingFields?: (show: boolean) => void;
}

export interface ArrayFormRef<U> {
    validate: () => boolean;
    addItem: (item?: U) => void;
    getState: () => U[];
    submit: () => Promise<void>;
}

function ArrayForm<T extends FormState, U extends FormState = T>(
    props: ArrayFormProps<T, U>,
    ref?: ForwardedRef<ArrayFormRef<U>>
): JSX.Element {

    const reducer = (state: T[], action: ArrayFormAction): T[] => {
        switch (action.type) {
            case "update-index":
                state[action.index] = {
                    ...state[action.index],
                    ...action.state,
                } as any;

                return [...state];
            case "delete-index":
                return [...state.filter((_, i) => i !== action.index)];
            case "add-index":
                const data = action.data ?? {}
                return [
                    ...state,
                    props.data.transformer
                        ? (props.data.transformer.in(data as U) as T)
                        : (data as T),
                ];
            case "overwrite":
                return action.state;
        }
    };

    const transformInput = useCallback(
        (value: U[]): Partial<T>[] => {
            if (props.data.transformer) {
                return value.map((v) => props.data.transformer!.in(v));
            }
            return value as any;
        },
        [props.data]
    );

    const transformOutput = (value: T[]): U[] => {
        if (props.data.transformer) {
            return value.map((v) => props.data.transformer!.out(v));
        }
        return value as any;
    };

    const [formState, dispatch] = useReducer(
        reducer,
        transformInput(props.initialValue as U[]) as T[]
    );
    
    const onSubmit = () => {
        if (props.setShowMissingFields !== undefined) {
            if (validate()) {
                return props.onSubmit(transformOutput(formState as T[]))
            } else {
                props.setShowMissingFields(true)
                return Promise.resolve()
            }
        } else {
            return props.onSubmit(transformOutput(formState as T[]))
        }
    }

    // Exposes the functions needed for other components to implement
    // this form without the built-in Add and Submit buttons.
    useImperativeHandle(ref, () => ({
        validate: (): boolean => validate(),
        addItem: (item?: U): void => dispatch({ type: "add-index", data: item }),
        getState: (): U[] => transformOutput(formState),
        submit: (): Promise<void> => onSubmit()
    }));

    const validate = (): boolean => {
        // Check each duplicated section
        const valid = formState.reduce((prev, _curr, index) => {
            return (
                prev &&
                // Check each field inside a section
                Object.keys(props.data.fields).reduce((prev, curr) => {
                    const tempState = {
                        get: formState,
                        set: () => { },
                        index: index,
                    }

                    const currentField = props.data.fields[curr]
                    const indexFormState = formState[index]
                    if (!currentField || !indexFormState) {
                        return prev;
                    }

                    return prev &&
                        (
                            (!currentField.required(state) && indexFormState[curr] === undefined)
                            || currentField.validate(indexFormState[curr], tempState)
                        )
                }, true)
            );
        }, true);

        return valid && formState.length >= (props.minimumRequired ?? 0);
    };

    useEffect(() => {
        dispatch({
            type: "overwrite",
            state: transformInput(props.initialValue as U[]),
        });
    }, [props.initialValue, props.data, transformInput]);

    const state = (index: number) => ({
        get: formState[index],
        set: (state: any) => dispatch({ type: "update-index", index: index, state: state }),
        index: index,
        getAll: formState
    });

    function getSectionTitle(formState: any, _index: number): string {
        if (props.sectionNameCallback !== undefined) {
            return props.sectionNameCallback(formState)
        }
        return props.sectionName
        // return `${props.sectionName} ${_index + 1}`
    }

    const hideAdd = props.hideAddButton ? true : props.maximumAllowed ? props.maximumAllowed === formState.length : false

    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
                style={{
                    overflow: 'hidden'
                }}
            >
                <AnimatePresence initial={false}>
                    {formState.map((formData, index) => {
                        const indexformState = formState[index]
                        const isEmpty = Object.values(indexformState).filter(v => v !== undefined).length === 0

                        return (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: 'auto' }}
                                exit={{ opacity: 0, height: 0 }}
                                transition={{
                                    // duration: 10
                                    opacity: { duration: 0.1 },
                                    height: { duration: 0.2 }
                                }}
                                style={{
                                    willChange: "unset"
                                }}
                            >
                                {
                                    !props.useRowStyle &&
                                    <Row style={{ paddingBottom: '16px' }}>
                                        <CollapsableWrapper
                                            key={index}
                                            id={`${props.sectionName}_${index}`}
                                            showDelete={true}
                                            onDelete={() => {
                                                dispatch({
                                                    type: "delete-index",
                                                    index: index,
                                                })
                                                if (props.onChange !== undefined) {
                                                    // TEMP:
                                                    // can't just use the await timing hack as per <FormFields> onChange below as deleting is a single operation directly relating to the array items (as opposed to the contents of each array item as it's own field, as per below)
                                                    // so instead, just replicate the reducer logic from above in the case of removing an index
                                                    // this is only relevant for Dairy additional production systems currently
                                                    props.onChange([...formState.filter((_, i) => i !== index)])
                                                }
                                            }}
                                            startCollapsed={!isEmpty}
                                            label={getSectionTitle(formData, index)}
                                            children={
                                                <Row
                                                    style={{
                                                        padding: '24px',
                                                        width: '100%',
                                                        rowGap: "12px",
                                                        flexWrap: 'wrap',
                                                        justifyContent: 'space-between'
                                                    }}>
                                                    <FormFields
                                                        data={props.data}
                                                        state={state(index)}
                                                        getValue={(field) => indexformState ? indexformState[field] : undefined}
                                                        onChange={async (field, value) => {
                                                            await dispatch({
                                                                type: "update-index",
                                                                index: index,
                                                                state: { [field]: value },
                                                            });
                                                            if (props.onChange !== undefined) {
                                                                await props.onChange(formState)
                                                            }
                                                        }}
                                                        fieldSize={props.fieldSize ?? "small"}
                                                        showMissingFields={props.showMissingFields}
                                                        setShowMissingFields={props.setShowMissingFields}
                                                        isArrayForm />
                                                </Row>
                                            }
                                        />
                                    </Row>
                                }
                                {
                                    props.useRowStyle &&
                                    <Row key={index} style={{ width: '100%' }}>
                                        <Column style={{ width: '100%' }}>
                                            {index > 0 && <Row style={{ width: '100%', marginTop: '20px' }}>
                                                <Column style={{ width: '60%' }}>
                                                    <Divider style={{ backgroundColor: ruminatiColors.green_3_30 }} />
                                                </Column>
                                            </Row>}
                                            <Row style={{ width: '100%', marginTop: '20px' }}>
                                                <Column style={{ width: "calc(100% - 40px)" }}>
                                                <Row
                                                    style={{
                                                        width: '100%',
                                                        flexWrap: 'wrap',
                                                        justifyContent: 'space-between'
                                                    }}>
                                                    <FormFields
                                                        data={props.data}
                                                        state={state(index)}
                                                        getValue={(field) => indexformState ? indexformState[field] : undefined}
                                                        onChange={async (field, value) => {
                                                            await dispatch({
                                                                type: "update-index",
                                                                index: index,
                                                                state: { [field]: value },
                                                            });
                                                            if (props.onChange !== undefined) {
                                                                await props.onChange(formState);
                                                            }
                                                        }}
                                                        fieldSize={props.fieldSize ?? "small"}
                                                        showMissingFields={props.showMissingFields}
                                                        setShowMissingFields={props.setShowMissingFields}
                                                        isArrayForm />
                                                </Row>
                                                </Column>
                                                <Column style={{ width: '40px', paddingTop: '20px', alignItems: ' flex-end' }}>
                                                    {formState.length > 1 &&
                                                        <div
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => dispatch({
                                                                type: "delete-index",
                                                                index: index,
                                                            })}>
                                                            <Icon icon="bin" />
                                                        </div>
                                                    }
                                                </Column>
                                            </Row>
                                        </Column>
                                    </Row>
                                }
                            </motion.div>
                        );
                    })}
                </AnimatePresence>
            </form>

            {!hideAdd && !props.useRowStyle && <GreenCard
                style={{
                    marginBottom: props.addMarginUnderneath === false ? "0px" : "24px",
                    cursor: 'pointer',
                    border: `1px solid ${props.minimumRequired && props.showMissingFields && formState.length < props.minimumRequired ? ruminatiColors.red : ruminatiColors.green_3}`
                }}
                onClick={() => {
                    if (formState.length === 0 && props.setShowMissingFields) {
                        props.setShowMissingFields(false)
                    }
                    dispatch({ type: "add-index" });
                }}
            >
                <Row style={{ justifyContent: 'space-between' }}>
                    <Column>
                        <ParagraphText style={{ fontSize: '16px' }}>Add {props.sectionName}</ParagraphText>
                    </Column>
                    <Column>
                        <Row style={{ alignItems: 'center', gap: '8px' }}>
                            {props.minimumRequired !== undefined && props.showMissingFields && formState.length < props.minimumRequired && (
                                <BaseText
                                    style={{
                                        color: ruminatiColors.red,
                                        fontSize: "14px",
                                        userSelect: "none",
                                        margin: "0 0 0 8px",
                                    }}
                                >
                                    Requires at least {props.minimumRequired} {props.sectionName}
                                </BaseText>
                            )}
                            <Icon icon="plus" />
                        </Row>
                    </Column>
                </Row>
            </GreenCard>}

            {!hideAdd && props.useRowStyle && <Row style={{margin: '20px 0px'}}>
                <SmallButton 
                colorScheme="outline" 
                onClick={() => {
                    if (formState.length === 0 && props.setShowMissingFields) {
                        props.setShowMissingFields(false)
                    }
                    dispatch({ type: "add-index" })
                }}>
                    <Row>
                        <Column style={{marginRight: '10px'}}><Icon icon="plus-small"/></Column>
                        <Column>Add {props.sectionName}</Column>
                    </Row>
                </SmallButton>
            </Row>}

            {!props.hideSubmitButton && <Centre
                style={{
                    marginTop: "8px",
                }}
            >
                <LoadingButton
                    type="submit"
                    size="medium"
                    disabled={props.showMissingFields !== undefined ? false : !validate()}
                    onClick={onSubmit}
                >
                   {props.submitText ?? "Continue"}
                </LoadingButton>
            </Centre>
            }
        </>
    );
}

export default forwardRef(ArrayForm);
