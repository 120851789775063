import { usePopupStore } from "../../state/popup_store";
import SubButton from "./sub_button";

type LPGUseButtonProps = {
    state?: any;
    onSubmit: (val: any) => void;
};

/**
 * A button which shows the LPG usage popup on click
 */
export default function LPGUseButton(props: LPGUseButtonProps) {
    const popupStore = usePopupStore();

    return (
        <div style={{ padding: "0 0 0 8px" }}>
            <SubButton
                colorScheme="orange"
                onClick={() =>
                    popupStore.addPopup(
                        'LPGUsageCalculatorPopup',
                        props.onSubmit,
                        {}
                    )
                }
            >
                Calculate
            </SubButton>
        </div>
    );
}
