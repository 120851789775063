import { Form, FormData } from "../../models/form/form";
import { NumberFormField } from "../../models/form/form_fields/basic_form_fields";
import { calculateElectricityUseFromSpend } from "../../utilities/electricity";
import { InfoCard } from "../card";
import { Row } from "../styled_layout";


export interface CalculatorDTO {
    amountSpent: number;
}

const calculatorForm = (): FormData<CalculatorDTO> => ({
    fields: {
        amountSpent: new NumberFormField({
            label: "Annual Electricity Bill",
            unit: "$",
            required: true,
            validator: (value) => value !== undefined && value >= 0,
        })
    },
});

/**
 * The Electrity Use Calculator form, intended to be shown inside a popup
 */
export default function ElectricityUseCalculator({
    onSubmit,
    metadata
}: {
    onSubmit: (val: number) => void;
    metadata?: any
}) {
    const formData = calculatorForm();
    return (
        <div style={{ padding: "20px", width: '450px' }}>
            <InfoCard 
                content={'Estimate your electricity usage based on you annual electricity bill and the average cost of energy for your region'}
            />
            <Row style={{height: '20px'}}></Row>
            <Form
            
                data={formData}
                initialValue={{}}
                fieldSize="small"
                submitText={`Calculate KwH used`}
                buttonSize="medium"
                onSubmit={async (value: CalculatorDTO) => {
                    const amountUsed = calculateElectricityUseFromSpend(value.amountSpent, metadata.localityId)
                    if (amountUsed === null) return onSubmit(0)
                    onSubmit(amountUsed);
                }}
            />
        </div>
    );
}
