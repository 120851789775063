import { create } from 'zustand';
import { AgriWebbFarm } from '../models/agriwebb';
import { Integrations } from "../models/integrations";

interface IntegrationsState {
    integrationsRetrieved: boolean;
    integrations: undefined | Integrations;
    setIntegrations: (r: Integrations) => void;
    setIntegrationRetrieved: (r: boolean) => void;

    agriWebbFarms: AgriWebbFarm[] | undefined;
    setAgriWebbFarms: (r: AgriWebbFarm[]) => void;

    clearAgriWebbFarms: () => void;
}

/**
 * Stores agriwebb data from the database. This mainly consists of
 * the available properties (name & ids) so they can be passed into
 * other queries
 */
export const useIntegrationsStore = create<IntegrationsState>((set) => ({
    integrationsRetrieved: false,
    integrations: undefined,
    setIntegrations: (integrations: Integrations) => set({ integrations }),
    setIntegrationRetrieved: (integrationsRetrieved: boolean) => set({ integrationsRetrieved }),

    agriWebbFarms: undefined,
    setAgriWebbFarms: (farms: AgriWebbFarm[]) => set({ agriWebbFarms: farms }),
    clearAgriWebbFarms: () => set({ agriWebbFarms: undefined })
})
);