import { FormData } from "../../../../models/form/form";
import { getCurrentFinancialYear } from "../../../../utilities/dates";
import { InitiativePopUp } from "../initiative_popup";
import { ManagePasturesDTO, ManageSupplementsDTO } from "../../../../models/reduction_plans_models";
import { Report } from "../../../../models/property_models";
import { SliderFormField } from "../../../../models/form/form_fields/basic_form_fields";
import { getYearField, getDefaultReductionPlanYearOptions, addExistingReductionPlanYearsToOptions } from "../initiative_helpers";
import { FieldSize } from "../../../../models/form/form_fields/form_field";

interface SupplementsFormData {
    supplementaryFeed: number;
    supplementaryFeedTargetYear: number;
}

export default function ManageSupplementaryFeedPopup(props: {
    initialData?: ManageSupplementsDTO;
    compareReport?: Report;
    onSubmit: (data: ManagePasturesDTO) => Promise<void>;
}) {
    const yearOptions = getDefaultReductionPlanYearOptions()
    const existingReductionPlanYears = [
        props.initialData?.supplementaryFeed?.targetYear,
    ].filter(v => v !== undefined) as number[]
    addExistingReductionPlanYearsToOptions(existingReductionPlanYears, yearOptions)

    const supplementaryFeed = props.compareReport?.supplementaryFeedInformation
    const centreValue = supplementaryFeed ? supplementaryFeed.cottonSeed + supplementaryFeed.grain + supplementaryFeed.hay : undefined

    const initialData: ManageSupplementsDTO = props.initialData ?? {
        supplementaryFeed: {
            targetValue: centreValue ?? 0,
            targetYear: getCurrentFinancialYear()
        }
    }

    const formData: FormData<SupplementsFormData, ManageSupplementsDTO> = {
        fields: {
            supplementaryFeed: new SliderFormField({
                required: true,
                sliderProps: {
                    defaultRange: { min: 0, max: 1000 },
                    prevReportValue: centreValue,
                    initialValue: initialData.supplementaryFeed?.targetValue ?? 0,
                    unitLabel: "tonnes",
                    label: "Supplementary Feed (tonnes/year)",
                },
            }),
            supplementaryFeedTargetYear: getYearField('Year of completion', FieldSize.Full, yearOptions),
        },
        transformer: {
            in: (value) => ({
                supplementaryFeed: value.supplementaryFeed?.targetValue,
                supplementaryFeedTargetYear: value.supplementaryFeed?.targetYear
            }),
            out: (value) => ({
                ...value.supplementaryFeedTargetYear ? {
                    supplementaryFeed: {
                        targetValue: value.supplementaryFeed,
                        targetYear: value.supplementaryFeedTargetYear
                    }
                } : {}
            })
        }
    };
    return (
        <InitiativePopUp
            formData={formData}
            initialFormValues={initialData}
            onSubmit={(state) => props.onSubmit(state)}
        />
    );
}
