import { ConfigProvider, Switch } from 'antd'

import { ruminatiColors } from '@/utilities/colors'

export type SwitchAntdProps = {
  disabled?: boolean
  checked: boolean
  onChange: (checked: boolean) => void
}

export default function SwitchAntd(props: SwitchAntdProps) {
  return (<ConfigProvider
    theme={{
      components: {
        Switch: {
          colorPrimary: ruminatiColors.green_3,
          colorPrimaryHover: ruminatiColors.green_4,
          handleBg: ruminatiColors.bone
        }
      },
    }}
  >
    <Switch
      checked={props.checked}
      disabled={props.disabled ?? false}
      onChange={props.onChange} />
  </ConfigProvider>)
}