import { create } from 'zustand';
import { User } from '../models/auth_models';
import { UserVoucherDTO } from '@/models/voucher';

interface AuthState {
    loggedIn: boolean;

    user: User | undefined;
    vouchers: UserVoucherDTO[] | undefined;
    setVouchers: (r: UserVoucherDTO[]) => void;

    signIn: (user: User) => void;
    logOut: () => void;
}

export const useAuthStore = create<AuthState>((set) => ({
    loggedIn: false,

    user: undefined,

    /**
     * Signs in a user and sets the user's info
     * @returns void
     */
    signIn: (user: User) => set((_state) => ({
        loggedIn: true,
        user: user
    })),


    /**
     * Signs out a user and resets the user info
     * @returns void
     */
    logOut: () => set({ loggedIn: false, user: undefined }),
    
    vouchers: undefined,
    setVouchers: (vouchers: UserVoucherDTO[]) => set({ vouchers }),
}));