import { uniq, flatten } from "lodash-es"
import papaparse from 'papaparse'

import { 
  AgriWebbAnimal, 
  SeasonalMobResults, 
  AgriWebbMobAgeClassCattle, 
  AgriWebbMobAgeClassSheep, 
  SeasonalAnimalResults, 
  AgriWebbAgeClassCattle, 
  AgriWebbAgeClassSheep, 
  AgriWebbAgeClassLivestock,
  AgriWebbMobRecord
} from "@/models/agriwebb"
import { LivestockClassAUS } from "@/models/report"
import { calculateAverageOfArray } from "./maths"
import { trimDecimalPlaces } from "./numbers"
import { calculateLiveweightGain } from "./livestock"

const agriwebbAnimalAgeClassesToRumiLivestockClass: Record<AgriWebbAgeClassCattle | AgriWebbAgeClassSheep, number> = {
  // Cattle
  'calf': 6,
  'heifer_calf': 6,
  'steer_calf': 2,
  'bull_calf': 2,
  'non_breeding_bull_calf': 2,
  'weaner': 6,
  'heifer_weaner': 6,
  'steer_weaner': 2,
  'bull_weaner': 2,
  'non_breeding_bull_weaner': 2,
  'yearling': 7,
  'heifer': 7,
  'spayed_heifer': 7,
  'cow': 5,
  'spayed_cow': 5,
  'steer': 4,
  'bull': 1,
  'non_breeding_bull': 1,
  'non_breeding_mature_bull': 1,

  // Sheep
  'lamb': 6,
  'ewe_lamb': 6,
  'ram_lamb': 7,
  'wether_lamb': 7,
  'ewe_weaner': 5,
  'ram_weaner': 1,
  'wether_weaner': 7,
  'hogget': 6,
  'ewe_hogget': 6,
  'ram_hogget': 1,
  'wether_hogget': 7,
  'maiden_ewe': 3,
  'ewe': 5,
  'wether': 2,
  'ram': 1
}

export function generateLivestockClassesFromAgriWebbAnimalData(allAgriWebbData: SeasonalAnimalResults): LivestockClassAUS[] {
  const out: LivestockClassAUS[] = []
  const uniqueClasses = uniq(flatten(allAgriWebbData.map(sd => sd.result?.map(r => r.ageClass))))
  uniqueClasses.forEach(c => {
    if (c) out.push(generateLivestockClassFromAgriWebbData(c, allAgriWebbData))
  })
  return out
}

function generateLivestockClassFromAgriWebbData (ageClass: AgriWebbAgeClassLivestock, allAgriWebbData: SeasonalAnimalResults): LivestockClassAUS {
  const filteredData = filterAllAgriWebbDataByAgeClassName(ageClass, allAgriWebbData)

  const rumiClassId = agriwebbAnimalAgeClassesToRumiLivestockClass[ageClass]
  const springData = filteredData.find(fd => fd.seasonId === 'spring')
  const summerData = filteredData.find(fd => fd.seasonId === 'summer')
  const autumnData = filteredData.find(fd => fd.seasonId === 'autumn')
  const winterData = filteredData.find(fd => fd.seasonId === 'winter')
  const out = {
    classId: rumiClassId,
    headcount: {
      spring: springData?.result?.length ?? 0,
      summer: summerData?.result?.length ?? 0,
      autumn: autumnData?.result?.length ?? 0,
      winter: winterData?.result?.length ?? 0
    },
    liveweight: {
      spring: springData && springData.result && springData.result.length > 0 ? calculateAverageLiveweight(springData.result ?? []) : 0,
      summer: summerData && summerData.result && summerData.result.length > 0 ? calculateAverageLiveweight(summerData.result ?? []) : 0,
      autumn: autumnData && autumnData.result && autumnData.result.length > 0 ? calculateAverageLiveweight(autumnData.result ?? []) : 0,
      winter: winterData && winterData.result && winterData.result.length > 0 ? calculateAverageLiveweight(winterData.result ?? []) : 0,
    },
    liveweightGain: {
      spring: springData && springData.result && springData.result.length > 0 ? calculateAverageLiveweightGain(springData.result ?? []) : 0,
      summer: summerData && summerData.result && summerData.result.length > 0 ? calculateAverageLiveweightGain(summerData.result ?? []) : 0,
      autumn: autumnData && autumnData.result && autumnData.result.length > 0 ? calculateAverageLiveweightGain(autumnData.result ?? []) : 0,
      winter: winterData && winterData.result && winterData.result.length > 0 ? calculateAverageLiveweightGain(winterData.result ?? []) : 0,
    }
  }
  return out
}

function filterAllAgriWebbDataByAgeClassName(ageClass: AgriWebbAgeClassLivestock, allAgriWebbData: SeasonalAnimalResults): SeasonalAnimalResults {
  return allAgriWebbData.map(s => {
    return {
      seasonId: s.seasonId,
      result: s.result?.filter(r => r.ageClass === ageClass)
    }
  })
}

function calculateAverageLiveweight (animals: AgriWebbAnimal[]): number {
  const weights: number[] = []
  animals.forEach(a => {
    if (a.state.weights.liveWeight !== null) weights.push(a.state.weights.liveWeight.value)
    else if (a.state.weights.liveWeight === null && a.state.weights.estimatedWeight !== null) weights.push(a.state.weights.estimatedWeight.value)
  })
  if (weights.length === 0) return 0
  return trimDecimalPlaces(calculateAverageOfArray(weights))
}

function calculateAverageLiveweightGain (animals: AgriWebbAnimal[]): number {
  const weights: number[] = []
  animals.forEach(a => {
    if (a.state.weights.liveAverageDailyGain !== null) weights.push(a.state.weights.liveAverageDailyGain.value)
    else if (a.state.weights.liveAverageDailyGain === null && a.state.weights.assumedAverageDailyGain !== null) weights.push(a.state.weights.assumedAverageDailyGain.value)
    else if (a.state.weights.overallAverageDailyGain !== null) weights.push(a.state.weights.overallAverageDailyGain.value)
  })
  if (weights.length === 0) return 0
  return trimDecimalPlaces(calculateAverageOfArray(weights))
}


export const agriwebbMobClassesToRumiLivestockClass: Record<AgriWebbMobAgeClassCattle | AgriWebbMobAgeClassSheep, number> = {
  // Cattle
  'Calves': 6,
  'Heifer Calves': 6,
  'Steer Calves': 2,
  'Bull Calves': 2,
  'Weaners': 6,
  'Heifer Weaners': 6,
  'Steer Weaners': 2,
  'Bull Weaners': 2,
  'Heifers': 7,
  'Cows': 5,
  'Steers': 4,
  'Bulls': 1,

  // Sheep
  'Lambs': 6,
  'Ewe Lambs': 6,
  'Ram Lambs': 7,
  'Wether Lambs': 7,
  'Ewe Weaners': 5,
  'Ram Weaners': 1,
  'Wether Weaners': 7,
  'Hoggets': 6,
  'Ewe Hoggets': 6,
  'Ram Hoggets': 1,
  'Wether Hoggets': 7,
  'Ewes': 5,
  'Wethers': 2,
  'Rams': 1
}

export async function generateLivestockClassesFromAgriWebbMobData (agriwebbMobData: SeasonalMobResults, livestockType: string): Promise<LivestockClassAUS[]> {
  return new Promise(async (resolve) => {
    const out: LivestockClassAUS[] = []

    const springData = await parseAgriwebbMobData(agriwebbMobData.spring)
    const summerData = await parseAgriwebbMobData(agriwebbMobData.summer)
    const autumnData = await parseAgriwebbMobData(agriwebbMobData.autumn)
    const winterData = await parseAgriwebbMobData(agriwebbMobData.winter)
    const allMobData = [...springData, ...summerData, ...autumnData, ...winterData]
    const filteredMobs = allMobData.filter(m => m.Species === livestockType)
    const uniqueAgeClasses = uniq(filteredMobs.map(mob => mob["Age Class"]))

    uniqueAgeClasses.forEach(agriwebbAgeClass => {
      const rumiClassId = agriwebbMobClassesToRumiLivestockClass[agriwebbAgeClass]
      if (rumiClassId) {
        const springMob = springData.find(m => m["Age Class"] === agriwebbAgeClass)
        const summerMob = summerData.find(m => m["Age Class"] === agriwebbAgeClass)
        const autumnMob = autumnData.find(m => m["Age Class"] === agriwebbAgeClass)
        const winterMob = winterData.find(m => m["Age Class"] === agriwebbAgeClass)
        const livestockClass = {
          classId: rumiClassId,
          name: springMob ? springMob.Mob : summerMob ? summerMob.Mob : autumnMob ? autumnMob.Mob : winterMob ? winterMob.Mob : undefined,
          headcount: {
            spring: calculateHeadcountFromMob(springMob),
            summer: calculateHeadcountFromMob(summerMob),
            autumn: calculateHeadcountFromMob(autumnMob),
            winter: calculateHeadcountFromMob(winterMob),
          },
          liveweight: {
            spring: calculateLiveweightFromMob(springMob),
            summer: calculateLiveweightFromMob(summerMob),
            autumn: calculateLiveweightFromMob(autumnMob),
            winter: calculateLiveweightFromMob(winterMob),
          },
          liveweightGain: {
            spring: 0,
            summer: 0,
            autumn: 0,
            winter: 0
          }
        }
        livestockClass.liveweightGain.spring = calculateLiveweightGain(livestockClass.liveweightGain.summer, livestockClass.liveweightGain.spring)
        livestockClass.liveweightGain.summer = calculateLiveweightGain(livestockClass.liveweightGain.autumn, livestockClass.liveweightGain.summer)
        livestockClass.liveweightGain.autumn = calculateLiveweightGain(livestockClass.liveweightGain.winter, livestockClass.liveweightGain.autumn)
        livestockClass.liveweightGain.winter = 0
        out.push(livestockClass)
      }
    })
    resolve(out)
  })
}

function calculateHeadcountFromMob (mob: AgriWebbMobRecord | undefined): number {
  if (!mob) return 0
  return calculateAverageOfArray([parseFloat(mob.Opening), parseFloat(mob.Closing)])
}

function calculateIndividualWeightOfMobAnimal (weight: number, headcount: number): number {
  if (headcount === 0) return weight
  return weight / headcount
}

function calculateLiveweightFromMob (mob: AgriWebbMobRecord | undefined): number {
  if (!mob) return 0
  const openingWeight = parseFloat(mob["Opening Weight"])
  const openingHeadcount = parseFloat(mob["Opening"])
  const closingWeight = parseFloat(mob["Closing Weight"])
  const closingHeadcount = parseFloat(mob["Closing"])

  if (openingWeight === 0 && closingWeight > 0) {
    return calculateIndividualWeightOfMobAnimal(closingWeight, closingHeadcount)
  } else if (closingWeight === 0 && openingWeight > 0) {
    return calculateIndividualWeightOfMobAnimal(openingWeight, openingHeadcount)
  }

  return calculateAverageOfArray([
    calculateIndividualWeightOfMobAnimal(openingWeight, openingHeadcount),
    calculateIndividualWeightOfMobAnimal(closingWeight, closingHeadcount),
  ])
}

async function parseAgriwebbMobData (csvString: string): Promise<AgriWebbMobRecord[]> {
  return new Promise((resolve) => {
    if (csvString === '') resolve([])
    papaparse.parse(csvString, {
      header: true,
      skipEmptyLines: true,
      complete: (results: any) => {
        resolve(results.data)
      }
    })
  })
}
