import { PropsWithChildren } from "react";
import { ruminatiColors } from "../../utilities/colors";
import Button from "./button";

type TextButtonProps = {
    textColor?: string;
    hoverTextColor?: string;
    activeTextColor?: string;
    fontSize?: string;
    lineHeight?: string;
    bottomBorder?: boolean;
    disabled?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

/**
 *  A button that does not include background, just text with optional underline
 * @param textColor optional. The color of the text. Defaults to effective
 * black 30%
 * @param hoverTextColor optional. The color of the text on hover. Defaults
 * to effective black 30%
 * @param activeTextColor optional. The color of the text on pressed down.
 * Defaults to effective black 30%
 * @param fontSize optional. The font size of text in the button
 * @param lineHeight optional. The line height of the text in the button
 * @param bottomBorder optional. If true, will show a border under the text.
 * Defaults to false
 * @param disabled optional. Prevents click interaction and greys out
 * button. Defaults to false
 * @param children optional. The content of the button
 * @param onClick optional. The function called when the button is clicked
 */
export default function TextButton(props: PropsWithChildren<TextButtonProps>) {
    return (
        <Button
            type="button"
            onClick={props.onClick}
            backgroundColor="transparent"
            hoverBackgroundColor="transparent"
            activeBackgroundColor="transparent"
            textColor={
                props.disabled
                    ? ruminatiColors.effective_black_30
                    : props.textColor
            }
            hoverTextColor={
                props.disabled
                    ? ruminatiColors.effective_black_30
                    : props.hoverTextColor
            }
            activeTextColor={
                props.disabled
                    ? ruminatiColors.effective_black_30
                    : props.activeTextColor
            }
            border="none"
            borderRadius="0px"
            disabled={props.disabled}
            fontSize={props.fontSize}
            lineHeight={props.lineHeight}
            height="auto"
            padding="0px"
            bottomBorderWidth={props.bottomBorder ? 1 : 0}
        >
            {props.children}
        </Button>
    );
}
