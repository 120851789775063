import { VisionInvitation } from "../models/vision_invitation";
import { LinkedFarmDTO } from "../models/vision";
import { Property } from "@/models/property_models";

export const apiUrl = import.meta.env.REACT_APP_API_BASE_URL;

export function getUnusedInvitations (invitations: VisionInvitation[], linkedFarms: LinkedFarmDTO[], properties: Property[]): VisionInvitation[] {
  if (properties.length === 0 && invitations.length > 0) return []
  return invitations.filter(p => linkedFarms.find(lf => lf.visionOrgId === p.visionOrgId) === undefined)
}

export function composeIconUrl(url: string): string {
  if (url.includes('https://')) return url
  return `${apiUrl}${url}`
}