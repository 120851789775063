import { create } from 'zustand';

type ConfigState = {
  queryTimeouts: {
    properties: number // ms
  }
  setQueryTimeout: (queryName: keyof ConfigState['queryTimeouts'], timeout: number) => void
  isHelpDrawerOpen: boolean
  setIsHelpDrawerOpen: (isOpen: boolean) => void
}

export const useConfigStore = create<ConfigState>((set) => ({
  queryTimeouts: {
    properties: 30000
  },
  setQueryTimeout: (queryName: keyof ConfigState['queryTimeouts'], timeout: number) => {
    set((state) => ({
      queryTimeouts: {
        ...state.queryTimeouts,
        [queryName]: timeout
      }
    }))
  },
  isHelpDrawerOpen: false,
  setIsHelpDrawerOpen: (isOpen: boolean) => {
    set({ isHelpDrawerOpen: isOpen })
  }
}))