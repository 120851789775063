import { ruminatiColors } from "../../utilities/colors";
import { Centre } from "../styled_layout";

/**
** A styled container used as the header of a given page.
**/
export default function PageHeader({ children }: { children: any }) {
    return <Centre style={{
        width: "936px",
        alignItems: "center",
        borderBottom: `1px solid ${ruminatiColors.green_3_30}`,
        marginBottom: "32px",
        height: "72px",
    }}>
        {children}
    </Centre>
}