import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { ruminatiColors } from "../../utilities/colors";
import { Column } from "../styled_layout";
import InteractiveGraph, { GraphPoint, GraphSeries } from "./interactive_graph";
import {
    Initiative,
    InitiativeCategory,
    InitiativeType,
    ReductionPlan,
    ReductionPlanInputDTO,
    initiativeCategoriesMap,
    initiativeNamesMap,
} from "../../models/reduction_plans_models";
import { useDatabaseStore } from "../../state/database_store";
import { Report, ReportResults } from "../../models/property_models";
import {
    getCurrentFinancialYear,
    // getNextFinancialYears,
    getYearsBetween,
} from "../../utilities/dates";
import {
    BaselineType,
    getCompletedReports,
    getEarliestCompletedReport,
    getLatestCompletedReport,
} from "../../utilities/reports";
import {
    calculateProjectedResultsForYear,
    getActivePlansCount,
} from "../../utilities/reduction_plans";
import GraphOptionsTable, { ToggleTableDatum } from "./graph_options_table";

export enum EmissionDataOption {
    EmissionsData = "Emissions Data",
    GrainIntensity = "Grain Intensity",
    BeefIntensity = "Beef Intensity",
    SheepIntensity = "Sheep Intensity",
    WoolIntensity = "Wool Intensity",
    FeedlotIntensity = "Feedlot Intensity",
    FeedlotScope12Intensity = "Feedlot Intensity Scope1 & 2",
    ProcessorScope12Intensity = "kg CO2e/t HCSW (Scope 1 and 2)",
    ProcessorIntensity = "kg CO2e/t HCSW (All scopes)",
    FurtherProcessorScope12Intensity = "kg CO2e/t Product (Scope 1 and 2)",
    FurtherProcessorIntensity = "kg CO2e/t Product (All scopes)",
    DairyMilkSolids = "Milk Solids",
    DairyLiveWeight = "Beef Sold",
    DairyFPCM = "FPCM"
}

type ReductionGraphProps = {
    showTable?: boolean;
    showTooltip?: true;
    currentYear?: string;
    onYearSelect?: (year: number) => void;
    onEditPlan?: (plan: ReductionPlan) => void;
    onToggledPlansChanged?: (plans: ReductionPlan[]) => void;
    onExploreInitiatives?: (cateogry: InitiativeCategory) => void;
    dataOption?: EmissionDataOption;
    baseline: BaselineType;
};

export enum ToggleState {
    On,
    Off,
}

export namespace ToggleState {
    export function toggle(state: ToggleState): ToggleState {
        switch (state) {
            case ToggleState.Off:
                return ToggleState.On;
            case ToggleState.On:
                return ToggleState.Off;
        }
    }
}

export default function ReductionGraph(props: ReductionGraphProps) {
    const { propertyId } = useParams();

    const databaseStore = useDatabaseStore();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const reports: Report[] =
        databaseStore.reports?.filter((r) => r.propertyId === propertyId) ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const reductionPlans: ReductionPlan[] =
        (databaseStore.reductionPlans ?? {})[propertyId!] ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const initiatives: Initiative[] =
        (databaseStore.availableInitiatives ?? {})[propertyId!] ?? [];

    const reductionPlanCategory = useCallback((plan: ReductionPlan): InitiativeCategory => {
            return initiatives.find((i) => i.id === plan.initiativeType)!
                .category;
        },
        [initiatives]
    );

    const reductionPlanActiveForYear = useCallback(
        (plan: ReductionPlan, year: number): boolean => {
            const inputActiveForYear = (
                input: ReductionPlanInputDTO,
                year: number
            ): boolean => {
                if ((input as any).startYear) {
                    return (input as any).startYear === year;
                } else {
                    for (const field of Object.keys(input)) {
                        if ((input as any)[field]["targetYear"] >= year)
                            return true;
                    }
                    return false;
                }
            };

            if (plan.inputs instanceof Array) {
                return plan.inputs.reduce<boolean>(
                    (val, input) => val || inputActiveForYear(input, year),
                    false
                );
            } else {
                return inputActiveForYear(plan.inputs, year);
            }
        },
        []
    );

    const startYear = 2020
    const endYear = 2030

    const graphYears = getYearsBetween(startYear, endYear);

    const nonProjectedYears = getCompletedReports(reports).map(r => r.financialYear)

    const [planToggleMap, setPlanToggleMap] = useState(
        {} as { [plan: string]: ToggleState }
    );
    const [subPlanToggleMap, setSubPlanToggleMap] = useState(
        {} as { [plan: string]: { [index: number]: ToggleState } }
    );

    const [categoryToggleMap, setCategoryToggleMap] = useState({
        [InitiativeCategory.AbsorbEmissions]: ToggleState.On,
        [InitiativeCategory.AvoidEmissions]: ToggleState.On,
        [InitiativeCategory.FutureSolutions]: ToggleState.On,
    });

    // Currently selected FY.
    const [selectedYear, setSelectedYear] = useState<number>(
        getCurrentFinancialYear()
    );

    const setFinancialYear = (year: number) => {
        setSelectedYear(year);
        if (props.onYearSelect) {
            props.onYearSelect(year);
        }
    };

    // Global toggle table active status.
    const [toggleTableActive, setToggleTableActive] = useState<boolean>(true);

    // Whether to show toggle table.
    const [showToggleTable, setShowToggleTable] = useState<boolean>(!nonProjectedYears.includes(selectedYear));

    const toggledPlans = useCallback((): ReductionPlan[] => {
        let plans = reductionPlans;

        // remove plans that are toggled off
        plans = plans.filter((plan) =>
            planToggleMap[plan.id] !== ToggleState.Off && categoryToggleMap[reductionPlanCategory(plan)] !== ToggleState.Off);

        // remove subplans that are toggled off
        plans = plans.map((plan) =>
            plan.removingIndexes(
                Object.keys(subPlanToggleMap[plan.id] ?? {})
                    .map((index) => parseInt(index))
                    
                    .filter((index) => (subPlanToggleMap[plan.id]!)[index] === ToggleState.Off)
            )
        );
        return plans
    }, [reductionPlans, planToggleMap, subPlanToggleMap, categoryToggleMap, reductionPlanCategory])

    useEffect(() => {
        if (props.onToggledPlansChanged) props.onToggledPlansChanged(toggledPlans())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggledPlans])

    const getIntensityData = useCallback(
        (result: ReportResults) => {
            switch (props.dataOption) {
                case EmissionDataOption.BeefIntensity:
                    return result.intensity.kgCo2PerKgBeefMeatSold;
                case EmissionDataOption.WoolIntensity:
                    return result.intensity.kgCo2PerKgGreasyWool;
                case EmissionDataOption.SheepIntensity:
                    return result.intensity.kgCo2PerKgSheepMeatSold;
                case EmissionDataOption.GrainIntensity:
                    return result.intensity.kgCo2PerKgGrainProduced;
                case EmissionDataOption.DairyFPCM:
                    return result.intensity.kgCo2PerKgMilkFPCM;
                case EmissionDataOption.DairyMilkSolids:
                    return result.intensity.kgCo2PerKgMilk;
                case EmissionDataOption.DairyLiveWeight:
                    return result.intensity.kgCo2PerKgDairyMeat;
                case EmissionDataOption.FeedlotIntensity:
                    return result.intensity.kgCo2PerKgMeatFed;
                case EmissionDataOption.FeedlotScope12Intensity:
                    return result.intensity.kgCo2PerKgMeatFedScope1and2;
            }
        },
        [props.dataOption]
    );

    const buildIntensityData = useMemo((): { series: GraphSeries[], baseline: number } => {
        const dataPoints: GraphPoint[] = [];

        const latestReport = getLatestCompletedReport(reports);
        for (const year of [startYear, ...graphYears]) {
            const report = getCompletedReports(reports).find(
                (r: Report) => r.financialYear === year
            );

            const result: any = report?.results;

            // If report for this year has been completed.
            if (result) {
                // Build intensity data.
                dataPoints.push({
                    x: year,
                    y: getIntensityData(result) ?? 0,
                    breakdown: { Value: getIntensityData(result) ?? 0 },
                    isProjection: false,
                });
            }
            // If no completed report for this year exists
            else {
                // If this year is a projected year, use data from latest report
                // and deltas from reduction plans.
                if (year > latestReport?.financialYear!) {
                    const projectedResults = calculateProjectedResultsForYear(year, getCompletedReports(reports), toggledPlans());
                    dataPoints.push({
                        x: year,
                        y: getIntensityData(projectedResults) ?? 0,
                        breakdown: { Value: getIntensityData(projectedResults) ?? 0 },
                        isProjection: true,
                    });
                }
            }
        }

        return {
            series: [
                {
                    id: props.dataOption ?? "",
                    label: "intensity",
                    color: ruminatiColors.data_orange,
                    fillColor: ruminatiColors.data_orange_translucent,
                    points: dataPoints,
                },
            ],
            baseline: (dataPoints && dataPoints[0] && dataPoints[0].y) ? dataPoints[0].y : 0
        };
    }, [
        reports,
        getIntensityData,
        graphYears,
        props,
        toggledPlans,
        startYear,
    ]);

    const buildEmissionsData = useMemo((): { series: GraphSeries[], baseline: BaselineType } => {
        const avoidedEmissionsDataPoints: GraphPoint[] = [];
        const absorbedEmissionsDataPoints: GraphPoint[] = [];

        const latestReport = getLatestCompletedReport(reports);
        for (const year of [startYear, ...graphYears]) {
            const report = getCompletedReports(reports).find(
                (r: Report) => r.financialYear === year
            );

            const result: ReportResults | undefined = report?.results;

            // If report for this year has been completed.
            if (result) {
                const netEmissions = result.totals.total - result.totals.sequestration;
                const avoidedEmissions = props.baseline.total - result.totals.total;

                avoidedEmissionsDataPoints.push({
                    x: year,
                    y: props.baseline.total - props.baseline.sequestration - avoidedEmissions,
                    breakdown: {
                        "Scope 1": (props.baseline.results?.byScopes.scope1 ?? 0) - result.byScopes.scope1,
                        "Scope 2": (props.baseline.results?.byScopes.scope2 ?? 0) - result.byScopes.scope2,
                        "Scope 3": (props.baseline.results?.byScopes.scope3 ?? 0) - result.byScopes.scope3,
                    },
                    isProjection: false,
                });

                absorbedEmissionsDataPoints.push({
                    x: year,
                    y: netEmissions,
                    breakdown: {
                        Trees: result.sequestration.trees - (props.baseline.results?.sequestration.trees ?? 0),
                        Soil: result.sequestration.soil - (props.baseline.results?.sequestration.soil ?? 0),
                    },
                    isProjection: false,
                });
            }

            // If no completed report for this year exists
            else {
                // If this year is a projected year, use data from latest report
                // and deltas from reduction plans.
                if (year > latestReport?.financialYear!) {
                    const plans = toggledPlans();

                    const projectedResults = calculateProjectedResultsForYear(
                        year,
                        getCompletedReports(reports),
                        plans
                    );

                    const netEmissions = projectedResults.totals.total - projectedResults.totals.sequestration;
                    const avoidedEmissions = props.baseline.total - projectedResults.totals.total;

                    avoidedEmissionsDataPoints.push({
                        x: year,
                        y: props.baseline.total - props.baseline.sequestration - avoidedEmissions,
                        breakdown: {
                            "Scope 1": (props.baseline.results?.byScopes.scope1 ?? 0) - projectedResults.byScopes.scope1,
                            "Scope 2": (props.baseline.results?.byScopes.scope2 ?? 0) - projectedResults.byScopes.scope2,
                            "Scope 3": (props.baseline.results?.byScopes.scope3 ?? 0) - projectedResults.byScopes.scope3,
                        },
                        isProjection: true,
                    });

                    absorbedEmissionsDataPoints.push({
                        x: year,
                        y: netEmissions,
                        breakdown: {
                            Trees: projectedResults.sequestration.trees - (props.baseline.results?.sequestration.trees ?? 0),
                            Soil: projectedResults.sequestration.soil - (props.baseline.results?.sequestration.soil ?? 0),
                        },
                        isProjection: true,
                    });
                }
            }
        }
        return {
            series: [
                {
                    id: "avoided",
                    label: "Avoided Emissions",
                    color: ruminatiColors.data_blue,
                    fillColor: ruminatiColors.data_blue_translucent,
                    points: avoidedEmissionsDataPoints,
                },
                {
                    id: "net",
                    label: "Absorbed Emissions",
                    color: ruminatiColors.data_green,
                    fillColor: ruminatiColors.data_green_translucent,
                    points: absorbedEmissionsDataPoints,
                },
            ],
            baseline: props.baseline,
        };
    }, [
        reports,
        graphYears,
        startYear,
        toggledPlans,
        props.baseline
    ]);

    const calculateYRange = useCallback((): { max: number, min: number } => {
        const completedReports = getCompletedReports(reports)

        const calculateIntensityRange = (key: keyof ReportResults['intensity']): { max: number, min: number } => {
            let max = Math.max(...completedReports.map((r) => r.results?.intensity[key] ?? 0))
            let min = Math.min(...completedReports.map((r) => r.results?.intensity[key] ?? 0))
            for (const plan of reductionPlans) {
                if (plan.initiativeType === InitiativeType.ManageLivestock) continue
                max += Math.max(0, plan.totalIntensity(key))
                min += Math.min(0, plan.totalIntensity(key))
            }
            return { max, min }
        }

        let range: { max: number, min: number }
        switch (props.dataOption) {
            case EmissionDataOption.BeefIntensity:
                range = calculateIntensityRange('kgCo2PerKgBeefMeatSold')
                break
            case EmissionDataOption.SheepIntensity:
                range = calculateIntensityRange('kgCo2PerKgSheepMeatSold')
                break
            case EmissionDataOption.WoolIntensity:
                range = calculateIntensityRange('kgCo2PerKgGreasyWool')
                break
            case EmissionDataOption.GrainIntensity:
                range = calculateIntensityRange('kgCo2PerKgGrainProduced')
                break
            case EmissionDataOption.DairyFPCM:
                range = calculateIntensityRange('kgCo2PerKgMilkFPCM')
                break
            case EmissionDataOption.DairyMilkSolids:
                range = calculateIntensityRange('kgCo2PerKgMilk')
                break
            case EmissionDataOption.DairyLiveWeight:
                range = calculateIntensityRange('kgCo2PerKgDairyMeat')
                break
            case EmissionDataOption.FeedlotIntensity:
                range = calculateIntensityRange('kgCo2PerKgMeatFed')
                break
            case EmissionDataOption.FeedlotScope12Intensity:
                range = calculateIntensityRange('kgCo2PerKgMeatFedScope1and2')
                break
            default: {
                let max = Math.max(...completedReports.map((r) => r.results?.totals.net ?? 0))
                let min = Math.min(...completedReports.map((r) => r.results?.totals.net ?? 0))

                const latestReport = getLatestCompletedReport(completedReports);

                for (const plan of reductionPlans) {
                    max += Math.max(0, plan.total('net', latestReport?.financialYear))
                    min += Math.min(0, plan.total('net', latestReport?.financialYear))
                }

                range = { max, min }
                break
            }
        }



        const padding = Math.abs(range.max - range.min) * 0.15
        return {
            max: range.max + padding,
            min: range.min - padding,
        }
    }, [reductionPlans, props.dataOption, reports])

    // Create table data for a given year and initiative category.
    const createTableData = useCallback(
        (year: number, category: InitiativeCategory): ToggleTableDatum[] => {
            const latestReport = getLatestCompletedReport(reports)
            if (latestReport && latestReport.financialYear >= year) return []

            const plans = reductionPlans.filter(
                (r) => reductionPlanCategory(r) === category
            );
            var tableData: ToggleTableDatum[] = [];

            for (const plan of plans) {
                if (!reductionPlanActiveForYear(plan, year)) continue;
                const toggleOn = planToggleMap[plan.id] !== ToggleState.Off;
                const disabled = !(categoryToggleMap[category] !== ToggleState.Off)
                tableData.push({
                    disabled,
                    isOn: toggleOn,
                    label: initiativeNamesMap[plan.initiativeType],
                    onEdit: props.onEditPlan,
                    showToggle: !(plan.inputs instanceof Array),
                    onToggle: () => {
                        setPlanToggleMap({
                            ...planToggleMap,
                            [plan.id]: ToggleState.toggle(planToggleMap[plan.id] ?? ToggleState.On),
                        });
                    },
                    plan
                });
                if (plan.inputs instanceof Array) {
                    for (const { input, index } of plan.inputs.map(
                        (input, index) => ({ input, index })
                    )) {
                        if ((input as any).startYear !== year) continue;
                        const subToggleCurrent = (subPlanToggleMap[plan.id] ?? [])[index] ?? ToggleState.On;
                        const subToggleOn = toggleOn && subToggleCurrent !== ToggleState.Off;

                        tableData.push({
                            disabled,
                            isOn: subToggleOn,
                            label: (input as any).name ?? initiativeNamesMap[plan.initiativeType],
                            onEdit: props.onEditPlan,
                            showToggle: true,
                            isChild: true,
                            onToggle: () => {
                                setSubPlanToggleMap({
                                    ...subPlanToggleMap,
                                    [plan.id]: {
                                        ...subPlanToggleMap[plan.id],
                                        [index]: ToggleState.toggle(subToggleCurrent),
                                    },
                                });
                            },
                            plan
                        });
                    }
                }
            }

            return tableData;
        },
        [
            reductionPlanCategory,
            reductionPlanActiveForYear,
            reductionPlans,
            planToggleMap,
            subPlanToggleMap,
            categoryToggleMap,
            props,
            reports
        ]
    );

    const absorbEmissionsTableData = useMemo(
        () => createTableData(selectedYear, InitiativeCategory.AbsorbEmissions),
        [selectedYear, createTableData]
    );

    const avoidEmissionsTableData = useMemo(
        () => createTableData(selectedYear, InitiativeCategory.AvoidEmissions),
        [selectedYear, createTableData]
    );

    const innovativeSolutionsTableData = useMemo(
        () =>
            createTableData(
                selectedYear,
                InitiativeCategory.FutureSolutions
            ),
        [selectedYear, createTableData]
    );

    const showToggleTableForYear = (year: number) => {
        setFinancialYear(year);
        if (nonProjectedYears.includes(year))setShowToggleTable(false);
        else setShowToggleTable(true);
    }

    const percentageDifference: number = useMemo(() => {
        const latestReport = getLatestCompletedReport(reports);
        const earliestReport = getEarliestCompletedReport(reports);
        if (latestReport === undefined || earliestReport === undefined) return 0;
        if (earliestReport.financialYear > selectedYear) return 0;

        const yearReport = getCompletedReports(reports).find((r) => r.financialYear === selectedYear);

        const results = yearReport?.results ? yearReport.results : calculateProjectedResultsForYear(
            selectedYear,
            getCompletedReports(reports),
            toggledPlans()
        );

        let baseline = 0;
        let value = 0;

        switch (props.dataOption) {
            default:
                baseline = buildEmissionsData.baseline.total - buildEmissionsData.baseline.sequestration;
                value = results.totals.net;
                break;
            case EmissionDataOption.BeefIntensity:
                baseline = buildIntensityData.baseline
                value = results.intensity.kgCo2PerKgBeefMeatSold ?? 0;
                break;
            case EmissionDataOption.SheepIntensity:
                baseline = buildIntensityData.baseline
                value = results.intensity.kgCo2PerKgSheepMeatSold ?? 0;
                break;
            case EmissionDataOption.WoolIntensity:
                baseline = buildIntensityData.baseline
                value = results.intensity.kgCo2PerKgGreasyWool ?? 0;
                break;
            case EmissionDataOption.GrainIntensity:
                baseline = buildIntensityData.baseline
                value = results.intensity.kgCo2PerKgGrainProduced ?? 0;
                break;
            case EmissionDataOption.DairyFPCM:
                baseline = buildIntensityData.baseline
                value = results.intensity.kgCo2PerKgMilkFPCM ?? 0;
                break;
            case EmissionDataOption.DairyLiveWeight:
                baseline = buildIntensityData.baseline
                value = results.intensity.kgCo2PerKgDairyMeat ?? 0;
                break;
            case EmissionDataOption.DairyMilkSolids:
                baseline = buildIntensityData.baseline
                value = results.intensity.kgCo2PerKgMilk ?? 0;
                break;
            case EmissionDataOption.FeedlotIntensity:
                baseline = buildIntensityData.baseline
                value = results.intensity.kgCo2PerKgMeatFed ?? 0;
                break;
            case EmissionDataOption.FeedlotScope12Intensity:
                baseline = buildIntensityData.baseline
                value = results.intensity.kgCo2PerKgMeatFedScope1and2 ?? 0;
                break;
        }

        const netDifference = value - baseline;
        if (baseline === 0) {
            return 0;
        }
        return Math.round(netDifference / baseline * 100);
    }, [selectedYear, toggledPlans, props.dataOption, buildEmissionsData, buildIntensityData, reports]);

    const baselineValue = useMemo(() => {
        return props.dataOption === EmissionDataOption.EmissionsData
            ? buildEmissionsData.baseline.total - buildEmissionsData.baseline.sequestration
            : buildIntensityData.baseline
    }, [props, buildEmissionsData, buildIntensityData]);

    return (
        <Column style={{ width: "100%" }}>
            <Column style={{ width: "100%", position: "relative", padding: "0" }}>

                <InteractiveGraph
                    startYear={startYear}
                    endYear={endYear}
                    currentYear={props.currentYear}
                    yRange={calculateYRange()}
                    series={
                        props.dataOption === EmissionDataOption.EmissionsData
                            ? buildEmissionsData.series
                            : buildIntensityData.series
                    }
                    baseline={baselineValue}
                    readOnly={!props.showTable}
                    showTooltip={props.showTooltip}
                    graphButtonOnClick={
                        props.showTable
                            ? (year: number) => {
                                showToggleTableForYear(year);

                                // When selecting a new year via a graph button,
                                // always show the corresponding toggle table.
                                if (selectedYear !== year) {
                                    setToggleTableActive(true);
                                } else {
                                    setToggleTableActive(!toggleTableActive);
                                }
                            } : (year: number) => setFinancialYear(year)
                    }
                    graphButtonActive={toggleTableActive}
                    gridlineOnClick={
                        props.showTable
                            ? (year: number) => showToggleTableForYear(year)
                            : (year: number) => setFinancialYear(year)}

                    nonProjectedYears={nonProjectedYears}
                    yearCounts={getActivePlansCount(reductionPlans, getYearsBetween(nonProjectedYears.at(-1) ?? startYear, endYear))}
                    percentageChange={percentageDifference}
                    reportYears={reports.map(r => r.financialYear)}
                />
                <GraphOptionsTable 
                    showTable={
                      showToggleTable && 
                      toggleTableActive && 
                      props.showTable &&
                      (absorbEmissionsTableData.length > 0 ||
                       avoidEmissionsTableData.length > 0 ||
                       innovativeSolutionsTableData.length > 0
                      )
                    }
                    absorbEmissionsTableData={absorbEmissionsTableData}
                    avoidEmissionsTableData={avoidEmissionsTableData}
                    innovativeSolutionsTableData={innovativeSolutionsTableData}
                    initiativeCategoriesMap={initiativeCategoriesMap}
                    categoryToggleMap={categoryToggleMap}
                    setCategoryToggleMap={setCategoryToggleMap}
                    onExplore={(category) => {
                        if (props.onExploreInitiatives) props.onExploreInitiatives(category)
                    }}
                />
            </Column>
        </Column>
    );
}
