import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "antd"

import { EmissionDataOption } from "../../components/graph/reduction_graph";
import { Property, PropertyType, Report } from "../../models/property_models";
import SmallButton from "../../components/buttons/small_button";
import { BodyText, ButtonText, HeadlineFigureText } from "../../components/styled_text";
import { EmissionCardHeader } from "./emission_card_header";
import { Column, Row } from "../../components/styled_layout";
import { ruminatiColors } from "../../utilities/colors";
import { formatReportNumber, isNotNullOrUndefined } from "../../utilities/functions";
import EmissionUnit from "../../models/emission-unit";
import { formatYearStringToFinancialYearString, getCurrentFinancialYear, formatFinancialYearStringToShortPrettyFinancialYearString } from "../../utilities/dates";
import { getReportOfYear } from "../../utilities/reports";
import ChangeIndicator, { ChangeIndicatorType, getDefaultColorForChangeIndicator } from "../../components/change_indicator";
import { Card, EmissionUnitText } from "./farm";
import { EmptyCardContent } from "./empty_card_content";
import { countryIsNZ } from "../../utilities/countries";
import Icon from "@/components/icon";

export function IntensityCard({
    subscribed,
    property,
    reports,
    report,
}: {
    subscribed: boolean;
    property: Property;
    reports: Report[];
    report?: Report;
}) {
    // Selected data option.
    const [selectedData, setSelectedData] = useState<
        Exclude<EmissionDataOption, EmissionDataOption.EmissionsData>
    >(
        property.cattleFarm
            ? EmissionDataOption.BeefIntensity
            : property.sheepFarm
                ? EmissionDataOption.SheepIntensity
                : property.sheepFarm
                    ? EmissionDataOption.WoolIntensity
                    : property.grainFarm
                        ? EmissionDataOption.GrainIntensity
                        : property.propertyType === PropertyType.Feedlot
                            ? EmissionDataOption.FeedlotIntensity
                            : property.propertyType === PropertyType.Dairy
                            ? EmissionDataOption.DairyFPCM
                            : property.propertyType === PropertyType.FurtherProcessor 
                                ? EmissionDataOption.FurtherProcessorScope12Intensity
                                : EmissionDataOption.ProcessorScope12Intensity

    );

    const isEmpty =
        report === undefined ||
        report?.results?.intensity === undefined;

    const createIntensityButton = (
        label: string,
        dataType: Exclude<EmissionDataOption, EmissionDataOption.EmissionsData>
    ): JSX.Element => {
        return (
            <SmallButton
                borderRadius="4px"
                height="24px"
                paddingWidth="8px"
                colorScheme={dataType === selectedData ? "green" : "outline"}
                onClick={() => setSelectedData(dataType)}
                disabled={!subscribed}
            >
                <ButtonText
                    style={{ cursor: !subscribed ? "not-allowed" : "pointer" }}
                >
                    {" "}
                    {label}
                </ButtonText>
            </SmallButton>
        );
    };

    const header = (
        <EmissionCardHeader
            title={'Intensity'}
            afterTitle={
                <Row style={{ columnGap: "4px" }}>
                    <div style={{ width: "8px" }} />
                    {report && subscribed && <>
                        {property.cattleFarm &&
                            createIntensityButton(
                                "Beef",
                                EmissionDataOption.BeefIntensity
                            )}
                        {property.sheepFarm &&
                            createIntensityButton(
                                "Sheep",
                                EmissionDataOption.SheepIntensity
                            )}
                        {property.sheepFarm &&
                            createIntensityButton(
                                "Wool",
                                EmissionDataOption.WoolIntensity
                            )}

                        {property.grainFarm &&
                            createIntensityButton(
                                "Crops",
                                EmissionDataOption.GrainIntensity
                            )}
                        {property.propertyType === PropertyType.Feedlot &&
                            createIntensityButton(
                                "Scope 1 & 2",
                                EmissionDataOption.FeedlotScope12Intensity
                            )}
                        {property.propertyType === PropertyType.Feedlot &&
                            createIntensityButton(
                                "All Scopes",
                                EmissionDataOption.FeedlotIntensity
                            )
                        }
                        {property.propertyType === PropertyType.Processor &&
                            createIntensityButton(
                                "Scopes 1 & 2",
                                EmissionDataOption.ProcessorScope12Intensity
                        )}
                        {property.propertyType === PropertyType.Processor &&
                            createIntensityButton(
                                "All Scopes",
                                EmissionDataOption.ProcessorIntensity
                        )}
                        {property.propertyType === PropertyType.FurtherProcessor &&
                            createIntensityButton(
                                "Scopes 1 & 2",
                                EmissionDataOption.FurtherProcessorScope12Intensity
                        )}
                        {property.propertyType === PropertyType.FurtherProcessor &&
                            createIntensityButton(
                                "All Scopes",
                                EmissionDataOption.FurtherProcessorIntensity
                        )}
                        
                        {property.propertyType === PropertyType.Dairy &&
                            createIntensityButton(
                                "FPCM",
                                EmissionDataOption.DairyFPCM
                        )}
                        {property.propertyType === PropertyType.Dairy &&
                            createIntensityButton(
                                "Milk Solids",
                                EmissionDataOption.DairyMilkSolids
                        )}
                        {property.propertyType === PropertyType.Dairy &&
                            createIntensityButton(
                                "Meat",
                                EmissionDataOption.DairyLiveWeight
                        )}
                    </>}
                </Row>
            }
            year={isEmpty ? undefined : report?.financialYear}
        />
    );

    const getData = (): number | undefined | null => {
        switch (selectedData) {
            case EmissionDataOption.BeefIntensity:
                return report?.results?.intensity?.kgCo2PerKgBeefMeatSold
            case EmissionDataOption.GrainIntensity:
                return report?.results?.intensity?.kgCo2PerKgGrainProduced
            case EmissionDataOption.SheepIntensity:
                return report?.results?.intensity?.kgCo2PerKgSheepMeatSold
            case EmissionDataOption.WoolIntensity:
                return report?.results?.intensity?.kgCo2PerKgGreasyWool
            case EmissionDataOption.FeedlotIntensity:
                return report?.results?.intensity?.kgCo2PerKgMeatFed
            case EmissionDataOption.FeedlotScope12Intensity:
                return report?.results?.intensity?.kgCo2PerKgMeatFedScope1and2
            case EmissionDataOption.ProcessorIntensity:
                return report?.results?.intensity?.kgCO2ePerTonneHCSW
            case EmissionDataOption.ProcessorScope12Intensity:
                return report?.results?.intensity?.kgCO2ePerTonneHCSWScope1and2
            case EmissionDataOption.FurtherProcessorIntensity:
                return report?.results?.intensity?.kgCO2ePerTonneHCSW
            case EmissionDataOption.FurtherProcessorScope12Intensity:
                return report?.results?.intensity?.kgCO2ePerTonneHCSWScope1and2
            case EmissionDataOption.DairyFPCM:
                return report?.results?.intensity?.kgCo2PerKgMilkFPCM
            case EmissionDataOption.DairyLiveWeight:
                return report?.results?.intensity?.kgCo2PerKgDairyMeat
            case EmissionDataOption.DairyMilkSolids:
                return report?.results?.intensity?.kgCo2PerKgMilk
        }
    };

    const getUnit = (): EmissionUnit => {
        switch (selectedData) {
            case EmissionDataOption.WoolIntensity:
                return EmissionUnit.IntensityWool
            case EmissionDataOption.SheepIntensity:
            case EmissionDataOption.BeefIntensity:
                return EmissionUnit.IntensityLivestock;
            case EmissionDataOption.GrainIntensity:
                return EmissionUnit.IntensityGrain;
            case EmissionDataOption.FeedlotIntensity:
                return EmissionUnit.IntensityLivestock;
            case EmissionDataOption.FeedlotScope12Intensity:
                return EmissionUnit.IntensityLivestock;
            case EmissionDataOption.ProcessorScope12Intensity:
            case EmissionDataOption.ProcessorIntensity:
                return EmissionUnit.IntensityHscw;
            case EmissionDataOption.FurtherProcessorIntensity:
            case EmissionDataOption.FurtherProcessorScope12Intensity:
                return EmissionUnit.IntensityProduct
            case EmissionDataOption.DairyFPCM:
                return EmissionUnit.DairyIntensityFPCM
            case EmissionDataOption.DairyMilkSolids:
                return EmissionUnit.DairyIntensityMilkSolids
            case EmissionDataOption.DairyLiveWeight:
                return EmissionUnit.DairyIntensityLW
        }
    };

    const getNationalAverage = (): number => {
        switch (selectedData) {
            case EmissionDataOption.WoolIntensity:
                return countryIsNZ(property.country) ? 13.4 : 20.5
            case EmissionDataOption.SheepIntensity:
                return countryIsNZ(property.country) ? 14.8 : 6.8
            case EmissionDataOption.BeefIntensity:
                return countryIsNZ(property.country) ? 21.9 : 12.6;
            case EmissionDataOption.GrainIntensity:
                return 0.275;
            case EmissionDataOption.ProcessorScope12Intensity:
                return 447;
            case EmissionDataOption.DairyFPCM:
                return 1;
            case EmissionDataOption.DairyMilkSolids:
                return 13.5;
            case EmissionDataOption.DairyLiveWeight:
                return 4.4;
            default:
                return 0;
        }
    }

    const data = getData();

    const getNationalAverageComparison = (): number => {
        return (((data ?? 0) - getNationalAverage()) / getNationalAverage())
    }

    const isIncrease = getNationalAverageComparison() > 0;
    const indicatorType = isIncrease ? ChangeIndicatorType.Increase : ChangeIndicatorType.Decrease

    const previousFY = getCurrentFinancialYear() - 1;

    const previousYearReport = getReportOfYear(reports, previousFY);

    const navigate = useNavigate();

    const goToReportPage = (id: string) => navigate(`/summary/${id}`)

    const goToCreateReportPage = () => navigate(`/farm/${property.id}/create-report?year=${previousFY}`)

    // TODO: not complete with all types yet
    const source = selectedData === EmissionDataOption.BeefIntensity
    ? { name: "MLA, 2019", url: "https://www.goodmeat.com.au/globalassets/mla-corporate/research-and-development/final-reports/2021/b.cch.2109--project-overview.pdf" }
    : selectedData === EmissionDataOption.SheepIntensity || selectedData === EmissionDataOption.WoolIntensity
        ? { name: "MLA, 2021", url: "https://www.sheepsustainabilityframework.com.au/globalassets/sheep-sustainability/media/ssf-final-21_lr.pdf" }
        : selectedData === EmissionDataOption.GrainIntensity
            ? { name: "CSIRO, 2011", url: "https://publications.csiro.au/rpr/download?dsid=DS2&pid=csiro%3AEP103907" }
            : null

    const body = !subscribed ? (
        <EmptyCardContent
            text={"See your farms Intensity data"}
            buttonText="View Subscription"
            buttonOnClick={() => { navigate("/account/subscription"); }}
        />
    ) : isEmpty ? (
        <EmptyCardContent
            text={`Update Emission Report for ${formatYearStringToFinancialYearString(previousFY.toString())}`}
            buttonText="Update Now"
            buttonOnClick={previousYearReport ? () => goToReportPage(previousYearReport.id) : goToCreateReportPage}
        />
    ) : !isNotNullOrUndefined(data) ? (
        <>
            <Column style={{ justifyContent: "center", alignItems: "center", gap: "7px", width: "100%", paddingTop: '10px', paddingBottom: '10px' }}>
                <BodyText>
                    No Intensity Data available for {formatFinancialYearStringToShortPrettyFinancialYearString(previousFY.toString())}.
                </BodyText>

                <BodyText>
                    No production recorded for this enterprise.
                </BodyText>
            </Column>
        </>
    ) : (
        <Column style={{ width: "100%", alignItems: "start", gap: "8px" }}>
            <Row style={{ alignItems: "end", gap: "8px" }}>
                <HeadlineFigureText>
                    {report?.results?.intensity ? formatReportNumber(data as number) : "-"}
                </HeadlineFigureText>
                <EmissionUnitText>
                    {report?.results?.intensity ? getUnit() : ""}
                </EmissionUnitText>
            </Row>
            {
                [
                    EmissionDataOption.FeedlotIntensity,
                    EmissionDataOption.FeedlotScope12Intensity,
                    EmissionDataOption.ProcessorIntensity,
                    EmissionDataOption.FurtherProcessorIntensity,
                    EmissionDataOption.FurtherProcessorScope12Intensity
                ].indexOf(selectedData) === -1 ?
                    <Row style={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "7px"
                    }}>
                        <Column style={{ flexGrow: 1, flexShrink: 1 }}>
                            <BodyText style={{ color: ruminatiColors.orange }}>
                                {formatReportNumber(data as number - getNationalAverage())}
                                {getUnit()}
                                {isIncrease ? ' above ' : ' below '}
                                National Average
                                {source && (
                                    <Tooltip
                                        title={(
                                            <BodyText style={{ padding: '8px', color: ruminatiColors.bone }}>
                                                Source: <Link to={source?.url} target="_blank" style={{ display: 'inline', color: ruminatiColors.bone, textDecoration: 'underline' }}>{source?.name}</Link>
                                            </BodyText>
                                        )}
                                        color={ruminatiColors.effective_black}
                                        placement="top"
                                    >
                                        <span style={{verticalAlign: "middle", paddingLeft: "2px"}}>
                                        <Icon icon="info-circle" fillColor={ruminatiColors.orange} />
                                        </span>
                                    </Tooltip>
                                )}
                            </BodyText>
                        </Column>
                        <Column style={{ flexGrow: 2 }}>
                            <Row>
                                <ChangeIndicator type={indicatorType} />
                                <BodyText
                                    style={{
                                        color: getDefaultColorForChangeIndicator(indicatorType),
                                    }}
                                >
                                    {isIncrease ? '+' : '-'}
                                    {formatReportNumber(getNationalAverageComparison() * 100 * (isIncrease ? 1 : -1))}%
                                </BodyText>
                            </Row>
                        </Column>
                    </Row>
                    : undefined}
        </Column>
    );
    return <Card header={header} body={body} id={'intensityCard'}/>
}