import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { ruminatiColors } from "../../utilities/colors";

import Icon from "../icon";
import Tip from '../tip'
import {
    DropdownOptionData,
    OnChangeFunction,
} from "../../models/form/form_fields/form_field";
import {
    BaseFormField,
    DropdownDisplay,
    DropdownOption,
    DropdownOptions,
    FormWrapper,
    InputWrapper,
    StyledInput
} from "../styled_input";
import useWindowSize from "../../hooks/useWindowSize";
import { BodyText, BaseText } from "../styled_text";
import { Column, Row } from "../styled_layout";

export interface InputFieldProps<T> {
    id: string;
    tip?: string;
    label?: string;
    units?: string;
    placeholder?: string;
    size?: "large" | "small";
    onChange?: OnChangeFunction<T>;
    value?: T;
    width?: string;
    error?: boolean;
    disabled?: boolean;
}

interface TextFieldProps extends InputFieldProps<string> {
    footerElement?: JSX.Element;
    number?: boolean;
    obscure?: boolean;
    characterValidator?: (char: string, value: string) => boolean;
}

export function TextField(props: TextFieldProps) {
    const [value, setValue] = useState<string | undefined>(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleChange = (newValue: string): string | undefined => {
        if (newValue.length < (value?.length ?? 0)) {
            return newValue
        }

        if (newValue.length > (value?.length ?? 0)) {
            if (props.characterValidator) {
                return props.characterValidator(newValue.charAt(newValue.length - 1), (value ?? "")) ? newValue : value
            }
        }

        return newValue
    }

    const [passwordHidden, setPasswordHidden] = useState(true);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const _size = props.size ?? "large";

    // Called to give focus to the DOM field element
    const focusField = () => {
        if (inputRef.current) inputRef.current.focus();
    };

    // Toggle the visibility of the password
    const toggleVisibility = (e: MouseEvent) => {
        setPasswordHidden(!passwordHidden);
        e.stopPropagation();
    };

    const fontSize = _size === 'small' ? "16px" : "20px"

    function calculateInputSize (): {
        inputWidth: number | undefined,
        styleWidth: string | undefined
     } {
        if (value === undefined) {
             if (!props.units) {
                return {
                    inputWidth: undefined,
                    styleWidth: '100%'
                }
            } else if (props.placeholder) {
                return {
                    inputWidth: props.placeholder?.length,
                    styleWidth: undefined
                }
            }
            return {
                inputWidth: 1,
                styleWidth: undefined
            }
        }

        if (props.units === undefined) {
            return {
                inputWidth: undefined,
                styleWidth: '100%'
            }
        }

        return {
            inputWidth: value.toString().length + 1,
            styleWidth: undefined
        }
    }

    return (
    <>
        <Row 
            style={{
            justifyContent: 'flex-start', 
            width: '100%',
            marginBottom: "8px"
        }}>
            <BodyText style={{fontSize}} aria-label={props.label}>
                {props.label}
            </BodyText>
        </Row>
        <Row style={{justifyContent: 'flex-start', width: props.width ?? "100%"}}>
            <BaseFormField
                ref={wrapperRef}
                isSmall={_size === "small"}
                color={props.error ? ruminatiColors.red : ruminatiColors.green_3}
                style={{ cursor: "text" }}
                onClick={focusField}
                width={"100%"}
            >
                <FormWrapper onClick={focusField}>

                    <InputWrapper style={{alignItems: 'center'}}>
                        <Row style={{width: '100%', justifyContent: 'flex-start'}}>
                            <Column style={{
                                justifyContent: 'flex-start',
                                flexGrow: calculateInputSize().styleWidth === '100%' ? 1 : undefined
                            }}>
                                {/* INPUT FIELD */}
                                <StyledInput
                                    size={calculateInputSize().inputWidth}
                                    ref={inputRef}
                                    type={
                                        props.obscure && passwordHidden
                                            ? "password"
                                            : "text"
                                    }
                                    disabled={props.disabled}
                                    color={ruminatiColors.green_3}
                                    spellCheck="false"
                                    value={(value ?? "").toString()}
                                    style={{
                                        fontSize,
                                        alignSelf: 'flex-start',
                                        width: calculateInputSize().styleWidth,
                                        padding: '0px',
                                        textAlign: 'left'
                                    }}
                                    onChange={(event) => {
                                        // const value = event.target.value
                                        const value = handleChange(event.target.value);
                                        setValue(value);
                                        if (props.onChange !== undefined)
                                            props.onChange(
                                                (value?.length ?? 0) > 0 ? value : undefined
                                            );
                                    }}
                                    placeholder={props.placeholder}
                                />
                            </Column>

                            {props.units ? 
                            <Column style={{flexGrow: 2}}>
                                <Row style={{width: '100%', justifyContent: 'flex-start'}}>
                                    {/* UNITS TEXT */}
                                    <BodyText style={{
                                        position: "relative",
                                        display: "inline-flex",
                                        alignItems: "center",
                                    }}>{props.units}</BodyText>
                                </Row>
                            </Column> : undefined
                            }
                        </Row>
                  
                    </InputWrapper>

                    {/* PASSWORD VISIBILITY */}
                    {props.obscure && (
                        <div
                            style={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                padding: "0 0 0 8px",
                            }}
                            onClick={(e: MouseEvent) => toggleVisibility(e)}
                        >
                            <Icon
                                icon={
                                    passwordHidden
                                        ? "visibilityOn"
                                        : "visibilityOff"
                                }
                            />
                        </div>
                    )}
                </FormWrapper>

                {props.error ? 
                <BaseText
                    style={{
                        color: ruminatiColors.red,
                        fontSize: "14px",
                        userSelect: "none",
                        margin: "0 0 0 8px",
                        textAlign: 'right'
                    }}
                >
                    {value === undefined ? 'Required' : `Invalid ${props.label}`}
                </BaseText> : undefined}
  
                {/* FOOTER ELEMENT */}
                {props.footerElement}

                {/* TIP BUTTON */}
                {props.tip && (
                    <Tip
                        tip={props.tip}
                    />
                )}
            </BaseFormField>
        </Row>
    </>
    );
}

export interface DropdownFieldProps<T extends string | number>
    extends InputFieldProps<T> {
    options: DropdownOptionData<T>[];
    dropdownType?: "select" | "suggestion";
    onSelect?: OnChangeFunction<T>;
}

export interface Frame {
    x: number;
    y: number;
    width: number;
    height: number
}

export function DropdownField<T extends string | number>(
    props: DropdownFieldProps<T>
) {
    const [value, setValue] = useState<T | undefined>(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<HTMLDivElement>(null);

    const [optionsFrame, setOptionsFrame] = useState<Frame | undefined>(undefined)

    const _size = props.size ?? "large";

    const [, height] = useWindowSize();

    const calcMaxHeight = useCallback(() => {
        if (wrapperRef.current) {
            const top =
                window.scrollY + wrapperRef.current.getBoundingClientRect().top;

            const pageHeight = Math.max(
                document.body.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.clientHeight,
                document.documentElement.scrollHeight,
                document.documentElement.offsetHeight
            );

            const bottomDistance = pageHeight - top - 100;
            const viewportSpace = height ?? window.innerHeight / 2 - 100;

            const _maxHeight = Math.min(bottomDistance, viewportSpace);
            return _maxHeight
        }
    }, [wrapperRef, height]);

    const updateDropdownFrame = useCallback((force?: boolean) => {
        if (wrapperRef.current && (document.activeElement === selectRef.current || force)) {
            // console.log(`updating`)
            // find closest ancestor with transform set
            const bounds = wrapperRef.current.getBoundingClientRect()
            let parent = wrapperRef.current?.parentElement
            while (parent) {
                if (parent.style.transform) {
                    break;
                }
                parent = parent.parentElement
            }
            const parentBounds = parent?.getBoundingClientRect()
            const frame = {
                x: bounds.x - (parentBounds?.x ?? 0),
                y: bounds.y - (parentBounds?.y ?? 0),
                width: bounds.width,
                height: calcMaxHeight() ?? 100
            }
            setOptionsFrame(frame)
        }
    }, [wrapperRef, calcMaxHeight])

    // Calculate the distance of the select field to the bottom of the screen
    // and limit dropdown option box to that height
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const listener = () => updateDropdownFrame()
        window.addEventListener('scroll', listener)
        window.addEventListener('resize', listener)
        return () => {
            window.removeEventListener('scroll', listener)
            window.removeEventListener('resize', listener)
        }
    }, [updateDropdownFrame]);

    const selectOption = (newValue: T) => {
        // Set the value option to the value if a select field, otherwise its
        // display name, if autocomplete
        setValue(newValue);

        // Send value to callbacks
        if (props.onSelect) props.onSelect(newValue);
        if (props.onChange) props.onChange(newValue);

        // Close the dropdown options
        (document.activeElement as HTMLElement).blur();
    };

    // Called to give focus to the DOM field element
    const toggleFocusField = (e: MouseEvent | React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        updateDropdownFrame(true)

        if (selectRef.current) {
            if (document.activeElement === selectRef.current) {
                selectRef.current.blur();
            } else {
                selectRef.current.focus();
            }
        }
    };

    const getName = (value: T) => {
        const option: DropdownOptionData<T> | undefined = props.options?.find(
            (option) => option.value === value
        );
        if (option) {
            return option.name;
        }

        return "";
    };

    return (
    <>
        <Row 
            style={{
            justifyContent: 'flex-start', 
            width: '100%',
            marginBottom: "8px"
        }}>
            <BodyText style={{fontSize: props.size === "small" ? "16px" : "20px"}}>
                    {props.label}
            </BodyText>
        </Row>
        <Row style={{justifyContent: 'flex-start', width: props.width ?? "100%"}}>
            <BaseFormField
                id={props.id}
                ref={wrapperRef}
                isSmall={_size === "small"}
                color={props.error ? ruminatiColors.red : props.disabled ? ruminatiColors.green_3_30 : ruminatiColors.green_3}
                style={{ cursor: props.disabled ? "not-allowed" : "pointer" }}
                onClick={(e) => toggleFocusField(e)}
                width={props.width ?? "100%"}
            >
                <FormWrapper onClick={(e) => toggleFocusField(e)}>
                    <InputWrapper>
                        {/* SELECTED VALUE */}
                        <DropdownDisplay
                            ref={selectRef}
                            tabIndex={-1}
                            size={props.size ?? "large"}
                            color={
                                value === undefined
                                    ? ruminatiColors.effective_black_30
                                    : ruminatiColors.green_3
                            }
                            disabled={props.disabled}
                            onMouseDown={(e) => e.preventDefault()}
                        >
                            <p className="selected-item">{value ? getName(value) : props.placeholder}</p>
                        </DropdownDisplay>

                    </InputWrapper>

                    {/* DROPDOWN OPTIONS */}
                    {props.options.length > 0 && (
                        <DropdownOptions
                            className="dropdown-options"
                            top={(optionsFrame?.y ?? 0) + (props.size === "small" ? 56 : 72)}
                            left={optionsFrame?.x}
                            width={optionsFrame?.width}
                            maxHeight={optionsFrame?.height}
                            fixed={true}
                        >
                            {props.options.map((option) => {
                                return (
                                    <DropdownOption
                                        tabIndex={-1}
                                        key={`${props.id}-${option.value}`}
                                        onClick={(e) => {
                                            selectOption(option.value);
                                            e.stopPropagation();
                                        }}
                                    >
                                        {option.icon && (
                                            <div
                                                style={{
                                                    padding: "0 8px 0 0",
                                                    display: "flex",
                                                }}
                                            >
                                                <Icon icon={option.icon} />
                                            </div>
                                        )}
                                        <p>{option.name}</p>
                                    </DropdownOption>
                                );
                            })}
                        </DropdownOptions>
                    )}

                    {props.error ? 
                    <BaseText
                        style={{
                            fontSize: "14px",
                            userSelect: "none",
                            margin: "0 0 0 8px",
                        }}
                    >
                        {value === undefined ? 'Required' : `Invalid ${props.label}`}
                    </BaseText> : undefined}

                    {/* DROPDOWN ARROW */}
                    <div style={{ padding: "0 0 0 8px", height: "32px" }}>
                        <Icon icon="chevronDown" />
                    </div>
                </FormWrapper>

                {/* TIP BUTTON */}
                {props.tip && (
                    <Tip
                        tip={props.tip}
                    />
                )}
            </BaseFormField>
        </Row>
        </>
    );
}
