import { useState } from "react";
import { motion } from 'framer-motion'
import styled from "styled-components";

import Icon from "@/components/icon";
import { BodyText } from "@/components/styled_text";

import { ruminatiColors } from '@/utilities/colors'

// Accordion is just a div with a border on top
export const Accordion = styled.div`
  border-top: 1px solid ${ruminatiColors.green_3_30};
`;


interface AccordionItemProps {
  renderTitle: string | (() => React.ReactNode);
  renderContent: string | (() => React.ReactNode);
}

export function AccordionItem({ renderTitle, renderContent }: AccordionItemProps) {
  const [open, setOpen] = useState(false);

  return (
    <AccordionItemWrapper>
      <AccordionItemHeader onClick={() => setOpen(!open)}>
        {typeof renderTitle === 'string'
          ? (
            <AccordionItemTitle>{renderTitle}</AccordionItemTitle>
          )
          : renderTitle()
        }
        <motion.div
          initial={{ rotate: 0 }}
          animate={{ rotate: open ? 180 : 0 }}
          transition={{ duration: 0.3 }}
          style={{ height: '32px' }} // TODO: why does the div have a height larger than the icon without this?
        >
          <Icon icon="chevronDown" fillColor={ruminatiColors.green_3} />
        </motion.div>
      </AccordionItemHeader>
      <AccordionItemContentWrapperMotion
        initial={{ height: 0, paddingTop: 0 }}
        animate={{ height: open ? 'auto' : 0, paddingTop: open ? 12 : 0 }}
        transition={{ duration: 0.3 }}
      >
        {typeof renderContent === 'string'
          ? (
            <AccordionItemContentText>{renderContent}</AccordionItemContentText>
          )
          : renderContent()
        }
      </AccordionItemContentWrapperMotion>
    </AccordionItemWrapper>
  )
}

const AccordionItemWrapper = styled.div`
  padding: 12px 6px;
  border-bottom: 1px solid ${ruminatiColors.green_3_30};
`;

const AccordionItemHeader = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
`;

const AccordionItemContentWrapperMotion = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

// common styles that are useful for passing in as title / content
export const AccordionItemTitle = styled.h5`
  font-weight: 500;
  font-size: 17px;
  font-family: "Approach", sans-serif;
  text-align: left;
  color: ${ruminatiColors.green_3};
  line-height: 24px;
  margin: 0;
`;

export const AccordionItemContentText = styled(BodyText)`
  line-height: 24px;
`