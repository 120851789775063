import { ruminatiColors } from "../../utilities/colors";

export type MapFooterProps = {
    footer?: JSX.Element;
};

/**
 * A footer area that sits underneath the map
 * @param footer optional. If true, will show the element underneath the map.
 */
export default function MapFooter({
  footer
}: MapFooterProps) {
    return (
        <div
          style={{
              backgroundColor: ruminatiColors.dark_green,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "72px",
              width: "100%",
          }}
      >
          {footer}
      </div>        
  );
}
