import { useEffect, PropsWithChildren } from "react"
import Header from "../components/headers/header";
import { ScreenInnerWrapper, ScreenWrapper } from "../components/styled_layout";
import { Footer } from "@/components/Footer";

/**
 * A helper component for basic layout of a screen.
 * @param children the content of the screen
 * @returns the Screen wrapper component
 */
export default function Screen({ 
    children,
    pageTitle 
}: PropsWithChildren<{ 
    pageTitle? :string
 }>) {
    
    const defaultTitle = "Ruminati";

    // Google Analytics seems to automatically send an event
    // when the document title changes
    useEffect(() => {
      const completeTitle = (pageTitle ? `${defaultTitle} | ${pageTitle}` : defaultTitle)
      document.title = completeTitle;
    }, [pageTitle])

    return (
        <ScreenWrapper>
            <Header />
            <ScreenInnerWrapper>{children}</ScreenInnerWrapper>
            <Footer />
        </ScreenWrapper>
    );
}


