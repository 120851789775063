import {
    ForwardedRef,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import styled from "styled-components";
import Icon from "../icon";
import { Column } from "../styled_layout";
import { ButtonText } from "../styled_text";
import { DropdownOptions } from "../styled_input";
import { ruminatiColors } from "../../utilities/colors";
import useWindowSize from "../../hooks/useWindowSize";
import MainButton from "./main_button";

export interface DropdownButtonRef {
    close: () => void;
    open: () => void;
}

type DropdownButtonProps = {
    buttonText: string;
    dropdownMenu: JSX.Element;
    colorScheme?: "green" | "orange" | "transparent";
    useBlurBackground?: boolean;
    width?: string;
};
/**
 * A button that opens a dropdown menu on click.
 * @param props {@link DropdownButtonProps}.
 */

function DropdownButton(
    props: DropdownButtonProps,
    ref: ForwardedRef<DropdownButtonRef>
): JSX.Element {
    // Whether the dropdown menu is opened.
    const [isActive, setIsActive] = useState(false);

    // Exposes necessary functions.
    useImperativeHandle(ref, () => ({
        close: (): void => setIsActive(false),
        open: (): void => setIsActive(true),
    }));

    const wrapperRef = useRef<HTMLDivElement>(null);

    const [, height] = useWindowSize();

    const [maxOptionHeight, setMaxOptionHeight] = useState<number>(100);

    const [maxOverlayHeight, setMaxOverlayHeight] = useState<number>(100);

    const [timeoutVal, setTimeoutVal] = useState<number | undefined>(undefined);

    // Close dropdown menu when clicked outside.
    const closeDropdown = (e: MouseEvent) => {
        if (
            wrapperRef.current &&
            isActive &&
            !wrapperRef.current.contains(e.target as Node)
        ) {
            window.clearTimeout(timeoutVal);
            setIsActive(false);
        }
    };

    // Calculate the distance of the select field to the bottom of the screen
    // and limit dropdown option box to that height.
    const calcMaxHeight = useCallback(() => {
        if (wrapperRef.current) {
            const top =
                window.scrollY + wrapperRef.current.getBoundingClientRect().top;

            const pageHeight = Math.max(
                document.body.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.clientHeight,
                document.documentElement.scrollHeight,
                document.documentElement.offsetHeight
            );

            const bottomDistance = pageHeight - top - 100;
            const viewportSpace = height ?? window.innerHeight / 2 - 100;

            const maxHeight = Math.min(bottomDistance, viewportSpace);
            setMaxOptionHeight(maxHeight);
        }
    }, [wrapperRef, height]);

    // Calculate the distance of the select field to the bottom of the screen
    // and limit the blur overlay to that height.
    const calcOverlayHeight = useCallback(() => {
        if (wrapperRef.current) {
            const top =
                window.scrollY + wrapperRef.current.getBoundingClientRect().top;

            const pageHeight = Math.max(
                document.body.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.clientHeight,
                document.documentElement.scrollHeight,
                document.documentElement.offsetHeight
            );

            setMaxOverlayHeight(pageHeight - top + 48);
        }
    }, [wrapperRef]);

    useEffect(() => {
        calcMaxHeight();
        calcOverlayHeight();
        window.clearTimeout(timeoutVal);

        // Recalculate in the case of animated elements.
        setTimeoutVal(window.setTimeout(() => calcMaxHeight(), 300));
        setTimeoutVal(window.setTimeout(() => calcOverlayHeight(), 300));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calcMaxHeight, calcOverlayHeight]);

    // Listens to mouse down event.
    document.addEventListener("mousedown", closeDropdown);

    // Close dropdown when scrolling.
    window.addEventListener("scroll", () => setIsActive(false));

    return (
        <ButtonColumn
            style={{
                flexWrap: "wrap",
                justifyContent: "normal",
                position: "relative",
            }}
            ref={wrapperRef}
        >
            <MainButton
                colorScheme={props.colorScheme}
                size="small"
                onClick={() => {
                    setIsActive(!isActive);
                }}
                width={props.width}
            >
                <Icon icon="chevronDown" fillColor={props.colorScheme === "transparent" ? ruminatiColors.green_3 : ruminatiColors.bone} />
                <ButtonText
                    style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: props.colorScheme === "transparent" ? ruminatiColors.green_3 : ruminatiColors.bone
                    }}
                >
                    {props.buttonText}
                </ButtonText>
            </MainButton>

            {isActive &&  

                <>
                   <BlurOverlay
                        top={48}
                        onClick={() => {
                            setIsActive(false);
                        }}
                        visible={props.useBlurBackground === false ? false : isActive}
                        height={maxOverlayHeight}
                    />

                    <DropdownMenuContainer
                        className="dropdown-options"
                        top={48}
                        maxHeight={maxOptionHeight}
                        visible={isActive}
                        colorScheme={props.colorScheme}
                    >
                        <div onClick={() => setIsActive(false)}></div>
                        {props.dropdownMenu}
                    </DropdownMenuContainer>
                </>
            }
        </ButtonColumn>
    );
}

export default forwardRef(DropdownButton);

const ButtonColumn = styled(Column)`
    margin-right: 8px;
    &:last-child {
        margin-right: 0px;
    }
`;

const DropdownMenuContainer = styled(DropdownOptions).attrs((props: { visible: boolean, colorScheme: string }) => props)`
    width:auto;
    border: 1px solid ${(props) => props.colorScheme === "transparent" ? ruminatiColors.green_3 : ruminatiColors.orange_40_opaque};
    font-family: "TTInterfaces", sans-serif;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    z-index: ${(props) => `${props.visible ? 9999 : 0}`};
    opacity: ${(props) => `${props.visible ? 1 : 0}`};
    transition: 0.2s ease opacity;
`;

const BlurOverlay = styled.div.attrs((props: { top: number, visible: boolean, height: number }) => props)`
    top: ${(props) => `${props.top}px`};
    position: absolute;
    height: ${(props) => `${props.height - 100}px`};
    width: 200vw;
    transition: 0.3s ease background-color;
    background-color: ${ruminatiColors.bone_60};
    overflow: hidden;
    z-index: ${(props) => `${props.visible ? 9998 : 0}`};
    opacity: ${(props) => `${props.visible ? 1 : 0}`};
    transition: 0.2s ease opacity;
`;
