import styled from "styled-components";
import { BodyText } from "../styled_text";
import { ruminatiColors } from "../../utilities/colors";
import {useMap} from 'react-map-gl/maplibre';
import Icon, { IconType } from "../icon";
import { Row, Column } from "../styled_layout";
import { MouseEventHandler } from "react";

export default function ZoomButtonOverlay ({ invertColors }: {invertColors?: boolean}) {
  const {myMapA} = useMap();

  const zoomOut = () => { myMapA?.zoomOut() }
  const zoomIn = () => { myMapA?.zoomIn() }
  const padding = "8px 10px"
  const renderZoomButton = (icon: IconType, onClick: MouseEventHandler) => {
    return (
      <Column className="zoom-btn" style={{cursor: 'pointer', transition: '0.3s', height: '100%'}} onClick={onClick}>
        <Row style={{padding: padding, height: '100%'}}>
          <Icon icon={icon} fillColor={invertColors ? 'white' : ruminatiColors.green_3}/>
        </Row>
      </Column>
    )
  }

  return (
      <ZoomButtonOverlayWrapper invertColors={invertColors}>
          {renderZoomButton('plus-small', zoomIn)}
          {renderZoomButton('minus', zoomOut)}
          <Column style={{padding: padding}}>
            <BodyText style={{ color: invertColors ? 'white' : ruminatiColors.green_3 }}>Zoom</BodyText>
          </Column>         
      </ZoomButtonOverlayWrapper>
  )
}

const ZoomButtonOverlayWrapper = styled(Row)<{invertColors?: boolean}>`
    position: absolute;
    top: ${props => props.invertColors ? `65px` : `10px`};
    right: 10px;
    z-index: 10;
    height: 42px;
    align-items: center;
    margin-top: ${props => props.invertColors ? `16px` : `0px`};
    margin-bottom: ${props => props.invertColors ? `16px` : `0px`};

    background-color: ${props => props.invertColors ? ruminatiColors.green_3 : ruminatiColors.bone};
    border: 1px solid ${ruminatiColors.dark_green};
    border-radius: 8px;
    overflow: hidden;

    .zoom-btn:hover {
      background-color: ${props => props.invertColors ? 'none' : ruminatiColors.green_3_10};
      svg path {
        fill: ${props => props.invertColors ? ruminatiColors.light_orange : ruminatiColors.dark_green};
        stroke: ${props => props.invertColors ? ruminatiColors.light_orange : ruminatiColors.dark_green};
      }
    }
`;

