import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { useAuthStore } from "../state/auth_store";
import { SubscriptionType } from "../models/subscription_model";

/**
 * Redirects the user if not PRIME tier
 * @param children the react page component
 */
export default function SubscriptionGuard({ children }: PropsWithChildren<unknown>) {
    const user = useAuthStore((state) => state.user);
    // console.log(user);
    if (user?.subscription.type !== SubscriptionType.PRIME) {
        return <Navigate to="/farms" />;
    }
    return children
}
