import styled from "styled-components";
import { ruminatiColors } from "../../utilities/colors";
import { Column, Row } from "../styled_layout";

type LargeTextStatProps = {
    label: string;
    subLabel: string;
    value: string;
    unit: string;
    smallerUnit: boolean;
    statColor: string;
    className: string
};

export default function LargeTextStat(props: LargeTextStatProps) {
    return (
        <Column style={{ flex: 1, alignItems: "start" }} className={props.className}>
            <Row
                style={{
                    width: "100%",
                    justifyContent: "start",
                    columnGap: "8px",
                    flexWrap: "nowrap",
                }}
            >
                <Label>{props.label}</Label>
                <SubLabel>{props.subLabel}</SubLabel>
            </Row>
            <Divider />
            <p style={{ margin: "0" }}>
                <span
                    style={{
                        color: props.statColor,
                        fontFamily: "Approach",
                        fontWeight: "600",
                        fontSize: "48px",
                        margin: "0",
                        whiteSpace: "nowrap",
                        lineHeight: 1.0,
                    }}
                >
                    {props.value}
                </span>
                <span
                    style={{
                        color: props.statColor,
                        fontFamily: "Approach",
                        fontWeight: "500",
                        fontSize: props.smallerUnit ? "16px" : "20px",
                        margin: " 0 0 0 8px",
                        whiteSpace: "nowrap"
                    }}
                >
                    {props.unit}
                </span>
            </p>
        </Column>
    );
}

const Label = styled.p`
    font-family: TTInterfaces;
    font-weight: 500;
    font-size: 14px;
    color: ${ruminatiColors.green_3};
    line-height: 24px;
    margin: 0;
`;

const Divider = styled.hr`
    width: 100%;
    border: none;
    border-bottom: 1px solid ${ruminatiColors.green_3_30};
`;

const SubLabel = styled(Label)`
    font-style: italic;
    color: ${ruminatiColors.light_green};
`;
