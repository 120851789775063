import SubButton from "./sub_button";
import { State } from "@/models/form/form_fields/form_field";
import { ReportManureManagementForm } from "@/models/report";
import { Row } from "../styled_layout";
import { usePopupStore } from "@/state/popup_store";
import { DairyManureManagementCalculatorPopupID } from "../popup/dairy_manure_management_popup";

type ManureManagementPrefillButtonProps = {
    stateId: number
    state: State<ReportManureManagementForm>;
};

export default function ManureManagementPrefillButton(props: ManureManagementPrefillButtonProps) {
    const popupStore = usePopupStore();

    return (<Row>
        <div style={{ padding: "0 0 0 8px" }}>
            <SubButton
                onClick={() =>
                  props.state.set({
                    milkingCows: {
                      "Pasture": 84,
                      "Lagoon": 12,
                      "SumpDispersal": 1,
                      "DrainToPaddock": 1, 
                      "SolidStorage" : 2
                    },
                    otherCows: {
                      "Pasture": 100,
                      "Lagoon": 0,
                      "SumpDispersal": 0,
                      "DrainToPaddock": 0, 
                      "SolidStorage" : 0
                    }
                  })
                }
            >
                Pre-fill with state-based averages
            </SubButton>
        </div>
        <div style={{ padding: "0 0 0 8px" }}>
          <SubButton
              onClick={() =>
                popupStore.addPopup(
                  DairyManureManagementCalculatorPopupID,
                  (val) => props.state.set(val)
              )
          }>
              Calculate
          </SubButton>
        </div>
      </Row>
    );
}
