import SubButton from "./sub_button"
import { State } from "@/models/form/form_fields/form_field"
import { LivestockClassAUS, ReportDairyLivestock } from "@/models/report"
import { calculateLiveweightGain } from "@/utilities/livestock"

export default function LiveweightGainCalculator<T extends LivestockClassAUS | ReportDairyLivestock> ({
  state
}: {
  state: State<T>
}) {

  return <SubButton 
  padding={"0px 16px"}
  onClick={() => {
    const liveweight = state.get.liveweight
    const out = {
      spring: calculateLiveweightGain(liveweight?.summer, liveweight?.spring),
      summer: calculateLiveweightGain(liveweight?.autumn, liveweight?.summer),
      autumn: calculateLiveweightGain(liveweight?.winter, liveweight?.autumn),
      winter: 0
    }
    // const out = 'summer' in liveweight ?
    //     {
    //         spring: calculateLiveweightGain(liveweight?.summer, liveweight?.spring),
    //         summer: calculateLiveweightGain(liveweight?.autumn, liveweight?.summer),
    //         autumn: calculateLiveweightGain(liveweight?.winter, liveweight?.autumn),
    //         winter: 0
    //     } : {
    //       jan: calculateLiveweightGain(liveweight?.feb, liveweight?.jan),
    //       feb: calculateLiveweightGain(liveweight?.mar, liveweight?.feb),
    //       mar: calculateLiveweightGain(liveweight?.apr, liveweight?.mar),
    //       apr: calculateLiveweightGain(liveweight?.may, liveweight?.apr),
    //       may: calculateLiveweightGain(liveweight?.jun, liveweight?.may),
    //       jun: calculateLiveweightGain(liveweight?.jul, liveweight?.jun),
    //       jul: calculateLiveweightGain(liveweight?.aug, liveweight?.jul),
    //       aug: calculateLiveweightGain(liveweight?.sep, liveweight?.aug),
    //       sep: calculateLiveweightGain(liveweight?.oct, liveweight?.sep),
    //       oct: calculateLiveweightGain(liveweight?.nov, liveweight?.oct),
    //       nov: calculateLiveweightGain(liveweight?.dec, liveweight?.nov),
    //       dec: calculateLiveweightGain(liveweight?.jan, liveweight?.dec)
    //     }

    state.set({
        ...state.get,
        liveweightGain: out
    })
  }}
>
    Calculate
</SubButton>
}