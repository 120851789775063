import { FormData } from "../../../../models/form/form";
import { getCurrentFinancialYear } from "../../../../utilities/dates";
import { InitiativePopUp } from "../initiative_popup";
import { ManageLivestockDTO } from "../../../../models/reduction_plans_models";
import { Report } from "../../../../models/property_models";
import { useDatabaseStore } from "../../../../state/database_store";
import { DSEUnit, convertDSEtoAE, convertAEtoDSE } from "../../../../utilities/dse";
import { SliderFormField } from "../../../../models/form/form_fields/basic_form_fields";
import { getYearField, getDefaultReductionPlanYearOptions, addExistingReductionPlanYearsToOptions } from "../initiative_helpers";
import { FieldSize } from "../../../../models/form/form_fields/form_field";

interface LivestockFormData {
    beefDSE?: number;
    beefTargetYear?: number;
    sheepDSE?: number;
    sheepTargetYear?: number;
}

export default function ManageLiveStockPopUp(props: {
    initialData?: ManageLivestockDTO;
    compareReport?: Report;
    onSubmit: (data: ManageLivestockDTO) => Promise<void>
}) {
    const properties = useDatabaseStore((state) => state.properties);
    const property = (properties ?? []).find((p) => p.id === props.compareReport?.propertyId);

    const yearOptions = getDefaultReductionPlanYearOptions()
    const existingReductionPlanYears = [
        props.initialData?.beefDSE?.targetYear,
        props.initialData?.sheepDSE?.targetYear
    ].filter(v => v !== undefined) as number[]
    addExistingReductionPlanYearsToOptions(existingReductionPlanYears, yearOptions)

    const preferredUnits: DSEUnit | undefined = property?.cattleFarm ? props.compareReport?.livestockInformation?.beefUnits as DSEUnit : props.compareReport?.livestockInformation?.sheepUnits as DSEUnit

    function formatDseValueToPreferredUnits(value: number): number {
        if (preferredUnits === DSEUnit.DSE) return value
        return convertDSEtoAE(value)
    }

    const initialData: ManageLivestockDTO = props.initialData ?? {
        beefDSE: {
            targetValue: props.compareReport?.livestockInformation?.beefDSE ?? 0,
            targetYear: getCurrentFinancialYear()
        },
        sheepDSE: {
            targetValue: props.compareReport?.livestockInformation?.sheepDSE ?? 0,
            targetYear: getCurrentFinancialYear()
        }
    }

    const formData: FormData<LivestockFormData, ManageLivestockDTO> = {
        fields: {
            ...(property?.cattleFarm
                ? {
                    beefDSE: new SliderFormField({
                        required: true,
                        sliderProps: {
                            defaultRange: { min: 0, max: 100000 },
                            prevReportValue: formatDseValueToPreferredUnits(props.compareReport?.livestockInformation?.beefDSE ?? 0),
                            initialValue: formatDseValueToPreferredUnits(initialData.beefDSE?.targetValue ?? 0),
                            unitLabel: preferredUnits,
                            label: `Beef ${preferredUnits}`,
                        },
                    }),
                    beefTargetYear: getYearField('Year of completion', FieldSize.Full, yearOptions),
                } : {}),
            ...(property?.sheepFarm
                ? {
                    sheepDSE: new SliderFormField({
                        required: true,
                        sliderProps: {
                            defaultRange: { min: 0, max: 100000 },
                            prevReportValue: formatDseValueToPreferredUnits(props.compareReport?.livestockInformation?.sheepDSE ?? 0),
                            initialValue: formatDseValueToPreferredUnits(initialData?.sheepDSE?.targetValue ?? 0),
                            unitLabel: preferredUnits,
                            label: `Sheep ${preferredUnits}`,
                        },
                    }),
                    sheepTargetYear: getYearField('Year of completion', FieldSize.Full, yearOptions),
                } : {})
        },
        transformer: {
            in: (value) => {
                return {
                    beefDSE: formatDseValueToPreferredUnits(value.beefDSE?.targetValue ?? 0),
                    beefTargetYear: value.beefDSE?.targetYear,
                    sheepDSE: formatDseValueToPreferredUnits(value.sheepDSE?.targetValue ?? 0),
                    sheepTargetYear: value.sheepDSE?.targetYear
                }
            },
            out: (value) => ({
                ...value.beefTargetYear ? {
                    beefDSE: {
                        targetValue: preferredUnits === DSEUnit.DSE ? value.beefDSE ?? 0 : convertAEtoDSE(value.beefDSE ?? 0),
                        targetYear: value.beefTargetYear
                    }
                } : {},
                ...value.sheepTargetYear ? {
                    sheepDSE: {
                        targetValue: preferredUnits === DSEUnit.DSE ? value.sheepDSE ?? 0 : convertAEtoDSE(value.sheepDSE ?? 0),
                        targetYear: value.sheepTargetYear
                    }
                } : {}
            })
        }
    };
    return (
        <InitiativePopUp
            formData={formData}
            initialFormValues={initialData}
            onSubmit={(state) => props.onSubmit(state)}
        />
    );
}
