import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * A simple hook to scroll to the top of the page on router change
 */
export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}
