import styled from "styled-components";
import { Report } from "../models/property_models";
import { ruminatiColors } from "../utilities/colors";
import { BaseText } from "./styled_text";
import ChangeIndicator, { ChangeIndicatorType, getDefaultColorForChangeIndicator } from "./change_indicator";
import { formatReportNumber } from "../utilities/functions";
import EmissionUnit from "../models/emission-unit";
import { Row, Column } from "./styled_layout";
import { formatFinancialYearStringToShortPrettyFinancialYearString } from "../utilities/dates";

/**
 * A component for showing report summary results in an inline
 */
export default function InlineResultsSummary({
    report,
    previous,
    fallbackColor,
    fallbackText,
}: {
    report?: Report;
    previous?: Report;
    fallbackColor?: string;
    fallbackText?: string;
}) {
    const resultStats = report?.results;
    const prevResultStats = previous?.results;

    const isBaseline = previous === undefined

    const getIndicator = (currVal?: number, prevVal?: number) => {
        currVal = currVal ?? 0
        prevVal = prevVal ?? 0

        // Display same for first time
        if (isBaseline) return ChangeIndicatorType.Same;

        // Within theshhold of 1, will count as same
        if (Math.abs(currVal - prevVal) < 1) return ChangeIndicatorType.Same;

        if (currVal > prevVal) return ChangeIndicatorType.Increase;

        return ChangeIndicatorType.Decrease;
    };

    const scope1Indicator = getIndicator(resultStats?.byScopes.scope1, prevResultStats?.byScopes.scope1);
    const scope2Indicator = getIndicator(resultStats?.byScopes.scope2, prevResultStats?.byScopes.scope2);
    const scope3Indicator = getIndicator(resultStats?.byScopes.scope3, prevResultStats?.byScopes.scope3);

    const totalIndicator = getIndicator(
        resultStats?.totals.net ?? 0,
        prevResultStats?.totals.net ?? 0
    )

    const getEmissionText = () => {
        switch (totalIndicator) {
            case ChangeIndicatorType.Decrease:
                return "Reduction";
            case ChangeIndicatorType.Increase:
                return "Increase";
            default:
                return "No Change";
        }
    };

    const diff = (a?: number, b?: number): number => {
        if (isBaseline) {
            return a ?? 0
        }
        return Math.ceil((a ?? 0) - (b ?? 0))
    }

    function getScopeRender (label: string, value: number, indicatorType: ChangeIndicatorType | undefined, unitLabel?: string): JSX.Element {
        const textIconColor = indicatorType ? getDefaultColorForChangeIndicator(indicatorType) : ruminatiColors.green_3
        return (
        <Column 
            style={{
                padding: '0px 12px 0px 0px',
                justifyContent: 'flex-start'
            }}>
            <ScopeChange className="scopeChange">
                <ScopeText style={{marginRight: '3px', color: ruminatiColors.green_3}}>
                    {label}
                </ScopeText>
                {indicatorType !== undefined ? <ChangeIndicator
                    type={indicatorType}
                /> : <span style={{marginRight: '4px'}}></span>
                }
                <ScopeText style={{
                    color: textIconColor
                }}>
                    {formatReportNumber(value, {maxDecimalSigFig: 0})} {unitLabel ?? undefined}
                </ScopeText>
            </ScopeChange>
        </Column>
        )
    }

    function getAboveText (): string {
        if (report && previous) {
            return `Emissions Change between ${formatFinancialYearStringToShortPrettyFinancialYearString(previous.financialYear.toString())} & ${formatFinancialYearStringToShortPrettyFinancialYearString(report.financialYear.toString())}`
        }
        if (report) return `Emissions Results ${formatFinancialYearStringToShortPrettyFinancialYearString(report.financialYear.toString())}`
        return ''
    }

    return (
        report !== undefined && report.complete ? (
            <Row style={{width: '100%'}}>
                <Column style={{ width: '100%' }}>
                    
                        <Row style={{ paddingBottom: "8px", width: '100%' }}>
                            <Column style={{width: '100%', alignItems: 'flex-start'}}>
                                <ScopeText>{getAboveText()}</ScopeText>
                            </Column>
                        </Row>
                    <ScopesRow>
                        {getScopeRender('Scope 1', diff(resultStats?.byScopes.scope1, prevResultStats?.byScopes.scope1), scope1Indicator)}
                        {getScopeRender('Scope 2', diff(resultStats?.byScopes.scope2, prevResultStats?.byScopes.scope2), scope2Indicator)}
                        {getScopeRender('Scope 3', diff(resultStats?.byScopes.scope3, prevResultStats?.byScopes.scope3), scope3Indicator)}
                        {previous ? 
                            getScopeRender(
                                getEmissionText(), 
                                diff(resultStats?.totals.net, prevResultStats?.totals.net),
                                totalIndicator, 
                                EmissionUnit.Total
                              )
                            : resultStats?.totals.net ? getScopeRender('Total', resultStats?.totals.net, undefined, EmissionUnit.Total) : undefined
                        }
                    </ScopesRow>
                </Column>
            </Row>
        ) : (
            <div
                style={{
                    padding: "4px 8px 4px",
                    borderRadius: "4px",
                    backgroundColor: fallbackColor ?? ruminatiColors.effective_black_60
                }}
            >
                <BaseText
                    style={{
                        color: "white",
                        fontSize: "14px",
                        lineHeight: "18px",
                    }}
                >
                    {fallbackText}
                </BaseText>
            </div>
        )
    );
}

const ScopeText = styled(BaseText)`
    color: ${ruminatiColors.light_green};
    font-size: 14px;
    line-height: 16px;
`;

const ScopesRow = styled(Row)`
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    > div > .scopeChange {
        border-right: 1px solid ${ruminatiColors.green_3_30};
    }
    > div:last-child > .scopeChange {
        border-right: 0px;
    }
`;

const ScopeChange = styled.div`
    display: flex;
    align-items: center;
    padding-right: 12px;
    color: ${ruminatiColors.light_green};
`;
