import styled from "styled-components";
import { ruminatiColors } from "../../utilities/colors";
import Heading from "../heading";
import { useEffect, useState, PropsWithChildren } from "react";
import { Column, Row } from "../styled_layout";
import Icon from "../icon";

type PopupProps = {
    onClose?: () => void;
    headerText?: string;
    width?: number;
    enabled?: boolean;
    preventClose?: boolean;
};

/**
 * A general popup component, can pass content through children prop
 * @param enabled optional. If true, the popup is visible
 * @param onClose optional. If given, will be called when the popup is closed
 * @param children optional. The content of the popup
 * @param headerText optional. The text in the top green section of the popup
 */
export default function Popup({
    onClose,
    children,
    headerText,
    width,
    enabled,
    preventClose,
}: PropsWithChildren<PopupProps>) {
    const [_enabled, setEnabled] = useState(false);

    useEffect(() => {
        setTimeout(() => setEnabled(enabled ?? true));
    }, [setEnabled, enabled])

    useEffect(() => {
        if (preventClose === true) {
            // Do not override browser back behaviour if popup cannot be closed
            return;
        }
        const onBack = () => {
            if (onClose) onClose()
        }
        window.addEventListener('popstate', onBack)
        return () => window.removeEventListener('popstate', onBack)
    }, [onClose, preventClose])

    const handleMouseDown = preventClose ? undefined : onClose;

    return (
        <PopupBackground
            className="popupBackground"
            onMouseDown={handleMouseDown}
            style={{
                backgroundColor: _enabled
                    ? ruminatiColors.bone_90
                    : "transparent",
                pointerEvents: enabled ? "auto" : "none",
            }}
        >
            <PopupWrapper
                className="popupWrapper"
                style={{
                    transform: _enabled ? "translateY(0)" : "translateY(300px)",
                    opacity: _enabled ? "1" : "0",
                    width: width ? `${width}px` : "",
                }}
                onMouseDown={(e) => e.stopPropagation()}
            >
                <PopupContainer>
                    { headerText && <PopupHeader>
                      <Row
                        style={{
                          width: '100%',
                          justifyContent: 'center'
                        }}>
                        <Column
                          style={{
                            flex: '1',
                            paddingLeft: '38px'
                          }}
                        >
                          <Heading level={4}>{headerText}</Heading>
                        </Column>
                        <Column
                          style={{
                            cursor: 'pointer',
                            marginLeft: 'auto',
                            marginRight: '20px',
                            width: '18px'
                          }}>
                          {!preventClose &&
                            <div
                              onClick={() => {
                                if (onClose) onClose()
                              }}
                              style={{ display: 'flex', padding: '3px' }}
                            >
                              <Icon icon="x" />
                            </div>
                          }
                        </Column>
                      </Row>
                    </PopupHeader>}
                    {children}
                </PopupContainer>
            </PopupWrapper>
        </PopupBackground>
    );
}

const PopupBackground = styled.div`
    position: fixed;
    width: 100%;
    height: 100vh;

    z-index: 9999;

    transition: 0.3s ease background-color;

    background-color: ${ruminatiColors.bone_90};

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
`;

const PopupContainer = styled.div`
    background-color: ${ruminatiColors.bone};
    border: 1px solid ${ruminatiColors.green_3};
    border-radius: 8px;

    min-width: 150px;
    max-width: 70vw;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const PopupWrapper = styled.div`
    transition: 0.3s ease;
`;

const PopupHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    font-family: "Approach", sans-serif;
    height: 64px;
    width: 100%;
    padding: 24px 0 24px 0;
    border-radius: 6px 6px 0 0;
    border-bottom: 1px solid ${ruminatiColors.effective_black_30};
`;
