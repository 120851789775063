import { PropsWithChildren } from 'react';

import Screen from '@/screens/screen';
import ApiErrorScreen from '@/screens/error_api';

import LoadingPlaceholder from '@/components/loading_placeholder';

import { useProperties } from '@/hooks/properties';

/**
 * Gets the users properties if they have not been loaded 
 */
export default function PropertyGuard({
    children,
}: PropsWithChildren<unknown>) {
    const {
        isLoading: propertiesIsLoading,
        error: propertiesError,
    } = useProperties()

    if (propertiesIsLoading) {
        return <Screen>
            <LoadingPlaceholder />
        </Screen>
    } else if (propertiesError) {
        return (
            <ApiErrorScreen />
        )
    } else {
        return children
    }
}
