import { FormState, FormData } from "../form";
import { FieldElement, State } from "./form_field";
import { FormPageRepeatableConfig } from "../form_page";

type FormFieldWrapperArgs<T extends FormState, U = T> = {
    data: FormData<T, U>;
};

export class FormFieldWrapper<T extends FormState, U = T> implements FieldElement<T> {
    data: FormData<T, U>;

    required = (_state: any) => true;

    // Validation state of wrapper depends on child fields (may be recursive)
    validate = (value: T | undefined, state: State<any>) => {
        const fieldsValid = Object.keys(this.data.fields).map((field) => {
            const currentElement = this.data.fields[field];
            return currentElement !== undefined && currentElement.validate(value !== undefined ? value[field] : undefined, state);
        });
        return fieldsValid.every(v => v);
    };

    constructor(args: FormFieldWrapperArgs<T, U>) {
        this.data = args.data;
    }
}

type CollapsableFieldWrapperArgs<T extends FormState, U = T> = FormFieldWrapperArgs<T, U> & { 
    label: string
    startCollapsed?: boolean
    showBorder?: boolean
};

export class CollapsableFieldWrapper<T extends FormState, U = T> extends FormFieldWrapper<T, U> {
    label: string;
    startCollapsed?: boolean
    showBorder?: boolean

    constructor(args: CollapsableFieldWrapperArgs<T, U>) {
        super(args);
        this.label = args.label;
        this.showBorder = args.showBorder ?? false
        this.startCollapsed = args.startCollapsed ?? false
    }
}

type RepeatableArrayFieldWrapperArgs<T extends FormState, U = T> = { 
    label?: string;
    useSeperatorAbove?: boolean
    repeatableConfig: FormPageRepeatableConfig;
    data: FormData<T, U>;
};

export class RepeatableArrayFieldWrapper<T extends FormState, U = T> {
    label?: string;
    repeatableConfig: FormPageRepeatableConfig;
    data: FormData<T, U>;
    useSeperatorAbove: boolean

    constructor(args: RepeatableArrayFieldWrapperArgs<T, U>) {
        this.data = args.data;
        this.label = args.label;
        this.useSeperatorAbove = args.useSeperatorAbove ?? true
        this.repeatableConfig = args.repeatableConfig
    }

    required = (_state: State<T>) => true;

    validate = (values: T[] | undefined, state: State<T>) => {
        if (!values || values.length === 0) return true
        const fields = this.data.fields
        const fieldKeys = Object.keys(fields)
        function validateValue (value: T) {
            const fieldsValid = fieldKeys.map((field) => {
                const currentElement = fields[field];
                if (currentElement && !currentElement.required(state)) {
                    return true
                }
                const res = currentElement !== undefined && currentElement.required(state) && currentElement.validate(value[field] !== undefined ? value[field] : undefined, state)
                return res;
            });

            return fieldsValid.every(v => v);
        }
        const validationResult = values?.every(v => validateValue(v))
        return validationResult
    };
}
