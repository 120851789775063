const electricityRatesByLocalityId = [
  {
    localityId: 1,
    localityName: 'ACT',
    avgPriceDollarsPerKwh: 0.2709
  },
  {
    localityId: 2,
    localityName: 'NSW',
    avgPriceDollarsPerKwh: 0.29
  },
  {
    localityId: 3,
    localityName: 'TAS',
    avgPriceDollarsPerKwh: 0.2699
  },
  {
    localityId: 5,
    localityName: 'SA',
    avgPriceDollarsPerKwh: 0.3771
  },
  {
    localityId: 6,
    localityName: 'VIC',
    avgPriceDollarsPerKwh: 0.2307
  },
  {
    localityId: 7,
    localityName: 'QLD',
    avgPriceDollarsPerKwh: 0.259
  },
  {
    localityId: 8,
    localityName: 'NT',
    avgPriceDollarsPerKwh: 0.2737
  },
  {
    localityId: 4,
    localityName: 'SW WA',
    avgPriceDollarsPerKwh: 0.3006
  },
  {
    localityId: 9,
    localityName: 'NW WA',
    avgPriceDollarsPerKwh: 0.3006
  }
]

export function calculateElectricityUseFromSpend (spend: number, localityId: number): number | null {
  const electricityRate = electricityRatesByLocalityId.find(r => r.localityId === localityId)
  if (electricityRate === undefined) return null
  return spend / electricityRate.avgPriceDollarsPerKwh
}