import { useState } from "react";

import { FormData } from "../../models/form/form";
import { DropdownFormField, NumberFormField } from "../../models/form/form_fields/basic_form_fields";
import { FertiliserUse } from "@/models/report";
import { fertiliserOptions, fertiliserOptionsWithoutUrea } from "@/models/form_chemical_lookup";
import { DropdownOptionData, FieldSize } from "@/models/form/form_fields/form_field";
import ArrayForm from "@/models/form/array_form";
import { ScrollableBody } from "../styled_layout";

const calculatorForm = (fertiliserFormOptions: DropdownOptionData<number>[]): FormData<FertiliserUse> => ({
    fields: {
      id: new DropdownFormField({
        required: true,
        label: 'Fertiliser Type',
        placeholder: "Fertiliser Type",
        options: fertiliserFormOptions,
        size: FieldSize.TwoThirds,
        useSuggestion: true
      }),
      rateApplied: new NumberFormField({
          required: true,
          label: "Rate applied",
          unit: "kg/ha",
          size: FieldSize.Third,
      })
    },
});
export const FertiliserUserPopupId = 'FertiliserUseId'

export default function FertiliserUsePopup({
    onSubmit,
    metadata
}: {
    onSubmit: (val: FertiliserUse[]) => void;
    metadata: {
        fertilisers: FertiliserUse[] | undefined
    }
}) {
    
    // If a user had already selected Urea before we disallowed it, allow it to be shown
    const existingValuesHasDeprecatedOption = metadata.fertilisers?.find(f => f.id === 72)
    const fertiliserSelectOptions = (existingValuesHasDeprecatedOption ? fertiliserOptions : fertiliserOptionsWithoutUrea).map(fo => {
        return {
            "value": fo.id,
            "name": fo.name
        }
      }) 
    const formData = calculatorForm(fertiliserSelectOptions)

    const [formValues,] = useState<Partial<FertiliserUse>[]>(
        metadata.fertilisers && metadata.fertilisers.length > 0 ? metadata.fertilisers : [{}]
    );


    return (
        <div style={{ padding: "20px", width: '750px' }}>
            <ScrollableBody>
                <ArrayForm
                    data={formData as FormData<any, any>}
                    initialValue={formValues}
                    onChange={async () => undefined}
                    onSubmit={async (values) => {
                        onSubmit(values as FertiliserUse[])
                    }}
                    sectionName={"Fertiliser"}
                    hideSubmitButton={false}
                    useRowStyle={true}
                    submitText="Submit"
                />
            </ScrollableBody>
        </div>
    );
}
