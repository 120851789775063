import { read } from "xlsx";
import type XLSX from "xlsx";
import { Property } from "../models/property_models";
import { calculateSumOfArray, calculateAverageOfArray } from './maths'
import { SeasonalFigures, LivestockClassAUS } from "../models/report";

export function openDragAndDroppedFile (data: ArrayBuffer): XLSX.WorkBook | undefined {
  try {
    const workbook: XLSX.WorkBook = read(data)
    return workbook    
  } catch (err) {
    console.log(err)
    return undefined
  }
}

interface VersionConfig {
  limeSheep: string,
  limeBeef: string,
  ureaSheep1: string,
  ureaSheep2: string,
  ureaBeef1: string,
  ureaBeef2: string,
  superSheep: string,
  superBeef: string,
  otherNSheep1: string,
  otherNSheep2: string,
  otherNBeef1: string,
  otherNBeef2: string,
  glyphosateSheep: string,
  glyphosateBeef: string,
  pesticideSheep: string,
  pesticideBeef: string,
  dieselSheep: string,
  dieselBeef: string,
  petrolSheep: string,
  petrolBeef: string,
  electricitySource: string,
  percentRenewable: string,
  electricityUseSheep: string,
  electricityUseBeef: string,
  grainSheep: string,
  grainBeef: string,
  cottonseedBeef: string,
  haySheep: string,
  hayBeef: string,
  sheepPurchased1: string,
  sheepPurchased2: string,
  percentMerino: string,
  sheepBoughtRowNumber: string,
  sheepWeightRowNumber: string,
  beefPurchased1: string,
  beefPurchased2: string,
  beefPercentagePurchased: string,
  beefPercentageTraded: string,
  beefDse: string,
  sheepDse: string
  beefBoughtRowNumber: string,
  beefWeightRowNumber: string,
  breederSheepSalesWeight: string,
  traderSheepSalesWeight: string,
  greasyWoolWeight: string,
  cleanWoolYieldPercentStartColumn: string,
  cleanWoolYieldPercentEndColumn: string,
  breederBeefSalesWeight: string,
  traderBeefSalesWeight: string
  
  cattleHeadcountRowStart: number
  cattleLiveweightRowStart: number
  cattleLiveweightGainStart: number
  cowsCalvingStart: number

  sheepHeadcountRowStart: number
  sheepLiveweightRowStart: number
  sheepLiveweightGainStart: number

  sheepLambingRowStart: number
  sheepEweLambingRowStart: number
  sheepNumberShornRow: number
}

interface VersionMaps {
  [key: string]: VersionConfig
}

const supportedVersions: VersionMaps = {
  v18: {
    limeSheep: 'D96',
    limeBeef: 'D85',
    ureaSheep1: 'D89',
    ureaSheep2: 'F89',
    ureaBeef1: 'D78',
    ureaBeef2: 'D79',
    superSheep: 'D83',
    superBeef: 'D83',
    otherNSheep1: 'E91',
    otherNSheep2: 'G91',
    otherNBeef1: 'E80',
    otherNBeef2: 'G80',
    glyphosateSheep: 'D107',
    glyphosateBeef: 'D97',
    pesticideSheep: 'D108',
    pesticideBeef: 'D98',
    dieselSheep: 'D102',
    dieselBeef: 'D91',
    petrolSheep: 'D103',
    petrolBeef: 'D92',
    electricitySource: 'D100',
    percentRenewable: 'D101',
    electricityUseSheep: 'D105',
    electricityUseBeef: 'D93',
    grainSheep: 'D105',
    grainBeef: 'D94',
    cottonseedBeef: 'D95',
    haySheep: 'D106',
    hayBeef: 'D96',
    sheepPurchased1: 'N35',
    sheepPurchased2: 'O35',
    percentMerino: 'D38',
    sheepBoughtRowNumber: '33',
    sheepWeightRowNumber: '34',
    beefPurchased1: 'O54',
    beefPurchased2: 'P54',
    beefPercentagePurchased: 'D58',
    beefPercentageTraded: 'E58',
    beefBoughtRowNumber: '52',
    beefWeightRowNumber: '53',
    beefDse: 'C8',
    sheepDse: 'D8',
    breederSheepSalesWeight: 'N43',
    traderSheepSalesWeight: 'O43',
    greasyWoolWeight: 'N52',
    cleanWoolYieldPercentStartColumn: 'D54',
    cleanWoolYieldPercentEndColumn: 'M54',
    breederBeefSalesWeight: 'O64',
    traderBeefSalesWeight: 'P64',

    cattleHeadcountRowStart: 11,
    cattleLiveweightRowStart: 27,
    cattleLiveweightGainStart: 33,
    cowsCalvingStart: 71,

    sheepHeadcountRowStart: 8,
    sheepLiveweightRowStart: 14,
    sheepLiveweightGainStart: 20,

    sheepLambingRowStart: 66,
    sheepEweLambingRowStart: 60,
    sheepNumberShornRow: 50
  },
  v21: {
    limeSheep: 'D101',
    limeBeef: 'D89',
    ureaSheep1: 'D96',
    ureaSheep2: 'F96',
    ureaBeef1: 'D84',
    ureaBeef2: 'D85',
    superSheep: 'D99',
    superBeef: 'D87',
    otherNSheep1: 'D98',
    otherNSheep2: 'F98',
    otherNBeef1: 'D86',
    otherNBeef2: 'F86',
    glyphosateSheep: 'D112',
    glyphosateBeef: 'D101',
    pesticideSheep: 'D113',
    pesticideBeef: 'D102',
    dieselSheep: 'D107',
    dieselBeef: 'D95',
    petrolSheep: 'D108',
    petrolBeef: 'D96',
    electricitySource: 'D105',
    percentRenewable: 'D106',
    electricityUseSheep: 'D109',
    electricityUseBeef: 'D97',
    grainSheep: 'D110',
    grainBeef: 'D98',
    cottonseedBeef: 'D99',
    haySheep: 'D111',
    hayBeef: 'D100',
    sheepPurchased1: 'N36',
    sheepPurchased2: 'O36',
    percentMerino: 'D39',
    sheepBoughtRowNumber: '34',
    sheepWeightRowNumber: '35',
    beefPurchased1: 'O56',
    beefPurchased2: 'P56',
    beefPercentagePurchased: 'D58',
    beefPercentageTraded: 'E58',
    beefBoughtRowNumber: '54',
    beefWeightRowNumber: '55',
    beefDse: 'C8',
    sheepDse: 'D8',
    breederSheepSalesWeight: 'N44',
    traderSheepSalesWeight: 'O44',
    greasyWoolWeight: 'N53',
    cleanWoolYieldPercentStartColumn: 'D55',
    cleanWoolYieldPercentEndColumn: 'M55',
    breederBeefSalesWeight: 'O64',
    traderBeefSalesWeight: 'P64',

    cattleHeadcountRowStart: 13,
    cattleLiveweightRowStart: 29,
    cattleLiveweightGainStart: 35,
    cowsCalvingStart: 71,

    sheepHeadcountRowStart: 9,
    sheepLiveweightRowStart: 15,
    sheepLiveweightGainStart: 21,

    sheepLambingRowStart: 66,
    sheepEweLambingRowStart: 60,
    sheepNumberShornRow: 51
  }
}

function getSheepTab (workbook: XLSX.WorkBook): XLSX.WorkSheet | undefined {
  return workbook.Sheets[' Data input - sheep']
}

function getBeefTab (workbook: XLSX.WorkBook): XLSX.WorkSheet | undefined {
  return workbook.Sheets['Data input - beef']
}

// function getSummaryTab (workbook: XLSX.WorkBook): XLSX.WorkSheet {
//   return workbook.Sheets['Data summary']
// }

function getCellValueOtherwiseZero (cellReference: XLSX.CellObject | undefined): number {
  if (cellReference === undefined) return 0
  return cellReference.v as number
}

function sumCellReferences (array: XLSX.CellObject[]): number {
  return calculateSumOfArray(array.map(ref => getCellValueOtherwiseZero(ref)))
}

function averageCellReferences (array: XLSX.CellObject[]): number {
  return calculateAverageOfArray(array.map(ref => getCellValueOtherwiseZero(ref)).filter(n => n !== 0))
}

function getLime(sheepTab: XLSX.WorkSheet, beefTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  return sumCellReferences([
    sheepTab[versionConfig.limeSheep],
    beefTab[versionConfig.limeBeef]
  ])
}

function getUrea(sheepTab: XLSX.WorkSheet, beefTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  return sumCellReferences([
    sheepTab[versionConfig.ureaSheep1],
    sheepTab[versionConfig.ureaSheep2], 
    beefTab[versionConfig.ureaBeef1],
    beefTab[versionConfig.ureaBeef2]
  ])
}

function getSingleSuperphosphate(sheepTab: XLSX.WorkSheet, beefTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  return sumCellReferences([
    sheepTab[versionConfig.superSheep],
    beefTab[versionConfig.superBeef]
  ])
}

function getOtherNitrogen (sheepTab: XLSX.WorkSheet, beefTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  return sumCellReferences([
    sheepTab[versionConfig.otherNSheep1],
    sheepTab[versionConfig.otherNSheep2], 
    beefTab[versionConfig.otherNBeef1],
    beefTab[versionConfig.otherNBeef2]
  ])
}

function getGlyphosate (sheepTab: XLSX.WorkSheet, beefTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  return sumCellReferences([
    sheepTab[versionConfig.glyphosateSheep],
    beefTab[versionConfig.glyphosateBeef]
  ])
}

function getPesticide (sheepTab: XLSX.WorkSheet, beefTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  return sumCellReferences([
    sheepTab[versionConfig.pesticideSheep],
    beefTab[versionConfig.pesticideBeef]
  ])
}

function getDiesel (sheepTab: XLSX.WorkSheet, beefTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  return sumCellReferences([
    sheepTab[versionConfig.dieselSheep],
    beefTab[versionConfig.dieselBeef]
  ])
}

function getPetrol (sheepTab: XLSX.WorkSheet, beefTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  return sumCellReferences([
    sheepTab[versionConfig.petrolSheep],
    beefTab[versionConfig.petrolBeef]
  ])
}

function getPercentRenewables (sheepTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  const source: string | number = getCellValueOtherwiseZero(sheepTab[versionConfig.electricitySource])
  const percentRenewable: string | number = getCellValueOtherwiseZero(sheepTab[versionConfig.percentRenewable])

  if (source === 0) return 0

  if (source as unknown as string === 'Renewable' || percentRenewable === 100) {
    return 1
  }
  if (source as unknown as string === 'State Grid') {
    if (percentRenewable > 0) return percentRenewable / 100
    return 0
  }

  return 1
}

function getElectricityUse (sheepTab: XLSX.WorkSheet, beefTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  return sumCellReferences([
    sheepTab[versionConfig.electricityUseSheep],
    beefTab[versionConfig.electricityUseBeef]
  ])
}

function getGrain (sheepTab: XLSX.WorkSheet, beefTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  return sumCellReferences([
    sheepTab[versionConfig.grainSheep],
    beefTab[versionConfig.grainBeef]
  ])
}

function getCottonseed (beefTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  return getCellValueOtherwiseZero(beefTab[versionConfig.cottonseedBeef])
}

function getHay (sheepTab: XLSX.WorkSheet, beefTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  return sumCellReferences([
    sheepTab[versionConfig.grainSheep],
    beefTab[versionConfig.grainBeef]
  ])
}

function getBeefWeightSold(beefTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  return sumCellReferences([
    beefTab[versionConfig.breederBeefSalesWeight],
    beefTab[versionConfig.traderBeefSalesWeight]
  ])
}

function getSheepWeightSold(sheepTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  return sumCellReferences([
    sheepTab[versionConfig.breederSheepSalesWeight],
    sheepTab[versionConfig.traderSheepSalesWeight]
  ])
}

function getWoolWeightSold(sheepTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  return getCellValueOtherwiseZero(sheepTab[versionConfig.greasyWoolWeight])
}

function getCleanWoolPercent(sheepTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  // Eg Need to get values in cells between 'D54' and 'M54'
  const startColumn = versionConfig.cleanWoolYieldPercentStartColumn
  const endColumn = versionConfig.cleanWoolYieldPercentEndColumn
  const rowReference = startColumn.slice(-2)

  const alphabetArray = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('')

  const firstLetterIndex = alphabetArray.findIndex(l => l === startColumn[0])
  const lastLetterIndex = alphabetArray.findIndex(l => l === endColumn[0])
  const cols = alphabetArray.splice(firstLetterIndex, lastLetterIndex - firstLetterIndex)
  const vals = cols.map(c => sheepTab[`${c}${rowReference}`])
  return averageCellReferences(vals)
}

// const intakeDseByLocality = [
//   {
//     "localityCode": 2,
//     "locality": "NSW",
//     "intakeDse": 0.793216746
//   },
//   {
//     "localityCode": 1,
//     "locality": "ACT",
//     "intakeDse": 0.793216746
//   },
//   {
//     "localityCode": 6,
//     "locality": "Vic",
//     "intakeDse": 0.848253608
//   },
//   {
//     "localityCode": 7,
//     "locality": "QLD",
//     "intakeDse": 1.004215414
//   },
//   {
//     "localityCode": 5,
//     "locality": "SA",
//     "intakeDse": 0.734943869
//   },
//   {
//     "localityCode": 9,
//     "locality": "SW WA",
//     "intakeDse": 0.766531534
//   },
//   {
//     "localityCode": 4,
//     "locality": "NW WA",
//     "intakeDse": 0.766531534
//   },
//   {
//     "localityCode": 3,
//     "locality": "TAS",
//     "intakeDse": 0.749134476
//   },
//   {
//     "localityCode": 8,
//     "locality": "NT",
//     "intakeDse": 0.98273487
//   }
// ]

// function calculateDse (entericMethane: number, stateCode: number) {
//   const localityIntake = intakeDseByLocality.find(l => l.localityCode === stateCode)
//   if (localityIntake === undefined) return 0

//   return entericMethane * 1000 / ((localityIntake.intakeDse * 20.7 / 1000) * 365 * 28)
// }

interface SheepTrade {
  proportionMerino: number,
  numberPurchased: number,
  averageWeightPurchased: number
}

const sheepLivestockTypes = [{
  name: 'Rams',
  column: 'D',
  classId: 1
},
{
  name: 'Whethers',
  column: 'E',
  classId: 2
},
{
  name: 'Ewes',
  column: 'F',
  classId: 3
},
{
  name: 'BreedingEwes',
  column: 'G',
  classId: 4
},
{
  name: 'OtherEwes',
  column: 'H',
  classId: 5
},
{
  name: 'EweLambs',
  column: 'I',
  classId: 6
},
{
  name: 'WhetherLambs',
  column: 'J',
  classId: 7
},
{
  name: 'TradeLambHoggets',
  column: 'K',
  classId: 8
},
{
  name: 'TradeWethers',
  column: 'L',
  classId: 9
},
{
  name: 'TradeEwes',
  column: 'M',
  classId: 10
}]
function calculateSheepTrade (sheepTab: XLSX.WorkSheet, versionConfig: VersionConfig): SheepTrade[] {
  const out: SheepTrade[] = []
  const weightPurchased = sumCellReferences([
    sheepTab[versionConfig.sheepPurchased1],
    sheepTab[versionConfig.sheepPurchased2]
  ])
  if (weightPurchased === 0) return out

  const merino = getCellValueOtherwiseZero(sheepTab[versionConfig.percentMerino])

  sheepLivestockTypes.forEach(t => {
    const boughtKey = `${t.column}${versionConfig.sheepBoughtRowNumber}`
    const weightKey = `${t.column}${versionConfig.sheepWeightRowNumber}`
    const numberBought = getCellValueOtherwiseZero(sheepTab[boughtKey])
    if (numberBought > 0) {
      out.push({
        proportionMerino: merino,
        numberPurchased: numberBought,
        averageWeightPurchased: getCellValueOtherwiseZero(sheepTab[weightKey])
      })
    }    
  })

  return out
}

interface TradingRegion {
  originCode: number,
  origin: string
}
const tradingRegionLookup: TradingRegion[] = [
  {
    "originCode": 1,
    "origin": "NT"
  },
  {
    "originCode": 2,
    "origin": "nth QLD"
  },
  {
    "originCode": 3,
    "origin": "sth/central QLD"
  },
  {
    "originCode": 4,
    "origin": "nth NSW"
  },
  {
    "originCode": 6,
    "origin": "sth NSW/VIC/sth SA"
  },
  {
    "originCode": 6,
    "origin": "nth/sth NSW/VIC/sth SA"
  },
  {
    "originCode": 10,
    "origin": "NSW/SA pastoral zone"
  },
  {
    "originCode": 11,
    "origin": "sw WA"
  },
  {
    "originCode": 12,
    "origin": "WA pastoral"
  },
  {
    "originCode": 13,
    "origin": "TAS"
  }
]

interface BeefTrade {
  origin: number
  averageWeightPurchased: number
  numberPurchased: number
}

function calculateOriginV18 (beefTab: XLSX.WorkSheet) {
  const useBreedingLookup = getCellValueOtherwiseZero(beefTab.D58) > getCellValueOtherwiseZero(beefTab.E58)
  return useBreedingLookup ? getCellValueOtherwiseZero(beefTab.D57) : getCellValueOtherwiseZero(beefTab.E57)
}

const beefLivestockTypes = [{
  name: 'Bulls >1',
  column: 'D',
  classId: 1
},
{
  name: 'Steers <1',
  column: 'E',
  classId: 2
},
{
  name: 'Steers 1-2',
  column: 'F',
  classId: 3
},
{
  name: 'Steers >2',
  column: 'G',
  classId: 4
},
{
  name: 'Cows >2',
  column: 'H',
  classId: 5
},
{
  name: 'Heifers <1',
  column: 'I',
  classId: 6
},
{
  name: 'Heifers 1-2',
  column: 'J',
  classId: 7
},
{
  name: 'Heifers >2',
  column: 'K',
  classId: 8
},
{
  name: 'Cows',
  column: 'L',
  classId: 9
},
{
  name: 'Heifers',
  column: 'M',
  classId: 10
},
{
  name: 'Steers <1',
  column: 'N',
  classId: 11
}]

function calculateBeefTrade (beefTab: XLSX.WorkSheet, versionConfig: VersionConfig, versionNumber: string): BeefTrade[] {

  const out: BeefTrade[] = []
  const weightPurchased = sumCellReferences([
    beefTab[versionConfig.beefPurchased1],
    beefTab[versionConfig.beefPurchased2]
  ])
  if (weightPurchased === 0) return out

  let sbGafDefaultOrigin: null | number | string = null
  let defaultRegion: null | undefined | TradingRegion  = null
  if (versionNumber === 'v18') {
    sbGafDefaultOrigin = calculateOriginV18(beefTab)
    defaultRegion = tradingRegionLookup.find(r => r.origin === sbGafDefaultOrigin)
    if (defaultRegion === undefined || defaultRegion === null) return out      
  }

  function lookupOriginCodev21 (column: string) {
    const region = tradingRegionLookup.find(r => r.origin === beefTab[`${column}58`].v)
    return region?.originCode
  }

  beefLivestockTypes.forEach(t => {
    const boughtKey = `${t.column}${versionConfig.beefBoughtRowNumber}`
    const weightKey = `${t.column}${versionConfig.beefWeightRowNumber}`
    const numberBought = getCellValueOtherwiseZero(beefTab[boughtKey])
    if (versionNumber === 'v21' && numberBought === 0) return
    const origin = versionNumber === 'v18' ? defaultRegion?.originCode as number : lookupOriginCodev21(t.column)
    if (origin === undefined) return

    if (numberBought > 0) {
      out.push({
        origin,
        numberPurchased: numberBought,
        averageWeightPurchased: getCellValueOtherwiseZero(beefTab[weightKey])
      })
    }    
  })

  return out
}

// function calculateBeefDse (dataSummaryTab: XLSX.WorkSheet, stateCode: number, versionConfig: VersionConfig) {
//   const entericMethane = getCellValueOtherwiseZero(dataSummaryTab[versionConfig.beefDse])
//   return calculateDse(entericMethane, stateCode)
// }

// function calculateSheepDse (dataSummaryTab: XLSX.WorkSheet, stateCode: number, versionConfig: VersionConfig) {
//   const entericMethane = getCellValueOtherwiseZero(dataSummaryTab[versionConfig.sheepDse])
//   return calculateDse(entericMethane, stateCode)
// }

function fillSeasonalValues (tab: XLSX.WorkSheet, column: string, startRowNumber: number): SeasonalFigures {
  return {
    spring: getCellValueOtherwiseZero(tab[`${column}${startRowNumber}`]),
    summer: getCellValueOtherwiseZero(tab[`${column}${startRowNumber + 1}`]),
    autumn: getCellValueOtherwiseZero(tab[`${column}${startRowNumber + 2}`]),
    winter: getCellValueOtherwiseZero(tab[`${column}${startRowNumber + 3}`])
  }
}

function getLivestockCattleClass (beefTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  const out: LivestockClassAUS[] = []

  beefLivestockTypes.forEach(t => {

    const headcount = fillSeasonalValues(beefTab, t.column, versionConfig.cattleHeadcountRowStart)
    const liveweight = fillSeasonalValues(beefTab, t.column, versionConfig.cattleLiveweightRowStart)
    const liveweightGain = fillSeasonalValues(beefTab, t.column, versionConfig.cattleLiveweightGainStart)

    if (calculateSumOfArray(Object.values(headcount)) > 0) {
      out.push({
        classId: t.classId,
        headcount,
        liveweight,
        liveweightGain
      })
    }    
  })
  return out
}

function getCowsCalving (beefTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  return fillSeasonalValues(beefTab, 'D', versionConfig.cowsCalvingStart)
}

function getLivestockSheepClass (sheepTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  const out: LivestockClassAUS[] = []

  sheepLivestockTypes.forEach(t => {

    const headcount = fillSeasonalValues(sheepTab, t.column, versionConfig.sheepHeadcountRowStart)
    const liveweight = fillSeasonalValues(sheepTab, t.column, versionConfig.sheepLiveweightRowStart)
    const liveweightGain = fillSeasonalValues(sheepTab, t.column, versionConfig.sheepLiveweightGainStart)

    if (calculateSumOfArray(Object.values(headcount)) > 0) {
      out.push({
        classId: t.classId,
        headcount,
        liveweight,
        liveweightGain
      })
    }    
  })
  return out
}

function getLambingRates (sheepTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  return fillSeasonalValues(sheepTab, 'G', versionConfig.sheepLambingRowStart)
}

function getEweLambingRates (sheepTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  return fillSeasonalValues(sheepTab, 'G', versionConfig.sheepEweLambingRowStart)
}

function getNumberShorn (sheepTab: XLSX.WorkSheet, versionConfig: VersionConfig) {
  const out: number[] = []
  sheepLivestockTypes.forEach(t => {
    out.push(getCellValueOtherwiseZero(sheepTab[`${t.column}${versionConfig.sheepNumberShornRow}`]))
  })
  return calculateSumOfArray(out)
}

function isV18 (workbook: XLSX.WorkBook): boolean {
  if(workbook.Sheets['notes'] === undefined) return false
  const ws: XLSX.WorkSheet = workbook.Sheets['notes']
  if (ws.A14.w === '7/4/22' && ws.B15 === undefined) return true
  return false
}

function isV19 (workbook: XLSX.WorkBook): boolean {
  if(workbook.Sheets['notes'] === undefined) return false
  const ws: XLSX.WorkSheet = workbook.Sheets['notes']
  if (ws.A22.w === '8/11/22' && ws.B23 === undefined) return true
  return false
}

function isV21 (workbook: XLSX.WorkBook): boolean {
  if(workbook.Sheets['notes'] === undefined) return false
  const ws: XLSX.WorkSheet = workbook.Sheets['notes']
  if (ws.A40.w === '1/5/23' && ws.B47 === undefined) return true
  return false
}

function isV22 (workbook: XLSX.WorkBook): boolean {
  if(workbook.Sheets['notes'] === undefined) return false
  const ws: XLSX.WorkSheet = workbook.Sheets['notes']
  if (ws.A51.w === '5/26/23' && ws.B57 === undefined) return true
  return false
}

function getVersionConfig(workbook: XLSX.WorkBook): {versionConfig: VersionConfig | null, versionNumber: string} {
  try {
    if (isV18(workbook)) return {versionConfig: supportedVersions.v18, versionNumber: 'v18'}
    if (isV19(workbook)) return {versionConfig: supportedVersions.v18, versionNumber: 'v19'}    
    if (isV21(workbook)) return {versionConfig: supportedVersions.v21, versionNumber: 'v21'}
    if (isV22(workbook)) return {versionConfig: supportedVersions.v21, versionNumber: 'v22'}
  } catch {
    return {versionConfig: null, versionNumber: 'unknown'}
  }
  return {versionConfig: null, versionNumber: 'unknown'}
}

export type SbGAFImportResults = {
  livestockInformationCattleClassAus: LivestockClassAUS[],
  livestockInformationCattleMiscAus: {
    percentageCalving: SeasonalFigures
  },
  livestockInformationSheepClassAus: LivestockClassAUS[],
  livestockInformationSheepMiscAus: {
    seasonalLambingRates: SeasonalFigures,
    proportionEwesLambing: SeasonalFigures
    greasyWoolKgs: number
    cleanWoolYieldPercentage: number
    numberSheepShorn: number
  },
  pasture: {
    urea: number,
    singleSuperphosphate: number,
    lime: number,
    otherNitrogen: number,
    glyphosate: number,
    pesticide: number
  },
  consumables: {
    diesel: number
    petrol: number
    percentRenewables: number
    electricityUsage: number
  },
  purchasedLivestock: {
    sheep: SheepTrade[]
    cattle: BeefTrade[]
  },
  supplementaryFeed: {
    grain: number
    cottonSeed: number
    hay: number
  },
  productionInformation: {
    cattleLiveweightSoldKgs: number
    sheepLiveweightSoldKgs: number
  }
}

export function extractDataFromv18Workbook (workbook: XLSX.WorkBook, _property: Property): SbGAFImportResults  {
  const config = getVersionConfig(workbook)
  if (config.versionNumber === 'unknown') throw Error('Not a supported spreadsheet')
  const versionConfig = config.versionConfig as VersionConfig

  const sheepTab = getSheepTab(workbook)
  const beefTab = getBeefTab(workbook)
  if (!sheepTab || !beefTab) throw Error('Sheep or Beef tabs not found.')

  const sheepTrade = calculateSheepTrade(sheepTab, versionConfig)
  const beefTrade = calculateBeefTrade(beefTab, versionConfig, config.versionNumber)

  return {
    livestockInformationCattleClassAus: getLivestockCattleClass(beefTab, versionConfig),
    livestockInformationCattleMiscAus: {
      percentageCalving: getCowsCalving(beefTab, versionConfig)
    },
    livestockInformationSheepClassAus: getLivestockSheepClass(sheepTab, versionConfig),
    livestockInformationSheepMiscAus: {
      seasonalLambingRates: getLambingRates(sheepTab, versionConfig),
      proportionEwesLambing: getEweLambingRates(sheepTab, versionConfig),
      greasyWoolKgs: getWoolWeightSold(sheepTab, versionConfig),
      cleanWoolYieldPercentage: getCleanWoolPercent(sheepTab, versionConfig) / 100,
      numberSheepShorn: getNumberShorn(sheepTab, versionConfig)
    },
    pasture: {
      urea: getUrea(sheepTab, beefTab, versionConfig),
      singleSuperphosphate: getSingleSuperphosphate(sheepTab, beefTab, versionConfig),
      lime: getLime(sheepTab, beefTab, versionConfig),
      otherNitrogen: getOtherNitrogen(sheepTab, beefTab, versionConfig),
      glyphosate: getGlyphosate(sheepTab, beefTab, versionConfig),
      pesticide: getPesticide(sheepTab, beefTab, versionConfig),
    },
    consumables: {
      diesel: getDiesel(sheepTab, beefTab, versionConfig),
      petrol: getPetrol(sheepTab, beefTab, versionConfig),
      percentRenewables: getPercentRenewables(sheepTab, versionConfig),
      electricityUsage: getElectricityUse(sheepTab, beefTab, versionConfig),
    },
    purchasedLivestock: {
      sheep: sheepTrade,
      cattle: beefTrade
    },
    supplementaryFeed: {
      grain: getGrain(sheepTab, beefTab, versionConfig),
      cottonSeed: getCottonseed(beefTab, versionConfig),
      hay: getHay(sheepTab, beefTab, versionConfig),
    },
    productionInformation: {
      cattleLiveweightSoldKgs: getBeefWeightSold(beefTab, versionConfig),
      sheepLiveweightSoldKgs: getSheepWeightSold(sheepTab, versionConfig)
    }
  }
}

