import { ruminatiColors } from "../utilities/colors";
import styled from "styled-components";
import { BaseText } from "./styled_text";

type BaseFormFieldProps = {
    color: string;
    isSmall: boolean;
    width: string;
};

export const BaseFormField = styled.div<BaseFormFieldProps>`
    height: ${(props) => (props.isSmall ? "48px" : "64px")};
    border-radius: 8px;
    padding: ${(props) => (props.isSmall ? "8px 12px" : "16px")};
    /* margin-bottom: 1%; */

    background-color: ${ruminatiColors.bone};

    font-family: "Approach", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;

    width: ${(props) => props.width};

    display: flex;
    justify-content: center;
    align-items: center;

    /* position: relative; */

    color: ${(props) => props.color};
    border: 1px solid ${(props) => props.color};

    transition: 0.3s ease border, 0.3s ease color;

    .dropdown-options {
        display: none;
    }

    .input-inside-label {
        color: ${ruminatiColors.green_3_30};
    }

    input,
    input::placeholder {
        font-family: "Approach", sans-serif;
        transition: 0.3s ease color;
    }

    input {
        color: ${(props) => props.color};
    }

    input::placeholder {
        color: ${ruminatiColors.effective_black_30};
    }

    input[type="password"] {
        font-family: sans-serif;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        appearance: none;
    }

    svg path {
        fill: ${(props) => props.color};
        transition: 0.3s ease;
    }

    &:hover {
        color: ${ruminatiColors.orange_50};
        border: 1px solid ${ruminatiColors.orange_50};

        input,
        input::placeholder,
        .input-inside-label {
            color: ${ruminatiColors.orange_50};
        }

        svg path {
            fill: ${ruminatiColors.orange_50};
        }
    }

    &:focus-within {
        color: ${ruminatiColors.orange};
        border: 1px solid ${ruminatiColors.orange};

        input,
        input::placeholder,
        .input-inside-label {
            color: ${ruminatiColors.orange};
        }

        svg path {
            fill: ${ruminatiColors.orange};
        }
    }
`;

export const FormWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    flex-grow: 1;

    min-width: 0;

    &:focus-within {
        .dropdown-options {
            display: block;
        }
    }
`;

export const InputWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    overflow: hidden;
`;

type DropdownOptionsProps = {
    fixed?: boolean;
    top?: number;
    left?: number;
    maxHeight?: number;
    width?: number;
};

export const DropdownOptions = styled.div<DropdownOptionsProps>`
    position: ${(props) => props.fixed ? "fixed" : "absolute"};
    left: ${(props) => props.left ?? 0}px;
    top: ${(props) => props.top ?? 72}px;
    z-index: 9999;

    font-family: "Approach", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;

    width: ${(props) => props.width !== undefined ? `${props.width}px` : "100%"};

    max-height: ${(props) =>
        props.maxHeight ? `${props.maxHeight}px` : "auto"};
    overflow-y: auto;

    background-color: ${ruminatiColors.bone};
    border: 1px solid ${ruminatiColors.orange};
    border-radius: 8px;

    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.07);

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: ${ruminatiColors.orange};
    }
`;

type DropdownOptionProps = {
    tabIndex: number;
    key: string | number;
    onClick?: (e: MouseEvent) => void;
    padding?: string;
    colorScheme?: string;
};

export const DropdownOption = styled.div<DropdownOptionProps>`
    color: ${(props) => props.colorScheme === "transparent" ? ruminatiColors.green_3 : ruminatiColors.orange};
    border-bottom: 1px solid ${(props) => props.colorScheme === "transparent" ? ruminatiColors.green_3 : ruminatiColors.orange_40_opaque};
    padding: ${(props) => props.padding ?? "16px"};

    display: flex;
    align-items: center;

    user-select: none;
    cursor: pointer;
    transition: 0.3s ease color;

    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        margin: 0;
        line-height: 32px;
    }

    &:hover {
        color: ${(props) => props.colorScheme === "transparent" ? ruminatiColors.green_3_50 : ruminatiColors.orange_50};
    }

    &:last-child {
        border-bottom: none;
    }
`;

export const DropdownFilterOptions = styled(DropdownOptions)`
    width: max(32vw, 32vh);
    color: ${ruminatiColors.orange};
    font-family: "TTInterfaces", sans-serif;
    font-size: 16px;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
`;

export const TextTipWrapper = styled.div`
    display: flex;
    align-items: center;
    min-width: 0;
    transition: 0.3s ease opacity;
    background-image: linear-gradient(
        90deg,
        ${ruminatiColors.bone_0},
        ${ruminatiColors.bone} 50px
    );
`;

export const TextTip = styled(BaseText)`
    font-family: "Approach", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    text-align: right;

    user-select: none;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledInput = styled.input`
    background-color: transparent;
    border: none;

    font-weight: 500;
    font-family: "Approach", sans-serif;
    font-size: 20px;
    line-height: 32px;

    outline: 0;
    no-select: none;
`;

export const StyledTextArea = styled.textarea`
    background-color: transparent;
    border: none;

    font-weight: 500;
    font-family: "Approach", sans-serif;
    font-size: 20px;
    line-height: 32px;

    outline: 0;
    width: 100%;
    no-select: none;
    resize: none;
    color: ${ruminatiColors.dark_green};
`;

interface DropdownDisplayProps {
    size: "small" | "large";
    color: string;
    disabled?: boolean;
    onMouseDown?: (e: MouseEvent) => void
}

export const DropdownDisplay = styled.div<DropdownDisplayProps>`
    background-color: transparent;
    border: none;

    color: ${(props) => props.color};

    font-weight: 500;
    font-family: "Approach", sans-serif;
    font-size: ${(props) => (props.size === "small" ? "16px" : "20px")};
    line-height: 32px;

    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    user-select: none;

    display: flex;
    align-items: center;

    margin: 0;

    outline: 0;
    flex-grow: 1;
    max-width: calc(100% - 32px);
    min-width: 0;

    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        margin: 0;
        line-height: 32px;
    }
`;

export const StyledTip = styled.div.attrs((props: { backgroundColor: string }) => props)`
    color: ${ruminatiColors.bone};
    background-color: ${(props) => props.backgroundColor ?? ruminatiColors.orange};
    border-radius: 8px;
    padding: 8px 16px;

    text-align: center;
    letter-spacing: 0.01em;

    font-family: "TTInterfaces", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;

    min-width: 250px;
    max-width: 500px;
    width: auto;

    position: absolute;
    bottom: 35px;
    right: -16px;
    cursor: default;

    &::after {
        content: "";

        position: absolute;
        bottom: -8px;
        right: 16px;

        width: 0;
        height: 0;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-top: 16px solid ${(props) => props.backgroundColor ?? ruminatiColors.orange};
    }
`;
