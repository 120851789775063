import { TypeOrFunctionOfState, InputFormFieldArgs, NumberType, Validator, FieldSize } from "./form_field"
import { monthLabels } from "@/models/form_options";
import { MultiInputFormField } from "./multi_input_form_field";

export type MonthInformation = { [key: string]: number | undefined };

const prevYearsMonths = monthLabels.slice(0, 7).map(ml => ml.key)
function getSeasonYear (seasonKey: string, financialYear: number) {
    if (prevYearsMonths.indexOf(seasonKey) > -1) return financialYear - 1
    return financialYear
}

function makeMonthLabels(financialYear?: number) {
    if (!financialYear) return monthLabels
    return monthLabels.map(sl => {
        return {
            key: sl.key,
            label: `${sl.label} ${getSeasonYear(sl.key, financialYear)}`
        }
    })
}

export type MonthlyInputFormFieldArgs = InputFormFieldArgs<MonthInformation> & {
    title: string,
    allUnits?: string,
    singleValidator?: Validator<number>,
    numberType?: NumberType,
    financialYear?: number,
    additionalAction?: TypeOrFunctionOfState<JSX.Element>
}

export class MonthlyInputFormField extends MultiInputFormField {
    constructor(args: MonthlyInputFormFieldArgs) {
        super({
            ...args,
            // unit: args.allUnits,
            // allUnits: args.allUnits,
            required: args.required ?? true,
            useError: false,
            useLabelsAbove: true,
            options: makeMonthLabels(args.financialYear),
            additionalAction: args.additionalAction,
            columnWidth: FieldSize.Sixth
        });
    }
}
