import { DropdownOptionData } from '../../../models/form/form_fields/form_field'
import { monthsForSelecting, formatFinancialYearStringToSimpleFinancialYearString } from '../../../utilities/dates'
import { Form } from "../../../models/form/form";
import { LabelField } from '../../../models/form/form_fields/decoration_form_fields';
import { CheckBoxFormField } from '../../../models/form/form_fields/basic_form_fields';


type DseTradeSelectorProps = {
  dseType: string;
  classes: DropdownOptionData<string>[];
  financialYear: string;
  onDone: (data: TradingParametersPage1) => void;
};

export interface TradingParametersPage1 {
  classes: string[],
  months: string[],
}

/**
 * The DSE Trading form, used by both Cattle and Sheep, intended to be shown inside a popup
 */
export default function DseTradeSelector({
  dseType,
  classes,
  financialYear,
  onDone
}: DseTradeSelectorProps) {

  async function progressStep(formData: TradingParametersPage1) {
    onDone(formData)
  }

  function generateForm(dseType: string, classes: DropdownOptionData<string>[], monthsForSelecting: DropdownOptionData<string>[]) {
    const fyString = formatFinancialYearStringToSimpleFinancialYearString(financialYear)

    return {
      fields: {
        label: new LabelField({
          label: `What classes of ${dseType} did you trade in ${fyString} financial year?`,
          align: 'center'
        }),
        classes: new CheckBoxFormField({
          required: true,
          placeholder: "",
          options: classes
        }),
        label2: new LabelField({
          label: `Select the months of the year you purchased or sold ${dseType} in the trading herd.`,
          align: 'center'
        }),
        months: new CheckBoxFormField({
          required: true,
          placeholder: "",
          options: monthsForSelecting
        })
      }
    };
  }

  return (
    <Form<TradingParametersPage1>
      data={generateForm(dseType, classes, monthsForSelecting)}
      initialValue={{}}
      onSubmit={(formData) => progressStep(formData)}
      submitText='Continue'
      buttonWidth="30%"
      fieldSize="small"
      width="100%"
    />
  )
}
