import { Progress } from "antd"
import { useEffect, useState } from "react"

import { Row } from "./styled_layout"
import { BodyText } from "./styled_text"
import { ruminatiColors } from "@/utilities/colors"

export default function ActiveProgress() {
  const [progress, setProgress] = useState<number>(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress(progress + 0.5)
    }, 50)
    return () => clearInterval(intervalId);
  }, [progress, setProgress])
  
  return <>
    <Row style={{ width: '200px', marginTop: '40px' }}>
      <Progress
        percent={progress}
        showInfo={false}
        strokeColor={ruminatiColors.dark_green}
        status="active"
      />
    </Row>
    <Row>
      <BodyText>Loading your data from AgriWebb</BodyText>
    </Row>
  </>
}
