import styled from "styled-components";
import { Column } from "./styled_layout";
import { ruminatiColors } from "../utilities/colors";

type CardContainerProps = {
    borderColor?: string;
    padding?: string;
};


export const CardContainer = styled(Column)<CardContainerProps>`
    border: 1px solid ${(props) => props.borderColor ?? ruminatiColors.green_3};
    border-radius: 8px;
    padding: ${(props) => props.padding ?? "16px 24px 24px"};
    width: 100%;
    gap: 12px;
    overflow: hidden;
`;