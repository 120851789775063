export const calculateSumOfArray = (arr?: number[]) => {
  if (arr === undefined) return 0
  if (arr.length === 0) return 0
  return arr.reduce((total, current) => {
    return total + current
  }, 0)
}

export const calculateAverageOfArray = (arr: number[]) => {
  return arr.reduce((a, b) => a + b, 0) / arr.length
}

export const calculateProductOfArray = (arr: number[]) => {
  let value = 1;
  for (let i = 0; i < arr.length; i++) {
      value *= arr[i] ?? 1;
  }
  return value;
}

export function sumOfProducts(arrays: number[][]): number {
  if (arrays === undefined) return 0
  if (arrays.length === 1) return calculateSumOfArray(arrays[0])
  
  const numRecords = arrays[0].length
  const products = []
  for (let index = 0; index < numRecords; index++) {
    products.push(calculateProductOfArray(arrays.map(arr => arr[index])))
  }
  return calculateSumOfArray(products)
}

export function calculatePercentage (value: number, total: number) {
  return (value / total) * 100
}