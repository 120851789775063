import { useRef } from "react";
import DropdownButton, { DropdownButtonRef } from "./buttons/dropdown_button";
import { DropdownOption } from "./styled_input";

export type DropdownSelectOption = {
    label: string;
    onClick?: () => void;
};

/**
 * A dropdown select menu.
 * @param text the button's text
 * @param options an array of {@link DropdownSelectOption}.
 * @returns a button which opens a dropdown menu onClick.
 */
export default function DropdownSelect({
    text,
    options,
    colorScheme = "green",
    width,
    useBlurBackground
}: {
    text?: string;
    options: DropdownSelectOption[];
    colorScheme?: 'green' | 'orange' | 'transparent';
    width?: string
    useBlurBackground?: boolean
}) {
    // To use DropdownButton's exposed functions.
    const dropdownRef = useRef<DropdownButtonRef>(null);

    return (
        <DropdownButton
            buttonText={text ?? ""}
            ref={dropdownRef}
            width={width}
            colorScheme={colorScheme}
            useBlurBackground={useBlurBackground}
            dropdownMenu={
                <>
                    {options.map((option, index) => {
                        return (
                            <DropdownOption
                                key={index}
                                tabIndex={-1}
                                onClick={() => {
                                    if (option.onClick) {
                                        option.onClick();
                                    }
                                    dropdownRef.current?.close();
                                }}
                                padding={"8px 19px 8px 19px"}
                                colorScheme={colorScheme}
                            >
                                <p style={{ fontWeight: "normal", letterSpacing: "0.32px" }}>
                                    {option.label}
                                </p>
                            </DropdownOption>
                        );
                    })}
                </>
            }
        />
    );
}
