import { BubbleDataPoint, Chart, ChartType, ChartTypeRegistry, Point, TooltipModel } from "chart.js";
import { ruminatiColors } from "../../utilities/colors";
import { formatReportNumber, formatReportPercent } from "../../utilities/functions";

export const customTooltip = function (
    this: TooltipModel<ChartType>,
    args: {
        chart: Chart<
            keyof ChartTypeRegistry,
            (number | [number, number] | BubbleDataPoint | Point | null)[],
            unknown
        >;
        tooltip: TooltipModel<ChartType>;
    }
) {
    // Tooltip Element
    let tooltipEl = document.getElementById("chartjs-tooltip");

    // Create element on first render
    if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.id = "chartjs-tooltip";
        tooltipEl.innerHTML = "<table></table>";
        document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    const tooltipModel = args.tooltip;
    if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = "0";
        return;
    }

    const tableRoot = tooltipEl.querySelector("table");

    // Remove previous tooltip.
    while (tableRoot?.firstChild) {
        tableRoot.firstChild.remove();
    }

    const firstDataPoint = args.tooltip.dataPoints && args.tooltip.dataPoints[0];

    if(!firstDataPoint) return;

    const data: any = firstDataPoint.dataset;
    const index: number = firstDataPoint.dataIndex;

    if (data.pointRadius[index] === 0) return;

    if (!data.breakdowns || data.breakdowns.length <= index) return
    // Create tooltip's header.
    const titleLines = args.tooltip.title || [];
    const tableHead = document.createElement("thead");

    titleLines.forEach((title) => {
        // Create a row for header.
        const tr = document.createElement("tr");
        tr.style.backgroundColor = data.color ?? ruminatiColors.orange;
        tr.style.color = ruminatiColors.bone;
        tr.style.fontWeight = "500";

        // Label cell.
        const th = document.createElement("th");
        th.style.padding = "8px 8px";
        th.style.borderBottom = `1px solid ${ruminatiColors.effective_black_30}`;
        th.style.width = "100%";
        th.colSpan = 2;
        th.style.textAlign = "left";
        th.style.fontWeight = "500";
        th.appendChild(document.createTextNode(`${data.label}`));

        // Year cell.
        const th2 = document.createElement("th");
        th2.style.padding = "8px 8px";
        th2.style.borderBottom = `1px solid ${ruminatiColors.effective_black_30}`;
        th2.style.width = "100%";
        th2.colSpan = 1;
        th2.style.fontWeight = "500";
        th2.appendChild(document.createTextNode(`${title}`));

        // Add all cells to the current row.
        tr.appendChild(th);
        tr.appendChild(th2);

        // Add current row to table header.
        tableHead.appendChild(tr);
    });

    // Create tooltip's body.
    const tableBody = document.createElement("tbody");

    const breakdown = data.breakdowns[index];

    let total: number = 0;
    for (const key in breakdown) {
        total += breakdown[key] as number;
    }

    for (const key in breakdown) {
        // Create a row for each breakdown element.
        const tr = document.createElement("tr");
        tr.style.backgroundColor = "inherit";
        tr.style.borderWidth = "1px";
        tr.style.color = ruminatiColors.green_3;

        // Label cell.
        const td = document.createElement("td");
        td.style.padding = "4px 8px";
        td.style.whiteSpace = "nowrap";
        td.style.borderBottom = `1px solid ${ruminatiColors.effective_black_30}`;
        td.appendChild(document.createTextNode(`${key}`));

        // Value/Total cell.
        const td1 = document.createElement("td");
        td1.style.padding = "4px 8px";
        td1.style.borderSpacing = "0";
        td1.style.borderBottom = `1px solid ${ruminatiColors.effective_black_30}`;
        td1.style.textAlign = "right";
        td1.appendChild(
            document.createTextNode(`${formatReportPercent(breakdown[key] / total, true)}`)
        );

        // Value cell.
        const td2 = document.createElement("td");
        td2.style.padding = "4px 8px";
        td2.style.borderSpacing = "0";
        td2.style.borderBottom = `1px solid ${ruminatiColors.effective_black_30}`;
        td2.style.textAlign = "right";
        td2.appendChild(document.createTextNode(`${formatReportNumber(breakdown[key], { maxDecimalPlaces: 2 }, true)}`));

        // Add all cells to the current row.
        tr.appendChild(td);
        tr.appendChild(td1);
        tr.appendChild(td2);

        // Add current row to table body.
        tableBody.appendChild(tr);


        // Add new tooltip.
        tableRoot!.appendChild(tableHead);
        tableRoot!.appendChild(tableBody);
    }


    const position = args.chart.canvas.getBoundingClientRect();
    const bodyFont = "TTInterfaces";

    // Tooltip container's styling & position.
    tooltipEl.style.border = `1px solid ${ruminatiColors.effective_black_30}`;
    tooltipEl.style.borderBottom = `none`;

    tooltipEl.style.width = "200px";
    tooltipEl.style.boxSizing = "border-box";
    tooltipEl.style.borderRadius = "4px";
    tooltipEl.style.backgroundColor = ruminatiColors.bone;
    tooltipEl.style.opacity = "100%";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.left = position.left + window.scrollX + tooltipModel.caretX + "px";
    tooltipEl.style.top = position.top + window.scrollY + tooltipModel.caretY + "px";

    // Tooltip's font.
    tooltipEl.style.fontFamily = bodyFont;
    tooltipEl.style.fontWeight = "500";
    tooltipEl.style.fontSize = "12px";
    tooltipEl.style.lineHeight = "14px";

    // Disable pointer event on tooltip.
    tooltipEl.style.pointerEvents = "none";
};
