import { usePopupStore } from "../../state/popup_store";
import { Property, Report } from "../../models/property_models";
import { useDatabaseStore } from "../../state/database_store";
import { getPropertyFromReport, getReport } from "../../utilities/functions";

import { useParams } from "react-router-dom";
import SubButton from "./sub_button";

type ElectricityUseButtonProps = {
    state?: any;
    onSubmit: (val: any) => void;
};

/**
 * A button which shows the ElectricityUse popup on click
 */
export default function ElectricityUseButton(props: ElectricityUseButtonProps) {
    const popupStore = usePopupStore();
    const databaseStore = useDatabaseStore();
    const { reportId } = useParams();
    const disabled = props.state.get.electricitySource === 2
    
    const report: Report | undefined = getReport(
        databaseStore.reports,
        reportId
    );
    const property: Property | undefined = getPropertyFromReport(
        databaseStore.properties,
        report
    );

    return (
        <div style={{ padding: "0 0 0 8px" }}>
            <SubButton
                colorScheme="orange"
                disabled={disabled}
                onClick={() =>
                    popupStore.addPopup(
                        'ElectricityUsePopup',
                        props.onSubmit,
                        {
                            localityId: property?.stateId
                        }
                    )
                }
            >
                Calculate
            </SubButton>
        </div>
    );
}
